import { faInfoCircle } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Coding, Organization, Practitioner } from "fhir"
import { useFormikContext } from "formik"
import { IconField } from "primereact/iconfield"
import { InputIcon } from "primereact/inputicon"
import { Tooltip } from "primereact/tooltip"
import { FC, useMemo } from "react"

import { DropdownField, InputField, PractitionerApi, SuggestionsEntity, ValueSetIds } from "commons"
import { useValueSet } from "value-set"

import { AutoCompleteAddressField } from "./AutoCompleteAddressField"

const AddressField: FC<Props> = ({ parentFieldName, showAutoComplete = true, disabled = false, context }) => {
  const { codes: states } = useValueSet({ valueSetId: ValueSetIds.CONTINENTAL_USA_STATES })
  const { address, setFieldValue } = useAddressContext(context)

  const parentFieldFullName = parentFieldName ? parentFieldName + "." : ""

  const isPoBoxAddress = useMemo(
    () => /\b(?:[Pp]\.?\s*[Oo]\.?|post\s+office)(\s+)?(?:[Bb]ox|[0-9]*)?\b/g.test(address?.line?.[0] ?? ""),
    [address?.line],
  )

  const handleAddressAutoComplete = (suggestion: SuggestionsEntity) => {
    Object.entries(suggestion).forEach(([key, value]) => {
      switch (key) {
        case "street_line": {
          setFieldValue(`${parentFieldFullName}line[0]`, value)
          break
        }

        case "secondary": {
          setFieldValue(`${parentFieldFullName}line[1]`, value)
          break
        }

        case "zipcode": {
          setFieldValue(`${parentFieldFullName}postalCode`, value)
          break
        }

        case "city":
          setFieldValue(`${parentFieldFullName}city`, value)
          break

        case "state": {
          setFieldValue(`${parentFieldFullName}state`, value)
          break
        }
      }
    })
  }

  return (
    <>
      <div className="@container col-span-2 w-full">
        <IconField iconPosition="right">
          {showAutoComplete ? (
            <AutoCompleteAddressField
              field={`${parentFieldFullName}line[0]`}
              onAutoCompleteAddress={handleAddressAutoComplete}
              label="Address Line 1"
              placeholder={`Enter street ` + `\u0430` + `ddress`}
              isReadonly={disabled}
              className="relative"
            />
          ) : (
            <InputField
              field={`${parentFieldFullName}line[0]`}
              label="Address Line 1"
              placeholder={`Enter street ` + `\u0430` + `ddress`}
              disabled={disabled}
              autocomplete="off"
              type="text"
              className="relative"
            />
          )}
          {!!isPoBoxAddress && (
            <InputIcon className="flex items-center translate-y-2">
              <Tooltip
                target=".tooltiped"
                event="hover"
                position="left"
                content="It looks like you're trying to set up a P.O. Box address. Please note that P.O. Boxes may not be suitable for faster deliveries."
              />
              <FontAwesomeIcon icon={faInfoCircle} className="text-orange-500 cursor-pointer tooltiped" />
            </InputIcon>
          )}
        </IconField>
        <InputField
          field={`${parentFieldFullName}line[1]`}
          label="Address Line 2"
          disabled={disabled}
          type="text"
          autocomplete="off"
        />
        <div className="p-fluid grid grid-cols-1 gap-4 @sm:grid-cols-3">
          <InputField
            field={`${parentFieldFullName}city`}
            label="City"
            className="grow"
            disabled={disabled}
            autocomplete="off"
            type="text"
          />
          <DropdownField
            field={`${parentFieldFullName}state`}
            label="State"
            className="grow"
            options={states as Coding[]}
            optionLabel="display"
            disabled={disabled}
          />
          <InputField
            field={`${parentFieldFullName}postalCode`}
            label="ZIP Code"
            className="grow"
            disabled={disabled}
            autocomplete="off"
            type="text"
          />
        </div>
      </div>
    </>
  )
}

const useAddressContext = (context: AddressContextType) => {
  const { setFieldValue, values } = useFormikContext()

  const address = useMemo(() => {
    switch (true) {
      case context === "Practitioner":
        return (values as Practitioner).address?.[0]
      case context === "Organization":
        return (values as Organization).address?.[0]
      case context === "PractitionerApi":
        return (values as PractitionerApi).practitioner.address?.[0]
      default:
        return undefined
    }
  }, [context, values])

  return { address, setFieldValue }
}

type AddressContextType = "Organization" | "PractitionerApi" | "Practitioner"

type Props = {
  context: AddressContextType
  parentFieldName?: string
  showAutoComplete?: boolean
  disabled?: boolean
}

export { AddressField }
