import { IconDefinition, IconName } from "@fortawesome/fontawesome-svg-core"

import { Button } from "commons"

const FooterActions = ({ actions, actionsDisabled = false }: Props) => {
  return (
    <div className="flex flex-shrink-0 justify-end gap-3 px-4 py-4  border-gray-100 border-t-2">
      {actions.map(({ label, command, disabled, style = "primary", title, loading = false, icon, className }, key) => (
        <Button
          key={key}
          buttonStyle={style as "default" | "primary" | "floating" | "outlined" | "text"}
          label={label}
          size="lg"
          disabled={disabled || actionsDisabled}
          title={title}
          onClick={command}
          loading={loading}
          icon={icon}
          className={className}
        />
      ))}
    </div>
  )
}

type Props = {
  actions: {
    label: string
    command: () => void
    disabled?: boolean
    style?: string
    icon?: IconDefinition | IconName
    title?: string
    loading?: boolean
    className?: string
  }[]
  actionsDisabled?: boolean
}
export { FooterActions }
