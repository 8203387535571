import { getFirstEmail, getFirstPhone } from "fhir/utils"

import { EmailField, PhoneField, PractitionerApi } from "commons"
import { useUpdatePractitioner } from "practitioners/hooks"
import { useLoginContext } from "security"

import { InformationCard } from "./InformationCard"
import { sanitize, telecomValidationSchema } from "./validations"

const ContactInformation = () => {
  const { logedInPractitioner, reloadPractitioner } = useLoginContext()
  const { updatePractitioner, isUpdating } = useUpdatePractitioner(reloadPractitioner)

  const onSubmit = (practitionerData: PractitionerApi) => updatePractitioner(sanitize(practitionerData))

  return (
    <InformationCard
      title="Contact Information"
      data={{
        Email: getFirstEmail(logedInPractitioner.practitioner.telecom),
        Telephone: getFirstPhone(logedInPractitioner.practitioner.telecom),
      }}
      initialValue={logedInPractitioner}
      validationSchema={telecomValidationSchema}
      isUpdating={isUpdating}
      onSubmit={onSubmit}
    >
      <EmailField
        field="practitioner.telecom[0].value"
        label="Email"
        isPractitionerRole
        validateDuplicate={true}
        initialValue={getFirstEmail(logedInPractitioner.practitioner.telecom)}
      />
      <PhoneField field="practitioner.telecom[1].value" label="Phone" className="md:col-span-2" />
    </InformationCard>
  )
}

export { ContactInformation }
