import { CodeableConcept, type Coding } from "fhir"
import { SYSTEM_VALUES } from "system-values"

const medicineClassificationCodings = [
  { code: "mental-wellness", system: "http://chartedhealth.com/fhir/therapeutic-class", display: "Mental wellness" },
  { code: "non-sterile", system: "http://chartedhealth.com/fhir/therapeutic-class", display: "Non sterile" },
  { code: "peptides", system: "http://chartedhealth.com/fhir/therapeutic-class", display: "Peptides" },
  {
    code: "sterile",
    system: "http://chartedhealth.com/fhir/therapeutic-class",
    display: "Sterile",
  },
] as Coding[]

const requisitionEmailEnabled: CodeableConcept = {
  text: "Requisition email enabled",
  coding: [
    {
      code: "requisition-email-enabled",
      system: SYSTEM_VALUES.SETTING_DEFINITION_FLAG,
      display: "requisition-email-enabled",
    },
  ],
}

export { medicineClassificationCodings, requisitionEmailEnabled }
