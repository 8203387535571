import { SubsNotification } from "fhir"
import { classNames } from "primereact/utils"
import { Chip } from "primereact/chip"

import { SubsNotificationRow } from "./SubsNotificationRow"

const SubsNotificationDetails = ({ subsNotification }: Props) => (
  <div className="grow grid grid-cols-5 h-full px-4">
    <div className={classNames("mt-3 overflow-auto col-span-5")}>
      <fieldset className="ml-4">
        <SubsNotificationRow title="Notification id" content={subsNotification?.id} />
        <SubsNotificationRow
          title="Notification status"
          content={
            <p title="Status">
              <Chip
                label={subsNotification?.status}
                className={classNames("ml-2 custom-chip", {
                  "is-danger": subsNotification?.status === "failed",
                  "is-success": subsNotification?.status === "success",
                })}
              />
            </p>
          }
        />
        <SubsNotificationRow title="Duration" content={`${subsNotification?.duration} ms`} />
      </fieldset>

      <fieldset className="ml-4">
        <label className="-ml-4">SubsNotification</label>
        {subsNotification?.notification?.resource?.id ? (
          <SubsNotificationRow title="Id" content={subsNotification?.notification?.resource?.id} />
        ) : null}
        {subsNotification?.notification?.resource?.owner ? (
          <SubsNotificationRow title="Owner" content={subsNotification?.notification?.resource?.owner?.display} />
        ) : null}
        {subsNotification?.notification?.resource?.for ? (
          <SubsNotificationRow
            title={subsNotification?.notification?.resource?.for?.resourceType ?? ""}
            content={subsNotification?.notification?.resource?.for?.id}
          />
        ) : null}
        {subsNotification?.notification?.resource?.subject?.[0] ? (
          <SubsNotificationRow
            title={subsNotification?.notification?.resource?.subject[0].resourceType ?? ""}
            content={
              subsNotification?.notification?.resource?.subject?.[0].display ??
              subsNotification?.notification?.resource?.subject?.[0].id
            }
          />
        ) : null}
        {subsNotification?.notification?.resource?.defaultCreditCard ? (
          <SubsNotificationRow
            title="Default credit card"
            content={subsNotification?.notification?.resource?.defaultCreditCard}
          />
        ) : null}
        {subsNotification?.notification?.resource?.description ? (
          <SubsNotificationRow title="Description" content={subsNotification?.notification?.resource?.description} />
        ) : null}
        {subsNotification?.notification?.resource?.statusReason ? (
          <SubsNotificationRow
            title="Status reason"
            content={subsNotification?.notification?.resource?.statusReason?.text}
          />
        ) : null}
        {subsNotification?.notification?.resource?.resourceType ? (
          <SubsNotificationRow title="Resource type" content={subsNotification?.notification?.resource?.resourceType} />
        ) : null}

        <SubsNotificationRow title="Type" content={subsNotification?.notification?.type} />
      </fieldset>
      {subsNotification?.response ? (
        <fieldset className="ml-4">
          <label className="-ml-4">Response</label>
          <SubsNotificationRow title="Status" content={subsNotification?.response?.status} />
          {subsNotification?.response?.body?.title ? (
            <SubsNotificationRow title="Description" content={subsNotification?.response.body.title} />
          ) : null}
          {subsNotification?.response?.body?.traceId ? (
            <SubsNotificationRow title="Trace id" content={subsNotification?.response.body.traceId} />
          ) : null}
          {subsNotification?.response?.body?.type ? (
            <SubsNotificationRow title="Type" content={subsNotification?.response.body.type} />
          ) : null}
        </fieldset>
      ) : null}

      <fieldset className="ml-4">
        <label className="-ml-4">Subscription</label>
        <SubsNotificationRow title="Subscription id" content={subsNotification?.subscription?.id} />
      </fieldset>
      {subsNotification?.error ? (
        <fieldset className="ml-4">
          <label className="-ml-4">Error</label>
          <SubsNotificationRow title="Error message" content={subsNotification?.error.message} />
        </fieldset>
      ) : null}
    </div>
  </div>
)

type Props = {
  subsNotification?: SubsNotification
}

export { SubsNotificationDetails }
