import BigNumber from "bignumber.js"
import { getTypeInfo, types } from "credit-card-type"
import { format as dateFnFormat, parseISO } from "date-fns"
import {
  Address,
  ChargeItemDefinition,
  ChargeItemDefinitionPropertyGroupArrayPriceComponentArray,
  Coding,
  Device,
  isCoverage,
  isMedicationKnowledge,
  isMedicationRequest,
  isResourceObject,
  Location,
  MedicationKnowledge,
  MedicationRequest,
  ParametersParameterArrayValue,
  PractitionerRole,
  ResourceObject,
  ServiceRequest,
} from "fhir"
import { createElement } from "react"
import { toast } from "react-toastify"
import * as Yup from "yup"
import { AnyObject } from "yup/lib/types"

import { BadgeProps, NotificationSuccess } from "commons"
import { BILLING_TYPES_CODES, formatsByTypes, PRACTITIONER_ROLE } from "data"

const strCapitalize = (str: string) => (str ? `${str[0].toUpperCase()}${str.substring(1)}` : "")

const humanNameSchema = (parentFieldName?: string) => {
  const parentFullFieldName = parentFieldName ? parentFieldName + "." : ""

  return Yup.object().shape({
    given: Yup.array()
      .of(
        Yup.string().test("test-first-name", "First name is required", (value, context) => {
          return context?.path === `${parentFullFieldName}name[0].given[0]` ? value !== undefined && value !== "" : true
        }),
      )
      .min(1, "Name is required"),
    family: Yup.string().required("Last name is required"),
  })
}

const getRequiredAddressSchema = () => {
  const hasLine1 = (value: string[] | undefined, { createError, path }: Yup.TestContext<AnyObject>) =>
    !value?.[0]?.trim() ? createError({ path: `${path}[0]`, message: "Line1 is required" }) : true

  return Yup.object().shape({
    line: Yup.array().test("line1-check", hasLine1),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    postalCode: Yup.string().required("ZIP is required"),
  })
}

const getNotRequiredAddressSchema = (isRequired?: boolean) => {
  const hasLine2 = (value: string[] | undefined, { createError, path }: Yup.TestContext<AnyObject>) =>
    value?.[1]?.trim() && !value?.[0]?.trim() ? createError({ path: `${path}[0]`, message: "Line1 is required" }) : true

  const hasLine1 = (value: string[] | undefined, { createError, path }: Yup.TestContext<AnyObject>) =>
    !value?.[0]?.trim() ? createError({ path: `${path}[0]`, message: "Line1 is required" }) : true

  const lineConditions = (city: string, state: string, postalCode: string) =>
    city?.trim() || state?.trim() || postalCode?.trim()

  const cityConditions = (line: string[], state: string, postalCode: string) =>
    line?.[0]?.trim() || line?.[1]?.trim() || state?.trim() || postalCode?.trim()

  const stateConditions = (line: string[], city: string, postalCode: string) =>
    line?.[0]?.trim() || line?.[1]?.trim() || city?.trim() || postalCode?.trim()

  const postalCodeConditions = (line: string[], city: string, state: string) =>
    line?.[0]?.trim() || line?.[1]?.trim() || city?.trim() || state?.trim()

  return Yup.object().shape(
    {
      line: Yup.array()
        .test("line2-check", hasLine2)
        .when(["city", "state", "postalCode"], {
          is: (city: string, state: string, postalCode: string) =>
            (isRequired && lineConditions(city, state, postalCode)) || lineConditions(city, state, postalCode),
          then: Yup.array().test("line1-validation", hasLine1),
        }),
      city: Yup.string().when(["line", "state", "postalCode"], {
        is: (line: string[], state: string, postalCode: string) =>
          (isRequired && cityConditions(line, state, postalCode)) || cityConditions(line, state, postalCode),
        then: Yup.string().required("City is required"),
      }),
      state: Yup.string().when(["line", "city", "postalCode"], {
        is: (line: string[], city: string, postalCode: string) =>
          (isRequired && stateConditions(line, city, postalCode)) || stateConditions(line, city, postalCode),
        then: Yup.string().required("State is required"),
      }),
      postalCode: Yup.string().when(["line", "city", "state"], {
        is: (line: string[], city: string, state: string) =>
          (isRequired && postalCodeConditions(line, city, state)) || postalCodeConditions(line, city, state),
        then: Yup.string().required("ZIP is required"),
      }),
    },
    [
      ["line", "state"],
      ["line", "postalCode"],
      ["line", "city"],
      ["city", "state"],
      ["city", "postalCode"],
      ["state", "postalCode"],
    ],
  )
}

const telecomSchema = Yup.object().shape({
  system: Yup.string()
    .oneOf(["phone", "fax", "email", "pager", "url", "sms", "other"], "Invalid value")
    .required("Specify telecom system"),
  use: Yup.string()
    .oneOf(["home", "work", "temp", "old", "mobile"], "Invalid value")
    .required("Specify this telecom usage"),
  value: Yup.string().when("system", (system, yup) => system && yup.required(`${strCapitalize(system)} is required`)),
})

const notPhoneRequiredTelecomSchema = Yup.object().shape({
  value: Yup.string().when("system", (system, yup) => {
    if (system && system !== "phone") return yup.required(`${strCapitalize(system)} is required`)
  }),
})

const displayNotificationSuccess = (message: string) => {
  if (window.innerWidth > 768) {
    toast.success(createElement(NotificationSuccess, { message }), { autoClose: 2000 })
  } else {
    toast.success(createElement(NotificationSuccess, { message }), {
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      style: { bottom: "4rem", minHeight: 0 },
    })
  }
}

const getDefaultAvatar = (name: string, bg: string) => {
  return `${window.VITE_APP_AVATAR_SERVICE_URL}/9.x/initials/svg?seed=${name}&backgroundColor=${bg}`
}

const convertCCType = (type?: string) => {
  switch (type) {
    case "AE":
      return types.AMERICAN_EXPRESS
    case "V":
      return types.VISA
    case "MC":
      return types.MASTERCARD
    case "D":
      return types.DISCOVER
    case "DC":
      return types.DINERS_CLUB
    case "JCB":
      return types.JCB
    default:
      return "unsuported"
  }
}

const formatCreditCardNumber = (cardNumber: string, type?: string, asMask = false) => {
  const card = getTypeInfo(convertCCType(type))

  if (card && cardNumber?.length > 0) {
    const expectedCardLength = card?.lengths?.[0]
    if (cardNumber.length < expectedCardLength) {
      cardNumber = `${`${asMask ? "9" : "X"}`.repeat(expectedCardLength - cardNumber.length)}${cardNumber}`
    }

    const offsets = ([] as number[]).concat(0, card.gaps, cardNumber.length)
    const components = [] as string[]

    for (let i = 0; offsets[i] < cardNumber.length; i++) {
      const start = offsets[i]
      const end = Math.min(offsets[i + 1], cardNumber.length)
      components.push(cardNumber.substring(start, end))
    }

    return components.join("-")
  }

  return cardNumber
}

function calculateAge(birthday: string | undefined) {
  if (!birthday) {
    return "unknown age"
  }

  const date = new Date(birthday)
  const ageDifMs = Date.now() - date.getTime()
  const ageDate = new Date(ageDifMs)

  return `${Math.abs(ageDate.getUTCFullYear() - 1970)}yo`
}

const getBadgeColor = (text: string): BadgeProps => {
  switch (text.toLowerCase()) {
    case "completed":
    case "resolved":
    case "active":
    case "balanced":
    case "delivered":
    case "open":
    case "click":
    case "final results available":
      return { text, colorStyle: "green" }

    case "stopped":
    case "not-done":
    case "recurrence":
    case "cancelled":
    case "entered-in-error":
    case "revoked":
    case "dropped":
    case "spam":
    case "unsubscribe":
      return { text: text === "revoked" ? "cancelled" : text, colorStyle: "red" }
    case "draft":
    case "bounce":
    case "deferred":
    case "requisition pending":
      return { text, colorStyle: "yellow" }

    case "issued":
    case "in-progress":
    case "processed":
    case "preliminary results":
      return { text, colorStyle: "blue" }
    case "requisition available":
      return { text, colorStyle: "orange" }
    case "retired":
      return { text: "archived", colorStyle: "gray" }
    default:
      return { text, colorStyle: "gray" }
  }
}

const emptyAddress: Address = {
  country: "US",
  line: ["", ""],
  city: "",
  state: "",
  postalCode: "",
  use: "home",
}

const isAbortError = (reason: unknown): reason is DOMException =>
  reason instanceof DOMException && reason.name === "AbortError"

const copyToClipboard = async (trace: string, onSuccess?: () => void) => {
  navigator.clipboard
    .writeText(trace)
    .then(onSuccess)
    .catch(() => {
      console.error("Failed to copy. Check navigator permits.")
      /* Rejected - text failed to copy to the clipboard */
    })
}

const sanitizeURL = (url: string) => {
  const splittedUrl = url.split("https://")
  const sanitizedUrl = splittedUrl[0].concat(splittedUrl[1]?.replaceAll("//", "/") ?? "")
  return sanitizedUrl.startsWith("/") ? sanitizedUrl.slice(1) : sanitizedUrl
}

const IsNetworkError = (message: string) => {
  const errorMessages = new Set([
    "Failed to fetch", // Chrome
    "NetworkError when attempting to fetch resource.", // Firefox
    "The Internet connection appears to be offline.", // Safari 16
    "Load failed", // Safari 17+
    "Network request failed", // `cross-fetch`
    "fetch failed", // Undici (Node.js)
  ])

  return errorMessages.has(message)
}

const isLocation = (
  resource?: object[] | boolean | number | number | null | ResourceObject | string,
): resource is Location => isResourceObject(resource) && resource.resourceType === "Location"

const isDevice = (
  resource?: object[] | boolean | number | number | null | ResourceObject | string,
): resource is Device => isResourceObject(resource) && resource.resourceType === "Device"

const formatDate = (date: Date, format = "yyyy-MM-dd") => dateFnFormat(date, format)

const formatStringDate = (date?: string, format = formatsByTypes.LONG_DATE) => {
  return date ? formatDate(parseISO(date), format) : undefined
}

const isPRPractitioner = (pr: PractitionerRole) =>
  pr.code?.some(({ coding }) => coding?.[0]?.code === PRACTITIONER_ROLE.PRACTITIONER)

const getCidIdentifier = (code: string, factor = 1) => `${code}-${factor}`

const PRICE_SUB_SYSTEM = "sku"
const PRICE_SUB_SYSTEM_PROP61 = "sku-ca"
const priceSubSystems = [PRICE_SUB_SYSTEM_PROP61]
const lineInvoiceTypes = {
  TAX: "tax",
  FEE: "surcharge",
  DISCOUNT: "discount",
  BASE: "base",
  INFORMATIONAL: "informational",
}

const getCommonCode = ({
  codes,
  shippingAddressState,
  fallback = "no-code",
}: {
  codes: Coding[] | undefined
  shippingAddressState?: string
  fallback?: string
}) => {
  const priceSubSystemStateSuffix = shippingAddressState ? `-${shippingAddressState.toLowerCase()}` : null

  const priceSubSystemForState = priceSubSystemStateSuffix
    ? priceSubSystems.find((subSystem) => subSystem.endsWith(priceSubSystemStateSuffix))
    : null

  const effectivePriceSubSystem = priceSubSystemForState || PRICE_SUB_SYSTEM
  const effectiveCode =
    codes?.find(({ system }) => system?.includes(effectivePriceSubSystem))?.code ||
    codes?.find(({ system }) => system?.includes(PRICE_SUB_SYSTEM))?.code

  return effectiveCode ?? fallback
}

const getIndexedCID = (cids?: ChargeItemDefinition[]) =>
  cids?.reduce<Record<string, ChargeItemDefinition>>((prev, cid) => {
    return {
      ...prev,
      [getCidIdentifier(
        getCommonCode({ codes: cid.code?.coding }),
        cid?.propertyGroup?.[0]?.priceComponent?.[0]?.factor,
      )]: cid,
    }
  }, {}) ?? {}

const getBasePrice = (priceComponent?: ChargeItemDefinitionPropertyGroupArrayPriceComponentArray[]) => {
  return priceComponent?.find((price) => price.type === lineInvoiceTypes.BASE)
}

const getBillToPatientFeePrice = (priceComponent?: ChargeItemDefinitionPropertyGroupArrayPriceComponentArray[]) => {
  return priceComponent?.find(
    (price) =>
      price.type === lineInvoiceTypes.INFORMATIONAL && price.code?.coding?.some((c) => c.code === "bill-patient"),
  )
}

const getMedCodes = ({
  meds,
  withQty = false,
}: {
  meds?: Array<MedicationRequest | MedicationKnowledge>
  withQty?: boolean
}) =>
  meds?.reduce<Coding[] | ParametersParameterArrayValue[]>(
    (prev, med) =>
      isMedicationRequest(med) && med.medication?.CodeableConcept?.coding
        ? withQty
          ? ([
              ...prev,
              ...med.medication.CodeableConcept.coding.map((c) => ({
                Coding: c,
                Quantity: med.dispenseRequest?.quantity,
              })),
            ] as ParametersParameterArrayValue[])
          : ([...prev, ...med.medication.CodeableConcept.coding] as Coding[])
        : isMedicationKnowledge(med) && med.code?.coding
          ? [...prev, ...med.code.coding]
          : prev,
    [],
  )

const sumPrice = (num1: number | BigNumber, num2: number | BigNumber) => {
  const bNum1 = new BigNumber(num1)
  const bNum2 = new BigNumber(num2)
  const sum = bNum1.plus(bNum2)

  return { num1: bNum1, num2: bNum2, sum }
}

const substractPrice = (num1: number | BigNumber, num2: number | BigNumber) => {
  const bNum1 = new BigNumber(num1)
  const bNum2 = new BigNumber(num2)
  const sub = bNum1.minus(bNum2)

  return { num1: bNum1, num2: bNum2, sub }
}

const getMoneyCurrencyAlt = (currency?: string) => {
  switch (currency) {
    case "USD":
      return "$"
    case "EUR":
      return "€"

    default:
      return "$"
  }
}

const getServiceRequestBillingType = (sr?: ServiceRequest): BILLING_TYPES_CODES => {
  const billingType = sr?.insurance?.[0].localRef
    ? isCoverage(sr?.contained?.[0]) && sr?.contained?.[0]?.payor?.[0]?.resourceType === "Patient"
      ? BILLING_TYPES_CODES.BILL_PATIENT
      : BILLING_TYPES_CODES.BILL_PRACTICE
    : BILLING_TYPES_CODES.INSURANCE

  return billingType
}
const enqueue = <T extends AnyObject>(list: T[], newElement: T, type: string) => {
  let added = false
  for (let i = 0; i < list.length && !added; i++) {
    if (getFirstName(list[i], type)?.localeCompare(getFirstName(newElement, type)) === 1) {
      list.splice(i, 0, newElement)
      added = true
    }
  }
  if (!added) list.push(newElement)
}

const getFirstName = <T extends AnyObject>(element: T, type: string) => {
  switch (type) {
    case "PatientInfo":
      return element.patient?.name?.[0].given?.[0]
    case "PractitionerApi":
      return element.practitioner?.name?.[0].given?.[0]
    default:
      return element.name
  }
}

const getStringAddress = (address?: Address) => {
  if (!address) {
    return "Unspecified address"
  }

  const { line, city, state, country, postalCode } = address ?? {}

  return Array.from([line, city, state, country, postalCode])
    .flat()
    .filter((d) => d && d !== "")
    .join(", ")
}

export {
  calculateAge,
  copyToClipboard,
  displayNotificationSuccess,
  emptyAddress,
  formatCreditCardNumber,
  formatDate,
  formatStringDate,
  getBadgeColor,
  getBasePrice,
  getBillToPatientFeePrice,
  getCidIdentifier,
  getCommonCode,
  getDefaultAvatar,
  getIndexedCID,
  getMedCodes,
  getMoneyCurrencyAlt,
  getNotRequiredAddressSchema,
  getRequiredAddressSchema,
  getServiceRequestBillingType,
  humanNameSchema,
  isAbortError,
  isDevice,
  isLocation,
  IsNetworkError,
  isPRPractitioner,
  notPhoneRequiredTelecomSchema,
  sanitizeURL,
  strCapitalize,
  substractPrice,
  sumPrice,
  telecomSchema,
  enqueue,
  getStringAddress,
}
