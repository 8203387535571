const ordersQueryKeys = {
  all: ["organization-orders"] as const,
  list: (
    organizationId?: string,
    category?: string,
    patientId?: string,
    performer?: string,
    status?: string,
    authored?: Date,
    occurrence?: Date,
    searchText?: string,
  ) => [
    ...ordersQueryKeys.all,
    ...(organizationId ? [organizationId] : []),
    ...(category ? [category] : []),
    ...(patientId ? [patientId] : []),
    ...(performer ? [performer] : []),
    ...(status ? [status] : []),
    ...(authored ? [authored] : []),
    ...(occurrence ? [occurrence] : []),
    ...(searchText ? [searchText] : []),
  ],
  listWithSettings: (
    organizationId?: string,
    category?: string,
    patientId?: string,
    performer?: string,
    status?: string,
    authored?: Date,
    occurrence?: Date,
    searchText?: string,
  ) => [
    ...ordersQueryKeys.all,
    "orders-adn-settings",
    ...(organizationId ? [organizationId] : []),
    ...(category ? [category] : []),
    ...(patientId ? [patientId] : []),
    ...(performer ? [performer] : []),
    ...(status ? [status] : []),
    ...(authored ? [authored] : []),
    ...(occurrence ? [occurrence] : []),
    ...(searchText ? [searchText] : []),
  ],
}

export { ordersQueryKeys }
