import { IconDefinition, faMemoCircleInfo, faSignOut } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { humanNameAsString } from "fhir"
import { Button } from "primereact/button"
import { OverlayPanel } from "primereact/overlaypanel"
import { useRef } from "react"
import { classNames } from "primereact/utils"

import { useAuth, useLoginContext } from "security"

import { AvatarImage } from "./AvatarImage"

const UserProfileMenu = ({ actions, avatarClassName = "h-20 w-20", className, horizontal }: Props) => {
  const { logout, user } = useAuth()
  const { logedInPractitioner } = useLoginContext()
  const { name, photo } = logedInPractitioner.practitioner ?? {}

  const op = useRef<OverlayPanel>(null)

  const logoutAction: ActionType = { label: "Logout", icon: faSignOut, command: logout }
  const seePolicyAction: ActionType = {
    label: "Policy & Terms",
    icon: faMemoCircleInfo,
    command: () => window.open("https://www.chartedhealth.com/156-2/", "_blank", "noreferrer"),
  }

  return (
    <div className={classNames("w-full relative", className)} onClick={(event) => op?.current?.toggle(event)}>
      <div className="flex ease-in-out items-center">
        <span className="sr-only">Open user menu</span>
        {horizontal ? (
          <div className="flex ml-2">
            <AvatarImage
              name={humanNameAsString(name?.[0])}
              photoUrl={photo?.[0]?.url}
              className={classNames("rounded-full", avatarClassName)}
            />
            <div className="pl-3 text-left">
              <p className="text-sm">{user?.name}</p>
              <p className="text-xs text-gray-500">{user?.email}</p>
            </div>
          </div>
        ) : (
          <AvatarImage
            name={humanNameAsString(name?.[0])}
            photoUrl={photo?.[0]?.url}
            className={classNames("rounded-full", avatarClassName)}
          />
        )}
      </div>

      <OverlayPanel ref={op} dismissable className="w-auto h-auto z-20">
        <div className="flex flex-col">
          <div className="flex items-center">
            <AvatarImage
              name={humanNameAsString(name?.[0])}
              photoUrl={photo?.[0]?.url}
              className="rounded-full h-12 w-12"
            />
            <div className="ml-3">
              <p className="font-semibold">{user?.name}</p>
              <p className="text-gray-500">{user?.email}</p>
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <hr className="my-3" />
            {[...(actions ?? []), seePolicyAction, logoutAction].map(({ command, icon, label }) => (
              <Button
                key={label}
                onClick={() => {
                  op.current?.hide()
                  setTimeout(() => command(), 200)
                }}
                className="text-xs h-8 button-default"
              >
                <div className="flex justify-center items-center flex-grow font-semibold">
                  <FontAwesomeIcon icon={icon} className="mr-2" />
                  <span>{label}</span>
                </div>
              </Button>
            ))}
          </div>
        </div>
      </OverlayPanel>
    </div>
  )
}

export type ActionType = { label: string; icon: IconDefinition; command(): void }

type Props = {
  avatarClassName?: string
  actions?: ActionType[]
  className?: string
  horizontal?: boolean
}

export { UserProfileMenu }
