import { useEffect, useReducer, useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FileUpload, FileUploadHeaderTemplateOptions } from "primereact/fileupload"
import { faBuilding, faBuildingColumns, faLocationDot, faUpload, faUserDoctor } from "@fortawesome/pro-solid-svg-icons"
import { humanNameAsString, Reference } from "fhir"
import { Sidebar } from "primereact/sidebar"
import { Dropdown } from "primereact/dropdown"

import { useOrganizationRefs } from "organizations"
import { usePractitionersOrganization } from "practitioners"
import { FooterActions } from "commons"
import { getStringAddress } from "utils"

import { useUploadPatients } from "../hooks"

const UploadPatientsModal = ({ visible, onHide }: Props) => {
  const isSmallScreen = window.innerWidth < 768
  const fileUploadRef = useRef<FileUpload>(null)
  const {
    selectedFile,
    selectedOrg,
    selectedPract,
    fileError,
    fileTypeError,
    orgError,
    practError,
    reset,
    setFileError,
    setFileTypeError,
    setOrgError,
    setPractError,
    setSelectedFile,
    setSelectedOrg,
    setSelectedPract,
  } = useReducerState()

  const onClose = () => {
    reset()
    onHide()
  }

  const { organizations, organizationRefs } = useOrganizationRefs()
  const {
    practitioners,
    practitionerRefs,
    isLoading: isLoadingPracts,
  } = usePractitionersOrganization({ organizationId: selectedOrg?.id ?? "" })
  const { uploadPatients, isUploading } = useUploadPatients(onClose)

  const onClick = () => {
    if (!selectedFile) setFileError(true)
    if (!selectedOrg) setOrgError(true)
    if (!selectedPract) setPractError(true)

    if (selectedFile && selectedOrg && selectedPract && selectedFile?.type.split("/")[1] === "csv") {
      uploadPatients({
        file: selectedFile as File,
        organization: selectedOrg ?? {},
        practitioner: selectedPract ?? {},
      })
    }
  }

  useEffect(() => {
    if (selectedFile && selectedFile?.type.split("/")[1] !== "csv") {
      setFileError(false)
      setFileTypeError(true)
    }
  }, [selectedFile])

  const orgTemplate = (option: Reference) => {
    const org = organizations?.[option.id!]
    return (
      org && (
        <div className="text-sm md:w-[30vw]">
          <div className="font-bold">
            <FontAwesomeIcon icon={faBuilding} className="fa-fw mr-1" />
            <span title="Organization" className="text-base">
              {`${org.name}`}
            </span>
          </div>
          <div title="Part of">
            <FontAwesomeIcon icon={faBuildingColumns} className="fa-fw mr-1" />
            <span title="Part of" className="text-base">
              {`[${org.partOf?.display ?? "root"}]`}
            </span>
          </div>
          <div title="Address">
            <FontAwesomeIcon icon={faLocationDot} className="fa-fw mr-1" />
            {getStringAddress(org.address?.[0])}
          </div>
        </div>
      )
    )
  }
  const practTemplate = (option: Reference) => {
    const pract = practitioners?.[option.id!]
    return (
      <div className="text-sm">
        <div className="font-bold">
          <FontAwesomeIcon icon={faUserDoctor} className="fa-fw mr-1" />
          <span title="Practitioner" className="text-base">
            {`${humanNameAsString(pract?.name?.[0])}`}
          </span>
        </div>
      </div>
    )
  }
  const footerActions = [
    {
      label: "Cancel",
      command: onClose,
      className: "button-default border-gray-200 ring-gray-200 hover:ring-gray-200 hover:bg-gray-300",
    },
    { label: "Import", command: onClick, icon: faUpload },
  ]

  const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
    const { className, chooseButton, cancelButton } = options
    return (
      <div className={className} style={{ backgroundColor: "transparent", display: "flex", alignItems: "center" }}>
        {chooseButton}
        {cancelButton}
      </div>
    )
  }
  const itemTemplate = (inFile: object) => {
    const file = inFile as File
    const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(file.size) : "0 B"
    return (
      <div className="flex align-items-center flex-wrap border-red-200">
        <div className="flex align-items-center flex-col" style={{ width: "100%" }}>
          <span className="flex flex-column text-left ml-3">{file.name}</span>
          <span className="flex flex-column text-left ml-3">
            <small>{`Size: ${formatedValue}`}</small>
          </span>
        </div>
      </div>
    )
  }

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <span className="text-gray-500 my-1">
          <small>Drag and drop csv here</small>
        </span>
      </div>
    )
  }

  const chooseOptions = {
    icon: "pi pi-fw pi-file",
    label: "Chose file",
    className: "p-button-sm bg-primary text-white border-primary hover:bg-primary-hover focus:primary-ring-shadow",
  }
  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    label: "Clear",
    className: "p-button-sm button-default",
  }

  return (
    <Sidebar
      visible={visible || isUploading}
      position={isSmallScreen ? "bottom" : "right"}
      onHide={onClose}
      className={isSmallScreen ? "h-[95%] rounded-t-xl" : "md:w-1/2 lg:w-1/3"}
      maskClassName="slideover"
      dismissable
      header={
        <div className="flex items-center justify-between">
          <div className="px-4 sm:px-6 py-6">
            <h2 className="text-lg font-semibold leading-6 text-gray-900">Import patients from CSV</h2>
          </div>
        </div>
      }
    >
      <div className="bg-white divide-gray-200 divide-y flex flex-col w-full m-2">
        <div className="flex flex-1 flex-col overflow-hidden">
          <div className="flex flex-1 flex-col overflow-y-auto">
            <div className="field space-y-2 px-3 relative">
              <label htmlFor="file" className="text-sm font-medium text-gray-700 mt-3 mx-2">
                File
              </label>
              <FileUpload
                ref={fileUploadRef}
                name="file"
                chooseOptions={chooseOptions}
                cancelOptions={cancelOptions}
                headerTemplate={headerTemplate}
                itemTemplate={itemTemplate}
                emptyTemplate={emptyTemplate}
                accept="text/csv"
                customUpload
                onSelect={(event) => {
                  setFileError(false)
                  setSelectedFile(event.files[0])
                }}
                onClear={() => {
                  setSelectedFile(undefined)
                  setFileTypeError(false)
                }}
              />
              <div className="ml-2 h-2 mt-1">
                {fileError && (
                  <div className="flex items-start p-error h-2 mt-1">
                    <small>File is required</small>
                  </div>
                )}
                {fileTypeError && (
                  <div className="flex items-start p-error h-2 mt-1">
                    <small>The file must be of type csv</small>
                  </div>
                )}
              </div>
            </div>
            <div className="field space-y-2 px-3 relative">
              <label htmlFor="organization" className="text-sm font-medium text-gray-700 mt-3 mx-2">
                Organization
              </label>
              <Dropdown
                className="w-full"
                name="organization"
                options={organizationRefs}
                optionLabel="display"
                itemTemplate={orgTemplate}
                resetFilterOnHide
                showFilterClear
                filter
                filterBy="display"
                value={selectedOrg}
                onChange={(e) => {
                  if (e.value) {
                    setOrgError(false)
                    setSelectedOrg(e.value)
                  }
                }}
              />
              <div className="ml-2 h-2 mt-1">
                {orgError && (
                  <div className="flex items-start p-error h-2 mt-1">
                    <small>Organization is required</small>
                  </div>
                )}
              </div>
            </div>
            <div className="field space-y-2 px-3 relative">
              <label htmlFor="practitioner" className="text-sm font-medium text-gray-700 mt-3 mx-2">
                Practitioner
              </label>
              <Dropdown
                className="w-full"
                name="practitioner"
                options={practitionerRefs}
                optionLabel="display"
                itemTemplate={practTemplate}
                resetFilterOnHide
                showFilterClear
                filter
                filterBy="display"
                loading={isLoadingPracts}
                disabled={!practitionerRefs?.length}
                value={selectedPract}
                onChange={(e) => {
                  if (e.value) {
                    setPractError(false)
                    setSelectedPract(e.value)
                  }
                }}
              />
              <div className="ml-2 h-2 mt-1">
                {practError && (
                  <div className="flex items-start p-error">
                    <small>Practitioner is required</small>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <FooterActions actions={footerActions} />
      </div>
    </Sidebar>
  )
}
const initialState = {
  selectedFile: undefined,
  selectedOrg: undefined,
  selectedPract: undefined,
  fileError: false,
  fileTypeError: false,
  orgError: false,
  practError: false,
} as State

const reducer = (
  state: State,
  {
    type,
    payload,
  }: {
    type: "reset" | "fileError" | "fileTypeError" | "orgError" | "practError" | "setFile" | "setOrg" | "setPract"
    payload?: File | Reference | boolean | undefined
  },
) => {
  switch (type) {
    case "reset":
      return { ...initialState }
    case "fileError":
      return { ...state, fileError: payload as boolean }
    case "fileTypeError":
      return { ...state, fileTypeError: payload as boolean }
    case "orgError":
      return { ...state, orgError: payload as boolean }
    case "practError":
      return { ...state, practError: payload as boolean }
    case "setFile":
      return { ...state, selectedFile: payload as File }
    case "setOrg":
      return { ...state, selectedOrg: payload as Reference }
    case "setPract":
      return { ...state, selectedPract: payload as Reference }
    default:
      return state
  }
}

const useReducerState = () => {
  const state = initialState
  const [{ selectedFile, selectedOrg, selectedPract, fileError, fileTypeError, orgError, practError }, dispatch] =
    useReducer(reducer, state)

  const reset = () => {
    dispatch({ type: "reset" })
  }

  const setFileError = (payload: boolean) => {
    dispatch({ type: "fileError", payload })
  }
  const setFileTypeError = (payload: boolean) => {
    dispatch({ type: "fileTypeError", payload })
  }
  const setOrgError = (payload: boolean) => {
    dispatch({ type: "orgError", payload })
  }
  const setPractError = (payload: boolean) => {
    dispatch({ type: "practError", payload })
  }

  const setSelectedFile = (payload?: File) => {
    dispatch({ type: "setFile", payload })
  }
  const setSelectedOrg = (payload: Reference) => {
    dispatch({ type: "setOrg", payload })
  }
  const setSelectedPract = (payload: Reference) => {
    dispatch({ type: "setPract", payload })
  }

  return {
    selectedFile,
    selectedOrg,
    selectedPract,
    fileError,
    fileTypeError,
    orgError,
    practError,
    reset,
    setFileError,
    setFileTypeError,
    setOrgError,
    setPractError,
    setSelectedFile,
    setSelectedOrg,
    setSelectedPract,
  }
}

type State = {
  selectedFile?: File
  selectedOrg?: Reference
  selectedPract?: Reference
  fileError: boolean
  fileTypeError: boolean
  orgError: boolean
  practError: boolean
}

type Props = {
  visible: boolean
  onHide(): void
}

export { UploadPatientsModal }
