const organizationQueryKeys = {
  all: ["organizations"] as const,
  list: (filter: string, active?: string, root?: string) => [...organizationQueryKeys.all, filter, active, root],
  practitioners: (organizationId: string, onlyActives?: boolean) => [
    "organization/practitioners",
    organizationId,
    ...(onlyActives ? [onlyActives] : []),
  ],
  organizationId: (organizationId?: string) => [...organizationQueryKeys.all, organizationId],
  withPractitionerId: (practitionerId?: string) => [...organizationQueryKeys.all, practitionerId],
  currentOrganization: {
    all: ["currentOrganization"] as const,
    current: (organizationId: string) => [...organizationQueryKeys.currentOrganization.all, organizationId],
  },
  account: {
    all: ["organization-account"] as const,
    current: (organizationId?: string) => [...organizationQueryKeys.account.all, organizationId],
  },
  unreadMessagesCount: ["organization-unread-messages-count"],
  practitionerRole: (practitionerId: string, currentOrganizationId: string) => [
    "organization/practitionerRole",
    practitionerId,
    currentOrganizationId,
  ],
  diagnosticLabs: (filter?: string) => ["diagnosticLabs", filter],
}

export { organizationQueryKeys }
