import { SelectButton } from "primereact/selectbutton"
import { FC, useState } from "react"

import { ACCOUNT_TYPE } from "account"
import { useOrganizationContext } from "organizations"

import { PaymentMethod } from "./PaymentMethod"
import { PaymentReaders } from "./PaymentReaders"

const PaymentContainer: FC = () => {
  const { cardReadersEnabled } = useOrganizationContext()
  const [tabType, setTabType] = useState(ACCOUNT_TYPE.CREDIT_CARD_HOLDING_ACCOUNT)

  return (
    <div className="flex flex-col w-full h-full px-1.5 pt-1.5">
      {cardReadersEnabled && (
        <SelectButton
          value={tabType}
          options={[
            { name: "Credit Cards", value: ACCOUNT_TYPE.CREDIT_CARD_HOLDING_ACCOUNT },
            { name: "Readers", value: ACCOUNT_TYPE.CARD_READER_ACCOUNT },
          ]}
          optionValue="value"
          optionLabel="name"
          onChange={(e) => setTabType(e.value)}
          className="mb-5"
          allowEmpty={false}
        />
      )}
      {tabType === ACCOUNT_TYPE.CREDIT_CARD_HOLDING_ACCOUNT ? <PaymentMethod /> : <PaymentReaders />}
    </div>
  )
}

export { PaymentContainer }
