import { useMutation, useQueryClient } from "@tanstack/react-query"
import { List, Parameters, ServiceRequest } from "fhir"

import { IClient, useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { datadogLogs, registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { ordersQueryKeys } from "../query-keys"

const useRevokeLabOrder = (onSettled?: () => void, onSuccess?: () => void) => {
  const { operationRequest } = useClient(IClient.AIDBOX)
  const queryClient = useQueryClient()

  const revoke = ({ order, revokeReason }: { order: ServiceRequest; revokeReason: string }) => {
    const list: List = {
      resourceType: "List",
      mode: "working",
      status: "current",
      entry: [
        {
          item: {
            resourceType: "ServiceRequest",
            id: order.id,
          },
        },
      ],
    }

    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "orders",
          resource: list,
        },
        {
          name: "reason",
          value: {
            string: revokeReason,
          },
        },
      ],
    }

    return operationRequest(`Patient/${order.subject.id}/cpoe`, "POST", "cancel", undefined, parameters)
  }

  const { mutate, isPending } = useMutation({
    mutationFn: revoke,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, { order }) => {
      queryClient.refetchQueries({
        queryKey: ordersQueryKeys.all,
        type: "all",
      })

      displayNotificationSuccess("Order cancelled successfully")
      datadogLogs.logger.info(`Order with id ${order.id} cancelled successfully`)
      onSuccess?.()
    },
    onSettled,
  })

  return { revokeLabOrder: mutate, isRevoking: isPending }
}

export { useRevokeLabOrder }
