import { ReactNode, useEffect, useState } from "react"
import { Dialog } from "primereact/dialog"

import { Button } from "./Buttons"

const ConfirmDialog = ({
  onConfirm,
  hideDialog,
  visible,
  headerTitle = "Confirmation",
  actionName = "Accept",
  confirmText = "Are you sure you want to do this?",
  confirmElement,
  isLoading,
  waitToFinish,
}: Props) => {
  const [buttonClicked, setButtonClicked] = useState(false)

  useEffect(() => {
    if (buttonClicked && !waitToFinish && !isLoading) hideDialog()
  }, [buttonClicked, isLoading])

  const renderModalFooter = () => (
    <>
      <Button label="Close" buttonStyle="default" size="xl" className="mr-3" onClick={hideDialog} />
      <Button
        label={actionName}
        loading={isLoading}
        size="xl"
        className="m-0"
        onClick={() => {
          onConfirm()
          setButtonClicked(true)
          !waitToFinish && hideDialog()
        }}
      />
    </>
  )

  return (
    <Dialog
      header={headerTitle}
      closable={true}
      draggable={false}
      resizable={false}
      visible={visible}
      style={{ width: "25vw" }}
      onHide={hideDialog}
      footer={renderModalFooter}
    >
      {confirmElement ?? <span>{confirmText}</span>}
    </Dialog>
  )
}

type Props = {
  visible?: boolean
  headerTitle?: string
  confirmText?: string
  confirmElement?: ReactNode
  actionName?: string
  isLoading?: boolean
  waitToFinish?: boolean
  onConfirm(): void
  hideDialog(): void
}

export { ConfirmDialog }
