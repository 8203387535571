import { InputSwitch } from "primereact/inputswitch"
import { SettingItemArray } from "fhir"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faVials } from "@fortawesome/pro-light-svg-icons"
import { Dropdown } from "primereact/dropdown"

import { useOrganizationLabLocation } from "organizations"

import { requisitionEmailEnabled } from "../data"

const OrderLabsWithLocationSetting = ({ setting, isDisabled, onChange }: Props) => {
  const { locations } = useOrganizationLabLocation(setting.value?.Reference?.id as string)

  return (
    <div className="flex flex-col border-b last:border-b-0 mt-4">
      <div className="flex items-center flex-1">
        <FontAwesomeIcon icon={faVials} size="2x" className="w-8 text-gray-400" />
        <span className="font-medium capitalize ml-2">
          {setting.code?.display?.toLocaleLowerCase().replace("enabled", "") || "Unspecified"}
        </span>
      </div>
      <div className="flex items-center justify-between ml-10 mr-10 border-b py-4">
        <div className="flex flex-col">
          <span className="text-gray-700">Enable to order</span>
          <span className="text-sm text-gray-500">Enable to place orders to this laboratory</span>
        </div>
        <InputSwitch
          disabled={isDisabled || !setting.value?.Reference?.id}
          checked={setting.value?.boolean ?? false}
          onChange={(e) => {
            onChange(setting.code?.code as string, {
              ...setting,
              value: {
                ...setting.value,
                boolean: e.value,
                ...(locations[0]?.id ? { uri: "Location/" + locations[0].id } : {}),
              },
            })
          }}
        />
      </div>
      <div className="flex items-center justify-between ml-10 mr-10 py-4">
        <div className="flex flex-col">
          <span className="text-gray-700">Send requisition email</span>
          <span className="text-sm text-gray-500">Enable to send requisition emails to patients</span>
        </div>
        <InputSwitch
          disabled={isDisabled || !setting.value?.boolean}
          checked={setting.flag?.[0]?.coding?.[0]?.code === requisitionEmailEnabled.coding?.[0]?.code}
          onChange={(e) => {
            onChange(setting.code?.code as string, {
              ...setting,
              flag: e.value ? [requisitionEmailEnabled] : undefined,
            })
          }}
        />
      </div>

      <div className="flex items-center justify-between ml-10 mr-10 border-t py-4">
        <div className="flex flex-col">
          <span className="text-gray-700">Location</span>
          <span className="text-sm text-gray-500">Location of the laboratory for this practice</span>
        </div>
        {locations.length > 1 ? (
          <Dropdown
            inputId="locations-select"
            className="p-inputtext-sm w-60"
            options={locations}
            value={setting.value?.uri?.replace("Location/", "")}
            optionValue="id"
            optionLabel="name"
            disabled={!setting.value?.boolean || isDisabled}
            onChange={(e) =>
              onChange(setting.code?.code as string, {
                ...setting,
                value: { ...setting.value, uri: "Location/" + e.value },
              })
            }
          />
        ) : (
          <p className="text-gray-700 text-sm">{locations[0]?.name ?? "Unspecified"}</p>
        )}
      </div>
    </div>
  )
}

type Props = {
  setting: SettingItemArray
  isDisabled: boolean
  onChange(code: string, item: SettingItemArray): void
}

export { OrderLabsWithLocationSetting }
