import { faBuilding } from "@fortawesome/pro-light-svg-icons"
import {
  faCalendarCheck,
  faCalendarDays,
  faExternalLink,
  faFileInvoiceDollar,
  faPills,
  faTrashCan,
  faUser,
  faVials,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format, parseISO } from "date-fns"

import { MenuStyles, StackedListItemProps } from "commons"
import { formatsByTypes, isMedicationOrder } from "data"
import { getBadgeColor, getServiceRequestBillingType, strCapitalize } from "utils"

import { Order } from "../types"
import { getLabOrderIdentifier, getLabOrderStatus } from "../utils"

const orderModelBuilder = (order: Order, showOrder: () => void, cancelOrder?: () => void): StackedListItemProps => {
  const itemsCount =
    order.sr.basedOn?.filter((ref) =>
      isMedicationOrder(order.sr) ? ref.resourceType === "MedicationRequest" : ref.resourceType === "ServiceRequest",
    ) ?? []
  const isMedicationRequest = isMedicationOrder(order.sr)
  const orderStatus = isMedicationRequest ? order.sr.status : getLabOrderStatus(order.sr)?.display

  const orderBillingTypeDisplay = strCapitalize(getServiceRequestBillingType(order.sr).replace("bill-", ""))

  const data = isMedicationOrder(order.sr)
    ? [
        {
          lineItems: [
            {
              name: "Order number",
              value: order.sr.identifier?.[0]?.value ?? "Unspecified number",
            },
          ],
        },
        {
          lineItems: [
            { name: "Patient", value: order.sr.subject.display, icon: faUser },
            { name: "Bill to", value: orderBillingTypeDisplay, icon: faFileInvoiceDollar },
            {
              name: "Occurrence",
              value: format(
                new Date(order.sr.occurrence?.dateTime ?? (order.sr.authoredOn as string)),
                formatsByTypes.LONG_DATE,
              ),
              icon: faCalendarCheck,
            },
            { name: "Medication Requests", value: `${itemsCount.length}`, icon: faPills },
            { name: "Organization", value: order.orgName, icon: faBuilding },
          ],
        },
      ]
    : [
        {
          lineItems: [
            {
              name: "Laboratory",
              value: `${strCapitalize(order.sr.performer?.[0]?.display as string)} (${orderStatus !== "draft" ? `${getLabOrderIdentifier(order.sr, [])}` : ""})`,
            },
          ],
        },
        {
          lineItems: [
            { name: "Patient", value: order.sr.subject.display, icon: faUser },
            { name: "Bill to", value: orderBillingTypeDisplay, icon: faFileInvoiceDollar },
            {
              name: "Authored on",
              value: format(parseISO(order.sr.authoredOn as string), formatsByTypes.LONG_DATE),
              icon: faCalendarDays,
            },
            { name: "Tests", value: `${itemsCount.length}`, icon: faVials },
            ...(order.sr.occurrence?.dateTime
              ? [
                  {
                    name: "Occurrence",
                    icon: faCalendarCheck,
                    value: format(parseISO(order.sr.occurrence?.dateTime as string), formatsByTypes.LONG_DATE),
                  },
                ]
              : []),
            { name: "Organization", value: order.orgName, icon: faBuilding },
          ],
        },
      ]

  return {
    leftData: data,
    menu: [
      { command: showOrder, label: "Show order", icon: <FontAwesomeIcon icon={faExternalLink} className="fa-fw" /> },
      ...(cancelOrder
        ? [
            {
              command: cancelOrder,
              label: "Cancel order",
              icon: <FontAwesomeIcon icon={faTrashCan} className="fa-fw" />,
              disabled: ["draft", "on-hold"].includes(order.sr.status) || orderStatus?.toLowerCase() === "cancelled",
            },
          ]
        : []),
    ],
    menuStyle: cancelOrder ? MenuStyles.Dropdown : MenuStyles.ExternalAction,
    badge: getBadgeColor(orderStatus?.toLowerCase() ?? "unspecified"),
  }
}

export { orderModelBuilder }
