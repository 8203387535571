import { organizationSettingId } from "data"

const settingsQueryKeys = {
  all: (organizationId: string, settingId: string) => ["settings", organizationId, settingId],
  currentBranding: (organizationId: string) => [organizationSettingId.branding, organizationId],
  modulesVisibility: (organizationId: string) => [organizationSettingId.ehrModules, organizationId],
  paymentMethods: (organizationId: string) => [organizationSettingId.paymentMethods, organizationId],
  labFacilities: (organizationId: string) => [organizationSettingId.labFacilities, organizationId],
  consents: (organizationId: string) => [organizationSettingId.consents, organizationId],
  labCombos: (
    organizationId: string,
    performerLabs?: string[],
    archived?: boolean,
    searchText?: string,
    hidden?: boolean,
  ) => [
    organizationSettingId.labFacilities,
    "combos",
    organizationId,
    ...(performerLabs ? [performerLabs?.join(",")] : []),
    ...(archived !== undefined ? [archived] : []),
    ...(searchText ? [searchText] : []),
    ...(hidden !== undefined ? [hidden] : []),
  ],
  labPanels: (
    organizationId: string,
    performerLabs?: string[],
    archived?: boolean,
    combo?: string,
    searchText?: string,
  ) => [
    organizationSettingId.labFacilities,
    "panels",
    organizationId,
    ...(performerLabs ? [performerLabs?.join(",")] : []),
    ...(combo ? [combo] : []),
    ...(archived !== undefined ? [archived] : []),
    ...(searchText ? [searchText] : []),
  ],
  meds: ({ organizationId, category, catalogs, medsClassificationCodes, archived, searchText }: medsArgs) => [
    "meds",
    organizationId,
    category,
    ...(catalogs ? [catalogs?.join(",")] : []),
    ...(archived !== undefined ? [archived] : []),
    ...(searchText ? [searchText] : []),
    ...(medsClassificationCodes ? [medsClassificationCodes?.join(",")] : []),
  ],
  definition: ["settingsDefinition"],
}

interface medsArgs {
  organizationId: string
  category: string
  catalogs?: string[]
  medsClassificationCodes?: string[]
  archived?: boolean
  searchText?: string
}

export { settingsQueryKeys }
