import { faLocationDot } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AccountCreditCardArray, Address, getAddress } from "fhir"
import { useFormikContext } from "formik"
import { Checkbox } from "primereact/checkbox"
import { useMemo, useState } from "react"

import { AddressField, AddressVerificationFeedback, InputField, useSmartyAddressVerificationContext } from "commons"
import { emptyAddress } from "data"
import { formatCreditCardNumber } from "utils"

import { CreditcardNumberField } from "./CreditCardNumberField"
import { ExpirationDateField } from "./ExpirationDateField"

const CreditCardForm = ({ isEditing, showBillingAddress = true, shippingAddress }: Props) => {
  const { values, setValues, isSubmitting, setFieldValue } = useFormikContext<AccountCreditCardArray>()
  const { addressVerificationInfo, autoCompleteRecommendedAddress, bypassAddressValidation } =
    useSmartyAddressVerificationContext()
  const isSameAddress = useMemo(
    () => (shippingAddress ? JSON.stringify(values["billingAddress"]) === JSON.stringify(shippingAddress) : false),
    [shippingAddress, values],
  )
  const [useShipping, setUseShipping] = useState(isSameAddress)

  const onChange = (value: boolean) => {
    if (shippingAddress) {
      setValues((prevValues) => ({
        ...prevValues,
        billingAddress: value ? shippingAddress : isEditing ? prevValues["billingAddress"] : emptyAddress,
      }))
    }

    setUseShipping(value)
  }
  const renderBasicInfo = () => (
    <>
      {isEditing ? (
        <div className="flex gap-4">
          <div className="flex-1">
            <div className="text-sm mb-1">Number</div>
            <div className="border border-slate-200 rounded-md mb-2 text-gray-400 text-sm p-3 h-11 truncate block cursor-default select-none">
              {formatCreditCardNumber(values.last4Digits as string, values.type)}
            </div>
          </div>
          <div className="w-1/3">
            <div className="text-sm mb-1">CVV</div>
            <div className="border border-slate-200 rounded-md mb-2 text-gray-400 text-sm p-3 h-11 truncate block cursor-default select-none">
              ****
            </div>
          </div>
        </div>
      ) : (
        <CreditcardNumberField field="number" label="Number" disabled={isSubmitting} />
      )}
      {isEditing && <ExpirationDateField field="expirationMonth" label="Expiration date" disabled />}
      <InputField field="cardHolderName" label="Cardholder name" disabled={isEditing} />
    </>
  )

  return (
    <div className="flex flex-col gap-4">
      {showBillingAddress ? (
        <fieldset className="flex flex-col p-fluid gap-4 p-3">
          <legend>Basic information</legend>
          {renderBasicInfo()}
        </fieldset>
      ) : (
        renderBasicInfo()
      )}

      {showBillingAddress && (
        <div className="flex flex-col justify-between divide-y divide-gray-300">
          <fieldset className="p-fluid gap-4 p-3">
            <legend>Billing Address</legend>
            {shippingAddress && (
              <div className="w-full">
                <div className="flex items-center text-sm gap-1">
                  <Checkbox
                    inputId="useshipping"
                    onChange={(e) => onChange(e.checked ?? false)}
                    checked={useShipping}
                    value="Use shipping address"
                  />
                  <label
                    htmlFor="useshipping"
                    className="text-gray-500 text-sm cursor-pointer"
                    title="Use shipping address"
                  >
                    <FontAwesomeIcon icon={faLocationDot} className="mr-1" />
                    {getAddress([shippingAddress])}
                  </label>
                </div>
                <span className="text-slate-400 text-sm ml-7"></span>
              </div>
            )}
            {!useShipping && (
              <fieldset className="relative p-fluid grid grid-cols-2 gap-4 p-3 mb-5">
                <AddressField parentFieldName="billingAddress" disabled={isSubmitting} context={"Organization"} />
              </fieldset>
            )}
          </fieldset>
          <AddressVerificationFeedback
            addressVerificationInfo={addressVerificationInfo}
            handleAutoCompleteRecommendedAddress={() =>
              autoCompleteRecommendedAddress?.(setFieldValue, "billingAddress")
            }
            handleBypassAddressValidation={bypassAddressValidation}
          />
        </div>
      )}
    </div>
  )
}

type Props = {
  isEditing: boolean
  showBillingAddress?: boolean
  shippingAddress?: Address
}

export { CreditCardForm }
