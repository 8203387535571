import {
  SubsNotificationError,
  SubsNotificationNotificationExtended,
  SubsNotificationResponseExtended,
} from "./fhir-extended"

export interface IdentityProvider {
  active?: boolean
  authorize_endpoint?: string
  base_url?: string
  client?: IdentityProviderClient
  extension?: Extension[]
  id?: string
  introspection_endpoint?: string
  isEmailUniqueness?: boolean
  isScim?: boolean
  jwks_uri?: string
  meta?: Meta
  modifierExtension?: Extension[]
  organizations?: string[]
  registration_endpoint?: string
  resourceType?: string
  revocation_endpoint?: string
  scopes?: string[]
  system?: string
  title?: string
  token_endpoint?: string
  toScim?: IdentityProviderToScim
  type?: IdentityProviderType
  userinfo_endpoint?: string
  userinfo_header?: string
}

export interface IdentityProviderClient {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  redirect_uri?: string
  secret?: string
}

export interface ValueReference {
  resourceType?: FluffyArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface UsageContextValue {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Quantity?: Quantity
  Range?: Range
  Reference?: ValueReference
}

export interface UsageContext {
  code: Coding
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  value?: UsageContextValue
}

export interface TimingReference {
  resourceType?: ScheduleResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface TriggerDefinitionTiming {
  date?: string
  dateTime?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: TimingReference
  Timing?: Timing
}

export interface TriggerDefinition {
  condition?: Expression
  data?: DataRequirement[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  name?: string
  timing?: TriggerDefinitionTiming
  type: string
}

export interface SignatureWho {
  resourceType?: ObserverResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SignatureOnBehalfOf {
  resourceType?: ObserverResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Signature {
  data?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  onBehalfOf?: SignatureOnBehalfOf
  sigFormat?: string
  targetFormat?: string
  type: Coding[]
  when: Date
  who: SignatureWho
}

export interface SampledData {
  data?: string
  dimensions: number
  extension?: Extension[]
  factor?: number
  id?: string
  lowerLimit?: number
  modifierExtension?: Extension[]
  origin: Quantity
  period: number
  upperLimit?: number
}

export interface RelatedArtifact {
  citation?: string
  display?: string
  document?: Attachment
  extension?: Extension[]
  id?: string
  label?: string
  modifierExtension?: Extension[]
  resource?: string
  type: string
  url?: string
}

export interface Reference {
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  resourceType?: string
  type?: string
  uri?: string
}

export interface ParameterDefinition {
  documentation?: string
  extension?: Extension[]
  id?: string
  max?: string
  min?: number
  modifierExtension?: Extension[]
  name?: string
  profile?: string
  type: string
  use: string
}

export interface Money {
  currency?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  value?: number
}

export interface Meta {
  createdAt?: Date
  extension?: Extension[]
  id?: string
  lastUpdated?: Date
  modifierExtension?: Extension[]
  profile?: string[]
  security?: Coding[]
  source?: string
  sourceId?: string
  tag?: Coding[]
  versionId?: string
}

export interface HumanName {
  extension?: Extension[]
  family?: string
  given?: string[]
  id?: string
  modifierExtension?: Extension[]
  period?: Period
  prefix?: string[]
  suffix?: string[]
  text?: string
  use?: string
}

export interface Expression {
  description?: string
  expression?: string
  extension?: Extension[]
  id?: string
  language: string
  modifierExtension?: Extension[]
  name?: string
  reference?: string
}

export interface TimingRepeatBounds {
  Duration?: Duration
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
  Range?: Range
}

export interface TimingRepeat {
  bounds?: TimingRepeatBounds
  count?: number
  countMax?: number
  dayOfWeek?: string[]
  duration?: number
  durationMax?: number
  durationUnit?: string
  extension?: Extension[]
  frequency?: number
  frequencyMax?: number
  id?: string
  modifierExtension?: Extension[]
  offset?: number
  period?: number
  periodMax?: number
  periodUnit?: string
  timeOfDay?: string[]
  when?: string[]
}

export interface Timing {
  code?: CodeableConcept
  event?: string[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  repeat?: TimingRepeat
}

export interface Ratio {
  denominator?: Quantity
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  numerator?: Quantity
}

export interface DosageDoseAndRateArrayRate {
  extension?: Extension[]
  modifierExtension?: Extension[]
  Quantity?: Quantity
  Range?: Range
  Ratio?: Ratio
}

export interface Range {
  extension?: Extension[]
  high?: Quantity
  id?: string
  low?: Quantity
  modifierExtension?: Extension[]
}

export interface Quantity {
  code?: string
  comparator?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  system?: string
  unit?: string
  value?: number
}

export interface DosageDoseAndRateArrayDose {
  extension?: Extension[]
  modifierExtension?: Extension[]
  Quantity?: Quantity
  Range?: Range
}

export interface DosageDoseAndRateArray {
  dose?: DosageDoseAndRateArrayDose
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  rate?: DosageDoseAndRateArrayRate
  type?: CodeableConcept
}

export interface DosageAsNeeded {
  boolean?: boolean
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface Dosage {
  additionalInstruction?: CodeableConcept[]
  asNeeded?: DosageAsNeeded
  doseAndRate?: DosageDoseAndRateArray[]
  extension?: Extension[]
  id?: string
  maxDosePerAdministration?: Quantity
  maxDosePerLifetime?: Quantity
  maxDosePerPeriod?: Ratio
  method?: CodeableConcept
  modifierExtension?: Extension[]
  patientInstruction?: string
  route?: CodeableConcept
  sequence?: number
  site?: CodeableConcept
  text?: string
  timing?: Timing
}

export interface Distance {
  code?: string
  comparator?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  system?: string
  unit?: string
  value?: number
}

export interface PurpleReference {
  resourceType?: EnrollmentResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface DataRequirementSubject {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: PurpleReference
}

export interface DataRequirementSortArray {
  direction: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  path: string
}

export interface Duration {
  code?: string
  comparator?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  system?: string
  unit?: string
  value?: number
}

export interface DataRequirementDateFilterArrayValue {
  dateTime?: string
  Duration?: Duration
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
}

export interface DataRequirementDateFilterArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  path?: string
  searchParam?: string
  value?: DataRequirementDateFilterArrayValue
}

export interface DataRequirementCodeFilterArray {
  code?: Coding[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  path?: string
  searchParam?: string
  valueSet?: string
}

export interface DataRequirement {
  codeFilter?: DataRequirementCodeFilterArray[]
  dateFilter?: DataRequirementDateFilterArray[]
  extension?: Extension[]
  id?: string
  limit?: number
  modifierExtension?: Extension[]
  mustSupport?: string[]
  profile?: string[]
  sort?: DataRequirementSortArray[]
  subject?: DataRequirementSubject
  type: string
}

export interface Count {
  code?: string
  comparator?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  system?: string
  unit?: string
  value?: number
}

export interface Contributor {
  contact?: ContactDetail[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  name: string
  type: string
}

export interface ContactPoint {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  period?: Period
  rank?: number
  system?: string
  use?: string
  value?: string
}

export interface Coding {
  code?: string
  display?: string
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  system?: string
  userSelected?: boolean
  version?: string
}

export interface ContactDetail {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  name?: string
  telecom?: ContactPoint[]
}

export interface Attachment {
  contentType?: string
  creation?: string
  data?: string
  extension?: Extension[]
  hash?: string
  id?: string
  language?: string
  modifierExtension?: Extension[]
  size?: number
  title?: string
  url?: string
}

export interface Coding {
  code?: string
  display?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  system?: string
  userSelected?: boolean
  version?: string
}

export interface CodeableConcept {
  coding?: Coding[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  text?: string
}

export interface Assigner {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Identifier {
  assigner?: Reference
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  period?: Period
  system?: string
  type?: CodeableConcept
  use?: string
  value?: string
}

export interface AuthorReference {
  resourceType?: PurpleArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface AnnotationAuthor {
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: AuthorReference
  string?: string
}

export interface Annotation {
  author?: AnnotationAuthor
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  text: string
  time?: string
}

export interface Age {
  code?: string
  comparator?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  system?: string
  unit?: string
  value?: number
}

export interface Period {
  end?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  start?: string
}

export interface Address {
  city?: string
  country?: string
  district?: string
  extension?: Extension[]
  id?: string
  line?: string[]
  modifierExtension?: Extension[]
  period?: Period
  postalCode?: string
  state?: string
  text?: string
  type?: string
  use?: string
}

export interface ExtensionValue {
  Address?: Address
  Age?: Age
  Annotation?: Annotation
  Attachment?: Attachment
  base64Binary?: string
  boolean?: boolean
  canonical?: string
  code?: string
  CodeableConcept?: CodeableConcept
  Coding?: Coding
  ContactDetail?: ContactDetail
  ContactPoint?: ContactPoint
  Contributor?: Contributor
  Count?: Count
  DataRequirement?: DataRequirement
  date?: string
  dateTime?: string
  decimal?: number
  Distance?: Distance
  Dosage?: Dosage
  Duration?: Duration
  Expression?: Expression
  extension?: Extension[]
  HumanName?: HumanName
  id?: string
  Identifier?: Identifier
  instant?: Date
  integer?: number
  markdown?: string
  Meta?: Meta
  modifierExtension?: Extension[]
  Money?: Money
  oid?: string
  ParameterDefinition?: ParameterDefinition
  Period?: Period
  positiveInt?: number
  Quantity?: Quantity
  Range?: Range
  Ratio?: Ratio
  Reference?: Reference
  RelatedArtifact?: RelatedArtifact
  SampledData?: SampledData
  Signature?: Signature
  string?: string
  time?: string
  Timing?: Timing
  TriggerDefinition?: TriggerDefinition
  unsignedInt?: number
  uri?: string
  url?: string
  UsageContext?: UsageContext
  uuid?: string
}

export interface Extension {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  url: string
  value?: ExtensionValue
  valueAddress?: Address
  valueAge?: Age
  valueAnnotation?: Annotation
  valueAttachment?: Attachment
  valueBase64Binary?: string
  valueBoolean?: boolean
  valueCanonical?: string
  valueCode?: string
  valueCodeableConcept?: CodeableConcept
  valueCoding?: Coding
  valueContactDetail?: ContactDetail
  valueContactPoint?: ContactPoint
  valueContributor?: Contributor
  valueCount?: Count
  valueDataRequirement?: DataRequirement
  valueDate?: string
  valueDateTime?: string
  valueDecimal?: number
  valueDistance?: Distance
  valueDosage?: Dosage
  valueDuration?: Duration
  valueExpression?: Expression
  valueHumanName?: HumanName
  valueId?: string
  valueIdentifier?: Identifier
  valueInstant?: Date
  valueInteger?: number
  valueMarkdown?: string
  valueMeta?: Meta
  valueMoney?: Money
  valueOid?: string
  valueParameterDefinition?: ParameterDefinition
  valuePeriod?: Period
  valuePositiveInt?: number
  valueQuantity?: Quantity
  valueRange?: Range
  valueRatio?: Ratio
  valueReference?: any
  valueRelatedArtifact?: RelatedArtifact
  valueSampledData?: SampledData
  valueSignature?: Signature
  valueString?: string
  valueTime?: string
  valueTiming?: Timing
  valueTriggerDefinition?: TriggerDefinition
  valueUnsignedInt?: number
  valueUri?: string
  valueUrl?: string
  valueUsageContext?: UsageContext
  valueUuid?: string
}

export interface ResourceObject {
  id?: string
  implicitRules?: string
  language?: string
  meta?: Meta
  resourceType?: string
}

export const isResourceObject = (
  resource?: any[] | boolean | number | number | null | ResourceObject | string,
): resource is ResourceObject => !!(resource && typeof (resource as ResourceObject).resourceType === "string")

export enum FluffyArray {
  Group = "Group",
  HealthcareService = "HealthcareService",
  InsurancePlan = "InsurancePlan",
  Location = "Location",
  Organization = "Organization",
  PlanDefinition = "PlanDefinition",
  ResearchStudy = "ResearchStudy",
}

export enum ScheduleResourceType {
  Schedule = "Schedule",
}

export enum ObserverResourceType {
  Device = "Device",
  Organization = "Organization",
  Patient = "Patient",
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
  RelatedPerson = "RelatedPerson",
}

export enum EnrollmentResourceType {
  Group = "Group",
}

export enum AssignerResourceType {
  Organization = "Organization",
}

export enum PurpleArray {
  Organization = "Organization",
  Patient = "Patient",
  Practitioner = "Practitioner",
  RelatedPerson = "RelatedPerson",
}

export interface IdentityProviderToScim {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export enum IdentityProviderType {
  Aidbox = "aidbox",
  AzDev = "az-dev",
  Github = "github",
  Google = "google",
  OAuth = "OAuth",
  Oidc = "OIDC",
  Okta = "okta",
  Yandex = "yandex",
}

export interface SDCWorkflowVersion {
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
}

export interface DeviceRequest {
  authoredOn?: string
  basedOn?: Reference[]
  code?: DeviceRequestCode
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  encounter?: DeviceRequestEncounter
  extension?: Extension[]
  groupIdentifier?: Identifier
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  instantiatesCanonical?: string[]
  instantiatesUri?: string[]
  insurance?: DeviceRequestInsuranceArray[]
  intent: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  note?: Annotation[]
  occurrence?: DeviceRequestOccurrence
  parameter?: DeviceRequestParameterArray[]
  performer?: DeviceRequestPerformer
  performerType?: CodeableConcept
  priority?: string
  priorRequest?: Reference[]
  reasonCode?: CodeableConcept[]
  reasonReference?: DeviceRequestReasonReferenceArray[]
  relevantHistory?: DeviceRequestRelevantHistoryArray[]
  requester?: DeviceRequestRequester
  resourceType?: string
  status?: string
  subject: DeviceRequestSubject
  supportingInfo?: Reference[]
  text?: Narrative
}

export interface DeviceRequestCode {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: CodeReference
}

export interface CodeReference {
  resourceType?: UdiResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum UdiResourceType {
  Device = "Device",
}

export interface DeviceRequestEncounter {
  resourceType?: TentacledArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum TentacledArray {
  Encounter = "Encounter",
}

export interface DeviceRequestInsuranceArray {
  resourceType?: InsuranceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum InsuranceResourceType {
  ClaimResponse = "ClaimResponse",
  Coverage = "Coverage",
}

export interface DeviceRequestOccurrence {
  dateTime?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
  Timing?: Timing
}

export interface DeviceRequestParameterArray {
  code?: CodeableConcept
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  value?: DeviceRequestParameterArrayValue
}

export interface DeviceRequestParameterArrayValue {
  boolean?: boolean
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Quantity?: Quantity
  Range?: Range
}

export interface DeviceRequestPerformer {
  resourceType?: RequestedPerformerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum RequestedPerformerResourceType {
  CareTeam = "CareTeam",
  Device = "Device",
  HealthcareService = "HealthcareService",
  Organization = "Organization",
  Patient = "Patient",
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
  RelatedPerson = "RelatedPerson",
}

export interface DeviceRequestReasonReferenceArray {
  resourceType?: StickyArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum StickyArray {
  Condition = "Condition",
  DiagnosticReport = "DiagnosticReport",
  DocumentReference = "DocumentReference",
  Observation = "Observation",
}

export interface DeviceRequestRelevantHistoryArray {
  resourceType?: RelevantHistoryResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum RelevantHistoryResourceType {
  Provenance = "Provenance",
}

export interface DeviceRequestRequester {
  resourceType?: IndigoArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum IndigoArray {
  Device = "Device",
  Organization = "Organization",
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
}

export interface DeviceRequestSubject {
  resourceType?: IndecentArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum IndecentArray {
  Device = "Device",
  Group = "Group",
  Location = "Location",
  Patient = "Patient",
}

export interface Narrative {
  div: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  status: string
}

export interface ServiceRequest {
  asNeeded?: ServiceRequestAsNeeded
  authoredOn?: string
  basedOn?: ServiceRequestBasedOnArray[]
  bodySite?: CodeableConcept[]
  category?: CodeableConcept[]
  code?: CodeableConcept
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  doNotPerform?: boolean
  encounter?: ServiceRequestEncounter
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  instantiatesCanonical?: string[]
  instantiatesUri?: string[]
  insurance?: ServiceRequestInsuranceArray[]
  intent: string
  language?: string
  locationCode?: CodeableConcept[]
  locationReference?: ServiceRequestLocationReferenceArray[]
  meta?: Meta
  modifierExtension?: Extension[]
  note?: Annotation[]
  occurrence?: ServiceRequestOccurrence
  orderDetail?: CodeableConcept[]
  patientInstruction?: string
  performer?: ServiceRequestPerformerArray[]
  performerType?: CodeableConcept
  priority?: string
  quantity?: ServiceRequestQuantity
  reasonCode?: CodeableConcept[]
  reasonReference?: ServiceRequestReasonReferenceArray[]
  relevantHistory?: ServiceRequestRelevantHistoryArray[]
  replaces?: ServiceRequestReplacesArray[]
  requester?: ServiceRequestRequester
  requisition?: Identifier
  resourceType?: string
  specimen?: ServiceRequestSpecimenArray[]
  status: string
  subject: ServiceRequestSubject
  supportingInfo?: Reference[]
  text?: Narrative
}

export interface ServiceRequestAsNeeded {
  boolean?: boolean
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface ServiceRequestBasedOnArray {
  resourceType?: HilariousArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum HilariousArray {
  CarePlan = "CarePlan",
  MedicationRequest = "MedicationRequest",
  ServiceRequest = "ServiceRequest",
}

export interface ServiceRequestEncounter {
  resourceType?: TentacledArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ServiceRequestInsuranceArray {
  resourceType?: InsuranceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ServiceRequestLocationReferenceArray {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum LocationReferenceResourceType {
  Location = "Location",
}

export interface ServiceRequestOccurrence {
  dateTime?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
  Timing?: Timing
}

export interface ServiceRequestPerformerArray {
  resourceType?: RequestedPerformerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ServiceRequestQuantity {
  extension?: Extension[]
  modifierExtension?: Extension[]
  Quantity?: Quantity
  Range?: Range
  Ratio?: Ratio
}

export interface ServiceRequestReasonReferenceArray {
  resourceType?: StickyArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ServiceRequestRelevantHistoryArray {
  resourceType?: RelevantHistoryResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ServiceRequestReplacesArray {
  resourceType?: ReferralRequestResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ReferralRequestResourceType {
  ServiceRequest = "ServiceRequest",
}

export interface ServiceRequestRequester {
  resourceType?: ObserverResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ServiceRequestSpecimenArray {
  resourceType?: SpecimanResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum SpecimanResourceType {
  Specimen = "Specimen",
}

export interface ServiceRequestSubject {
  resourceType?: IndecentArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SDCFormVersion {
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
}

export interface DeviceMetric {
  calibration?: DeviceMetricCalibrationArray[]
  category: string
  color?: string
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  measurementPeriod?: Timing
  meta?: Meta
  modifierExtension?: Extension[]
  operationalStatus?: string
  parent?: DeviceMetricParent
  resourceType?: string
  source?: DeviceMetricSource
  text?: Narrative
  type: CodeableConcept
  unit?: CodeableConcept
}

export interface DeviceMetricCalibrationArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  state?: string
  time?: Date
  type?: string
}

export interface DeviceMetricParent {
  resourceType?: UdiResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface DeviceMetricSource {
  resourceType?: UdiResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface AwsAccount {
  "access-key-id"?: string
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  region?: string
  resourceType?: string
  "secret-access-key"?: string
}

export interface CarePlan {
  activity?: CarePlanActivityArray[]
  addresses?: CarePlanAddressesArray[]
  author?: CarePlanAuthor
  basedOn?: CarePlanBasedOnArray[]
  careTeam?: CarePlanCareTeamArray[]
  category?: CodeableConcept[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  contributor?: CarePlanContributorArray[]
  created?: string
  description?: string
  encounter?: CarePlanEncounter
  extension?: Extension[]
  goal?: CarePlanGoalArray[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  instantiatesCanonical?: string[]
  instantiatesUri?: string[]
  intent: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  note?: Annotation[]
  partOf?: CarePlanPartOfArray[]
  period?: Period
  replaces?: CarePlanReplacesArray[]
  resourceType?: string
  status: string
  subject: CarePlanSubject
  supportingInfo?: Reference[]
  text?: Narrative
  title?: string
}

export interface CarePlanActivityArray {
  detail?: CarePlanActivityArrayDetail
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  outcomeCodeableConcept?: CodeableConcept[]
  outcomeReference?: Reference[]
  progress?: Annotation[]
  reference?: ActivityReference
}

export interface CarePlanActivityArrayDetail {
  code?: CodeableConcept
  dailyAmount?: Quantity
  description?: string
  doNotPerform?: boolean
  extension?: Extension[]
  goal?: CarePlanActivityArrayDetailGoalArray[]
  id?: string
  instantiatesCanonical?: string[]
  instantiatesUri?: string[]
  kind?: string
  location?: DetailLocation
  modifierExtension?: Extension[]
  performer?: CarePlanActivityArrayDetailPerformerArray[]
  product?: CarePlanActivityArrayDetailProduct
  quantity?: Quantity
  reasonCode?: CodeableConcept[]
  reasonReference?: CarePlanActivityArrayDetailReasonReferenceArray[]
  scheduled?: CarePlanActivityArrayDetailScheduled
  status: string
  statusReason?: CodeableConcept
}

export interface CarePlanActivityArrayDetailGoalArray {
  resourceType?: GoalResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum GoalResourceType {
  Goal = "Goal",
}

export interface DetailLocation {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CarePlanActivityArrayDetailPerformerArray {
  resourceType?: RequestedPerformerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CarePlanActivityArrayDetailProduct {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: FluffyReference
}

export interface FluffyReference {
  resourceType?: AmbitiousArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum AmbitiousArray {
  Medication = "Medication",
  Substance = "Substance",
}

export interface CarePlanActivityArrayDetailReasonReferenceArray {
  resourceType?: StickyArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CarePlanActivityArrayDetailScheduled {
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
  string?: string
  Timing?: Timing
}

export interface ActivityReference {
  resourceType?: CunningArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum CunningArray {
  Appointment = "Appointment",
  CommunicationRequest = "CommunicationRequest",
  DeviceRequest = "DeviceRequest",
  MedicationRequest = "MedicationRequest",
  NutritionOrder = "NutritionOrder",
  RequestGroup = "RequestGroup",
  ServiceRequest = "ServiceRequest",
  Task = "Task",
  VisionPrescription = "VisionPrescription",
}

export interface CarePlanAddressesArray {
  resourceType?: ResultingConditionResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ResultingConditionResourceType {
  Condition = "Condition",
}

export interface CarePlanAuthor {
  resourceType?: OperatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum OperatorResourceType {
  CareTeam = "CareTeam",
  Device = "Device",
  Organization = "Organization",
  Patient = "Patient",
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
  RelatedPerson = "RelatedPerson",
}

export interface CarePlanBasedOnArray {
  resourceType?: MagentaArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum MagentaArray {
  CarePlan = "CarePlan",
}

export interface CarePlanCareTeamArray {
  resourceType?: CareTeamResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum CareTeamResourceType {
  CareTeam = "CareTeam",
}

export interface CarePlanContributorArray {
  resourceType?: OperatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CarePlanEncounter {
  resourceType?: TentacledArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CarePlanGoalArray {
  resourceType?: GoalResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CarePlanPartOfArray {
  resourceType?: MagentaArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CarePlanReplacesArray {
  resourceType?: MagentaArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CarePlanSubject {
  resourceType?: FriskyArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum FriskyArray {
  Group = "Group",
  Patient = "Patient",
}

export interface Observation {
  basedOn?: ObservationBasedOnArray[]
  bodySite?: CodeableConcept
  category?: CodeableConcept[]
  code: CodeableConcept
  component?: ObservationComponentArray[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  dataAbsentReason?: CodeableConcept
  derivedFrom?: ObservationDerivedFromArray[]
  device?: ObservationDevice
  effective?: ObservationEffective
  encounter?: ObservationEncounter
  extension?: Extension[]
  focus?: Reference[]
  hasMember?: ObservationHasMemberArray[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  interpretation?: CodeableConcept[]
  issued?: Date
  language?: string
  meta?: Meta
  method?: CodeableConcept
  modifierExtension?: Extension[]
  note?: Annotation[]
  partOf?: ObservationPartOfArray[]
  performer?: ObservationPerformerArray[]
  referenceRange?: ObservationReferenceRangeArray[]
  resourceType?: string
  specimen?: ObservationSpecimen
  status: string
  subject?: ObservationSubject
  text?: Narrative
  value?: ObservationValue
}

export interface ObservationBasedOnArray {
  resourceType?: MischievousArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum MischievousArray {
  CarePlan = "CarePlan",
  DeviceRequest = "DeviceRequest",
  ImmunizationRecommendation = "ImmunizationRecommendation",
  MedicationRequest = "MedicationRequest",
  NutritionOrder = "NutritionOrder",
  ServiceRequest = "ServiceRequest",
}

export interface ObservationComponentArray {
  code: CodeableConcept
  dataAbsentReason?: CodeableConcept
  extension?: Extension[]
  id?: string
  interpretation?: CodeableConcept[]
  modifierExtension?: Extension[]
  referenceRange?: ObservationReferenceRangeArray[]
  value?: ObservationComponentArrayValue
}

export interface ObservationReferenceRangeArray {
  age?: Range
  appliesTo?: CodeableConcept[]
  extension?: Extension[]
  high?: Quantity
  id?: string
  low?: Quantity
  modifierExtension?: Extension[]
  text?: string
  type?: CodeableConcept
}

export interface ObservationComponentArrayValue {
  boolean?: boolean
  CodeableConcept?: CodeableConcept
  dateTime?: string
  extension?: Extension[]
  integer?: number
  modifierExtension?: Extension[]
  Period?: Period
  Quantity?: Quantity
  Range?: Range
  Ratio?: Ratio
  SampledData?: SampledData
  string?: string
  time?: string
}

export interface ObservationDerivedFromArray {
  resourceType?: BraggadociousArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum BraggadociousArray {
  DocumentReference = "DocumentReference",
  ImagingStudy = "ImagingStudy",
  Media = "Media",
  MolecularSequence = "MolecularSequence",
  Observation = "Observation",
  QuestionnaireResponse = "QuestionnaireResponse",
}

export interface ObservationDevice {
  resourceType?: Array1
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array1 {
  Device = "Device",
  DeviceMetric = "DeviceMetric",
}

export interface ObservationEffective {
  dateTime?: string
  extension?: Extension[]
  instant?: Date
  modifierExtension?: Extension[]
  Period?: Period
  Timing?: Timing
}

export interface ObservationEncounter {
  resourceType?: TentacledArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ObservationHasMemberArray {
  resourceType?: HasMemberResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum HasMemberResourceType {
  MolecularSequence = "MolecularSequence",
  Observation = "Observation",
  QuestionnaireResponse = "QuestionnaireResponse",
}

export interface ObservationPartOfArray {
  resourceType?: Array2
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array2 {
  ImagingStudy = "ImagingStudy",
  Immunization = "Immunization",
  MedicationAdministration = "MedicationAdministration",
  MedicationDispense = "MedicationDispense",
  MedicationStatement = "MedicationStatement",
  Procedure = "Procedure",
}

export interface ObservationPerformerArray {
  resourceType?: MemberResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum MemberResourceType {
  CareTeam = "CareTeam",
  Organization = "Organization",
  Patient = "Patient",
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
  RelatedPerson = "RelatedPerson",
}

export interface ObservationSpecimen {
  resourceType?: SpecimanResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ObservationSubject {
  resourceType?: IndecentArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ObservationValue {
  boolean?: boolean
  CodeableConcept?: CodeableConcept
  dateTime?: string
  extension?: Extension[]
  integer?: number
  modifierExtension?: Extension[]
  Period?: Period
  Quantity?: Quantity
  Range?: Range
  Ratio?: Ratio
  SampledData?: SampledData
  string?: string
  time?: string
}

export interface MoneyQuantity {
  code?: string
  comparator?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  system?: string
  unit?: string
  value?: number
}

export interface Function {
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
}

export interface EnrollmentRequest {
  candidate?: Candidate
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  coverage?: EnrollmentRequestCoverage
  created?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  insurer?: EnrollmentRequestInsurer
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  provider?: EnrollmentRequestProvider
  resourceType?: string
  status?: string
  text?: Narrative
}

export interface Candidate {
  resourceType?: CandidateResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum CandidateResourceType {
  Patient = "Patient",
}

export interface EnrollmentRequestCoverage {
  resourceType?: CoverageResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum CoverageResourceType {
  Coverage = "Coverage",
}

export interface EnrollmentRequestInsurer {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface EnrollmentRequestProvider {
  resourceType?: AuthenticatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum AuthenticatorResourceType {
  Organization = "Organization",
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
}

export interface Group {
  active?: boolean
  actual: boolean
  characteristic?: GroupCharacteristicArray[]
  code?: CodeableConcept
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  managingEntity?: ManagingEntity
  member?: GroupMemberArray[]
  meta?: Meta
  modifierExtension?: Extension[]
  name?: string
  quantity?: number
  resourceType?: string
  text?: Narrative
  type: string
}

export interface GroupCharacteristicArray {
  code: CodeableConcept
  exclude: boolean
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  period?: Period
  value?: GroupCharacteristicArrayValue
}

export interface GroupCharacteristicArrayValue {
  boolean?: boolean
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Quantity?: Quantity
  Range?: Range
  Reference?: Reference
}

export interface ManagingEntity {
  resourceType?: ManagingEntityResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ManagingEntityResourceType {
  Organization = "Organization",
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
  RelatedPerson = "RelatedPerson",
}

export interface GroupMemberArray {
  entity: EntityClass
  extension?: Extension[]
  id?: string
  inactive?: boolean
  modifierExtension?: Extension[]
  period?: Period
}

export interface EntityClass {
  resourceType?: EntityResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum EntityResourceType {
  Device = "Device",
  Group = "Group",
  Medication = "Medication",
  Patient = "Patient",
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
  Substance = "Substance",
}

export interface MessageDefinition {
  allowedResponse?: MessageDefinitionAllowedResponseArray[]
  base?: string
  category?: string
  contact?: ContactDetail[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  copyright?: string
  date: string
  description?: string
  event?: MessageDefinitionEvent
  experimental?: boolean
  extension?: Extension[]
  focus?: MessageDefinitionFocusArray[]
  graph?: string[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  jurisdiction?: CodeableConcept[]
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  name?: string
  parent?: string[]
  publisher?: string
  purpose?: string
  replaces?: string[]
  resourceType?: string
  responseRequired?: string
  status: string
  text?: Narrative
  title?: string
  url?: string
  useContext?: UsageContext[]
  version?: string
}

export interface MessageDefinitionAllowedResponseArray {
  extension?: Extension[]
  id?: string
  message: string
  modifierExtension?: Extension[]
  situation?: string
}

export interface MessageDefinitionEvent {
  Coding?: Coding
  extension?: Extension[]
  modifierExtension?: Extension[]
  uri?: string
}

export interface MessageDefinitionFocusArray {
  code: string
  extension?: Extension[]
  id?: string
  max?: string
  min: number
  modifierExtension?: Extension[]
  profile?: string
}

export interface AuthConfig {
  asidCookieMaxAge?: number
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
  theme?: AuthConfigTheme
  twoFactor?: AuthConfigTwoFactor
}

export interface AuthConfigTheme {
  brand?: string
  extension?: Extension[]
  forgotPasswordUrl?: string
  modifierExtension?: Extension[]
  styleUrl?: string
  title?: string
}

export interface AuthConfigTwoFactor {
  extension?: Extension[]
  issuerName?: string
  modifierExtension?: Extension[]
  validPastTokensCount?: number
  webhook?: AuthConfigTwoFactorWebhook
}

export interface AuthConfigTwoFactorWebhook {
  endpoint: string
  extension?: Extension[]
  headers?: { [key: string]: string }
  modifierExtension?: Extension[]
  timeout?: number
}

export interface Appointment {
  appointmentType?: CodeableConcept
  basedOn?: AppointmentBasedOnArray[]
  cancelationReason?: CodeableConcept
  comment?: string
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  created?: string
  description?: string
  end?: Date
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  meta?: Meta
  minutesDuration?: number
  modifierExtension?: Extension[]
  participant: AppointmentParticipantArray[]
  patientInstruction?: string
  priority?: number
  reasonCode?: CodeableConcept[]
  reasonReference?: AppointmentReasonReferenceArray[]
  requestedPeriod?: Period[]
  resourceType?: string
  serviceCategory?: CodeableConcept[]
  serviceType?: CodeableConcept[]
  slot?: AppointmentSlotArray[]
  specialty?: CodeableConcept[]
  start?: Date
  status: string
  supportingInformation?: Reference[]
  text?: Narrative
}

export interface AppointmentBasedOnArray {
  resourceType?: ReferralRequestResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface AppointmentParticipantArray {
  actor?: PurpleActor
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  period?: Period
  required?: string
  status: string
  type?: CodeableConcept[]
}

export interface PurpleActor {
  resourceType?: Array3
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array3 {
  Device = "Device",
  HealthcareService = "HealthcareService",
  Location = "Location",
  Patient = "Patient",
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
  RelatedPerson = "RelatedPerson",
}

export interface AppointmentReasonReferenceArray {
  resourceType?: Array4
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array4 {
  Condition = "Condition",
  ImmunizationRecommendation = "ImmunizationRecommendation",
  Observation = "Observation",
  Procedure = "Procedure",
}

export interface AppointmentSlotArray {
  resourceType?: SlotResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum SlotResourceType {
  Slot = "Slot",
}

export interface SDCWorkflow {
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
}

export interface BiologicallyDerivedProduct {
  collection?: BiologicallyDerivedProductCollection
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  manipulation?: BiologicallyDerivedProductManipulation
  meta?: Meta
  modifierExtension?: Extension[]
  parent?: BiologicallyDerivedProductParentArray[]
  processing?: BiologicallyDerivedProductProcessingArray[]
  productCategory?: string
  productCode?: CodeableConcept
  quantity?: number
  request?: BiologicallyDerivedProductRequestArray[]
  resourceType?: string
  status?: string
  storage?: BiologicallyDerivedProductStorageArray[]
  text?: Narrative
}

export interface BiologicallyDerivedProductCollection {
  collected?: BiologicallyDerivedProductCollectionCollected
  collector?: PurpleCollector
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  source?: CollectionSource
}

export interface BiologicallyDerivedProductCollectionCollected {
  dateTime?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
}

export interface PurpleCollector {
  resourceType?: CollectorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum CollectorResourceType {
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
}

export interface CollectionSource {
  resourceType?: Array5
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array5 {
  Organization = "Organization",
  Patient = "Patient",
}

export interface BiologicallyDerivedProductManipulation {
  description?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  time?: BiologicallyDerivedProductManipulationTime
}

export interface BiologicallyDerivedProductManipulationTime {
  dateTime?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
}

export interface BiologicallyDerivedProductParentArray {
  resourceType?: Array6
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array6 {
  BiologicallyDerivedProduct = "BiologicallyDerivedProduct",
}

export interface BiologicallyDerivedProductProcessingArray {
  additive?: Additive
  description?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  procedure?: CodeableConcept
  time?: BiologicallyDerivedProductProcessingArrayTime
}

export interface Additive {
  resourceType?: AdditiveResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum AdditiveResourceType {
  Substance = "Substance",
}

export interface BiologicallyDerivedProductProcessingArrayTime {
  dateTime?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
}

export interface BiologicallyDerivedProductRequestArray {
  resourceType?: ReferralRequestResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface BiologicallyDerivedProductStorageArray {
  description?: string
  duration?: Period
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  scale?: string
  temperature?: number
}

export interface SeedImport {
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
}

export interface QuestionnaireResponse {
  author?: QuestionnaireResponseAuthor
  authored?: string
  basedOn?: QuestionnaireResponseBasedOnArray[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  encounter?: QuestionnaireResponseEncounter
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  implicitRules?: string
  item?: QuestionnaireResponseItemArrayItemArray[]
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  partOf?: QuestionnaireResponsePartOfArray[]
  questionnaire?: string
  resourceType?: string
  source?: QuestionnaireResponseSource
  status: string
  subject?: Reference
  text?: Narrative
}

export interface QuestionnaireResponseAuthor {
  resourceType?: ObserverResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface QuestionnaireResponseBasedOnArray {
  resourceType?: Array7
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array7 {
  CarePlan = "CarePlan",
  ServiceRequest = "ServiceRequest",
}

export interface QuestionnaireResponseEncounter {
  resourceType?: TentacledArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface QuestionnaireResponseItemArrayAnswerArray {
  extension?: Extension[]
  id?: string
  item?: QuestionnaireResponseItemArrayItemArray[]
  modifierExtension?: Extension[]
  value?: QuestionnaireResponseItemArrayAnswerArrayValue
}

export interface QuestionnaireResponseItemArrayItemArray {
  answer?: QuestionnaireResponseItemArrayAnswerArray[]
  definition?: string
  extension?: Extension[]
  id?: string
  item?: QuestionnaireResponseItemArrayItemArray[]
  linkId: string
  modifierExtension?: Extension[]
  text?: string
}

export interface QuestionnaireResponseItemArrayAnswerArrayValue {
  Attachment?: Attachment
  boolean?: boolean
  Coding?: Coding
  date?: string
  dateTime?: string
  decimal?: number
  extension?: Extension[]
  integer?: number
  modifierExtension?: Extension[]
  Quantity?: Quantity
  Reference?: Reference
  string?: string
  time?: string
  uri?: string
}

export interface QuestionnaireResponsePartOfArray {
  resourceType?: Array8
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array8 {
  Observation = "Observation",
  Procedure = "Procedure",
}

export interface QuestionnaireResponseSource {
  resourceType?: ExpressedByResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ExpressedByResourceType {
  Patient = "Patient",
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
  RelatedPerson = "RelatedPerson",
}

export interface Notebook {
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
}

export interface EffectEvidenceSynthesis {
  approvalDate?: string
  author?: ContactDetail[]
  certainty?: EffectEvidenceSynthesisCertaintyArray[]
  contact?: ContactDetail[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  copyright?: string
  date?: string
  description?: string
  editor?: ContactDetail[]
  effectEstimate?: EffectEvidenceSynthesisEffectEstimateArray[]
  effectivePeriod?: Period
  endorser?: ContactDetail[]
  exposure: EffectEvidenceSynthesisExposure
  exposureAlternative: EffectEvidenceSynthesisExposureAlternative
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  jurisdiction?: CodeableConcept[]
  language?: string
  lastReviewDate?: string
  meta?: Meta
  modifierExtension?: Extension[]
  name?: string
  note?: Annotation[]
  outcome: EffectEvidenceSynthesisOutcome
  population: EffectEvidenceSynthesisPopulation
  publisher?: string
  relatedArtifact?: RelatedArtifact[]
  resourceType?: string
  resultsByExposure?: EffectEvidenceSynthesisResultsByExposureArray[]
  reviewer?: ContactDetail[]
  sampleSize?: EffectEvidenceSynthesisSampleSize
  status: string
  studyType?: CodeableConcept
  synthesisType?: CodeableConcept
  text?: Narrative
  title?: string
  topic?: CodeableConcept[]
  url?: string
  useContext?: UsageContext[]
  version?: string
}

export interface EffectEvidenceSynthesisCertaintyArray {
  certaintySubcomponent?: EffectEvidenceSynthesisCertaintyArrayCertaintySubcomponentArray[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  note?: Annotation[]
  rating?: CodeableConcept[]
}

export interface EffectEvidenceSynthesisCertaintyArrayCertaintySubcomponentArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  note?: Annotation[]
  rating?: CodeableConcept[]
  type?: CodeableConcept
}

export interface EffectEvidenceSynthesisEffectEstimateArray {
  description?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  precisionEstimate?: EffectEvidenceSynthesisEffectEstimateArrayPrecisionEstimateArray[]
  type?: CodeableConcept
  unitOfMeasure?: CodeableConcept
  value?: number
  variantState?: CodeableConcept
}

export interface EffectEvidenceSynthesisEffectEstimateArrayPrecisionEstimateArray {
  extension?: Extension[]
  from?: number
  id?: string
  level?: number
  modifierExtension?: Extension[]
  to?: number
  type?: CodeableConcept
}

export interface EffectEvidenceSynthesisExposure {
  resourceType?: ExposureBackgroundResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ExposureBackgroundResourceType {
  EvidenceVariable = "EvidenceVariable",
}

export interface EffectEvidenceSynthesisExposureAlternative {
  resourceType?: ExposureBackgroundResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface EffectEvidenceSynthesisOutcome {
  resourceType?: ExposureBackgroundResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface EffectEvidenceSynthesisPopulation {
  resourceType?: ExposureBackgroundResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface EffectEvidenceSynthesisResultsByExposureArray {
  description?: string
  exposureState?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  riskEvidenceSynthesis: RiskEvidenceSynthesisClass
  variantState?: CodeableConcept
}

export interface RiskEvidenceSynthesisClass {
  resourceType?: RiskEvidenceSynthesisResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum RiskEvidenceSynthesisResourceType {
  RiskEvidenceSynthesis = "RiskEvidenceSynthesis",
}

export interface EffectEvidenceSynthesisSampleSize {
  description?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  numberOfParticipants?: number
  numberOfStudies?: number
}

export interface MedicinalProductContraindication {
  comorbidity?: CodeableConcept[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  disease?: CodeableConcept
  diseaseStatus?: CodeableConcept
  extension?: Extension[]
  id?: string
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  otherTherapy?: MedicinalProductContraindicationOtherTherapyArray[]
  population?: Population[]
  resourceType?: string
  subject?: MedicinalProductContraindicationSubjectArray[]
  text?: Narrative
  therapeuticIndication?: MedicinalProductContraindicationTherapeuticIndicationArray[]
}

export interface MedicinalProductContraindicationOtherTherapyArray {
  extension?: Extension[]
  id?: string
  medication?: MedicinalProductContraindicationOtherTherapyArrayMedication
  modifierExtension?: Extension[]
  therapyRelationshipType: CodeableConcept
}

export interface MedicinalProductContraindicationOtherTherapyArrayMedication {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: TentacledReference
}

export interface TentacledReference {
  resourceType?: Array9
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array9 {
  Medication = "Medication",
  MedicinalProduct = "MedicinalProduct",
  Substance = "Substance",
  SubstanceSpecification = "SubstanceSpecification",
}

export interface Population {
  age?: PopulationAge
  extension?: Extension[]
  gender?: CodeableConcept
  id?: string
  modifierExtension?: Extension[]
  physiologicalCondition?: CodeableConcept
  race?: CodeableConcept
}

export interface PopulationAge {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Range?: Range
}

export interface MedicinalProductContraindicationSubjectArray {
  resourceType?: Array10
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array10 {
  Medication = "Medication",
  MedicinalProduct = "MedicinalProduct",
}

export interface MedicinalProductContraindicationTherapeuticIndicationArray {
  resourceType?: TherapeuticIndicationResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum TherapeuticIndicationResourceType {
  MedicinalProductIndication = "MedicinalProductIndication",
}

export interface EpisodeOfCare {
  account?: EpisodeOfCareAccountArray[]
  careManager?: CareManager
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  diagnosis?: EpisodeOfCareDiagnosisArray[]
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  managingOrganization?: EpisodeOfCareManagingOrganization
  meta?: Meta
  modifierExtension?: Extension[]
  patient: EpisodeOfCarePatient
  period?: Period
  referralRequest?: EpisodeOfCareReferralRequestArray[]
  resourceType?: string
  status: string
  statusHistory?: EpisodeOfCareStatusHistoryArray[]
  team?: EpisodeOfCareTeamArray[]
  text?: Narrative
  type?: CodeableConcept[]
}

export interface EpisodeOfCareAccountArray {
  resourceType?: Array11
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array11 {
  Account = "Account",
}

export interface CareManager {
  resourceType?: CollectorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface EpisodeOfCareDiagnosisArray {
  condition: PurpleCondition
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  rank?: number
  role?: CodeableConcept
}

export interface PurpleCondition {
  resourceType?: ResultingConditionResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface EpisodeOfCareManagingOrganization {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface EpisodeOfCarePatient {
  resourceType?: CandidateResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface EpisodeOfCareReferralRequestArray {
  resourceType?: ReferralRequestResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface EpisodeOfCareStatusHistoryArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  period: Period
  status: string
}

export interface EpisodeOfCareTeamArray {
  resourceType?: CareTeamResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Evidence {
  approvalDate?: string
  author?: ContactDetail[]
  contact?: ContactDetail[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  copyright?: string
  date?: string
  description?: string
  editor?: ContactDetail[]
  effectivePeriod?: Period
  endorser?: ContactDetail[]
  exposureBackground: ExposureBackground
  exposureVariant?: EvidenceExposureVariantArray[]
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  jurisdiction?: CodeableConcept[]
  language?: string
  lastReviewDate?: string
  meta?: Meta
  modifierExtension?: Extension[]
  name?: string
  note?: Annotation[]
  outcome?: EvidenceOutcomeArray[]
  publisher?: string
  relatedArtifact?: RelatedArtifact[]
  resourceType?: string
  reviewer?: ContactDetail[]
  shortTitle?: string
  status: string
  subtitle?: string
  text?: Narrative
  title?: string
  topic?: CodeableConcept[]
  url?: string
  useContext?: UsageContext[]
  version?: string
}

export interface ExposureBackground {
  resourceType?: ExposureBackgroundResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface EvidenceExposureVariantArray {
  resourceType?: ExposureBackgroundResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface EvidenceOutcomeArray {
  resourceType?: ExposureBackgroundResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SubstancePolymer {
  class?: CodeableConcept
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  copolymerConnectivity?: CodeableConcept[]
  extension?: Extension[]
  geometry?: CodeableConcept
  id?: string
  implicitRules?: string
  language?: string
  meta?: Meta
  modification?: string[]
  modifierExtension?: Extension[]
  monomerSet?: SubstancePolymerMonomerSetArray[]
  repeat?: SubstancePolymerRepeatArray[]
  resourceType?: string
  text?: Narrative
}

export interface SubstancePolymerMonomerSetArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  ratioType?: CodeableConcept
  startingMaterial?: SubstancePolymerMonomerSetArrayStartingMaterialArray[]
}

export interface SubstancePolymerMonomerSetArrayStartingMaterialArray {
  amount?: SubstanceAmount
  extension?: Extension[]
  id?: string
  isDefining?: boolean
  material?: CodeableConcept
  modifierExtension?: Extension[]
  type?: CodeableConcept
}

export interface SubstanceAmount {
  amount?: SubstanceAmountAmount
  amountText?: string
  amountType?: CodeableConcept
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  referenceRange?: SubstanceAmountReferenceRange
}

export interface SubstanceAmountAmount {
  extension?: Extension[]
  modifierExtension?: Extension[]
  Quantity?: Quantity
  Range?: Range
  string?: string
}

export interface SubstanceAmountReferenceRange {
  extension?: Extension[]
  highLimit?: Quantity
  id?: string
  lowLimit?: Quantity
  modifierExtension?: Extension[]
}

export interface SubstancePolymerRepeatArray {
  averageMolecularFormula?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  numberOfUnits?: number
  repeatUnit?: SubstancePolymerRepeatArrayRepeatUnitArray[]
  repeatUnitAmountType?: CodeableConcept
}

export interface SubstancePolymerRepeatArrayRepeatUnitArray {
  amount?: SubstanceAmount
  degreeOfPolymerisation?: SubstancePolymerRepeatArrayRepeatUnitArrayDegreeOfPolymerisationArray[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  orientationOfPolymerisation?: CodeableConcept
  repeatUnit?: string
  structuralRepresentation?: SubstancePolymerRepeatArrayRepeatUnitArrayStructuralRepresentationArray[]
}

export interface SubstancePolymerRepeatArrayRepeatUnitArrayDegreeOfPolymerisationArray {
  amount?: SubstanceAmount
  degree?: CodeableConcept
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
}

export interface SubstancePolymerRepeatArrayRepeatUnitArrayStructuralRepresentationArray {
  attachment?: Attachment
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  representation?: string
  type?: CodeableConcept
}

export interface BackboneElement {
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
}

export interface SupplyDelivery {
  basedOn?: SupplyDeliveryBasedOnArray[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  destination?: SupplyDeliveryDestination
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  occurrence?: SupplyDeliveryOccurrence
  partOf?: SupplyDeliveryPartOfArray[]
  patient?: SupplyDeliveryPatient
  receiver?: SupplyDeliveryReceiverArray[]
  resourceType?: string
  status?: string
  suppliedItem?: SupplyDeliverySuppliedItem
  supplier?: Supplier
  text?: Narrative
  type?: CodeableConcept
}

export interface SupplyDeliveryBasedOnArray {
  resourceType?: Array12
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array12 {
  SupplyRequest = "SupplyRequest",
}

export interface SupplyDeliveryDestination {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SupplyDeliveryOccurrence {
  dateTime?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
  Timing?: Timing
}

export interface SupplyDeliveryPartOfArray {
  resourceType?: Array13
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array13 {
  Contract = "Contract",
  SupplyDelivery = "SupplyDelivery",
}

export interface SupplyDeliveryPatient {
  resourceType?: CandidateResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SupplyDeliveryReceiverArray {
  resourceType?: CollectorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SupplyDeliverySuppliedItem {
  extension?: Extension[]
  id?: string
  item?: SupplyDeliverySuppliedItemItem
  modifierExtension?: Extension[]
  quantity?: Quantity
}

export interface SupplyDeliverySuppliedItemItem {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: StickyReference
}

export interface StickyReference {
  resourceType?: UsedReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum UsedReferenceResourceType {
  Device = "Device",
  Medication = "Medication",
  Substance = "Substance",
}

export interface Supplier {
  resourceType?: AuthenticatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SubstanceNucleicAcid {
  areaOfHybridisation?: string
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  extension?: Extension[]
  id?: string
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  numberOfSubunits?: number
  oligoNucleotideType?: CodeableConcept
  resourceType?: string
  sequenceType?: CodeableConcept
  subunit?: SubstanceNucleicAcidSubunitArray[]
  text?: Narrative
}

export interface SubstanceNucleicAcidSubunitArray {
  extension?: Extension[]
  fivePrime?: CodeableConcept
  id?: string
  length?: number
  linkage?: SubstanceNucleicAcidSubunitArrayLinkageArray[]
  modifierExtension?: Extension[]
  sequence?: string
  sequenceAttachment?: Attachment
  subunit?: number
  sugar?: SubstanceNucleicAcidSubunitArraySugarArray[]
  threePrime?: CodeableConcept
}

export interface SubstanceNucleicAcidSubunitArrayLinkageArray {
  connectivity?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  modifierExtension?: Extension[]
  name?: string
  residueSite?: string
}

export interface SubstanceNucleicAcidSubunitArraySugarArray {
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  modifierExtension?: Extension[]
  name?: string
  residueSite?: string
}

export interface AdverseEvent {
  actuality: string
  category?: CodeableConcept[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  contributor?: AdverseEventContributorArray[]
  date?: string
  detected?: string
  encounter?: AdverseEventEncounter
  event?: CodeableConcept
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  implicitRules?: string
  language?: string
  location?: AdverseEventLocation
  meta?: Meta
  modifierExtension?: Extension[]
  outcome?: CodeableConcept
  recordedDate?: string
  recorder?: AdverseEventRecorder
  referenceDocument?: AdverseEventReferenceDocumentArray[]
  resourceType?: string
  resultingCondition?: AdverseEventResultingConditionArray[]
  seriousness?: CodeableConcept
  severity?: CodeableConcept
  study?: AdverseEventStudyArray[]
  subject: AdverseEventSubject
  subjectMedicalHistory?: AdverseEventSubjectMedicalHistoryArray[]
  suspectEntity?: AdverseEventSuspectEntityArray[]
  text?: Narrative
}

export interface AdverseEventContributorArray {
  resourceType?: Array14
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array14 {
  Device = "Device",
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
}

export interface AdverseEventEncounter {
  resourceType?: TentacledArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface AdverseEventLocation {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface AdverseEventRecorder {
  resourceType?: ExpressedByResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface AdverseEventReferenceDocumentArray {
  resourceType?: ReferenceDocumentResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ReferenceDocumentResourceType {
  DocumentReference = "DocumentReference",
}

export interface AdverseEventResultingConditionArray {
  resourceType?: ResultingConditionResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface AdverseEventStudyArray {
  resourceType?: StudyResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum StudyResourceType {
  ResearchStudy = "ResearchStudy",
}

export interface AdverseEventSubject {
  resourceType?: Array15
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array15 {
  Group = "Group",
  Patient = "Patient",
  Practitioner = "Practitioner",
  RelatedPerson = "RelatedPerson",
}

export interface AdverseEventSubjectMedicalHistoryArray {
  resourceType?: SubjectMedicalHistoryResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum SubjectMedicalHistoryResourceType {
  AllergyIntolerance = "AllergyIntolerance",
  Condition = "Condition",
  DocumentReference = "DocumentReference",
  FamilyMemberHistory = "FamilyMemberHistory",
  Immunization = "Immunization",
  Media = "Media",
  Observation = "Observation",
  Procedure = "Procedure",
}

export interface AdverseEventSuspectEntityArray {
  causality?: AdverseEventSuspectEntityArrayCausalityArray[]
  extension?: Extension[]
  id?: string
  instance: Nce
  modifierExtension?: Extension[]
}

export interface AdverseEventSuspectEntityArrayCausalityArray {
  assessment?: CodeableConcept
  author?: CausalityAuthor
  extension?: Extension[]
  id?: string
  method?: CodeableConcept
  modifierExtension?: Extension[]
  productRelatedness?: string
}

export interface CausalityAuthor {
  resourceType?: CollectorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Nce {
  resourceType?: Array16
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array16 {
  Device = "Device",
  Immunization = "Immunization",
  Medication = "Medication",
  MedicationAdministration = "MedicationAdministration",
  MedicationStatement = "MedicationStatement",
  Procedure = "Procedure",
  Substance = "Substance",
}

export interface AidboxQuery {
  "count-query"?: string
  "enable-links"?: boolean
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  "omit-sql"?: boolean
  params?: { [key: string]: AidboxQueryParam }
  query: string
  resourceType?: string
  type?: AidboxQueryType
}

export interface AidboxQueryParam {
  /**
   * Anything
   */
  default?: any
  extension?: Extension[]
  format?: string
  isRequired?: boolean
  modifierExtension?: Extension[]
  type?: PurpleType
}

export enum PurpleType {
  Boolean = "boolean",
  Integer = "integer",
  Number = "number",
  Object = "object",
  String = "string",
}

export enum AidboxQueryType {
  Execute = "execute",
  Query = "query",
}

export interface Endpoint {
  address: string
  connectionType: Coding
  contact?: ContactPoint[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  extension?: Extension[]
  header?: string[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  managingOrganization?: EndpointManagingOrganization
  meta?: Meta
  modifierExtension?: Extension[]
  name?: string
  payloadMimeType?: string[]
  payloadType: CodeableConcept[]
  period?: Period
  resourceType?: string
  status: string
  text?: Narrative
}

export interface EndpointManagingOrganization {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface BulkExportStatus {
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
}

export interface AzureContainer {
  account?: AzureContainerAccount
  container?: string
  extension?: string
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
  storage?: string
}

export interface AzureContainerAccount {
  resourceType?: Array17
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array17 {
  AzureAccount = "AzureAccount",
}

export interface Operation {
  action?: any[] | { [key: string]: any } | string
  app?: AppClass
  description?: string
  extension?: Extension[]
  id?: string
  "implicit-params"?: OperationImplicitParams
  meta?: Meta
  modifierExtension?: Extension[]
  module?: string
  public?: boolean
  request?: Array<RequestObject | string>
  resourceType?: string
  "route-params"?: OperationRouteParams
  transform?: OperationTransform
}

export interface AppClass {
  resourceType?: AppResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum AppResourceType {
  App = "App",
}

export interface OperationImplicitParams {
  extension?: Extension[]
  modifierExtension?: Extension[]
  path?: OperationImplicitParamsPath
  query?: OperationImplicitParamsQuery
}

export interface OperationImplicitParamsPath {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface OperationImplicitParamsQuery {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface RequestObject {
  name: string
}

export interface OperationRouteParams {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface OperationTransform {
  extension?: Extension[]
  modifierExtension?: Extension[]
  request?: OperationTransformRequest
}

export interface OperationTransformRequest {
  engine: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  part: Part
  template?: Reference
}

export enum Part {
  Body = "body",
}

export interface SubstanceReferenceInformation {
  classification?: SubstanceReferenceInformationClassificationArray[]
  comment?: string
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  extension?: Extension[]
  gene?: SubstanceReferenceInformationGeneArray[]
  geneElement?: SubstanceReferenceInformationGeneElementArray[]
  id?: string
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
  target?: SubstanceReferenceInformationTargetArray[]
  text?: Narrative
}

export interface SubstanceReferenceInformationClassificationArray {
  classification?: CodeableConcept
  domain?: CodeableConcept
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  source?: SubstanceReferenceInformationClassificationArraySourceArray[]
  subtype?: CodeableConcept[]
}

export interface SubstanceReferenceInformationClassificationArraySourceArray {
  resourceType?: ReferenceDocumentResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SubstanceReferenceInformationGeneArray {
  extension?: Extension[]
  gene?: CodeableConcept
  geneSequenceOrigin?: CodeableConcept
  id?: string
  modifierExtension?: Extension[]
  source?: SubstanceReferenceInformationGeneArraySourceArray[]
}

export interface SubstanceReferenceInformationGeneArraySourceArray {
  resourceType?: ReferenceDocumentResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SubstanceReferenceInformationGeneElementArray {
  element?: Identifier
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  source?: SubstanceReferenceInformationGeneElementArraySourceArray[]
  type?: CodeableConcept
}

export interface SubstanceReferenceInformationGeneElementArraySourceArray {
  resourceType?: ReferenceDocumentResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SubstanceReferenceInformationTargetArray {
  amount?: SubstanceReferenceInformationTargetArrayAmount
  amountType?: CodeableConcept
  extension?: Extension[]
  id?: string
  interaction?: CodeableConcept
  modifierExtension?: Extension[]
  organism?: CodeableConcept
  organismType?: CodeableConcept
  source?: SubstanceReferenceInformationTargetArraySourceArray[]
  target?: Identifier
  type?: CodeableConcept
}

export interface SubstanceReferenceInformationTargetArrayAmount {
  extension?: Extension[]
  modifierExtension?: Extension[]
  Quantity?: Quantity
  Range?: Range
  string?: string
}

export interface SubstanceReferenceInformationTargetArraySourceArray {
  resourceType?: ReferenceDocumentResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface PGSequence {
  cycle?: boolean
  data_type?: DataType
  extension?: Extension[]
  id?: string
  increment?: number
  maxvalue?: number
  meta?: Meta
  minvalue?: number
  modifierExtension?: Extension[]
  resourceType?: string
  start?: number
}

export enum DataType {
  Bigint = "bigint",
  Integer = "integer",
  Smallint = "smallint",
}

export interface SubstanceSourceMaterial {
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  countryOfOrigin?: CodeableConcept[]
  developmentStage?: CodeableConcept
  extension?: Extension[]
  fractionDescription?: SubstanceSourceMaterialFractionDescriptionArray[]
  geographicalLocation?: string[]
  id?: string
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  organism?: SubstanceSourceMaterialOrganism
  organismId?: Identifier
  organismName?: string
  parentSubstanceId?: Identifier[]
  parentSubstanceName?: string[]
  partDescription?: SubstanceSourceMaterialPartDescriptionArray[]
  resourceType?: string
  sourceMaterialClass?: CodeableConcept
  sourceMaterialState?: CodeableConcept
  sourceMaterialType?: CodeableConcept
  text?: Narrative
}

export interface SubstanceSourceMaterialFractionDescriptionArray {
  extension?: Extension[]
  fraction?: string
  id?: string
  materialType?: CodeableConcept
  modifierExtension?: Extension[]
}

export interface SubstanceSourceMaterialOrganism {
  author?: SubstanceSourceMaterialOrganismAuthorArray[]
  extension?: Extension[]
  family?: CodeableConcept
  genus?: CodeableConcept
  hybrid?: SubstanceSourceMaterialOrganismHybrid
  id?: string
  intraspecificDescription?: string
  intraspecificType?: CodeableConcept
  modifierExtension?: Extension[]
  organismGeneral?: SubstanceSourceMaterialOrganismOrganismGeneral
  species?: CodeableConcept
}

export interface SubstanceSourceMaterialOrganismAuthorArray {
  authorDescription?: string
  authorType?: CodeableConcept
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
}

export interface SubstanceSourceMaterialOrganismHybrid {
  extension?: Extension[]
  hybridType?: CodeableConcept
  id?: string
  maternalOrganismId?: string
  maternalOrganismName?: string
  modifierExtension?: Extension[]
  paternalOrganismId?: string
  paternalOrganismName?: string
}

export interface SubstanceSourceMaterialOrganismOrganismGeneral {
  class?: CodeableConcept
  extension?: Extension[]
  id?: string
  kingdom?: CodeableConcept
  modifierExtension?: Extension[]
  order?: CodeableConcept
  phylum?: CodeableConcept
}

export interface SubstanceSourceMaterialPartDescriptionArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  part?: CodeableConcept
  partLocation?: CodeableConcept
}

export interface CompartmentDefinition {
  code: string
  contact?: ContactDetail[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  date?: string
  description?: string
  experimental?: boolean
  extension?: Extension[]
  id?: string
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  name: string
  publisher?: string
  purpose?: string
  resource?: CompartmentDefinitionResourceArray[]
  resourceType?: string
  search: boolean
  status: string
  text?: Narrative
  url: string
  useContext?: UsageContext[]
  version?: string
}

export interface CompartmentDefinitionResourceArray {
  code: string
  documentation?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  param?: string[]
}

export interface DetectedIssue {
  author?: DetectedIssueAuthor
  code?: CodeableConcept
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  detail?: string
  evidence?: DetectedIssueEvidenceArray[]
  extension?: Extension[]
  id?: string
  identified?: DetectedIssueIdentified
  identifier?: Identifier[]
  implicated?: Reference[]
  implicitRules?: string
  language?: string
  meta?: Meta
  mitigation?: DetectedIssueMitigationArray[]
  modifierExtension?: Extension[]
  patient?: DetectedIssuePatient
  reference?: string
  resourceType?: string
  severity?: string
  status: string
  text?: Narrative
}

export interface DetectedIssueAuthor {
  resourceType?: Array14
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface DetectedIssueEvidenceArray {
  code?: CodeableConcept[]
  detail?: Reference[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
}

export interface DetectedIssueIdentified {
  dateTime?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
}

export interface DetectedIssueMitigationArray {
  action: CodeableConcept
  author?: MitigationAuthor
  date?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
}

export interface MitigationAuthor {
  resourceType?: CollectorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface DetectedIssuePatient {
  resourceType?: CandidateResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationAdministration {
  category?: CodeableConcept
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  context?: MedicationAdministrationContext
  device?: MedicationAdministrationDeviceArray[]
  dosage?: MedicationAdministrationDosage
  effective?: MedicationAdministrationEffective
  eventHistory?: MedicationAdministrationEventHistoryArray[]
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  instantiates?: string[]
  language?: string
  medication?: MedicationAdministrationMedication
  meta?: Meta
  modifierExtension?: Extension[]
  note?: Annotation[]
  partOf?: MedicationAdministrationPartOfArray[]
  performer?: MedicationAdministrationPerformerArray[]
  reasonCode?: CodeableConcept[]
  reasonReference?: MedicationAdministrationReasonReferenceArray[]
  request?: MedicationAdministrationRequest
  resourceType?: string
  status: string
  statusReason?: CodeableConcept[]
  subject: MedicationAdministrationSubject
  supportingInformation?: Reference[]
  text?: Narrative
}

export interface MedicationAdministrationContext {
  resourceType?: ContextResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ContextResourceType {
  Encounter = "Encounter",
  EpisodeOfCare = "EpisodeOfCare",
}

export interface MedicationAdministrationDeviceArray {
  resourceType?: UdiResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationAdministrationDosage {
  dose?: Quantity
  extension?: Extension[]
  id?: string
  method?: CodeableConcept
  modifierExtension?: Extension[]
  rate?: MedicationAdministrationDosageRate
  route?: CodeableConcept
  site?: CodeableConcept
  text?: string
}

export interface MedicationAdministrationDosageRate {
  extension?: Extension[]
  modifierExtension?: Extension[]
  Quantity?: Quantity
  Ratio?: Ratio
}

export interface MedicationAdministrationEffective {
  dateTime?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
}

export interface MedicationAdministrationEventHistoryArray {
  resourceType?: RelevantHistoryResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationAdministrationMedication {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: IndigoReference
}

export interface IndigoReference {
  resourceType?: AssociatedMedicationResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum AssociatedMedicationResourceType {
  Medication = "Medication",
}

export interface MedicationAdministrationPartOfArray {
  resourceType?: Array18
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array18 {
  MedicationAdministration = "MedicationAdministration",
  Procedure = "Procedure",
}

export interface MedicationAdministrationPerformerArray {
  actor: FluffyActor
  extension?: Extension[]
  function?: CodeableConcept
  id?: string
  modifierExtension?: Extension[]
}

export interface FluffyActor {
  resourceType?: ParticipantResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ParticipantResourceType {
  Device = "Device",
  Patient = "Patient",
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
  RelatedPerson = "RelatedPerson",
}

export interface MedicationAdministrationReasonReferenceArray {
  resourceType?: Array19
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array19 {
  Condition = "Condition",
  DiagnosticReport = "DiagnosticReport",
  Observation = "Observation",
}

export interface MedicationAdministrationRequest {
  resourceType?: AuthorizingPrescriptionResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum AuthorizingPrescriptionResourceType {
  MedicationRequest = "MedicationRequest",
}

export interface MedicationAdministrationSubject {
  resourceType?: FriskyArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface EvidenceVariable {
  approvalDate?: string
  author?: ContactDetail[]
  characteristic: EvidenceVariableCharacteristicArray[]
  contact?: ContactDetail[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  copyright?: string
  date?: string
  description?: string
  editor?: ContactDetail[]
  effectivePeriod?: Period
  endorser?: ContactDetail[]
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  jurisdiction?: CodeableConcept[]
  language?: string
  lastReviewDate?: string
  meta?: Meta
  modifierExtension?: Extension[]
  name?: string
  note?: Annotation[]
  publisher?: string
  relatedArtifact?: RelatedArtifact[]
  resourceType?: string
  reviewer?: ContactDetail[]
  shortTitle?: string
  status: string
  subtitle?: string
  text?: Narrative
  title?: string
  topic?: CodeableConcept[]
  type?: string
  url?: string
  useContext?: UsageContext[]
  version?: string
}

export interface EvidenceVariableCharacteristicArray {
  definition?: EvidenceVariableCharacteristicArrayDefinition
  description?: string
  exclude?: boolean
  extension?: Extension[]
  groupMeasure?: string
  id?: string
  modifierExtension?: Extension[]
  participantEffective?: EvidenceVariableCharacteristicArrayParticipantEffective
  timeFromStart?: Duration
  usageContext?: UsageContext[]
}

export interface EvidenceVariableCharacteristicArrayDefinition {
  canonical?: string
  CodeableConcept?: CodeableConcept
  DataRequirement?: DataRequirement
  Expression?: Expression
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: DefinitionReference
  TriggerDefinition?: TriggerDefinition
}

export interface DefinitionReference {
  resourceType?: EnrollmentResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface EvidenceVariableCharacteristicArrayParticipantEffective {
  dateTime?: string
  Duration?: Duration
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
  Timing?: Timing
}

export interface ImplementationGuide {
  contact?: ContactDetail[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  copyright?: string
  date?: string
  definition?: ImplementationGuideDefinition
  dependsOn?: ImplementationGuideDependsOnArray[]
  description?: string
  experimental?: boolean
  extension?: Extension[]
  fhirVersion: string[]
  global?: ImplementationGuideGlobalArray[]
  id?: string
  implicitRules?: string
  jurisdiction?: CodeableConcept[]
  language?: string
  license?: string
  manifest?: ImplementationGuideManifest
  meta?: Meta
  modifierExtension?: Extension[]
  name: string
  packageId: string
  publisher?: string
  resourceType?: string
  status: string
  text?: Narrative
  title?: string
  url: string
  useContext?: UsageContext[]
  version?: string
}

export interface ImplementationGuideDefinition {
  extension?: Extension[]
  grouping?: ImplementationGuideDefinitionGroupingArray[]
  id?: string
  modifierExtension?: Extension[]
  page?: ImplementationGuideDefinitionPage
  parameter?: ImplementationGuideDefinitionParameterArray[]
  resource: ImplementationGuideDefinitionResourceArray[]
  template?: ImplementationGuideDefinitionTemplateArray[]
}

export interface ImplementationGuideDefinitionGroupingArray {
  description?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  name: string
}

export interface ImplementationGuideDefinitionPage {
  extension?: Extension[]
  generation: string
  id?: string
  modifierExtension?: Extension[]
  name?: ImplementationGuideDefinitionPageName
  page?: ImplementationGuideDefinitionPage[]
  title: string
}

export interface ImplementationGuideDefinitionPageName {
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: NameReference
  url?: string
}

export interface NameReference {
  resourceType?: Array21
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array21 {
  Binary = "Binary",
}

export interface ImplementationGuideDefinitionParameterArray {
  code: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  value: string
}

export interface ImplementationGuideDefinitionResourceArray {
  description?: string
  example?: ImplementationGuideDefinitionResourceArrayExample
  extension?: Extension[]
  fhirVersion?: string[]
  groupingId?: string
  id?: string
  modifierExtension?: Extension[]
  name?: string
  reference: Reference
}

export interface ImplementationGuideDefinitionResourceArrayExample {
  boolean?: boolean
  canonical?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface ImplementationGuideDefinitionTemplateArray {
  code: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  scope?: string
  source: string
}

export interface ImplementationGuideDependsOnArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  packageId?: string
  uri: string
  version?: string
}

export interface ImplementationGuideGlobalArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  profile: string
  type: string
}

export interface ImplementationGuideManifest {
  extension?: Extension[]
  id?: string
  image?: string[]
  modifierExtension?: Extension[]
  other?: string[]
  page?: ImplementationGuideManifestPageArray[]
  rendering?: string
  resource: ImplementationGuideManifestResourceArray[]
}

export interface ImplementationGuideManifestPageArray {
  anchor?: string[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  name: string
  title?: string
}

export interface ImplementationGuideManifestResourceArray {
  example?: ImplementationGuideManifestResourceArrayExample
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  reference: Reference
  relativePath?: string
}

export interface ImplementationGuideManifestResourceArrayExample {
  boolean?: boolean
  canonical?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface Lambda {
  code: string
  extension?: Extension[]
  hook: Hook
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
}

export enum Hook {
  Audit = "audit",
}

export interface Goal {
  achievementStatus?: CodeableConcept
  addresses?: GoalAddressesArray[]
  category?: CodeableConcept[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  description: CodeableConcept
  expressedBy?: ExpressedBy
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  lifecycleStatus: string
  meta?: Meta
  modifierExtension?: Extension[]
  note?: Annotation[]
  outcomeCode?: CodeableConcept[]
  outcomeReference?: GoalOutcomeReferenceArray[]
  priority?: CodeableConcept
  resourceType?: string
  start?: GoalStart
  statusDate?: string
  statusReason?: string
  subject: GoalSubject
  target?: GoalTargetArray[]
  text?: Narrative
}

export interface GoalAddressesArray {
  resourceType?: Array22
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array22 {
  Condition = "Condition",
  MedicationStatement = "MedicationStatement",
  NutritionOrder = "NutritionOrder",
  Observation = "Observation",
  RiskAssessment = "RiskAssessment",
  ServiceRequest = "ServiceRequest",
}

export interface ExpressedBy {
  resourceType?: ExpressedByResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface GoalOutcomeReferenceArray {
  resourceType?: OutcomeReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum OutcomeReferenceResourceType {
  Observation = "Observation",
}

export interface GoalStart {
  CodeableConcept?: CodeableConcept
  date?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface GoalSubject {
  resourceType?: Array23
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array23 {
  Group = "Group",
  Organization = "Organization",
  Patient = "Patient",
}

export interface GoalTargetArray {
  detail?: GoalTargetArrayDetail
  due?: GoalTargetArrayDue
  extension?: Extension[]
  id?: string
  measure?: CodeableConcept
  modifierExtension?: Extension[]
}

export interface GoalTargetArrayDetail {
  boolean?: boolean
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  integer?: number
  modifierExtension?: Extension[]
  Quantity?: Quantity
  Range?: Range
  Ratio?: Ratio
  string?: string
}

export interface GoalTargetArrayDue {
  date?: string
  Duration?: Duration
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface Communication {
  about?: Reference[]
  basedOn?: Reference[]
  category?: CodeableConcept[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  encounter?: CommunicationEncounter
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  inResponseTo?: CommunicationInResponseToArray[]
  instantiatesCanonical?: string[]
  instantiatesUri?: string[]
  language?: string
  medium?: CodeableConcept[]
  meta?: Meta
  modifierExtension?: Extension[]
  note?: Annotation[]
  partOf?: Reference[]
  payload?: CommunicationPayloadArray[]
  priority?: string
  reasonCode?: CodeableConcept[]
  reasonReference?: CommunicationReasonReferenceArray[]
  received?: string
  recipient?: CommunicationRecipientArray[]
  resourceType?: string
  sender?: CommunicationSender
  sent?: string
  status: string
  statusReason?: CodeableConcept
  subject?: CommunicationSubject
  text?: Narrative
  topic?: CodeableConcept
}

export interface CommunicationEncounter {
  resourceType?: TentacledArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CommunicationInResponseToArray {
  resourceType?: InResponseToResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum InResponseToResourceType {
  Communication = "Communication",
}

export interface CommunicationPayloadArray {
  content?: CommunicationPayloadArrayContent
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
}

export interface CommunicationPayloadArrayContent {
  Attachment?: Attachment
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference
  string?: string
}

export interface CommunicationReasonReferenceArray {
  resourceType?: StickyArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CommunicationRecipientArray {
  resourceType?: Array24
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array24 {
  CareTeam = "CareTeam",
  Device = "Device",
  Group = "Group",
  HealthcareService = "HealthcareService",
  Organization = "Organization",
  Patient = "Patient",
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
  RelatedPerson = "RelatedPerson",
}

export interface CommunicationSender {
  resourceType?: Array25
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array25 {
  Device = "Device",
  HealthcareService = "HealthcareService",
  Organization = "Organization",
  Patient = "Patient",
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
  RelatedPerson = "RelatedPerson",
}

export interface CommunicationSubject {
  resourceType?: FriskyArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface AlphaSDC {
  description?: string
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
  template?: AlphaSDCTemplate
}

export interface AlphaSDCTemplate {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface Schedule {
  active?: boolean
  actor: ScheduleActorArray[]
  comment?: string
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  planningHorizon?: Period
  resourceType?: string
  serviceCategory?: CodeableConcept[]
  serviceType?: CodeableConcept[]
  specialty?: CodeableConcept[]
  text?: Narrative
}

export interface ScheduleActorArray {
  resourceType?: Array3
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Notification {
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  provider?: string
  providerData?: NotificationProviderData
  resourceType?: string
  status?: NotificationStatus
}

export interface NotificationProviderData {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export enum NotificationStatus {
  Delivered = "delivered",
  Error = "error",
}

export interface DocumentReference {
  authenticator?: Authenticator
  author?: DocumentReferenceAuthorArray[]
  category?: CodeableConcept[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  content: DocumentReferenceContentArray[]
  context?: DocumentReferenceContext
  custodian?: DocumentReferenceCustodian
  date?: Date
  description?: string
  docStatus?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  masterIdentifier?: Identifier
  meta?: Meta
  modifierExtension?: Extension[]
  outdated?: boolean
  relatesTo?: DocumentReferenceRelatesToArray[]
  resourceType?: string
  securityLabel?: CodeableConcept[]
  status: string
  subject?: DocumentReferenceSubject
  text?: Narrative
  type?: CodeableConcept
}

export interface Authenticator {
  resourceType?: AuthenticatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface DocumentReferenceAuthorArray {
  resourceType?: ObserverResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface DocumentReferenceContentArray {
  attachment: Attachment
  extension?: Extension[]
  format?: Coding
  id?: string
  modifierExtension?: Extension[]
}

export interface DocumentReferenceContext {
  encounter?: DocumentReferenceContextEncounterArray[]
  event?: CodeableConcept[]
  extension?: Extension[]
  facilityType?: CodeableConcept
  id?: string
  modifierExtension?: Extension[]
  period?: Period
  practiceSetting?: CodeableConcept
  related?: Reference[]
  sourcePatientInfo?: SourcePatientInfo
}

export interface DocumentReferenceContextEncounterArray {
  resourceType?: ContextResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SourcePatientInfo {
  resourceType?: CandidateResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface DocumentReferenceCustodian {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface DocumentReferenceRelatesToArray {
  code: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  target: RelatesToTarget
}

export interface RelatesToTarget {
  resourceType?: ReferenceDocumentResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface DocumentReferenceSubject {
  resourceType?: Array26
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array26 {
  Device = "Device",
  Group = "Group",
  Patient = "Patient",
  Practitioner = "Practitioner",
}

export interface AidboxJobStatus {
  error?: AidboxJobStatusError
  extension?: Extension[]
  id?: string
  job?: Reference
  locked?: boolean
  meta?: Meta
  modifierExtension?: Extension[]
  module?: string
  nextStart?: string
  resourceType?: string
  result?: AidboxJobStatusResult
  start?: string
  status?: string
  stop?: string
  text?: string
}

export interface AidboxJobStatusError {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface AidboxJobStatusResult {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface OrganizationAffiliation {
  active?: boolean
  code?: CodeableConcept[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  endpoint?: OrganizationAffiliationEndpointArray[]
  extension?: Extension[]
  healthcareService?: OrganizationAffiliationHealthcareServiceArray[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  location?: OrganizationAffiliationLocationArray[]
  meta?: Meta
  modifierExtension?: Extension[]
  network?: OrganizationAffiliationNetworkArray[]
  organization?: OrganizationAffiliationOrganization
  participatingOrganization?: ParticipatingOrganization
  period?: Period
  resourceType?: string
  specialty?: CodeableConcept[]
  telecom?: ContactPoint[]
  text?: Narrative
}

export interface OrganizationAffiliationEndpointArray {
  resourceType?: EndpointResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum EndpointResourceType {
  Endpoint = "Endpoint",
}

export interface OrganizationAffiliationHealthcareServiceArray {
  resourceType?: HealthcareServiceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum HealthcareServiceResourceType {
  HealthcareService = "HealthcareService",
}

export interface OrganizationAffiliationLocationArray {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface OrganizationAffiliationNetworkArray {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface OrganizationAffiliationOrganization {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ParticipatingOrganization {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Registration {
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  params?: RegistrationParams
  resource?: RegistrationResource
  resourceType?: string
  status?: RegistrationStatus
}

export interface RegistrationParams {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface RegistrationResource {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export enum RegistrationStatus {
  Activated = "activated",
  Active = "active",
}

export interface DeviceDefinition {
  capability?: DeviceDefinitionCapabilityArray[]
  contact?: ContactPoint[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  deviceName?: DeviceDefinitionDeviceNameArray[]
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  languageCode?: CodeableConcept[]
  manufacturer?: DeviceDefinitionManufacturer
  material?: DeviceDefinitionMaterialArray[]
  meta?: Meta
  modelNumber?: string
  modifierExtension?: Extension[]
  note?: Annotation[]
  onlineInformation?: string
  owner?: DeviceDefinitionOwner
  parentDevice?: ParentDevice
  physicalCharacteristics?: ProdCharacteristic
  property?: DeviceDefinitionPropertyArray[]
  quantity?: Quantity
  resourceType?: string
  safety?: CodeableConcept[]
  shelfLifeStorage?: ProductShelfLife[]
  specialization?: DeviceDefinitionSpecializationArray[]
  text?: Narrative
  type?: CodeableConcept
  udiDeviceIdentifier?: DeviceDefinitionUdiDeviceIdentifierArray[]
  url?: string
  version?: string[]
}

export interface DeviceDefinitionCapabilityArray {
  description?: CodeableConcept[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  type: CodeableConcept
}

export interface DeviceDefinitionDeviceNameArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  name: string
  type: string
}

export interface DeviceDefinitionManufacturer {
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: ManufacturerReference
  string?: string
}

export interface ManufacturerReference {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface DeviceDefinitionMaterialArray {
  allergenicIndicator?: boolean
  alternate?: boolean
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  substance: CodeableConcept
}

export interface DeviceDefinitionOwner {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ParentDevice {
  resourceType?: ParentDeviceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ParentDeviceResourceType {
  DeviceDefinition = "DeviceDefinition",
}

export interface ProdCharacteristic {
  color?: string[]
  depth?: Quantity
  extension?: Extension[]
  externalDiameter?: Quantity
  height?: Quantity
  id?: string
  image?: Attachment[]
  imprint?: string[]
  modifierExtension?: Extension[]
  nominalVolume?: Quantity
  scoring?: CodeableConcept
  shape?: string
  weight?: Quantity
  width?: Quantity
}

export interface DeviceDefinitionPropertyArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  type: CodeableConcept
  valueCode?: CodeableConcept[]
  valueQuantity?: Quantity[]
}

export interface ProductShelfLife {
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  modifierExtension?: Extension[]
  period: Quantity
  specialPrecautionsForStorage?: CodeableConcept[]
  type: CodeableConcept
}

export interface DeviceDefinitionSpecializationArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  systemType: string
  version?: string
}

export interface DeviceDefinitionUdiDeviceIdentifierArray {
  deviceIdentifier: string
  extension?: Extension[]
  id?: string
  issuer: string
  jurisdiction: string
  modifierExtension?: Extension[]
}

export interface CronJobStatus {
  extension?: Extension[]
  id?: string
  lastRun?: CronJobStatusLastRun
  meta?: Meta
  modifierExtension?: Extension[]
  nextRun?: string
  numFails?: number
  resourceType?: string
  status?: CronJobStatusStatus
}

export interface CronJobStatusLastRun {
  end?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  start?: string
}

export enum CronJobStatusStatus {
  Failed = "failed",
  Running = "running",
  Scheduled = "scheduled",
  Stop = "stop",
}

export interface SettingDefinition {
  accessPolicy?: CodeableConcept
  category?: CodeableConcept
  code: Coding
  date?: string
  description?: string
  extension?: Extension[]
  id?: string
  item?: SettingDefinitionItemArray[]
  meta?: Meta
  modifierExtension?: Extension[]
  name?: string
  resourceType?: "SettingDefinition"
  scope?: CodeableConcept
  status: string
}

export const isSettingDefinition = (
  resource?: any[] | boolean | number | number | null | ResourceObject | string,
): resource is SettingDefinition => isResourceObject(resource) && resource.resourceType === "SettingDefinition"

export interface SettingDefinitionItemArray {
  code?: Coding
  default?: SettingDefinitionItemArrayDefault
  description?: string
  extension?: Extension[]
  kind: Coding
  label?: string
  modifierExtension?: Extension[]
  required?: boolean
  secure?: boolean
  type: string
  flag?: CodeableConcept[]
}

export interface SettingDefinitionItemArrayDefault {
  Attachment?: Attachment
  boolean?: boolean
  Coding?: Coding
  decimal?: number
  extension?: Extension[]
  integer?: number
  modifierExtension?: Extension[]
  Reference?: Reference
  string?: string
  time?: string
  uri?: string
}

export interface Map {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
}

export interface Coverage {
  beneficiary: Beneficiary
  class?: CoverageClassArray[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  contract?: CoverageContractArray[]
  costToBeneficiary?: CoverageCostToBeneficiaryArray[]
  dependent?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  network?: string
  order?: number
  payor: CoveragePayorArray[]
  period?: Period
  policyHolder?: PolicyHolder
  relationship?: CodeableConcept
  resourceType?: string
  status: string
  subrogation?: boolean
  subscriber?: Subscriber
  subscriberId?: string
  text?: Narrative
  type?: CodeableConcept
}

export const isCoverage = (
  resource?: any[] | boolean | number | number | null | ResourceObject | string,
): resource is Coverage => isResourceObject(resource) && resource.resourceType === "Coverage"

export interface Beneficiary {
  resourceType?: CandidateResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CoverageClassArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  name?: string
  type: CodeableConcept
  value: string
}

export interface CoverageContractArray {
  resourceType?: ContractResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ContractResourceType {
  Contract = "Contract",
}

export interface CoverageCostToBeneficiaryArray {
  exception?: CoverageCostToBeneficiaryArrayExceptionArray[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  type?: CodeableConcept
  value?: CoverageCostToBeneficiaryArrayValue
}

export interface CoverageCostToBeneficiaryArrayExceptionArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  period?: Period
  type: CodeableConcept
}

export interface CoverageCostToBeneficiaryArrayValue {
  extension?: Extension[]
  modifierExtension?: Extension[]
  Money?: Money
  Quantity?: Quantity
}

export interface CoveragePayorArray {
  resourceType?: PayorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum PayorResourceType {
  Organization = "Organization",
  Patient = "Patient",
  RelatedPerson = "RelatedPerson",
}

export interface PolicyHolder {
  resourceType?: PayorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Subscriber {
  resourceType?: SubscriberResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum SubscriberResourceType {
  Patient = "Patient",
  RelatedPerson = "RelatedPerson",
}

export interface AuditEvent {
  action?: string
  agent: AuditEventAgentArray[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  entity?: AuditEventEntityArray[]
  extension?: Extension[]
  id?: string
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  outcome?: string
  outcomeDesc?: string
  period?: Period
  purposeOfEvent?: CodeableConcept[]
  recorded: Date
  resourceType?: string
  source: AuditEventSource
  subtype?: Coding[]
  text?: Narrative
  type: Coding
}

export interface AuditEventAgentArray {
  altId?: string
  extension?: Extension[]
  id?: string
  location?: AgentLocation
  media?: Coding
  modifierExtension?: Extension[]
  name?: string
  network?: AuditEventAgentArrayNetwork
  policy?: string[]
  purposeOfUse?: CodeableConcept[]
  requestor: boolean
  role?: CodeableConcept[]
  type?: CodeableConcept
  who?: PurpleWho
}

export interface AgentLocation {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface AuditEventAgentArrayNetwork {
  address?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  type?: string
}

export interface PurpleWho {
  resourceType?: ObserverResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface AuditEventEntityArray {
  description?: string
  detail?: AuditEventEntityArrayDetailArray[]
  extension?: Extension[]
  id?: string
  lifecycle?: Coding
  modifierExtension?: Extension[]
  name?: string
  query?: string
  role?: Coding
  securityLabel?: Coding[]
  type?: Coding
  what?: Reference
}

export interface AuditEventEntityArrayDetailArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  type: string
  value?: AuditEventEntityArrayDetailArrayValue
}

export interface AuditEventEntityArrayDetailArrayValue {
  base64Binary?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  string?: string
}

export interface AuditEventSource {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  observer: Observer
  site?: string
  type?: Coding[]
}

export interface Observer {
  resourceType?: ObserverResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface AidboxTaskExecution {
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
}

export interface MessageHeader {
  author?: MessageHeaderAuthor
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  definition?: string
  destination?: MessageHeaderDestinationArray[]
  enterer?: MessageHeaderEnterer
  event?: MessageHeaderEvent
  extension?: Extension[]
  focus?: Reference[]
  id?: string
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  reason?: CodeableConcept
  resourceType?: string
  response?: MessageHeaderResponse
  responsible?: MessageHeaderResponsible
  sender?: MessageHeaderSender
  source: MessageHeaderSource
  text?: Narrative
}

export interface MessageHeaderAuthor {
  resourceType?: CollectorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MessageHeaderDestinationArray {
  endpoint: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  name?: string
  receiver?: Receiver
  target?: DestinationTarget
}

export interface Receiver {
  resourceType?: AuthenticatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface DestinationTarget {
  resourceType?: UdiResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MessageHeaderEnterer {
  resourceType?: CollectorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MessageHeaderEvent {
  Coding?: Coding
  extension?: Extension[]
  modifierExtension?: Extension[]
  uri?: string
}

export interface MessageHeaderResponse {
  code: string
  details?: Details
  extension?: Extension[]
  id?: string
  identifier: string
  modifierExtension?: Extension[]
}

export interface Details {
  resourceType?: DetailsResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum DetailsResourceType {
  OperationOutcome = "OperationOutcome",
}

export interface MessageHeaderResponsible {
  resourceType?: AuthenticatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MessageHeaderSender {
  resourceType?: AuthenticatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MessageHeaderSource {
  contact?: ContactPoint
  endpoint: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  name?: string
  software?: string
  version?: string
}

export interface DisabledIndex {
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
}

export interface SimpleQuantity {
  code?: string
  comparator?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  system?: string
  unit?: string
  value?: number
}

export interface Contract {
  alias?: string[]
  applies?: Period
  author?: ContractAuthor
  authority?: ContractAuthorityArray[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  contentDefinition?: ContractContentDefinition
  contentDerivative?: CodeableConcept
  domain?: ContractDomainArray[]
  expirationType?: CodeableConcept
  extension?: Extension[]
  friendly?: ContractFriendlyArray[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  instantiatesCanonical?: InstantiatesCanonical
  instantiatesUri?: string
  issued?: string
  language?: string
  legal?: ContractLegalArray[]
  legallyBinding?: ContractLegallyBinding
  legalState?: CodeableConcept
  meta?: Meta
  modifierExtension?: Extension[]
  name?: string
  relevantHistory?: ContractRelevantHistoryArray[]
  resourceType?: string
  rule?: ContractRuleArray[]
  scope?: CodeableConcept
  signer?: ContractSignerArray[]
  site?: ContractSiteArray[]
  status?: string
  subject?: Reference[]
  subtitle?: string
  subType?: CodeableConcept[]
  supportingInfo?: Reference[]
  term?: ContractTermArrayArray[]
  text?: Narrative
  title?: string
  topic?: ContractTopic
  type?: CodeableConcept
  url?: string
  version?: string
}

export interface ContractAuthor {
  resourceType?: Array27
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array27 {
  Organization = "Organization",
  Patient = "Patient",
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
}

export interface ContractAuthorityArray {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ContractContentDefinition {
  copyright?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  publicationDate?: string
  publicationStatus: string
  publisher?: Publisher
  subType?: CodeableConcept
  type: CodeableConcept
}

export interface Publisher {
  resourceType?: AuthenticatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ContractDomainArray {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ContractFriendlyArray {
  content?: ContractFriendlyArrayContent
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
}

export interface ContractFriendlyArrayContent {
  Attachment?: Attachment
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: IndecentReference
}

export interface IndecentReference {
  resourceType?: Array28
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array28 {
  Composition = "Composition",
  DocumentReference = "DocumentReference",
  QuestionnaireResponse = "QuestionnaireResponse",
}

export interface InstantiatesCanonical {
  resourceType?: ContractResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ContractLegalArray {
  content?: ContractLegalArrayContent
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
}

export interface ContractLegalArrayContent {
  Attachment?: Attachment
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: HilariousReference
}

export interface HilariousReference {
  resourceType?: Array28
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ContractLegallyBinding {
  Attachment?: Attachment
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: LegallyBindingReference
}

export interface LegallyBindingReference {
  resourceType?: Array29
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array29 {
  Composition = "Composition",
  Contract = "Contract",
  DocumentReference = "DocumentReference",
  QuestionnaireResponse = "QuestionnaireResponse",
}

export interface ContractRelevantHistoryArray {
  resourceType?: RelevantHistoryResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ContractRuleArray {
  content?: ContractRuleArrayContent
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
}

export interface ContractRuleArrayContent {
  Attachment?: Attachment
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: AmbitiousReference
}

export interface AmbitiousReference {
  resourceType?: ReferenceDocumentResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ContractSignerArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  party: SignerParty
  signature: Signature[]
  type: Coding
}

export interface SignerParty {
  resourceType?: InformationSourceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum InformationSourceResourceType {
  Organization = "Organization",
  Patient = "Patient",
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
  RelatedPerson = "RelatedPerson",
}

export interface ContractSiteArray {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ContractTermArrayArray {
  action?: ContractTermArrayActionArray[]
  applies?: Period
  asset?: ContractTermArrayAssetArray[]
  extension?: Extension[]
  group?: ContractTermArrayArray[]
  id?: string
  identifier?: Identifier
  issued?: string
  modifierExtension?: Extension[]
  offer: ContractTermArrayOffer
  securityLabel?: ContractTermArraySecurityLabelArray[]
  subType?: CodeableConcept
  text?: string
  topic?: ContractTermArrayTopic
  type?: CodeableConcept
}

export interface ContractTermArrayActionArray {
  context?: ActionContext
  contextLinkId?: string[]
  doNotPerform?: boolean
  extension?: Extension[]
  id?: string
  intent: CodeableConcept
  linkId?: string[]
  modifierExtension?: Extension[]
  note?: Annotation[]
  occurrence?: ContractTermArrayActionArrayOccurrence
  performer?: ActionPerformer
  performerLinkId?: string[]
  performerRole?: CodeableConcept
  performerType?: CodeableConcept[]
  reason?: string[]
  reasonCode?: CodeableConcept[]
  reasonLinkId?: string[]
  reasonReference?: ContractTermArrayActionArrayReasonReferenceArray[]
  requester?: ContractTermArrayActionArrayRequesterArray[]
  requesterLinkId?: string[]
  securityLabelNumber?: number[]
  status: CodeableConcept
  subject?: ContractTermArrayActionArraySubjectArray[]
  type: CodeableConcept
}

export interface ActionContext {
  resourceType?: ContextResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ContractTermArrayActionArrayOccurrence {
  dateTime?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
  Timing?: Timing
}

export interface ActionPerformer {
  resourceType?: Array30
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array30 {
  CareTeam = "CareTeam",
  Device = "Device",
  Location = "Location",
  Organization = "Organization",
  Patient = "Patient",
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
  RelatedPerson = "RelatedPerson",
  Substance = "Substance",
}

export interface ContractTermArrayActionArrayReasonReferenceArray {
  resourceType?: Array31
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array31 {
  Condition = "Condition",
  DiagnosticReport = "DiagnosticReport",
  DocumentReference = "DocumentReference",
  Observation = "Observation",
  Questionnaire = "Questionnaire",
  QuestionnaireResponse = "QuestionnaireResponse",
}

export interface ContractTermArrayActionArrayRequesterArray {
  resourceType?: Array32
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array32 {
  Device = "Device",
  Group = "Group",
  Organization = "Organization",
  Patient = "Patient",
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
  RelatedPerson = "RelatedPerson",
}

export interface ContractTermArrayActionArraySubjectArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  reference: ContractTermArrayActionArraySubjectArrayReferenceArray[]
  role?: CodeableConcept
}

export interface ContractTermArrayActionArraySubjectArrayReferenceArray {
  resourceType?: Array32
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ContractTermArrayAssetArray {
  answer?: ContractTermArrayAnswerArray[]
  condition?: string
  context?: ContractTermArrayAssetArrayContextArray[]
  extension?: Extension[]
  id?: string
  linkId?: string[]
  modifierExtension?: Extension[]
  period?: Period[]
  periodType?: CodeableConcept[]
  relationship?: Coding
  scope?: CodeableConcept
  securityLabelNumber?: number[]
  subtype?: CodeableConcept[]
  text?: string
  type?: CodeableConcept[]
  typeReference?: Reference[]
  usePeriod?: Period[]
  valuedItem?: ContractTermArrayAssetArrayValuedItemArray[]
}

export interface ContractTermArrayAnswerArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  value?: ContractTermArrayOfferAnswerArrayValue
}

export interface ContractTermArrayOfferAnswerArrayValue {
  Attachment?: Attachment
  boolean?: boolean
  Coding?: Coding
  date?: string
  dateTime?: string
  decimal?: number
  extension?: Extension[]
  integer?: number
  modifierExtension?: Extension[]
  Quantity?: Quantity
  Reference?: Reference
  string?: string
  time?: string
  uri?: string
}

export interface ContractTermArrayAssetArrayContextArray {
  code?: CodeableConcept[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  reference?: Reference
  text?: string
}

export interface ContractTermArrayAssetArrayValuedItemArray {
  effectiveTime?: string
  entity?: ContractTermArrayAssetArrayValuedItemArrayEntity
  extension?: Extension[]
  factor?: number
  id?: string
  identifier?: Identifier
  linkId?: string[]
  modifierExtension?: Extension[]
  net?: Money
  payment?: string
  paymentDate?: string
  points?: number
  quantity?: Quantity
  recipient?: ValuedItemRecipient
  responsible?: ValuedItemResponsible
  securityLabelNumber?: number[]
  unitPrice?: Money
}

export interface ContractTermArrayAssetArrayValuedItemArrayEntity {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference
}

export interface ValuedItemRecipient {
  resourceType?: InformationSourceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ValuedItemResponsible {
  resourceType?: InformationSourceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ContractTermArrayOffer {
  answer?: ContractTermArrayAnswerArray[]
  decision?: CodeableConcept
  decisionMode?: CodeableConcept[]
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  linkId?: string[]
  modifierExtension?: Extension[]
  party?: ContractTermArrayOfferPartyArray[]
  securityLabelNumber?: number[]
  text?: string
  topic?: Reference
  type?: CodeableConcept
}

export interface ContractTermArrayOfferPartyArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  reference: ContractTermArrayOfferPartyArrayReferenceArray[]
  role: CodeableConcept
}

export interface ContractTermArrayOfferPartyArrayReferenceArray {
  resourceType?: Array32
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ContractTermArraySecurityLabelArray {
  category?: Coding[]
  classification: Coding
  control?: Coding[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  number?: number[]
}

export interface ContractTermArrayTopic {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference
}

export interface ContractTopic {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference
}

export interface SDCDocument {
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
}

export interface CodeSystem {
  caseSensitive?: boolean
  compositional?: boolean
  concept?: CodeSystemConceptArrayConceptArray[]
  contact?: ContactDetail[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  content: string
  copyright?: string
  count?: number
  date?: string
  description?: string
  experimental?: boolean
  extension?: Extension[]
  filter?: CodeSystemFilterArray[]
  hierarchyMeaning?: string
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  jurisdiction?: CodeableConcept[]
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  name?: string
  property?: CodeSystemPropertyArray[]
  publisher?: string
  purpose?: string
  resourceType?: string
  status: string
  supplements?: string
  text?: Narrative
  title?: string
  url?: string
  useContext?: UsageContext[]
  valueSet?: string
  version?: string
  versionNeeded?: boolean
}

export interface CodeSystemConceptArrayConceptArray {
  code: string
  concept?: CodeSystemConceptArrayConceptArray[]
  definition?: string
  designation?: CodeSystemConceptArrayDesignationArray[]
  display?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  property?: CodeSystemConceptArrayPropertyArray[]
}

export interface CodeSystemConceptArrayDesignationArray {
  extension?: Extension[]
  id?: string
  language?: string
  modifierExtension?: Extension[]
  use?: Coding
  value: string
}

export interface CodeSystemConceptArrayPropertyArray {
  code: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  value?: CodeSystemConceptArrayPropertyArrayValue
}

export interface CodeSystemConceptArrayPropertyArrayValue {
  boolean?: boolean
  code?: string
  Coding?: Coding
  dateTime?: string
  decimal?: number
  extension?: Extension[]
  integer?: number
  modifierExtension?: Extension[]
  string?: string
}

export interface CodeSystemFilterArray {
  code: string
  description?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  operator: string[]
  value: string
}

export interface CodeSystemPropertyArray {
  code: string
  description?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  type: string
  uri?: string
}

export interface Element {
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
}

export interface PlanDefinition {
  action?: PlanDefinitionActionArrayActionArray[]
  approvalDate?: string
  author?: ContactDetail[]
  catalogHeader?: Reference[]
  contact?: ContactDetail[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  copyright?: string
  date?: string
  description?: string
  editor?: ContactDetail[]
  effectivePeriod?: Period
  endorser?: ContactDetail[]
  experimental?: boolean
  extension?: Extension[]
  goal?: PlanDefinitionGoalArray[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  jurisdiction?: CodeableConcept[]
  language?: string
  lastReviewDate?: string
  library?: string[]
  logo?: Attachment[]
  meta?: Meta
  modifierExtension?: Extension[]
  name?: string
  publisher?: string
  purpose?: string
  relatedArtifact?: RelatedArtifact[]
  resourceType?: "PlanDefinition"
  reviewer?: ContactDetail[]
  status: string
  subject?: PlanDefinitionSubject
  subtitle?: string
  text?: Narrative
  title?: string
  topic?: CodeableConcept[]
  type?: CodeableConcept
  url?: string
  usage?: string
  useContext?: UsageContext[]
  version?: string
  excludedOrganization?: Reference[]
}

export interface PlanDefinitionActionArrayActionArray {
  action?: PlanDefinitionActionArrayActionArray[]
  cardinalityBehavior?: string
  code?: CodeableConcept[]
  condition?: PlanDefinitionActionArrayConditionArray[]
  definition?: PlanDefinitionActionArrayDefinition
  description?: string
  documentation?: RelatedArtifact[]
  dynamicValue?: PlanDefinitionActionArrayDynamicValueArray[]
  extension?: Extension[]
  goalId?: string[]
  groupingBehavior?: string
  id?: string
  input?: DataRequirement[]
  modifierExtension?: Extension[]
  output?: DataRequirement[]
  participant?: PlanDefinitionActionArrayParticipantArray[]
  precheckBehavior?: string
  prefix?: string
  priority?: string
  reason?: CodeableConcept[]
  relatedAction?: PlanDefinitionActionArrayRelatedActionArray[]
  requiredBehavior?: string
  selectionBehavior?: string
  subject?: PlanDefinitionActionArraySubject
  textEquivalent?: string
  timing?: PlanDefinitionActionArrayTiming
  title?: string
  transform?: string
  trigger?: TriggerDefinition[]
  type?: CodeableConcept
}

export interface PlanDefinitionActionArrayConditionArray {
  expression?: Expression
  extension?: Extension[]
  id?: string
  kind: string
  modifierExtension?: Extension[]
}

export interface PlanDefinitionActionArrayDefinition {
  canonical?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  uri?: string
}

export interface PlanDefinitionActionArrayDynamicValueArray {
  expression?: Expression
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  path?: string
}

export interface PlanDefinitionActionArrayParticipantArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  role?: CodeableConcept
  type: string
}

export interface PlanDefinitionActionArrayRelatedActionArray {
  actionId: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  offset?: PlanDefinitionActionArrayRelatedActionArrayOffset
  relationship: string
}

export interface PlanDefinitionActionArrayRelatedActionArrayOffset {
  Duration?: Duration
  extension?: Extension[]
  modifierExtension?: Extension[]
  Range?: Range
}

export interface PlanDefinitionActionArraySubject {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: CunningReference
}

export interface CunningReference {
  resourceType?: EnrollmentResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface PlanDefinitionActionArrayTiming {
  Age?: Age
  dateTime?: string
  Duration?: Duration
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
  Range?: Range
  Timing?: Timing
}

export interface PlanDefinitionCatalogHeaderArray {
  resourceType?: CatalogHeaderResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum CatalogHeaderResourceType {
  Composition = "Composition",
}

export interface PlanDefinitionGoalArray {
  addresses?: CodeableConcept[]
  category?: CodeableConcept
  description: CodeableConcept
  documentation?: RelatedArtifact[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  priority?: CodeableConcept
  start?: CodeableConcept
  target?: PlanDefinitionGoalArrayTargetArray[]
}

export interface PlanDefinitionGoalArrayTargetArray {
  detail?: PlanDefinitionGoalArrayTargetArrayDetail
  due?: Duration
  extension?: Extension[]
  id?: string
  measure?: CodeableConcept
  modifierExtension?: Extension[]
}

export interface PlanDefinitionGoalArrayTargetArrayDetail {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Quantity?: Quantity
  Range?: Range
}

export interface PlanDefinitionSubject {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: MagentaReference
}

export interface MagentaReference {
  resourceType?: EnrollmentResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Invoice {
  account?: InvoiceAccount
  cancelledReason?: string
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  date?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  issuer?: InvoiceIssuer
  language?: string
  lineItem?: InvoiceLineItemArray[]
  meta?: Meta
  modifierExtension?: Extension[]
  note?: Annotation[]
  participant?: InvoiceParticipantArray[]
  paymentTerms?: string
  recipient?: InvoiceRecipient
  resourceType?: string
  status: string
  subject?: InvoiceSubject
  text?: Narrative
  totalGross?: Money
  totalNet?: Money
  totalPriceComponent?: InvoicePriceComponentArray[]
  type?: CodeableConcept
}

export interface InvoiceAccount {
  resourceType?: Array11
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface InvoiceIssuer {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface InvoiceLineItemArray {
  chargeItem?: InvoiceLineItemArrayChargeItem
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  priceComponent?: InvoicePriceComponentArray[]
  sequence?: number
}

export interface InvoiceLineItemArrayChargeItem {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: ChargeItemReference
}

export interface ChargeItemReference {
  resourceType?: Array33
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array33 {
  ChargeItem = "ChargeItem",
}

export interface InvoicePriceComponentArray {
  amount?: Money
  code?: CodeableConcept
  extension?: Extension[]
  factor?: number
  id?: string
  modifierExtension?: Extension[]
  type: string
}

export interface InvoiceParticipantArray {
  actor: TentacledActor
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  role?: CodeableConcept
}

export interface TentacledActor {
  resourceType?: ObserverResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface InvoiceRecipient {
  resourceType?: PayorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface InvoiceSubject {
  resourceType?: FriskyArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface AidboxProfile {
  bind: Reference
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
  schema: { [key: string]: any }
}

export interface ClaimResponse {
  addItem?: ClaimResponseAddItemArray[]
  adjudication?: ClaimResponseAdjudicationArray[]
  communicationRequest?: ClaimResponseCommunicationRequestArray[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  created: string
  disposition?: string
  error?: ClaimResponseErrorArray[]
  extension?: Extension[]
  form?: Attachment
  formCode?: CodeableConcept
  fundsReserve?: CodeableConcept
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  insurance?: ClaimResponseInsuranceArray[]
  insurer: ClaimResponseInsurer
  item?: ClaimResponseItemArray[]
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  outcome: string
  patient: ClaimResponsePatient
  payeeType?: CodeableConcept
  payment?: ClaimResponsePayment
  preAuthPeriod?: Period
  preAuthRef?: string
  processNote?: ClaimResponseProcessNoteArray[]
  request?: ClaimResponseRequest
  requestor?: ClaimResponseRequestor
  resourceType?: string
  status: string
  subType?: CodeableConcept
  text?: Narrative
  total?: ClaimResponseTotalArray[]
  type: CodeableConcept
  use: string
}

export interface ClaimResponseAddItemArray {
  adjudication: ClaimResponseAdjudicationArray[]
  bodySite?: CodeableConcept
  detail?: ClaimResponseAddItemArrayDetailArray[]
  detailSequence?: number[]
  extension?: Extension[]
  factor?: number
  id?: string
  itemSequence?: number[]
  location?: ClaimResponseAddItemArrayLocation
  modifier?: CodeableConcept[]
  modifierExtension?: Extension[]
  net?: Money
  noteNumber?: number[]
  productOrService: CodeableConcept
  programCode?: CodeableConcept[]
  provider?: ClaimResponseAddItemArrayProviderArray[]
  quantity?: Quantity
  serviced?: ClaimResponseAddItemArrayServiced
  subdetailSequence?: number[]
  subSite?: CodeableConcept[]
  unitPrice?: Money
}

export interface ClaimResponseAdjudicationArray {
  amount?: Money
  category: CodeableConcept
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  reason?: CodeableConcept
  value?: number
}

export interface ClaimResponseAddItemArrayDetailArray {
  adjudication: ClaimResponseAdjudicationArray[]
  extension?: Extension[]
  factor?: number
  id?: string
  modifier?: CodeableConcept[]
  modifierExtension?: Extension[]
  net?: Money
  noteNumber?: number[]
  productOrService: CodeableConcept
  quantity?: Quantity
  subDetail?: ClaimResponseAddItemArrayDetailArraySubDetailArray[]
  unitPrice?: Money
}

export interface ClaimResponseAddItemArrayDetailArraySubDetailArray {
  adjudication: ClaimResponseAdjudicationArray[]
  extension?: Extension[]
  factor?: number
  id?: string
  modifier?: CodeableConcept[]
  modifierExtension?: Extension[]
  net?: Money
  noteNumber?: number[]
  productOrService: CodeableConcept
  quantity?: Quantity
  unitPrice?: Money
}

export interface ClaimResponseAddItemArrayLocation {
  Address?: Address
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: FriskyReference
}

export interface FriskyReference {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ClaimResponseAddItemArrayProviderArray {
  resourceType?: AuthenticatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ClaimResponseAddItemArrayServiced {
  date?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
}

export interface ClaimResponseCommunicationRequestArray {
  resourceType?: CommunicationRequestResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum CommunicationRequestResourceType {
  CommunicationRequest = "CommunicationRequest",
}

export interface ClaimResponseErrorArray {
  code: CodeableConcept
  detailSequence?: number
  extension?: Extension[]
  id?: string
  itemSequence?: number
  modifierExtension?: Extension[]
  subDetailSequence?: number
}

export interface ClaimResponseInsuranceArray {
  businessArrangement?: string
  claimResponse?: PurpleClaimResponse
  coverage: PurpleCoverage
  extension?: Extension[]
  focal: boolean
  id?: string
  modifierExtension?: Extension[]
  sequence: number
}

export interface PurpleClaimResponse {
  resourceType?: ClaimResponseResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ClaimResponseResourceType {
  ClaimResponse = "ClaimResponse",
}

export interface PurpleCoverage {
  resourceType?: CoverageResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ClaimResponseInsurer {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ClaimResponseItemArray {
  adjudication: ClaimResponseAdjudicationArray[]
  detail?: ClaimResponseItemArrayDetailArray[]
  extension?: Extension[]
  id?: string
  itemSequence: number
  modifierExtension?: Extension[]
  noteNumber?: number[]
}

export interface ClaimResponseItemArrayDetailArray {
  adjudication: ClaimResponseAdjudicationArray[]
  detailSequence: number
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  noteNumber?: number[]
  subDetail?: ClaimResponseItemArrayDetailArraySubDetailArray[]
}

export interface ClaimResponseItemArrayDetailArraySubDetailArray {
  adjudication?: ClaimResponseAdjudicationArray[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  noteNumber?: number[]
  subDetailSequence: number
}

export interface ClaimResponsePatient {
  resourceType?: CandidateResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ClaimResponsePayment {
  adjustment?: Money
  adjustmentReason?: CodeableConcept
  amount: Money
  date?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  modifierExtension?: Extension[]
  type: CodeableConcept
}

export interface ClaimResponseProcessNoteArray {
  extension?: Extension[]
  id?: string
  language?: CodeableConcept
  modifierExtension?: Extension[]
  number?: number
  text: string
  type?: string
}

export interface ClaimResponseRequest {
  resourceType?: ClaimResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ClaimResourceType {
  Claim = "Claim",
}

export interface ClaimResponseRequestor {
  resourceType?: AuthenticatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ClaimResponseTotalArray {
  amount: Money
  category: CodeableConcept
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
}

export interface ChargeItem {
  account?: ChargeItemAccountArray[]
  bodysite?: CodeableConcept[]
  code: CodeableConcept
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  context?: ChargeItemContext
  costCenter?: CostCenter
  definitionCanonical?: string[]
  definitionUri?: string[]
  enteredDate?: string
  enterer?: ChargeItemEnterer
  extension?: Extension[]
  factorOverride?: number
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  note?: Annotation[]
  occurrence?: ChargeItemOccurrence
  overrideReason?: string
  partOf?: ChargeItemPartOfArray[]
  performer?: ChargeItemPerformerArray[]
  performingOrganization?: PerformingOrganization
  priceOverride?: Money
  product?: ChargeItemProduct
  quantity?: Quantity
  R5_service?: ChargeItemR5ServiceArray[]
  reason?: CodeableConcept[]
  requestingOrganization?: RequestingOrganization
  resourceType?: string
  service?: ChargeItemServiceArray[]
  status: string
  subject: ChargeItemSubject
  supportingInformation?: Reference[]
  text?: Narrative
}

export interface ChargeItemR5ServiceArray {
  resourceType?: R5ServiceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum R5ServiceResourceType {
  CarePlan = "CarePlan",
  DiagnosticReport = "DiagnosticReport",
  ImagingStudy = "ImagingStudy",
  Immunization = "Immunization",
  MedicationAdministration = "MedicationAdministration",
  MedicationDispense = "MedicationDispense",
  MedicationRequest = "MedicationRequest",
  Observation = "Observation",
  Procedure = "Procedure",
  ServiceRequest = "ServiceRequest",
  SupplyDelivery = "SupplyDelivery",
}

export interface ChargeItemAccountArray {
  resourceType?: Array11
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ChargeItemContext {
  resourceType?: ContextResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CostCenter {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ChargeItemEnterer {
  resourceType?: ObserverResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ChargeItemOccurrence {
  dateTime?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
  Timing?: Timing
}

export interface ChargeItemPartOfArray {
  resourceType?: Array33
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ChargeItemPerformerArray {
  actor: StickyActor
  extension?: Extension[]
  function?: CodeableConcept
  id?: string
  modifierExtension?: Extension[]
}

export interface StickyActor {
  resourceType?: OperatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface PerformingOrganization {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ChargeItemProduct {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: MischievousReference
}

export interface MischievousReference {
  resourceType?: UsedReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface RequestingOrganization {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ChargeItemServiceArray {
  resourceType?: ServiceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ServiceResourceType {
  DiagnosticReport = "DiagnosticReport",
  ImagingStudy = "ImagingStudy",
  Immunization = "Immunization",
  MedicationAdministration = "MedicationAdministration",
  MedicationDispense = "MedicationDispense",
  Observation = "Observation",
  Procedure = "Procedure",
  SupplyDelivery = "SupplyDelivery",
}

export interface ChargeItemSubject {
  resourceType?: FriskyArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CoverageEligibilityResponse {
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  created: string
  disposition?: string
  error?: CoverageEligibilityResponseErrorArray[]
  extension?: Extension[]
  form?: CodeableConcept
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  insurance?: CoverageEligibilityResponseInsuranceArray[]
  insurer: CoverageEligibilityResponseInsurer
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  outcome: string
  patient: CoverageEligibilityResponsePatient
  preAuthRef?: string
  purpose: string[]
  request: CoverageEligibilityResponseRequest
  requestor?: CoverageEligibilityResponseRequestor
  resourceType?: string
  serviced?: CoverageEligibilityResponseServiced
  status: string
  text?: Narrative
}

export interface CoverageEligibilityResponseErrorArray {
  code: CodeableConcept
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
}

export interface CoverageEligibilityResponseInsuranceArray {
  benefitPeriod?: Period
  coverage: FluffyCoverage
  extension?: Extension[]
  id?: string
  inforce?: boolean
  item?: CoverageEligibilityResponseInsuranceArrayItemArray[]
  modifierExtension?: Extension[]
}

export interface FluffyCoverage {
  resourceType?: CoverageResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CoverageEligibilityResponseInsuranceArrayItemArray {
  authorizationRequired?: boolean
  authorizationSupporting?: CodeableConcept[]
  authorizationUrl?: string
  benefit?: CoverageEligibilityResponseInsuranceArrayItemArrayBenefitArray[]
  category?: CodeableConcept
  description?: string
  excluded?: boolean
  extension?: Extension[]
  id?: string
  modifier?: CodeableConcept[]
  modifierExtension?: Extension[]
  name?: string
  network?: CodeableConcept
  productOrService?: CodeableConcept
  provider?: PurpleProvider
  term?: CodeableConcept
  unit?: CodeableConcept
}

export interface CoverageEligibilityResponseInsuranceArrayItemArrayBenefitArray {
  allowed?: CoverageEligibilityResponseInsuranceArrayItemArrayBenefitArrayAllowed
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  type: CodeableConcept
  used?: CoverageEligibilityResponseInsuranceArrayItemArrayBenefitArrayUsed
}

export interface CoverageEligibilityResponseInsuranceArrayItemArrayBenefitArrayAllowed {
  extension?: Extension[]
  modifierExtension?: Extension[]
  Money?: Money
  string?: string
  unsignedInt?: number
}

export interface CoverageEligibilityResponseInsuranceArrayItemArrayBenefitArrayUsed {
  extension?: Extension[]
  modifierExtension?: Extension[]
  Money?: Money
  string?: string
  unsignedInt?: number
}

export interface PurpleProvider {
  resourceType?: CollectorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CoverageEligibilityResponseInsurer {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CoverageEligibilityResponsePatient {
  resourceType?: CandidateResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CoverageEligibilityResponseRequest {
  resourceType?: Array34
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array34 {
  CoverageEligibilityRequest = "CoverageEligibilityRequest",
}

export interface CoverageEligibilityResponseRequestor {
  resourceType?: AuthenticatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CoverageEligibilityResponseServiced {
  date?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
}

export interface BodyStructure {
  active?: boolean
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  description?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  image?: Attachment[]
  implicitRules?: string
  language?: string
  location?: CodeableConcept
  locationQualifier?: CodeableConcept[]
  meta?: Meta
  modifierExtension?: Extension[]
  morphology?: CodeableConcept
  patient: BodyStructurePatient
  resourceType?: string
  text?: Narrative
}

export interface BodyStructurePatient {
  resourceType?: CandidateResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Parameters {
  extension?: Extension[]
  id?: string
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  parameter?: ParametersParameterArrayArray[]
  resourceType?: "Parameters"
}

export interface ParametersParameterArrayArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  name: string
  part?: ParametersParameterArrayArray[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string | List
  value?: ParametersParameterArrayValue
}

export interface ParametersParameterArrayValue {
  Address?: Address
  Age?: Age
  Annotation?: Annotation
  Attachment?: Attachment
  base64Binary?: string
  boolean?: boolean
  canonical?: string
  code?: string
  CodeableConcept?: CodeableConcept
  Coding?: Coding
  ContactDetail?: ContactDetail
  ContactPoint?: ContactPoint
  Contributor?: Contributor
  Count?: Count
  DataRequirement?: DataRequirement
  date?: string
  dateTime?: string
  decimal?: number
  Distance?: Distance
  Dosage?: Dosage
  Duration?: Duration
  Expression?: Expression
  extension?: Extension[]
  HumanName?: HumanName
  id?: string
  Identifier?: Identifier
  instant?: Date
  integer?: number
  markdown?: string
  modifierExtension?: Extension[]
  Money?: Money
  oid?: string
  ParameterDefinition?: ParameterDefinition
  Period?: Period
  positiveInt?: number
  Quantity?: Quantity
  Range?: Range
  Ratio?: Ratio
  Reference?: Reference
  RelatedArtifact?: RelatedArtifact
  Resource?: List
  SampledData?: SampledData
  Signature?: Signature
  string?: string
  time?: string
  Timing?: Timing
  TriggerDefinition?: TriggerDefinition
  unsignedInt?: number
  uri?: string
  url?: string
  UsageContext?: UsageContext
  uuid?: string
}

export interface ClinicalImpression {
  assessor?: Assessor
  code?: CodeableConcept
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  date?: string
  description?: string
  effective?: ClinicalImpressionEffective
  encounter?: ClinicalImpressionEncounter
  extension?: Extension[]
  finding?: ClinicalImpressionFindingArray[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  investigation?: ClinicalImpressionInvestigationArray[]
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  note?: Annotation[]
  previous?: Previous
  problem?: ClinicalImpressionProblemArray[]
  prognosisCodeableConcept?: CodeableConcept[]
  prognosisReference?: ClinicalImpressionPrognosisReferenceArray[]
  protocol?: string[]
  resourceType?: string
  status: string
  statusReason?: CodeableConcept
  subject: ClinicalImpressionSubject
  summary?: string
  supportingInfo?: Reference[]
  text?: Narrative
}

export interface Assessor {
  resourceType?: CollectorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ClinicalImpressionEffective {
  dateTime?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
}

export interface ClinicalImpressionEncounter {
  resourceType?: TentacledArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ClinicalImpressionFindingArray {
  basis?: string
  extension?: Extension[]
  id?: string
  itemCodeableConcept?: CodeableConcept
  itemReference?: ItemReferenceClass
  modifierExtension?: Extension[]
}

export interface ItemReferenceClass {
  resourceType?: ItemReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ItemReferenceResourceType {
  Condition = "Condition",
  Media = "Media",
  Observation = "Observation",
}

export interface ClinicalImpressionInvestigationArray {
  code: CodeableConcept
  extension?: Extension[]
  id?: string
  item?: ClinicalImpressionInvestigationArrayItemArray[]
  modifierExtension?: Extension[]
}

export interface ClinicalImpressionInvestigationArrayItemArray {
  resourceType?: Array35
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array35 {
  DiagnosticReport = "DiagnosticReport",
  FamilyMemberHistory = "FamilyMemberHistory",
  ImagingStudy = "ImagingStudy",
  Media = "Media",
  Observation = "Observation",
  QuestionnaireResponse = "QuestionnaireResponse",
  RiskAssessment = "RiskAssessment",
}

export interface Previous {
  resourceType?: PreviousResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum PreviousResourceType {
  ClinicalImpression = "ClinicalImpression",
}

export interface ClinicalImpressionProblemArray {
  resourceType?: ProblemResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ProblemResourceType {
  AllergyIntolerance = "AllergyIntolerance",
  Condition = "Condition",
}

export interface ClinicalImpressionPrognosisReferenceArray {
  resourceType?: PrognosisReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum PrognosisReferenceResourceType {
  RiskAssessment = "RiskAssessment",
}

export interface ClinicalImpressionSubject {
  resourceType?: FriskyArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface TerminologyBundleFile {
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
}

export interface BatchValidationRun {
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
}

export interface FamilyMemberHistory {
  age?: FamilyMemberHistoryAge
  born?: FamilyMemberHistoryBorn
  condition?: FamilyMemberHistoryConditionArray[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  dataAbsentReason?: CodeableConcept
  date?: string
  deceased?: FamilyMemberHistoryDeceased
  estimatedAge?: boolean
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  instantiatesCanonical?: string[]
  instantiatesUri?: string[]
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  name?: string
  note?: Annotation[]
  patient: FamilyMemberHistoryPatient
  reasonCode?: CodeableConcept[]
  reasonReference?: FamilyMemberHistoryReasonReferenceArray[]
  relationship: CodeableConcept
  resourceType?: string
  sex?: CodeableConcept
  status: string
  text?: Narrative
}

export interface FamilyMemberHistoryAge {
  Age?: Age
  extension?: Extension[]
  modifierExtension?: Extension[]
  Range?: Range
  string?: string
}

export interface FamilyMemberHistoryBorn {
  date?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
  string?: string
}

export interface FamilyMemberHistoryConditionArray {
  code: CodeableConcept
  contributedToDeath?: boolean
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  note?: Annotation[]
  onset?: FamilyMemberHistoryConditionArrayOnset
  outcome?: CodeableConcept
}

export interface FamilyMemberHistoryConditionArrayOnset {
  Age?: Age
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
  Range?: Range
  string?: string
}

export interface FamilyMemberHistoryDeceased {
  Age?: Age
  boolean?: boolean
  date?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Range?: Range
  string?: string
}

export interface FamilyMemberHistoryPatient {
  resourceType?: CandidateResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface FamilyMemberHistoryReasonReferenceArray {
  resourceType?: Array36
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array36 {
  AllergyIntolerance = "AllergyIntolerance",
  Condition = "Condition",
  DiagnosticReport = "DiagnosticReport",
  DocumentReference = "DocumentReference",
  Observation = "Observation",
  QuestionnaireResponse = "QuestionnaireResponse",
}

export interface MedicinalProductAuthorization {
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  country?: CodeableConcept[]
  dataExclusivityPeriod?: Period
  dateOfFirstAuthorization?: string
  extension?: Extension[]
  holder?: Holder
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  internationalBirthDate?: string
  jurisdiction?: CodeableConcept[]
  jurisdictionalAuthorization?: MedicinalProductAuthorizationJurisdictionalAuthorizationArray[]
  language?: string
  legalBasis?: CodeableConcept
  meta?: Meta
  modifierExtension?: Extension[]
  procedure?: MedicinalProductAuthorizationProcedure
  regulator?: MedicinalProductAuthorizationRegulator
  resourceType?: string
  restoreDate?: string
  status?: CodeableConcept
  statusDate?: string
  subject?: MedicinalProductAuthorizationSubject
  text?: Narrative
  validityPeriod?: Period
}

export interface Holder {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicinalProductAuthorizationJurisdictionalAuthorizationArray {
  country?: CodeableConcept
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  jurisdiction?: CodeableConcept[]
  legalStatusOfSupply?: CodeableConcept
  modifierExtension?: Extension[]
  validityPeriod?: Period
}

export interface MedicinalProductAuthorizationProcedure {
  application?: MedicinalProductAuthorizationProcedure[]
  date?: MedicinalProductAuthorizationProcedureDate
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  modifierExtension?: Extension[]
  type: CodeableConcept
}

export interface MedicinalProductAuthorizationProcedureDate {
  dateTime?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
}

export interface MedicinalProductAuthorizationRegulator {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicinalProductAuthorizationSubject {
  resourceType?: Array37
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array37 {
  MedicinalProduct = "MedicinalProduct",
  MedicinalProductPackaged = "MedicinalProductPackaged",
}

export interface Concept {
  ancestors?: ConceptAncestors
  code: string
  definition?: string
  deprecated?: boolean
  designation?: ConceptDesignation
  display?: string
  extension?: Extension[]
  hierarchy?: string[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  property?: { [key: string]: any }
  resourceType?: string
  system: string
  valueset?: string[]
}

export interface ConceptAncestors {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface ConceptDesignation {
  definition?: { [key: string]: any }
  display?: { [key: string]: any }
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface Manifest {
  bootstrap?: ManifestBootstrap
  cache?: { [key: string]: any }
  "cache/state"?: any
  config?: ManifestConfig
  hooks?: ManifestHooks
  id: string
  import?: ManifestImport
  migrations?: ManifestMigrationsArray[]
  "pre-processors"?: { [key: string]: any }
  resources?: { [key: string]: any }
  resourceType?: any
  state?: ManifestState
  version?: number
}

export interface ManifestBootstrap {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface ManifestConfig {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface ManifestHooks {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface ManifestImport {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface ManifestMigrationsArray {
  id?: string
}

export interface ManifestState {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface FtrConfig {
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
}

export interface AidboxConfig {
  auth?: AidboxConfigAuth
  box?: AidboxConfigBox
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
}

export interface AidboxConfigAuth {
  baseUrl?: string
  extension?: Extension[]
  keys?: AidboxConfigAuthKeys
  modifierExtension?: Extension[]
}

export interface AidboxConfigAuthKeys {
  extension?: Extension[]
  modifierExtension?: Extension[]
  private?: string
  public?: string
  secret?: string
}

export interface AidboxConfigBox {
  extension?: Extension[]
  metadata?: AidboxConfigBoxMetadata
  modifierExtension?: Extension[]
}

export interface AidboxConfigBoxMetadata {
  extension?: Extension[]
  modifierExtension?: Extension[]
  name?: string
  security?: AidboxConfigBoxMetadataSecurity
  service?: AidboxConfigBoxMetadataService
  title?: string
}

export interface AidboxConfigBoxMetadataSecurity {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface AidboxConfigBoxMetadataService {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface Binary {
  contentType: string
  data?: string
  extension?: Extension[]
  id?: string
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
  securityContext?: Reference
}

export interface Composition {
  attester?: CompositionAttesterArray[]
  author: CompositionAuthorArray[]
  category?: CodeableConcept[]
  confidentiality?: string
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  custodian?: CompositionCustodian
  date: string
  encounter?: CompositionEncounter
  event?: CompositionEventArray[]
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  relatesTo?: CompositionRelatesToArray[]
  resourceType?: string
  section?: CompositionSectionArraySectionArray[]
  status: string
  subject?: Reference
  text?: Narrative
  title: string
  type: CodeableConcept
}

export const isComposition = (
  resource?: any[] | boolean | number | number | null | ResourceObject | string,
): resource is Composition => isResourceObject(resource) && resource.resourceType === "Composition"

export interface CompositionAttesterArray {
  extension?: Extension[]
  id?: string
  mode: string
  modifierExtension?: Extension[]
  party?: AttesterParty
  time?: string
}

export interface AttesterParty {
  resourceType?: InformationSourceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CompositionAuthorArray {
  resourceType?: ObserverResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CompositionCustodian {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CompositionEncounter {
  resourceType?: TentacledArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CompositionEventArray {
  code?: CodeableConcept[]
  detail?: Reference[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  period?: Period
}

export interface CompositionRelatesToArray {
  code: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  target?: CompositionRelatesToArrayTarget
}

export interface CompositionRelatesToArrayTarget {
  extension?: Extension[]
  Identifier?: Identifier
  modifierExtension?: Extension[]
  Reference?: TargetReference
}

export interface TargetReference {
  resourceType?: CatalogHeaderResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CompositionSectionArraySectionArray {
  author?: CompositionSectionArrayAuthorArray[]
  code?: CodeableConcept
  emptyReason?: CodeableConcept
  entry?: Reference[]
  extension?: Extension[]
  focus?: Reference
  id?: string
  mode?: string
  modifierExtension?: Extension[]
  orderedBy?: CodeableConcept
  section?: CompositionSectionArraySectionArray[]
  text?: Narrative
  title?: string
}

export interface CompositionSectionArrayAuthorArray {
  resourceType?: ObserverResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SubsNotification {
  duration?: number
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  error?: SubsNotificationError
  notification?: SubsNotificationNotification &
    SubsNotificationNotificationExtended<AccountCreditCardArray, CodeableConcept, Reference>
  resourceType?: string
  response?: SubsNotificationResponse & SubsNotificationResponseExtended
  retried?: boolean
  retries?: SubsNotificationRetriesArray[]
  retryOf?: RetryOf
  status?: SubsNotificationStatus
  subscription?: Subscription
}

export interface SubsNotificationNotification {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface SubsNotificationResponse {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface SubsNotificationRetriesArray {
  resourceType?: RetryResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum RetryResourceType {
  SubsSubscription = "SubsSubscription",
}

export interface RetryOf {
  resourceType?: RetryResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum SubsNotificationStatus {
  Failed = "failed",
  Success = "success",
}

export interface Subscription {
  resourceType?: RetryResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Grant {
  client?: GrantClient
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  patient?: GrantPatient
  "provided-scope"?: string[]
  "requested-scope"?: string[]
  resourceType?: string
  scope?: string
  start?: string
  user?: GrantUser
}

export interface GrantClient {
  resourceType?: ClientResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ClientResourceType {
  Client = "Client",
}

export interface GrantPatient {
  resourceType?: CandidateResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface GrantUser {
  resourceType?: UserResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum UserResourceType {
  User = "User",
}

export interface PractitionerRole {
  active?: boolean
  availabilityExceptions?: string
  availableTime?: PractitionerRoleAvailableTimeArray[]
  code?: CodeableConcept[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  endpoint?: PractitionerRoleEndpointArray[]
  extension?: Extension[]
  healthcareService?: PractitionerRoleHealthcareServiceArray[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  location?: PractitionerRoleLocationArray[]
  meta?: Meta
  modifierExtension?: Extension[]
  notAvailable?: PractitionerRoleNotAvailableArray[]
  organization?: PractitionerRoleOrganization
  period?: Period
  practitioner?: PractitionerRolePractitioner
  resourceType?: string
  specialty?: CodeableConcept[]
  telecom?: ContactPoint[]
  text?: Narrative
  relatedPractitioner?: RelatedPractitioner[]
}

export const isPractitionerRole = (
  resource?: any[] | boolean | number | number | null | ResourceObject | string,
): resource is PractitionerRole => isResourceObject(resource) && resource.resourceType === "PractitionerRole"

export interface PractitionerRoleAvailableTimeArray {
  allDay?: boolean
  availableEndTime?: string
  availableStartTime?: string
  daysOfWeek?: string[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
}

export interface PractitionerRoleEndpointArray {
  resourceType?: EndpointResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface PractitionerRoleHealthcareServiceArray {
  resourceType?: HealthcareServiceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface PractitionerRoleLocationArray {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface PractitionerRoleNotAvailableArray {
  description: string
  during?: Period
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
}

export interface PractitionerRoleOrganization {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface PractitionerRolePractitioner {
  resourceType?: PractitionerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum PractitionerResourceType {
  Practitioner = "Practitioner",
}

export interface Module {
  extension?: Extension[]
  id?: string
  meta?: ModuleMeta
  modifierExtension?: Extension[]
  module?: string
  resourceType?: string
  version?: number
}

export interface ModuleMeta {
  extension?: Extension[]
  modifierExtension?: Extension[]
  "post-sql"?: string
  "pre-sql"?: string
}

export interface Hl7V2Message {
  apiOperation?: string
  config?: Config
  event?: string
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  outcome?: Hl7V2MessageOutcome
  parsed?: Hl7V2MessageParsed
  resourceType?: string
  src: string
  status: Hl7V2MessageStatus
  type?: string
}

export interface Config {
  resourceType?: ConfigResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ConfigResourceType {
  Hl7V2Config = "Hl7v2Config",
}

export interface Hl7V2MessageOutcome {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface Hl7V2MessageParsed {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export enum Hl7V2MessageStatus {
  Error = "error",
  Processed = "processed",
  Received = "received",
}

export interface HealthcareService {
  active?: boolean
  appointmentRequired?: boolean
  availabilityExceptions?: string
  availableTime?: HealthcareServiceAvailableTimeArray[]
  category?: CodeableConcept[]
  characteristic?: CodeableConcept[]
  comment?: string
  communication?: CodeableConcept[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  coverageArea?: HealthcareServiceCoverageAreaArray[]
  eligibility?: HealthcareServiceEligibilityArray[]
  endpoint?: HealthcareServiceEndpointArray[]
  extension?: Extension[]
  extraDetails?: string
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  location?: HealthcareServiceLocationArray[]
  meta?: Meta
  modifierExtension?: Extension[]
  name?: string
  notAvailable?: HealthcareServiceNotAvailableArray[]
  photo?: Attachment
  program?: CodeableConcept[]
  providedBy?: ProvidedBy
  referralMethod?: CodeableConcept[]
  resourceType?: string
  serviceProvisionCode?: CodeableConcept[]
  specialty?: CodeableConcept[]
  telecom?: ContactPoint[]
  text?: Narrative
  type?: CodeableConcept[]
}

export interface HealthcareServiceAvailableTimeArray {
  allDay?: boolean
  availableEndTime?: string
  availableStartTime?: string
  daysOfWeek?: string[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
}

export interface HealthcareServiceCoverageAreaArray {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface HealthcareServiceEligibilityArray {
  code?: CodeableConcept
  comment?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
}

export interface HealthcareServiceEndpointArray {
  resourceType?: EndpointResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface HealthcareServiceLocationArray {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface HealthcareServiceNotAvailableArray {
  description: string
  during?: Period
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
}

export interface ProvidedBy {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Patient {
  active?: boolean
  address?: Address[]
  birthDate?: string
  communication?: PatientCommunicationArray[]
  contact?: PatientContactArray[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  deceased?: PatientDeceased
  extension?: Extension[]
  gender?: string
  generalPractitioner?: PatientGeneralPractitionerArray[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  link?: PatientLinkArray[]
  managingOrganization?: PatientManagingOrganization
  maritalStatus?: CodeableConcept
  meta?: Meta
  modifierExtension?: Extension[]
  multipleBirth?: PatientMultipleBirth
  name?: HumanName[]
  photo?: Attachment[]
  resourceType?: string
  telecom?: ContactPoint[]
  text?: Narrative
}

export const isMedicationRequest = (
  resource?: any[] | boolean | number | number | null | ResourceObject | string,
): resource is MedicationRequest => isResourceObject(resource) && resource.resourceType === "MedicationRequest"

export const isMedicationKnowledge = (
  resource?: any[] | boolean | number | number | null | ResourceObject | string,
): resource is MedicationKnowledge => isResourceObject(resource) && resource.resourceType === "MedicationKnowledge"

export const isPatient = (
  resource?: any[] | boolean | number | number | null | ResourceObject | string,
): resource is Patient => isResourceObject(resource) && resource.resourceType === "Patient"

export interface PatientCommunicationArray {
  extension?: Extension[]
  id?: string
  language: CodeableConcept
  modifierExtension?: Extension[]
  preferred?: boolean
}

export interface PatientContactArray {
  address?: Address
  extension?: Extension[]
  gender?: string
  id?: string
  modifierExtension?: Extension[]
  name?: HumanName
  organization?: ContactOrganization
  period?: Period
  relationship?: CodeableConcept[]
  telecom?: ContactPoint[]
}

export interface ContactOrganization {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface PatientDeceased {
  boolean?: boolean
  dateTime?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface PatientGeneralPractitionerArray {
  resourceType?: AuthenticatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface PatientLinkArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  other: Other
  type: string
}

export interface Other {
  resourceType?: SubscriberResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface PatientManagingOrganization {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface PatientMultipleBirth {
  boolean?: boolean
  extension?: Extension[]
  integer?: number
  modifierExtension?: Extension[]
}

export interface MedicationDispense {
  authorizingPrescription?: MedicationDispenseAuthorizingPrescriptionArray[]
  category?: CodeableConcept
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  context?: MedicationDispenseContext
  daysSupply?: Quantity
  destination?: MedicationDispenseDestination
  detectedIssue?: MedicationDispenseDetectedIssueArray[]
  dosageInstruction?: Dosage[]
  eventHistory?: MedicationDispenseEventHistoryArray[]
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  location?: MedicationDispenseLocation
  medication?: MedicationDispenseMedication
  meta?: Meta
  modifierExtension?: Extension[]
  note?: Annotation[]
  partOf?: MedicationDispensePartOfArray[]
  performer?: MedicationDispensePerformerArray[]
  quantity?: Quantity
  receiver?: MedicationDispenseReceiverArray[]
  resourceType?: string
  status: string
  statusReason?: MedicationDispenseStatusReason
  subject?: MedicationDispenseSubject
  substitution?: MedicationDispenseSubstitution
  supportingInformation?: Reference[]
  text?: Narrative
  type?: CodeableConcept
  whenHandedOver?: string
  whenPrepared?: string
}

export interface MedicationDispenseAuthorizingPrescriptionArray {
  resourceType?: AuthorizingPrescriptionResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationDispenseContext {
  resourceType?: ContextResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationDispenseDestination {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationDispenseDetectedIssueArray {
  resourceType?: DetectedIssueResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum DetectedIssueResourceType {
  DetectedIssue = "DetectedIssue",
}

export interface MedicationDispenseEventHistoryArray {
  resourceType?: RelevantHistoryResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationDispenseLocation {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationDispenseMedication {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: BraggadociousReference
}

export interface BraggadociousReference {
  resourceType?: AssociatedMedicationResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationDispensePartOfArray {
  resourceType?: ProcedureReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ProcedureReferenceResourceType {
  Procedure = "Procedure",
}

export interface MedicationDispensePerformerArray {
  actor: IndigoActor
  extension?: Extension[]
  function?: CodeableConcept
  id?: string
  modifierExtension?: Extension[]
}

export interface IndigoActor {
  resourceType?: ObserverResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationDispenseReceiverArray {
  resourceType?: Array38
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array38 {
  Patient = "Patient",
  Practitioner = "Practitioner",
}

export interface MedicationDispenseStatusReason {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: StatusReasonReference
}

export interface StatusReasonReference {
  resourceType?: DetectedIssueResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationDispenseSubject {
  resourceType?: FriskyArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationDispenseSubstitution {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  reason?: CodeableConcept[]
  responsibleParty?: MedicationDispenseSubstitutionResponsiblePartyArray[]
  type?: CodeableConcept
  wasSubstituted: boolean
}

export interface MedicationDispenseSubstitutionResponsiblePartyArray {
  resourceType?: CollectorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface AidboxTask {
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
}

export interface DeviceUseStatement {
  basedOn?: DeviceUseStatementBasedOnArray[]
  bodySite?: CodeableConcept
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  derivedFrom?: DeviceUseStatementDerivedFromArray[]
  device: DeviceUseStatementDevice
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  note?: Annotation[]
  reasonCode?: CodeableConcept[]
  reasonReference?: DeviceUseStatementReasonReferenceArray[]
  recordedOn?: string
  resourceType?: string
  source?: DeviceUseStatementSource
  status: string
  subject: DeviceUseStatementSubject
  text?: Narrative
  timing?: DeviceUseStatementTiming
}

export interface DeviceUseStatementBasedOnArray {
  resourceType?: ReferralRequestResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface DeviceUseStatementDerivedFromArray {
  resourceType?: Array39
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array39 {
  Claim = "Claim",
  DocumentReference = "DocumentReference",
  Observation = "Observation",
  Procedure = "Procedure",
  QuestionnaireResponse = "QuestionnaireResponse",
  ServiceRequest = "ServiceRequest",
}

export interface DeviceUseStatementDevice {
  resourceType?: UdiResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface DeviceUseStatementReasonReferenceArray {
  resourceType?: Array40
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array40 {
  Condition = "Condition",
  DiagnosticReport = "DiagnosticReport",
  DocumentReference = "DocumentReference",
  Media = "Media",
  Observation = "Observation",
}

export interface DeviceUseStatementSource {
  resourceType?: ExpressedByResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface DeviceUseStatementSubject {
  resourceType?: FriskyArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface DeviceUseStatementTiming {
  dateTime?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
  Timing?: Timing
}

export interface AidboxJob {
  action?: any[] | { [key: string]: any } | string
  at?: string
  every?: number
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  module?: string
  resourceType?: string
  status?: StatusObject
  text?: string
  type?: AidboxJobType
}

export interface StatusObject {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export enum AidboxJobType {
  EachDay = "each-day",
  Periodic = "periodic",
}

export interface CronJobRun {
  error?: CronJobRunError
  extension?: Extension[]
  id?: string
  jobId?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
  result?: CronJobRunResult
  status?: CronJobRunStatus
  when?: CronJobRunWhen
}

export interface CronJobRunError {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface CronJobRunResult {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export enum CronJobRunStatus {
  Fail = "fail",
  Success = "success",
}

export interface CronJobRunWhen {
  end?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  start?: string
}

export interface StructureMap {
  contact?: ContactDetail[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  copyright?: string
  date?: string
  description?: string
  experimental?: boolean
  extension?: Extension[]
  group: StructureMapGroupArray[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  import?: string[]
  jurisdiction?: CodeableConcept[]
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  name: string
  publisher?: string
  purpose?: string
  resourceType?: string
  status: string
  structure?: StructureMapStructureArray[]
  text?: Narrative
  title?: string
  url: string
  useContext?: UsageContext[]
  version?: string
}

export interface StructureMapGroupArray {
  documentation?: string
  extends?: string
  extension?: Extension[]
  id?: string
  input: StructureMapGroupArrayInputArray[]
  modifierExtension?: Extension[]
  name: string
  rule: StructureMapGroupArrayRuleArrayArrayRuleArray[]
  typeMode: string
}

export interface StructureMapGroupArrayInputArray {
  documentation?: string
  extension?: Extension[]
  id?: string
  mode: string
  modifierExtension?: Extension[]
  name: string
  type?: string
}

export interface StructureMapGroupArrayRuleArrayArrayRuleArray {
  dependent?: StructureMapGroupArrayRuleArrayDependentArray[]
  documentation?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  name: string
  rule?: StructureMapGroupArrayRuleArrayArrayRuleArray[]
  source: StructureMapGroupArrayRuleArraySourceArray[]
  target?: StructureMapGroupArrayRuleArrayTargetArray[]
}

export interface StructureMapGroupArrayRuleArrayDependentArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  name: string
  variable: string[]
}

export interface StructureMapGroupArrayRuleArraySourceArray {
  check?: string
  condition?: string
  context: string
  defaultValue?: StructureMapGroupArrayRuleArraySourceArrayDefaultValue
  element?: string
  extension?: Extension[]
  id?: string
  listMode?: string
  logMessage?: string
  max?: string
  min?: number
  modifierExtension?: Extension[]
  type?: string
  variable?: string
}

export interface StructureMapGroupArrayRuleArraySourceArrayDefaultValue {
  Address?: Address
  Age?: Age
  Annotation?: Annotation
  Attachment?: Attachment
  base64Binary?: string
  boolean?: boolean
  canonical?: string
  code?: string
  CodeableConcept?: CodeableConcept
  Coding?: Coding
  ContactDetail?: ContactDetail
  ContactPoint?: ContactPoint
  Contributor?: Contributor
  Count?: Count
  DataRequirement?: DataRequirement
  date?: string
  dateTime?: string
  decimal?: number
  Distance?: Distance
  Dosage?: Dosage
  Duration?: Duration
  Expression?: Expression
  extension?: Extension[]
  HumanName?: HumanName
  id?: string
  Identifier?: Identifier
  instant?: Date
  integer?: number
  markdown?: string
  Meta?: Meta
  modifierExtension?: Extension[]
  Money?: Money
  oid?: string
  ParameterDefinition?: ParameterDefinition
  Period?: Period
  positiveInt?: number
  Quantity?: Quantity
  Range?: Range
  Ratio?: Ratio
  Reference?: Reference
  RelatedArtifact?: RelatedArtifact
  SampledData?: SampledData
  Signature?: Signature
  string?: string
  time?: string
  Timing?: Timing
  TriggerDefinition?: TriggerDefinition
  unsignedInt?: number
  uri?: string
  url?: string
  UsageContext?: UsageContext
  uuid?: string
}

export interface StructureMapGroupArrayRuleArrayTargetArray {
  context?: string
  contextType?: string
  element?: string
  extension?: Extension[]
  id?: string
  listMode?: string[]
  listRuleId?: string
  modifierExtension?: Extension[]
  parameter?: StructureMapGroupArrayRuleArrayTargetArrayParameterArray[]
  transform?: string
  variable?: string
}

export interface StructureMapGroupArrayRuleArrayTargetArrayParameterArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  value?: StructureMapGroupArrayRuleArrayTargetArrayParameterArrayValue
}

export interface StructureMapGroupArrayRuleArrayTargetArrayParameterArrayValue {
  boolean?: boolean
  decimal?: number
  extension?: Extension[]
  id?: string
  integer?: number
  modifierExtension?: Extension[]
  string?: string
}

export interface StructureMapStructureArray {
  alias?: string
  documentation?: string
  extension?: Extension[]
  id?: string
  mode: string
  modifierExtension?: Extension[]
  url: string
}

export interface ImmunizationEvaluation {
  authority?: ImmunizationEvaluationAuthority
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  date?: string
  description?: string
  doseNumber?: ImmunizationEvaluationDoseNumber
  doseStatus: CodeableConcept
  doseStatusReason?: CodeableConcept[]
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  immunizationEvent: ImmunizationEvent
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  patient: ImmunizationEvaluationPatient
  resourceType?: string
  series?: string
  seriesDoses?: ImmunizationEvaluationSeriesDoses
  status: string
  targetDisease: CodeableConcept
  text?: Narrative
}

export interface ImmunizationEvaluationAuthority {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ImmunizationEvaluationDoseNumber {
  extension?: Extension[]
  modifierExtension?: Extension[]
  positiveInt?: number
  string?: string
}

export interface ImmunizationEvent {
  resourceType?: ImmunizationEventResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ImmunizationEventResourceType {
  Immunization = "Immunization",
}

export interface ImmunizationEvaluationPatient {
  resourceType?: CandidateResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ImmunizationEvaluationSeriesDoses {
  extension?: Extension[]
  modifierExtension?: Extension[]
  positiveInt?: number
  string?: string
}

export interface AidboxTaskLog {
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
}

export interface Library {
  approvalDate?: string
  author?: ContactDetail[]
  contact?: ContactDetail[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  content?: Attachment[]
  copyright?: string
  dataRequirement?: DataRequirement[]
  date?: string
  description?: string
  editor?: ContactDetail[]
  effectivePeriod?: Period
  endorser?: ContactDetail[]
  experimental?: boolean
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  jurisdiction?: CodeableConcept[]
  language?: string
  lastReviewDate?: string
  meta?: Meta
  modifierExtension?: Extension[]
  name?: string
  parameter?: ParameterDefinition[]
  publisher?: string
  purpose?: string
  relatedArtifact?: RelatedArtifact[]
  resourceType?: string
  reviewer?: ContactDetail[]
  status: string
  subject?: LibrarySubject
  subtitle?: string
  text?: Narrative
  title?: string
  topic?: CodeableConcept[]
  type: CodeableConcept
  url?: string
  usage?: string
  useContext?: UsageContext[]
  version?: string
}

export interface LibrarySubject {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference1
}

export interface Reference1 {
  resourceType?: EnrollmentResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Basic {
  author?: BasicAuthor
  code: CodeableConcept
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  created?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
  subject?: Reference
  text?: Narrative
}

export interface BasicAuthor {
  resourceType?: InformationSourceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Slot {
  appointmentType?: CodeableConcept
  comment?: string
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  end: Date
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  overbooked?: boolean
  resourceType?: string
  schedule: ScheduleClass
  serviceCategory?: CodeableConcept[]
  serviceType?: CodeableConcept[]
  specialty?: CodeableConcept[]
  start: Date
  status: string
  text?: Narrative
}

export interface ScheduleClass {
  resourceType?: ScheduleResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SDCFormMetadata {
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
}

export interface ActivityDefinition {
  approvalDate?: string
  author?: ContactDetail[]
  bodySite?: CodeableConcept[]
  code?: CodeableConcept
  contact?: ContactDetail[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  copyright?: string
  date?: string
  description?: string
  doNotPerform?: boolean
  dosage?: Dosage[]
  dynamicValue?: ActivityDefinitionDynamicValueArray[]
  editor?: ContactDetail[]
  effectivePeriod?: Period
  endorser?: ContactDetail[]
  experimental?: boolean
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  intent?: string
  jurisdiction?: CodeableConcept[]
  kind?: string
  language?: string
  lastReviewDate?: string
  library?: string[]
  location?: ActivityDefinitionLocation
  meta?: Meta
  modifierExtension?: Extension[]
  name?: string
  observationRequirement?: ActivityDefinitionObservationRequirementArray[]
  observationResultRequirement?: ActivityDefinitionObservationResultRequirementArray[]
  participant?: ActivityDefinitionParticipantArray[]
  priority?: string
  product?: ActivityDefinitionProduct
  profile?: string
  publisher?: string
  purpose?: string
  quantity?: Quantity
  relatedArtifact?: RelatedArtifact[]
  resourceType?: string
  reviewer?: ContactDetail[]
  specimenRequirement?: ActivityDefinitionSpecimenRequirementArray[]
  status: string
  subject?: ActivityDefinitionSubject
  subtitle?: string
  text?: Narrative
  timing?: ActivityDefinitionTiming
  title?: string
  topic?: CodeableConcept[]
  transform?: string
  url?: string
  usage?: string
  useContext?: UsageContext[]
  version?: string
}

export interface ActivityDefinitionDynamicValueArray {
  expression: Expression
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  path: string
}

export interface ActivityDefinitionLocation {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ActivityDefinitionObservationRequirementArray {
  resourceType?: ObservationRequirementResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ObservationRequirementResourceType {
  ObservationDefinition = "ObservationDefinition",
}

export interface ActivityDefinitionObservationResultRequirementArray {
  resourceType?: ObservationRequirementResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ActivityDefinitionParticipantArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  role?: CodeableConcept
  type: string
}

export interface ActivityDefinitionProduct {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference2
}

export interface Reference2 {
  resourceType?: AmbitiousArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ActivityDefinitionSpecimenRequirementArray {
  resourceType?: SpecimenRequirementResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum SpecimenRequirementResourceType {
  SpecimenDefinition = "SpecimenDefinition",
}

export interface ActivityDefinitionSubject {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference3
}

export interface Reference3 {
  resourceType?: EnrollmentResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ActivityDefinitionTiming {
  Age?: Age
  dateTime?: string
  Duration?: Duration
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
  Range?: Range
  Timing?: Timing
}

export interface UIHistory {
  command?: string
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
  type?: UIHistoryType
  user?: UIHistoryUser
}

export enum UIHistoryType {
  HTTP = "http",
  SQL = "sql",
}

export interface UIHistoryUser {
  resourceType?: UserResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Bundle {
  entry?: BundleEntryArray[]
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  implicitRules?: string
  language?: string
  link?: BundleLinkArray[]
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
  signature?: Signature
  timestamp?: Date
  total?: number
  type: string
}

export const isBundle = (
  resource?: any[] | boolean | number | number | null | ResourceObject | string,
): resource is Bundle => isResourceObject(resource) && resource.resourceType === "Bundle"

export interface BundleEntryArray {
  extension?: Extension[]
  fullUrl?: string
  id?: string
  link?: BundleLinkArray[]
  modifierExtension?: Extension[]
  request?: BundleEntryArrayRequest
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  response?: BundleEntryArrayResponse
  search?: BundleEntryArraySearch
}

export interface TransactionResponse {
  type: "transaction-response"
  resourceType: "Bundle"
  entry: Entry[]
  id: string
}

export interface Entry {
  resource: {
    entry: {
      resource: SettingDefinition
    }[]
    total: number
    type: "searchset"
    resourceType: "Bundle"
  }
  response: { status: string }
}

export interface BundleLinkArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  relation: string
  url: string
}

export interface BundleEntryArrayRequest {
  extension?: Extension[]
  id?: string
  ifMatch?: string
  ifModifiedSince?: Date
  ifNoneExist?: string
  ifNoneMatch?: string
  method: string
  modifierExtension?: Extension[]
  url: string
}

export interface BundleEntryArrayResponse {
  etag?: string
  extension?: Extension[]
  id?: string
  lastModified?: Date
  location?: string
  modifierExtension?: Extension[]
  outcome?: any[] | boolean | number | number | null | ResourceObject | string
  status: string
}

export interface BundleEntryArraySearch {
  extension?: Extension[]
  id?: string
  mode?: string
  modifierExtension?: Extension[]
  score?: number
}

export interface MedicinalProductInteraction {
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  description?: string
  effect?: CodeableConcept
  extension?: Extension[]
  id?: string
  implicitRules?: string
  incidence?: CodeableConcept
  interactant?: MedicinalProductInteractionInteractantArray[]
  language?: string
  management?: CodeableConcept
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
  subject?: MedicinalProductInteractionSubjectArray[]
  text?: Narrative
  type?: CodeableConcept
}

export interface MedicinalProductInteractionInteractantArray {
  extension?: Extension[]
  id?: string
  item?: MedicinalProductInteractionInteractantArrayItem
  modifierExtension?: Extension[]
}

export interface MedicinalProductInteractionInteractantArrayItem {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference4
}

export interface Reference4 {
  resourceType?: Array41
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array41 {
  Medication = "Medication",
  MedicinalProduct = "MedicinalProduct",
  ObservationDefinition = "ObservationDefinition",
  Substance = "Substance",
}

export interface MedicinalProductInteractionSubjectArray {
  resourceType?: Array42
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array42 {
  Medication = "Medication",
  MedicinalProduct = "MedicinalProduct",
  Substance = "Substance",
}

export interface MolecularSequence {
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  coordinateSystem: number
  device?: MolecularSequenceDevice
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  observedSeq?: string
  patient?: MolecularSequencePatient
  performer?: MolecularSequencePerformer
  pointer?: MolecularSequencePointerArray[]
  quality?: MolecularSequenceQualityArray[]
  quantity?: Quantity
  readCoverage?: number
  referenceSeq?: MolecularSequenceReferenceSeq
  repository?: MolecularSequenceRepositoryArray[]
  resourceType?: string
  specimen?: MolecularSequenceSpecimen
  structureVariant?: MolecularSequenceStructureVariantArray[]
  text?: Narrative
  type?: string
  variant?: MolecularSequenceVariantArray[]
}

export interface MolecularSequenceDevice {
  resourceType?: UdiResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MolecularSequencePatient {
  resourceType?: CandidateResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MolecularSequencePerformer {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MolecularSequencePointerArray {
  resourceType?: PointerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum PointerResourceType {
  MolecularSequence = "MolecularSequence",
}

export interface MolecularSequenceQualityArray {
  end?: number
  extension?: Extension[]
  fScore?: number
  gtFP?: number
  id?: string
  method?: CodeableConcept
  modifierExtension?: Extension[]
  precision?: number
  queryFP?: number
  queryTP?: number
  recall?: number
  roc?: MolecularSequenceQualityArrayRoc
  score?: Quantity
  standardSequence?: CodeableConcept
  start?: number
  truthFN?: number
  truthTP?: number
  type: string
}

export interface MolecularSequenceQualityArrayRoc {
  extension?: Extension[]
  fMeasure?: number[]
  id?: string
  modifierExtension?: Extension[]
  numFN?: number[]
  numFP?: number[]
  numTP?: number[]
  precision?: number[]
  score?: number[]
  sensitivity?: number[]
}

export interface MolecularSequenceReferenceSeq {
  chromosome?: CodeableConcept
  extension?: Extension[]
  genomeBuild?: string
  id?: string
  modifierExtension?: Extension[]
  orientation?: string
  referenceSeqId?: CodeableConcept
  referenceSeqPointer?: ReferenceSeqPointerClass
  referenceSeqString?: string
  strand?: string
  windowEnd?: number
  windowStart?: number
}

export interface ReferenceSeqPointerClass {
  resourceType?: PointerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MolecularSequenceRepositoryArray {
  datasetId?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  name?: string
  readsetId?: string
  type: string
  url?: string
  variantsetId?: string
}

export interface MolecularSequenceSpecimen {
  resourceType?: SpecimanResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MolecularSequenceStructureVariantArray {
  exact?: boolean
  extension?: Extension[]
  id?: string
  inner?: MolecularSequenceStructureVariantArrayInner
  length?: number
  modifierExtension?: Extension[]
  outer?: MolecularSequenceStructureVariantArrayOuter
  variantType?: CodeableConcept
}

export interface MolecularSequenceStructureVariantArrayInner {
  end?: number
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  start?: number
}

export interface MolecularSequenceStructureVariantArrayOuter {
  end?: number
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  start?: number
}

export interface MolecularSequenceVariantArray {
  cigar?: string
  end?: number
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  observedAllele?: string
  referenceAllele?: string
  start?: number
  variantPointer?: VariantPointer
}

export interface VariantPointer {
  resourceType?: OutcomeReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Specimen {
  accessionIdentifier?: Identifier
  collection?: SpecimenCollection
  condition?: CodeableConcept[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  container?: SpecimenContainerArray[]
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  note?: Annotation[]
  parent?: SpecimenParentArray[]
  processing?: SpecimenProcessingArray[]
  receivedTime?: string
  request?: SpecimenRequestArray[]
  resourceType?: string
  status?: string
  subject?: SpecimenSubject
  text?: Narrative
  type?: CodeableConcept
}

export interface SpecimenCollection {
  bodySite?: CodeableConcept
  collected?: SpecimenCollectionCollected
  collector?: FluffyCollector
  duration?: Duration
  extension?: Extension[]
  fastingStatus?: SpecimenCollectionFastingStatus
  id?: string
  method?: CodeableConcept
  modifierExtension?: Extension[]
  quantity?: Quantity
}

export interface SpecimenCollectionCollected {
  dateTime?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
}

export interface FluffyCollector {
  resourceType?: CollectorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SpecimenCollectionFastingStatus {
  CodeableConcept?: CodeableConcept
  Duration?: Duration
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface SpecimenContainerArray {
  additive?: SpecimenContainerArrayAdditive
  capacity?: Quantity
  description?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  modifierExtension?: Extension[]
  specimenQuantity?: Quantity
  type?: CodeableConcept
}

export interface SpecimenContainerArrayAdditive {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference5
}

export interface Reference5 {
  resourceType?: AdditiveResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SpecimenParentArray {
  resourceType?: SpecimanResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SpecimenProcessingArray {
  additive?: SpecimenProcessingArrayAdditiveArray[]
  description?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  procedure?: CodeableConcept
  time?: SpecimenProcessingArrayTime
}

export interface SpecimenProcessingArrayAdditiveArray {
  resourceType?: AdditiveResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SpecimenProcessingArrayTime {
  dateTime?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
}

export interface SpecimenRequestArray {
  resourceType?: ReferralRequestResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SpecimenSubject {
  resourceType?: Array43
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array43 {
  Device = "Device",
  Group = "Group",
  Location = "Location",
  Patient = "Patient",
  Substance = "Substance",
}

export interface AidboxMigration {
  action?: any[] | { [key: string]: any } | string
  dateTime?: string
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  module?: string
  resourceType?: string
  result?: AidboxMigrationResult
  status?: AidboxMigrationStatus
  text?: string
}

export interface AidboxMigrationResult {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export enum AidboxMigrationStatus {
  Done = "done",
  Error = "error",
  Pending = "pending",
}

export interface DiagnosticReport {
  basedOn?: DiagnosticReportBasedOnArray[]
  category?: CodeableConcept[]
  code: CodeableConcept
  conclusion?: string
  conclusionCode?: CodeableConcept[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  effective?: DiagnosticReportEffective
  encounter?: DiagnosticReportEncounter
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  imagingStudy?: DiagnosticReportImagingStudyArray[]
  implicitRules?: string
  issued?: Date
  language?: string
  media?: DiagnosticReportMediaArray[]
  meta?: Meta
  modifierExtension?: Extension[]
  performer?: DiagnosticReportPerformerArray[]
  presentedForm?: Attachment[]
  resourceType?: string
  result?: DiagnosticReportResultArray[]
  resultsInterpreter?: DiagnosticReportResultsInterpreterArray[]
  specimen?: DiagnosticReportSpecimenArray[]
  status: string
  subject?: DiagnosticReportSubject
  text?: Narrative
}

export interface DiagnosticReportBasedOnArray {
  resourceType?: Array44
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array44 {
  CarePlan = "CarePlan",
  ImmunizationRecommendation = "ImmunizationRecommendation",
  MedicationRequest = "MedicationRequest",
  NutritionOrder = "NutritionOrder",
  ServiceRequest = "ServiceRequest",
}

export interface DiagnosticReportEffective {
  dateTime?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
}

export interface DiagnosticReportEncounter {
  resourceType?: TentacledArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface DiagnosticReportImagingStudyArray {
  resourceType?: ImagingStudyResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ImagingStudyResourceType {
  ImagingStudy = "ImagingStudy",
}

export interface DiagnosticReportMediaArray {
  comment?: string
  extension?: Extension[]
  id?: string
  link: Link
  modifierExtension?: Extension[]
}

export interface Link {
  resourceType?: Array45
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array45 {
  Media = "Media",
}

export interface DiagnosticReportPerformerArray {
  resourceType?: ResultsInterpreterResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ResultsInterpreterResourceType {
  CareTeam = "CareTeam",
  Organization = "Organization",
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
}

export interface DiagnosticReportResultArray {
  resourceType?: OutcomeReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface DiagnosticReportResultsInterpreterArray {
  resourceType?: ResultsInterpreterResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface DiagnosticReportSpecimenArray {
  resourceType?: SpecimanResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface DiagnosticReportSubject {
  resourceType?: IndecentArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface RequestGroup {
  action?: RequestGroupActionArrayActionArray[]
  author?: RequestGroupAuthor
  authoredOn?: string
  basedOn?: Reference[]
  code?: CodeableConcept
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  encounter?: RequestGroupEncounter
  extension?: Extension[]
  groupIdentifier?: Identifier
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  instantiatesCanonical?: string[]
  instantiatesUri?: string[]
  intent: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  note?: Annotation[]
  priority?: string
  reasonCode?: CodeableConcept[]
  reasonReference?: RequestGroupReasonReferenceArray[]
  replaces?: Reference[]
  resourceType?: string
  status: string
  subject?: RequestGroupSubject
  text?: Narrative
}

export interface RequestGroupActionArrayActionArray {
  action?: RequestGroupActionArrayActionArray[]
  cardinalityBehavior?: string
  code?: CodeableConcept[]
  condition?: RequestGroupActionArrayConditionArray[]
  description?: string
  documentation?: RelatedArtifact[]
  extension?: Extension[]
  groupingBehavior?: string
  id?: string
  modifierExtension?: Extension[]
  participant?: RequestGroupActionArrayParticipantArray[]
  precheckBehavior?: string
  prefix?: string
  priority?: string
  relatedAction?: RequestGroupActionArrayRelatedActionArray[]
  requiredBehavior?: string
  resource?: Reference
  selectionBehavior?: string
  textEquivalent?: string
  timing?: RequestGroupActionArrayTiming
  title?: string
  type?: CodeableConcept
}

export interface RequestGroupActionArrayConditionArray {
  expression?: Expression
  extension?: Extension[]
  id?: string
  kind: string
  modifierExtension?: Extension[]
}

export interface RequestGroupActionArrayParticipantArray {
  resourceType?: ParticipantResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface RequestGroupActionArrayRelatedActionArray {
  actionId: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  offset?: RequestGroupActionArrayRelatedActionArrayOffset
  relationship: string
}

export interface RequestGroupActionArrayRelatedActionArrayOffset {
  Duration?: Duration
  extension?: Extension[]
  modifierExtension?: Extension[]
  Range?: Range
}

export interface RequestGroupActionArrayTiming {
  Age?: Age
  dateTime?: string
  Duration?: Duration
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
  Range?: Range
  Timing?: Timing
}

export interface RequestGroupAuthor {
  resourceType?: Array14
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface RequestGroupEncounter {
  resourceType?: TentacledArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface RequestGroupReasonReferenceArray {
  resourceType?: StickyArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface RequestGroupSubject {
  resourceType?: FriskyArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Provenance {
  activity?: CodeableConcept
  agent: ProvenanceAgentArray[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  entity?: ProvenanceEntityArray[]
  extension?: Extension[]
  id?: string
  implicitRules?: string
  language?: string
  location?: ProvenanceLocation
  meta?: Meta
  modifierExtension?: Extension[]
  occurred?: ProvenanceOccurred
  policy?: string[]
  reason?: CodeableConcept[]
  recorded: Date
  resourceType?: string
  signature?: Signature[]
  target: Reference[]
  text?: Narrative
}

export interface ProvenanceAgentArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  onBehalfOf?: AgentOnBehalfOf
  role?: CodeableConcept[]
  type?: CodeableConcept
  who: FluffyWho
}

export interface AgentOnBehalfOf {
  resourceType?: ObserverResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface FluffyWho {
  resourceType?: ObserverResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ProvenanceEntityArray {
  agent?: ProvenanceAgentArray[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  role: string
  what: Reference
}

export interface ProvenanceLocation {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ProvenanceOccurred {
  dateTime?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
}

export interface MedicinalProduct {
  additionalMonitoringIndicator?: CodeableConcept
  attachedDocument?: MedicinalProductAttachedDocumentArray[]
  clinicalTrial?: MedicinalProductClinicalTrialArray[]
  combinedPharmaceuticalDoseForm?: CodeableConcept
  contact?: MedicinalProductContactArray[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  crossReference?: Identifier[]
  domain?: Coding
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  legalStatusOfSupply?: CodeableConcept
  manufacturingBusinessOperation?: MedicinalProductManufacturingBusinessOperationArray[]
  marketingStatus?: MarketingStatus[]
  masterFile?: MedicinalProductMasterFileArray[]
  meta?: Meta
  modifierExtension?: Extension[]
  name: MedicinalProductNameArray[]
  packagedMedicinalProduct?: MedicinalProductPackagedMedicinalProductArray[]
  paediatricUseIndicator?: CodeableConcept
  pharmaceuticalProduct?: MedicinalProductPharmaceuticalProductArray[]
  productClassification?: CodeableConcept[]
  resourceType?: string
  specialDesignation?: MedicinalProductSpecialDesignationArray[]
  specialMeasures?: string[]
  text?: Narrative
  type?: CodeableConcept
}

export interface MedicinalProductAttachedDocumentArray {
  resourceType?: ReferenceDocumentResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicinalProductClinicalTrialArray {
  resourceType?: StudyResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicinalProductContactArray {
  resourceType?: ContactResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ContactResourceType {
  Organization = "Organization",
  PractitionerRole = "PractitionerRole",
}

export interface MedicinalProductManufacturingBusinessOperationArray {
  authorisationReferenceNumber?: Identifier
  confidentialityIndicator?: CodeableConcept
  effectiveDate?: string
  extension?: Extension[]
  id?: string
  manufacturer?: MedicinalProductManufacturingBusinessOperationArrayManufacturerArray[]
  modifierExtension?: Extension[]
  operationType?: CodeableConcept
  regulator?: ManufacturingBusinessOperationRegulator
}

export interface MedicinalProductManufacturingBusinessOperationArrayManufacturerArray {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ManufacturingBusinessOperationRegulator {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MarketingStatus {
  country: CodeableConcept
  dateRange: Period
  extension?: Extension[]
  id?: string
  jurisdiction?: CodeableConcept
  modifierExtension?: Extension[]
  restoreDate?: string
  status: CodeableConcept
}

export interface MedicinalProductMasterFileArray {
  resourceType?: ReferenceDocumentResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicinalProductNameArray {
  countryLanguage?: MedicinalProductNameArrayCountryLanguageArray[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  namePart?: MedicinalProductNameArrayNamePartArray[]
  productName: string
}

export interface MedicinalProductNameArrayCountryLanguageArray {
  country: CodeableConcept
  extension?: Extension[]
  id?: string
  jurisdiction?: CodeableConcept
  language: CodeableConcept
  modifierExtension?: Extension[]
}

export interface MedicinalProductNameArrayNamePartArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  part: string
  type: Coding
}

export interface MedicinalProductPackagedMedicinalProductArray {
  resourceType?: PackagedMedicinalProductResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum PackagedMedicinalProductResourceType {
  MedicinalProductPackaged = "MedicinalProductPackaged",
}

export interface MedicinalProductPharmaceuticalProductArray {
  resourceType?: PharmaceuticalProductResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum PharmaceuticalProductResourceType {
  MedicinalProductPharmaceutical = "MedicinalProductPharmaceutical",
}

export interface MedicinalProductSpecialDesignationArray {
  date?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  indication?: MedicinalProductSpecialDesignationArrayIndication
  intendedUse?: CodeableConcept
  modifierExtension?: Extension[]
  species?: CodeableConcept
  status?: CodeableConcept
  type?: CodeableConcept
}

export interface MedicinalProductSpecialDesignationArrayIndication {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference6
}

export interface Reference6 {
  resourceType?: TherapeuticIndicationResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Mapping {
  body: MappingBody
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  params?: MappingParams
  resourceType?: string
  returns?: Returns
  scopeSchema?: MappingScopeSchema
  text?: MappingText
}

export interface MappingBody {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface MappingParams {
  extension?: Extension[]
  modifierExtension?: Extension[]
  "omit-drop-blanks"?: boolean
}

export enum Returns {
  Resource = "resource",
  Transaction = "transaction",
}

export interface MappingScopeSchema {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface MappingText {
  div?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  status?: string
}

export interface ChargeItemDefinition {
  applicability?: ChargeItemDefinitionApplicabilityArray[]
  approvalDate?: string
  code?: CodeableConcept
  contact?: ContactDetail[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  copyright?: string
  date?: string
  derivedFromUri?: string[]
  description?: string
  effectivePeriod?: Period
  experimental?: boolean
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  instance?: ChargeItemDefinitionInstanceArray[]
  jurisdiction?: CodeableConcept[]
  language?: string
  lastReviewDate?: string
  meta?: Meta
  modifierExtension?: Extension[]
  partOf?: string[]
  propertyGroup?: ChargeItemDefinitionPropertyGroupArray[]
  publisher?: string
  replaces?: string[]
  resourceType?: string
  status: string
  text?: Narrative
  title?: string
  url: string
  useContext?: UsageContext[]
  version?: string
}

export interface ChargeItemDefinitionApplicabilityArray {
  description?: string
  expression?: string
  extension?: Extension[]
  id?: string
  language?: string
  modifierExtension?: Extension[]
}

export interface ChargeItemDefinitionInstanceArray {
  resourceType?: UsedReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ChargeItemDefinitionPropertyGroupArray {
  applicability?: ChargeItemDefinitionApplicabilityArray[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  priceComponent?: ChargeItemDefinitionPropertyGroupArrayPriceComponentArray[]
}

export interface ChargeItemDefinitionPropertyGroupArrayPriceComponentArray {
  amount?: Money
  code?: CodeableConcept
  extension?: Extension[]
  factor?: number
  id?: string
  modifierExtension?: Extension[]
  type: string
}

export interface Practitioner {
  active?: boolean
  address?: Address[]
  birthDate?: string
  communication?: CodeableConcept[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  extension?: Extension[]
  gender?: string
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  name?: HumanName[]
  photo?: Attachment[]
  qualification?: PractitionerQualificationArray[]
  resourceType?: string
  telecom?: ContactPoint[]
  text?: Narrative
}

export const isPractitioner = (
  resource?: any[] | boolean | number | number | null | ResourceObject | string,
): resource is Practitioner => isResourceObject(resource) && resource.resourceType === "Practitioner"

export interface PractitionerQualificationArray {
  code: CodeableConcept
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  issuer?: QualificationIssuer
  modifierExtension?: Extension[]
  period?: Period
}

export interface QualificationIssuer {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicinalProductPackaged {
  batchIdentifier?: MedicinalProductPackagedBatchIdentifierArray[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  description?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  legalStatusOfSupply?: CodeableConcept
  manufacturer?: MedicinalProductPackagedManufacturerArray[]
  marketingAuthorization?: MarketingAuthorization
  marketingStatus?: MarketingStatus[]
  meta?: Meta
  modifierExtension?: Extension[]
  packageItem: MedicinalProductPackagedPackageItemArrayPackageItemArray[]
  resourceType?: string
  subject?: MedicinalProductPackagedSubjectArray[]
  text?: Narrative
}

export interface MedicinalProductPackagedBatchIdentifierArray {
  extension?: Extension[]
  id?: string
  immediatePackaging?: Identifier
  modifierExtension?: Extension[]
  outerPackaging: Identifier
}

export interface MedicinalProductPackagedManufacturerArray {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MarketingAuthorization {
  resourceType?: MarketingAuthorizationResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum MarketingAuthorizationResourceType {
  MedicinalProductAuthorization = "MedicinalProductAuthorization",
}

export interface MedicinalProductPackagedPackageItemArrayPackageItemArray {
  alternateMaterial?: CodeableConcept[]
  device?: MedicinalProductPackagedPackageItemArrayDeviceArray[]
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  manufacturedItem?: MedicinalProductPackagedPackageItemArrayManufacturedItemArray[]
  manufacturer?: MedicinalProductPackagedPackageItemArrayManufacturerArray[]
  material?: CodeableConcept[]
  modifierExtension?: Extension[]
  otherCharacteristics?: CodeableConcept[]
  packageItem?: MedicinalProductPackagedPackageItemArrayPackageItemArray[]
  physicalCharacteristics?: ProdCharacteristic
  quantity: Quantity
  shelfLifeStorage?: ProductShelfLife[]
  type: CodeableConcept
}

export interface MedicinalProductPackagedPackageItemArrayDeviceArray {
  resourceType?: ParentDeviceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicinalProductPackagedPackageItemArrayManufacturedItemArray {
  resourceType?: ManufacturedItemResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ManufacturedItemResourceType {
  MedicinalProductManufactured = "MedicinalProductManufactured",
}

export interface MedicinalProductPackagedPackageItemArrayManufacturerArray {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicinalProductPackagedSubjectArray {
  resourceType?: Array46
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array46 {
  MedicinalProduct = "MedicinalProduct",
}

export interface Flag {
  author?: FlagAuthor
  category?: CodeableConcept[]
  code: CodeableConcept
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  encounter?: FlagEncounter
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  period?: Period
  resourceType?: string
  status: string
  subject: FlagSubject
  text?: Narrative
}

export interface FlagAuthor {
  resourceType?: Array47
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array47 {
  Device = "Device",
  Organization = "Organization",
  Patient = "Patient",
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
}

export interface FlagEncounter {
  resourceType?: TentacledArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface FlagSubject {
  resourceType?: Array48
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array48 {
  Group = "Group",
  Location = "Location",
  Medication = "Medication",
  Organization = "Organization",
  Patient = "Patient",
  PlanDefinition = "PlanDefinition",
  Practitioner = "Practitioner",
  Procedure = "Procedure",
}

export interface ExplanationOfBenefit {
  accident?: ExplanationOfBenefitAccident
  addItem?: ExplanationOfBenefitAddItemArray[]
  adjudication?: ExplanationOfBenefitAdjudicationArray[]
  benefitBalance?: ExplanationOfBenefitBenefitBalanceArray[]
  benefitPeriod?: Period
  billablePeriod?: Period
  careTeam?: ExplanationOfBenefitCareTeamArray[]
  claim?: ExplanationOfBenefitClaim
  claimResponse?: ExplanationOfBenefitClaimResponse
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  created: string
  diagnosis?: ExplanationOfBenefitDiagnosisArray[]
  disposition?: string
  enterer?: ExplanationOfBenefitEnterer
  extension?: Extension[]
  facility?: ExplanationOfBenefitFacility
  form?: Attachment
  formCode?: CodeableConcept
  fundsReserve?: CodeableConcept
  fundsReserveRequested?: CodeableConcept
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  insurance: ExplanationOfBenefitInsuranceArray[]
  insurer: ExplanationOfBenefitInsurer
  item?: ExplanationOfBenefitItemArray[]
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  originalPrescription?: ExplanationOfBenefitOriginalPrescription
  outcome: string
  patient: ExplanationOfBenefitPatient
  payee?: ExplanationOfBenefitPayee
  payment?: ExplanationOfBenefitPayment
  preAuthRef?: string[]
  preAuthRefPeriod?: Period[]
  precedence?: number
  prescription?: ExplanationOfBenefitPrescription
  priority?: CodeableConcept
  procedure?: ExplanationOfBenefitProcedureArray[]
  processNote?: ExplanationOfBenefitProcessNoteArray[]
  provider: ExplanationOfBenefitProvider
  referral?: ExplanationOfBenefitReferral
  related?: ExplanationOfBenefitRelatedArray[]
  resourceType?: string
  status: string
  subType?: CodeableConcept
  supportingInfo?: ExplanationOfBenefitSupportingInfoArray[]
  text?: Narrative
  total?: ExplanationOfBenefitTotalArray[]
  type: CodeableConcept
  use: string
}

export interface ExplanationOfBenefitAccident {
  date?: string
  extension?: Extension[]
  id?: string
  location?: ExplanationOfBenefitAccidentLocation
  modifierExtension?: Extension[]
  type?: CodeableConcept
}

export interface ExplanationOfBenefitAccidentLocation {
  Address?: Address
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference7
}

export interface Reference7 {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ExplanationOfBenefitAddItemArray {
  adjudication?: ExplanationOfBenefitAdjudicationArray[]
  bodySite?: CodeableConcept
  detail?: ExplanationOfBenefitAddItemArrayDetailArray[]
  detailSequence?: number[]
  extension?: Extension[]
  factor?: number
  id?: string
  itemSequence?: number[]
  location?: ExplanationOfBenefitAddItemArrayLocation
  modifier?: CodeableConcept[]
  modifierExtension?: Extension[]
  net?: Money
  noteNumber?: number[]
  productOrService: CodeableConcept
  programCode?: CodeableConcept[]
  provider?: ExplanationOfBenefitAddItemArrayProviderArray[]
  quantity?: Quantity
  serviced?: ExplanationOfBenefitAddItemArrayServiced
  subDetailSequence?: number[]
  subSite?: CodeableConcept[]
  unitPrice?: Money
}

export interface ExplanationOfBenefitAdjudicationArray {
  amount?: Money
  category: CodeableConcept
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  reason?: CodeableConcept
  value?: number
}

export interface ExplanationOfBenefitAddItemArrayDetailArray {
  adjudication?: ExplanationOfBenefitAdjudicationArray[]
  extension?: Extension[]
  factor?: number
  id?: string
  modifier?: CodeableConcept[]
  modifierExtension?: Extension[]
  net?: Money
  noteNumber?: number[]
  productOrService: CodeableConcept
  quantity?: Quantity
  subDetail?: ExplanationOfBenefitAddItemArrayDetailArraySubDetailArray[]
  unitPrice?: Money
}

export interface ExplanationOfBenefitAddItemArrayDetailArraySubDetailArray {
  adjudication?: ExplanationOfBenefitAdjudicationArray[]
  extension?: Extension[]
  factor?: number
  id?: string
  modifier?: CodeableConcept[]
  modifierExtension?: Extension[]
  net?: Money
  noteNumber?: number[]
  productOrService: CodeableConcept
  quantity?: Quantity
  unitPrice?: Money
}

export interface ExplanationOfBenefitAddItemArrayLocation {
  Address?: Address
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference8
}

export interface Reference8 {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ExplanationOfBenefitAddItemArrayProviderArray {
  resourceType?: AuthenticatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ExplanationOfBenefitAddItemArrayServiced {
  date?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
}

export interface ExplanationOfBenefitBenefitBalanceArray {
  category: CodeableConcept
  description?: string
  excluded?: boolean
  extension?: Extension[]
  financial?: ExplanationOfBenefitBenefitBalanceArrayFinancialArray[]
  id?: string
  modifierExtension?: Extension[]
  name?: string
  network?: CodeableConcept
  term?: CodeableConcept
  unit?: CodeableConcept
}

export interface ExplanationOfBenefitBenefitBalanceArrayFinancialArray {
  allowed?: ExplanationOfBenefitBenefitBalanceArrayFinancialArrayAllowed
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  type: CodeableConcept
  used?: ExplanationOfBenefitBenefitBalanceArrayFinancialArrayUsed
}

export interface ExplanationOfBenefitBenefitBalanceArrayFinancialArrayAllowed {
  extension?: Extension[]
  modifierExtension?: Extension[]
  Money?: Money
  string?: string
  unsignedInt?: number
}

export interface ExplanationOfBenefitBenefitBalanceArrayFinancialArrayUsed {
  extension?: Extension[]
  modifierExtension?: Extension[]
  Money?: Money
  unsignedInt?: number
}

export interface ExplanationOfBenefitCareTeamArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  provider: FluffyProvider
  qualification?: CodeableConcept
  responsible?: boolean
  role?: CodeableConcept
  sequence: number
}

export interface FluffyProvider {
  resourceType?: AuthenticatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ExplanationOfBenefitClaim {
  resourceType?: ClaimResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ExplanationOfBenefitClaimResponse {
  resourceType?: ClaimResponseResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ExplanationOfBenefitDiagnosisArray {
  diagnosis?: ExplanationOfBenefitDiagnosisArrayDiagnosis
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  onAdmission?: CodeableConcept
  packageCode?: CodeableConcept
  sequence: number
  type?: CodeableConcept[]
}

export interface ExplanationOfBenefitDiagnosisArrayDiagnosis {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference9
}

export interface Reference9 {
  resourceType?: ResultingConditionResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ExplanationOfBenefitEnterer {
  resourceType?: CollectorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ExplanationOfBenefitFacility {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ExplanationOfBenefitInsuranceArray {
  coverage: TentacledCoverage
  extension?: Extension[]
  focal: boolean
  id?: string
  modifierExtension?: Extension[]
  preAuthRef?: string[]
}

export interface TentacledCoverage {
  resourceType?: CoverageResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ExplanationOfBenefitInsurer {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ExplanationOfBenefitItemArray {
  adjudication?: ExplanationOfBenefitAdjudicationArray[]
  bodySite?: CodeableConcept
  careTeamSequence?: number[]
  category?: CodeableConcept
  detail?: ExplanationOfBenefitItemArrayDetailArray[]
  diagnosisSequence?: number[]
  encounter?: ExplanationOfBenefitItemArrayEncounterArray[]
  extension?: Extension[]
  factor?: number
  id?: string
  informationSequence?: number[]
  location?: ExplanationOfBenefitItemArrayLocation
  modifier?: CodeableConcept[]
  modifierExtension?: Extension[]
  net?: Money
  noteNumber?: number[]
  procedureSequence?: number[]
  productOrService: CodeableConcept
  programCode?: CodeableConcept[]
  quantity?: Quantity
  revenue?: CodeableConcept
  sequence: number
  serviced?: ExplanationOfBenefitItemArrayServiced
  subSite?: CodeableConcept[]
  udi?: ExplanationOfBenefitItemArrayUdiArray[]
  unitPrice?: Money
}

export interface ExplanationOfBenefitItemArrayDetailArray {
  adjudication?: ExplanationOfBenefitAdjudicationArray[]
  category?: CodeableConcept
  extension?: Extension[]
  factor?: number
  id?: string
  modifier?: CodeableConcept[]
  modifierExtension?: Extension[]
  net?: Money
  noteNumber?: number[]
  productOrService: CodeableConcept
  programCode?: CodeableConcept[]
  quantity?: Quantity
  revenue?: CodeableConcept
  sequence: number
  subDetail?: ExplanationOfBenefitItemArrayDetailArraySubDetailArray[]
  udi?: ExplanationOfBenefitItemArrayDetailArrayUdiArray[]
  unitPrice?: Money
}

export interface ExplanationOfBenefitItemArrayDetailArraySubDetailArray {
  adjudication?: ExplanationOfBenefitAdjudicationArray[]
  category?: CodeableConcept
  extension?: Extension[]
  factor?: number
  id?: string
  modifier?: CodeableConcept[]
  modifierExtension?: Extension[]
  net?: Money
  noteNumber?: number[]
  productOrService: CodeableConcept
  programCode?: CodeableConcept[]
  quantity?: Quantity
  revenue?: CodeableConcept
  sequence: number
  udi?: ExplanationOfBenefitItemArrayDetailArraySubDetailArrayUdiArray[]
  unitPrice?: Money
}

export interface ExplanationOfBenefitItemArrayDetailArraySubDetailArrayUdiArray {
  resourceType?: UdiResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ExplanationOfBenefitItemArrayDetailArrayUdiArray {
  resourceType?: UdiResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ExplanationOfBenefitItemArrayEncounterArray {
  resourceType?: TentacledArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ExplanationOfBenefitItemArrayLocation {
  Address?: Address
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference10
}

export interface Reference10 {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ExplanationOfBenefitItemArrayServiced {
  date?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
}

export interface ExplanationOfBenefitItemArrayUdiArray {
  resourceType?: UdiResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ExplanationOfBenefitOriginalPrescription {
  resourceType?: AuthorizingPrescriptionResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ExplanationOfBenefitPatient {
  resourceType?: CandidateResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ExplanationOfBenefitPayee {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  party?: PurpleParty
  type?: CodeableConcept
}

export interface PurpleParty {
  resourceType?: InformationSourceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ExplanationOfBenefitPayment {
  adjustment?: Money
  adjustmentReason?: CodeableConcept
  amount?: Money
  date?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  modifierExtension?: Extension[]
  type?: CodeableConcept
}

export interface ExplanationOfBenefitPrescription {
  resourceType?: Array49
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array49 {
  MedicationRequest = "MedicationRequest",
  VisionPrescription = "VisionPrescription",
}

export interface ExplanationOfBenefitProcedureArray {
  date?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  procedure?: ExplanationOfBenefitProcedureArrayProcedure
  sequence: number
  type?: CodeableConcept[]
  udi?: ExplanationOfBenefitProcedureArrayUdiArray[]
}

export interface ExplanationOfBenefitProcedureArrayProcedure {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference11
}

export interface Reference11 {
  resourceType?: ProcedureReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ExplanationOfBenefitProcedureArrayUdiArray {
  resourceType?: UdiResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ExplanationOfBenefitProcessNoteArray {
  extension?: Extension[]
  id?: string
  language?: CodeableConcept
  modifierExtension?: Extension[]
  number?: number
  text?: string
  type?: string
}

export interface ExplanationOfBenefitProvider {
  resourceType?: AuthenticatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ExplanationOfBenefitReferral {
  resourceType?: ReferralRequestResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ExplanationOfBenefitRelatedArray {
  claim?: PurpleClaim
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  reference?: Identifier
  relationship?: CodeableConcept
}

export interface PurpleClaim {
  resourceType?: ClaimResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ExplanationOfBenefitSupportingInfoArray {
  category: CodeableConcept
  code?: CodeableConcept
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  reason?: Coding
  sequence: number
  timing?: ExplanationOfBenefitSupportingInfoArrayTiming
  value?: ExplanationOfBenefitSupportingInfoArrayValue
}

export interface ExplanationOfBenefitSupportingInfoArrayTiming {
  date?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
}

export interface ExplanationOfBenefitSupportingInfoArrayValue {
  Attachment?: Attachment
  boolean?: boolean
  extension?: Extension[]
  modifierExtension?: Extension[]
  Quantity?: Quantity
  Reference?: Reference
  string?: string
}

export interface ExplanationOfBenefitTotalArray {
  amount: Money
  category: CodeableConcept
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
}

export interface Linkage {
  active?: boolean
  author?: LinkageAuthor
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  extension?: Extension[]
  id?: string
  implicitRules?: string
  item: LinkageItemArray[]
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
  text?: Narrative
}

export interface LinkageAuthor {
  resourceType?: AuthenticatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface LinkageItemArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  resource: Reference
  type: string
}

export interface OperationOutcome {
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  extension?: Extension[]
  id?: string
  implicitRules?: string
  issue: OperationOutcomeIssueArray[]
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
  text?: Narrative
}

export const isOperationOutcome = (
  resource?: any[] | boolean | number | number | null | ResourceObject | string,
): resource is OperationOutcome => isResourceObject(resource) && resource.resourceType === "OperationOutcome"

export interface OperationOutcomeIssueArray {
  code: string
  details?: CodeableConcept
  diagnostics?: string
  expression?: string[]
  extension?: Extension[]
  id?: string
  location?: string[]
  modifierExtension?: Extension[]
  severity: string
}

export interface MedicinalProductPharmaceutical {
  administrableDoseForm: CodeableConcept
  characteristics?: MedicinalProductPharmaceuticalCharacteristicsArray[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  device?: MedicinalProductPharmaceuticalDeviceArray[]
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  ingredient?: MedicinalProductPharmaceuticalIngredientArray[]
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
  routeOfAdministration: MedicinalProductPharmaceuticalRouteOfAdministrationArray[]
  text?: Narrative
  unitOfPresentation?: CodeableConcept
}

export interface MedicinalProductPharmaceuticalCharacteristicsArray {
  code: CodeableConcept
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  status?: CodeableConcept
}

export interface MedicinalProductPharmaceuticalDeviceArray {
  resourceType?: ParentDeviceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicinalProductPharmaceuticalIngredientArray {
  resourceType?: IngredientResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum IngredientResourceType {
  MedicinalProductIngredient = "MedicinalProductIngredient",
}

export interface MedicinalProductPharmaceuticalRouteOfAdministrationArray {
  code: CodeableConcept
  extension?: Extension[]
  firstDose?: Quantity
  id?: string
  maxDosePerDay?: Quantity
  maxDosePerTreatmentPeriod?: Ratio
  maxSingleDose?: Quantity
  maxTreatmentPeriod?: Duration
  modifierExtension?: Extension[]
  targetSpecies?: MedicinalProductPharmaceuticalRouteOfAdministrationArrayTargetSpeciesArray[]
}

export interface MedicinalProductPharmaceuticalRouteOfAdministrationArrayTargetSpeciesArray {
  code: CodeableConcept
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  withdrawalPeriod?: MedicinalProductPharmaceuticalRouteOfAdministrationArrayTargetSpeciesArrayWithdrawalPeriodArray[]
}

export interface MedicinalProductPharmaceuticalRouteOfAdministrationArrayTargetSpeciesArrayWithdrawalPeriodArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  supportingInformation?: string
  tissue: CodeableConcept
  value: Quantity
}

export interface Immunization {
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  doseQuantity?: Quantity
  education?: ImmunizationEducationArray[]
  encounter?: ImmunizationEncounter
  expirationDate?: string
  extension?: Extension[]
  fundingSource?: CodeableConcept
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  isSubpotent?: boolean
  language?: string
  location?: ImmunizationLocation
  lotNumber?: string
  manufacturer?: ImmunizationManufacturer
  meta?: Meta
  modifierExtension?: Extension[]
  note?: Annotation[]
  occurrence?: ImmunizationOccurrence
  patient: ImmunizationPatient
  performer?: ImmunizationPerformerArray[]
  primarySource?: boolean
  programEligibility?: CodeableConcept[]
  protocolApplied?: ImmunizationProtocolAppliedArray[]
  reaction?: ImmunizationReactionArray[]
  reasonCode?: CodeableConcept[]
  reasonReference?: ImmunizationReasonReferenceArray[]
  recorded?: string
  reportOrigin?: CodeableConcept
  resourceType?: string
  route?: CodeableConcept
  site?: CodeableConcept
  status: string
  statusReason?: CodeableConcept
  subpotentReason?: CodeableConcept[]
  text?: Narrative
  vaccineCode: CodeableConcept
}

export interface ImmunizationEducationArray {
  documentType?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  presentationDate?: string
  publicationDate?: string
  reference?: string
}

export interface ImmunizationEncounter {
  resourceType?: TentacledArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ImmunizationLocation {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ImmunizationManufacturer {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ImmunizationOccurrence {
  dateTime?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  string?: string
}

export interface ImmunizationPatient {
  resourceType?: CandidateResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ImmunizationPerformerArray {
  actor: IndecentActor
  extension?: Extension[]
  function?: CodeableConcept
  id?: string
  modifierExtension?: Extension[]
}

export interface IndecentActor {
  resourceType?: AuthenticatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ImmunizationProtocolAppliedArray {
  authority?: ProtocolAppliedAuthority
  doseNumber?: ImmunizationProtocolAppliedArrayDoseNumber
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  series?: string
  seriesDoses?: ImmunizationProtocolAppliedArraySeriesDoses
  targetDisease?: CodeableConcept[]
}

export interface ProtocolAppliedAuthority {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ImmunizationProtocolAppliedArrayDoseNumber {
  extension?: Extension[]
  modifierExtension?: Extension[]
  positiveInt?: number
  string?: string
}

export interface ImmunizationProtocolAppliedArraySeriesDoses {
  extension?: Extension[]
  modifierExtension?: Extension[]
  positiveInt?: number
  string?: string
}

export interface ImmunizationReactionArray {
  date?: string
  detail?: Detail
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  reported?: boolean
}

export interface Detail {
  resourceType?: OutcomeReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ImmunizationReasonReferenceArray {
  resourceType?: Array19
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface UISnippet {
  command?: string
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
  title?: string
  type?: UIHistoryType
  user?: UISnippetUser
}

export interface UISnippetUser {
  resourceType?: UserResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationKnowledge {
  administrationGuidelines?: MedicationKnowledgeAdministrationGuidelinesArray[]
  amount?: Quantity
  associatedMedication?: MedicationKnowledgeAssociatedMedicationArray[]
  catalogHeader?: MedicationKnowledgeCatalogHeaderArray[]
  code?: CodeableConcept
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  contraindication?: MedicationKnowledgeContraindicationArray[]
  cost?: MedicationKnowledgeCostArray[]
  doseForm?: CodeableConcept
  drugCharacteristic?: MedicationKnowledgeDrugCharacteristicArray[]
  extension?: Extension[]
  id?: string
  implicitRules?: string
  ingredient?: MedicationKnowledgeIngredientArray[]
  intendedRoute?: CodeableConcept[]
  kinetics?: MedicationKnowledgeKineticsArray[]
  language?: string
  manufacturer?: MedicationKnowledgeManufacturer
  medicineClassification?: MedicationKnowledgeMedicineClassificationArray[]
  meta?: Meta
  modifierExtension?: Extension[]
  monitoringProgram?: MedicationKnowledgeMonitoringProgramArray[]
  monograph?: MedicationKnowledgeMonographArray[]
  packaging?: MedicationKnowledgePackaging
  preparationInstruction?: string
  productType?: CodeableConcept[]
  regulatory?: MedicationKnowledgeRegulatoryArray[]
  relatedMedicationKnowledge?: MedicationKnowledgeRelatedMedicationKnowledgeArray[]
  resourceType?: string
  status?: string
  synonym?: string[]
  text?: Narrative
}

export interface MedicationKnowledgeAdministrationGuidelinesArray {
  dosage?: MedicationKnowledgeAdministrationGuidelinesArrayDosageArray[]
  extension?: Extension[]
  id?: string
  indication?: MedicationKnowledgeAdministrationGuidelinesArrayIndication
  modifierExtension?: Extension[]
  patientCharacteristics?: MedicationKnowledgeAdministrationGuidelinesArrayPatientCharacteristicsArray[]
}

export interface MedicationKnowledgeAdministrationGuidelinesArrayDosageArray {
  dosage: Dosage[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  type: CodeableConcept
}

export interface MedicationKnowledgeAdministrationGuidelinesArrayIndication {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference12
}

export interface Reference12 {
  resourceType?: ObservationRequirementResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationKnowledgeAdministrationGuidelinesArrayPatientCharacteristicsArray {
  characteristic?: MedicationKnowledgeAdministrationGuidelinesArrayPatientCharacteristicsArrayCharacteristic
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  R5_valueRange?: Range
  value?: string[]
}

export interface MedicationKnowledgeAdministrationGuidelinesArrayPatientCharacteristicsArrayCharacteristic {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Quantity?: Quantity
}

export interface MedicationKnowledgeAssociatedMedicationArray {
  resourceType?: AssociatedMedicationResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationKnowledgeCatalogHeaderArray {
  resourceType?: CatalogHeaderResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationKnowledgeContraindicationArray {
  resourceType?: DetectedIssueResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationKnowledgeCostArray {
  cost: Money
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  source?: string
  type: CodeableConcept
}

export interface MedicationKnowledgeDrugCharacteristicArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  type?: CodeableConcept
  value?: MedicationKnowledgeDrugCharacteristicArrayValue
}

export interface MedicationKnowledgeDrugCharacteristicArrayValue {
  attachment?: Attachment
  base64Binary?: string
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Quantity?: Quantity
  string?: string
}

export interface MedicationKnowledgeIngredientArray {
  extension?: Extension[]
  id?: string
  isActive?: boolean
  item?: MedicationKnowledgeIngredientArrayItem
  modifierExtension?: Extension[]
  strength?: Ratio
}

export interface MedicationKnowledgeIngredientArrayItem {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference13
}

export interface Reference13 {
  resourceType?: AdditiveResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationKnowledgeKineticsArray {
  areaUnderCurve?: Quantity[]
  extension?: Extension[]
  halfLifePeriod?: Duration
  id?: string
  lethalDose50?: Quantity[]
  modifierExtension?: Extension[]
}

export interface MedicationKnowledgeManufacturer {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationKnowledgeMedicineClassificationArray {
  classification?: CodeableConcept[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  type: CodeableConcept
}

export interface MedicationKnowledgeMonitoringProgramArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  name?: string
  type?: CodeableConcept
}

export interface MedicationKnowledgeMonographArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  source?: MonographSource
  type?: CodeableConcept
}

export interface MonographSource {
  resourceType?: Array50
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array50 {
  DocumentReference = "DocumentReference",
  Media = "Media",
}

export interface MedicationKnowledgePackaging {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  quantity?: Quantity
  type?: CodeableConcept
}

export interface MedicationKnowledgeRegulatoryArray {
  extension?: Extension[]
  id?: string
  maxDispense?: MedicationKnowledgeRegulatoryArrayMaxDispense
  modifierExtension?: Extension[]
  regulatoryAuthority: RegulatoryAuthority
  schedule?: MedicationKnowledgeRegulatoryArrayScheduleArray[]
  substitution?: MedicationKnowledgeRegulatoryArraySubstitutionArray[]
}

export interface MedicationKnowledgeRegulatoryArrayMaxDispense {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  period?: Duration
  quantity: Quantity
}

export interface RegulatoryAuthority {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationKnowledgeRegulatoryArrayScheduleArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  schedule: CodeableConcept
}

export interface MedicationKnowledgeRegulatoryArraySubstitutionArray {
  allowed: boolean
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  type: CodeableConcept
}

export interface MedicationKnowledgeRelatedMedicationKnowledgeArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  reference: MedicationKnowledgeRelatedMedicationKnowledgeArrayReferenceArray[]
  type: CodeableConcept
}

export interface MedicationKnowledgeRelatedMedicationKnowledgeArrayReferenceArray {
  resourceType?: Array51
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array51 {
  MedicationKnowledge = "MedicationKnowledge",
}

export interface AidboxSubscription {
  action?: any[] | { [key: string]: any } | string
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  module?: string
  resources?: string[]
  resourceType?: string
  type?: AidboxSubscriptionType
}

export enum AidboxSubscriptionType {
  Async = "async",
  Sync = "sync",
}

export interface User {
  active?: boolean
  addresses?: UserAddressesArray[]
  costCenter?: string
  data?: UserData
  department?: string
  division?: string
  email?: string
  emails?: UserEmailsArray[]
  employeeNumber?: string
  entitlements?: UserEntitlementsArray[]
  extension?: Extension[]
  fhirUser?: FhirUser
  gender?: string
  id?: string
  identifier?: Identifier[]
  ims?: UserImsArray[]
  inactive?: boolean
  link?: UserLinkArray[]
  locale?: string
  manager?: Manager
  meta?: Meta
  modifierExtension?: Extension[]
  name?: UserName
  organization?: UserOrganization
  password?: string
  phoneNumber?: string
  phoneNumbers?: UserPhoneNumbersArray[]
  photo?: string
  photos?: UserPhotosArray[]
  preferredLanguage?: string
  profileUrl?: string
  resourceType?: string
  roles?: UserRolesArray[]
  timezone?: string
  title?: string
  twoFactor?: UserTwoFactor
  userName?: string
  userType?: string
  x509Certificates?: UserX509CertificatesArray[]
}

export const isUser = (
  resource?: any[] | boolean | number | number | null | ResourceObject | string,
): resource is User => isResourceObject(resource) && resource.resourceType === "User"

export interface UserAddressesArray {
  country?: string
  extension?: Extension[]
  formatted?: string
  locality?: string
  modifierExtension?: Extension[]
  postalCode?: string
  region?: string
  streetAddress?: string
  type?: string
}

export interface UserData {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface UserEmailsArray {
  display?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  primary?: boolean
  type?: string
  value?: string
}

export interface UserEntitlementsArray {
  display?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  primary?: boolean
  type?: string
  value?: string
}

export interface FhirUser {
  resourceType?: FhirUserResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum FhirUserResourceType {
  Patient = "Patient",
  Person = "Person",
  Practitioner = "Practitioner",
}

export interface UserImsArray {
  display?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  primary?: boolean
  type?: string
  value?: string
}

export interface UserLinkArray {
  extension?: Extension[]
  link?: Reference
  modifierExtension?: Extension[]
  type?: string
}

export interface Manager {
  resourceType?: UserResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface UserName {
  extension?: Extension[]
  familyName?: string
  formatted?: string
  givenName?: string
  honorificPrefix?: string
  honorificSuffix?: string
  middleName?: string
  modifierExtension?: Extension[]
}

export interface UserOrganization {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface UserPhoneNumbersArray {
  display?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  primary?: boolean
  type?: string
  value?: string
}

export interface UserPhotosArray {
  display?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  primary?: boolean
  type?: string
  value?: string
}

export interface UserRolesArray {
  display?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  primary?: boolean
  type?: string
  value?: string
}

export interface UserTwoFactor {
  enabled: boolean
  extension?: Extension[]
  modifierExtension?: Extension[]
  secretKey: string
  transport?: string
}

export interface UserX509CertificatesArray {
  display?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  primary?: boolean
  type?: string
  value?: string
}

export interface ResearchSubject {
  actualArm?: string
  assignedArm?: string
  consent?: ConsentClass
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  individual: ResearchSubjectIndividual
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  period?: Period
  resourceType?: string
  status: string
  study: Study
  text?: Narrative
}

export interface ConsentClass {
  resourceType?: ConsentResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ConsentResourceType {
  Consent = "Consent",
}

export interface ResearchSubjectIndividual {
  resourceType?: CandidateResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Study {
  resourceType?: StudyResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface GcpServiceAccount {
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  "private-key"?: string
  resourceType?: string
  "service-account-email"?: string
}

export interface MedicinalProductIndication {
  comorbidity?: CodeableConcept[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  diseaseStatus?: CodeableConcept
  diseaseSymptomProcedure?: CodeableConcept
  duration?: Quantity
  extension?: Extension[]
  id?: string
  implicitRules?: string
  intendedEffect?: CodeableConcept
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  otherTherapy?: MedicinalProductIndicationOtherTherapyArray[]
  population?: Population[]
  resourceType?: string
  subject?: MedicinalProductIndicationSubjectArray[]
  text?: Narrative
  undesirableEffect?: MedicinalProductIndicationUndesirableEffectArray[]
}

export interface MedicinalProductIndicationOtherTherapyArray {
  extension?: Extension[]
  id?: string
  medication?: MedicinalProductIndicationOtherTherapyArrayMedication
  modifierExtension?: Extension[]
  therapyRelationshipType: CodeableConcept
}

export interface MedicinalProductIndicationOtherTherapyArrayMedication {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference14
}

export interface Reference14 {
  resourceType?: Array9
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicinalProductIndicationSubjectArray {
  resourceType?: Array10
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicinalProductIndicationUndesirableEffectArray {
  resourceType?: UndesirableEffectResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum UndesirableEffectResourceType {
  MedicinalProductUndesirableEffect = "MedicinalProductUndesirableEffect",
}

export interface SDCAddendum {
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
}

export interface BulkImportStatus {
  contentEncoding?: ContentEncoding
  extension?: Extension[]
  id?: string
  inputFormat?: InputFormat
  inputs?: BulkImportStatusInputsArray[]
  meta?: Meta
  mode?: Mode
  modifierExtension?: Extension[]
  resourceType?: string
  source?: string
  status?: BulkImportStatusStatus
  storageDetail?: BulkImportStatusStorageDetail
  time?: BulkImportStatusTime
  type?: BulkImportStatusType
  update?: boolean
}

export enum ContentEncoding {
  Gzip = "gzip",
  Plain = "plain",
}

export enum InputFormat {
  ApplicationFhirNdjson = "application/fhir+ndjson",
}

export interface BulkImportStatusInputsArray {
  extension?: Extension[]
  modifierExtension?: Extension[]
  resourceType: string
  status?: string
  time?: number
  url: string
}

export enum Mode {
  Bulk = "bulk",
  Transaction = "transaction",
}

export enum BulkImportStatusStatus {
  Active = "active",
  Cancelled = "cancelled",
  Failed = "failed",
  Finished = "finished",
}

export interface BulkImportStatusStorageDetail {
  extension?: Extension[]
  modifierExtension?: Extension[]
  type?: StorageDetailType
}

export enum StorageDetailType {
  File = "file",
  HTTPS = "https",
}

export interface BulkImportStatusTime {
  end?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  start?: string
}

export enum BulkImportStatusType {
  Aidbox = "aidbox",
  Fhir = "fhir",
}

export interface CronJob {
  active: boolean
  code?: CronJobCode
  description?: string
  engine?: CronJobEngine
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
  runHistory?: CronJobRunHistory
  schedule?: string
  sql?: string
}

export interface CronJobCode {
  extension?: Extension[]
  handler?: string
  modifierExtension?: Extension[]
  params?: CronJobCodeParams
}

export interface CronJobCodeParams {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export enum CronJobEngine {
  Code = "code",
  SQL = "sql",
}

export interface CronJobRunHistory {
  extension?: Extension[]
  keep?: number
  modifierExtension?: Extension[]
}

export interface PaymentNotice {
  amount: Money
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  created: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  payee?: PaymentNoticePayee
  payment: Payment
  paymentDate?: string
  paymentStatus?: CodeableConcept
  provider?: PaymentNoticeProvider
  recipient: PaymentNoticeRecipient
  request?: Reference
  resourceType?: string
  response?: Reference
  status: string
  text?: Narrative
}

export interface PaymentNoticePayee {
  resourceType?: AuthenticatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Payment {
  resourceType?: PaymentResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum PaymentResourceType {
  PaymentReconciliation = "PaymentReconciliation",
}

export interface PaymentNoticeProvider {
  resourceType?: AuthenticatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface PaymentNoticeRecipient {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface NamingSystem {
  contact?: ContactDetail[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  date: string
  description?: string
  extension?: Extension[]
  id?: string
  implicitRules?: string
  jurisdiction?: CodeableConcept[]
  kind: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  name: string
  publisher?: string
  resourceType?: string
  responsible?: string
  status: string
  text?: Narrative
  type?: CodeableConcept
  uniqueId: NamingSystemUniqueIDArray[]
  usage?: string
  useContext?: UsageContext[]
}

export interface NamingSystemUniqueIDArray {
  comment?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  period?: Period
  preferred?: boolean
  type: string
  value: string
}

export interface MedicationStatement {
  basedOn?: MedicationStatementBasedOnArray[]
  category?: CodeableConcept
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  context?: MedicationStatementContext
  dateAsserted?: string
  derivedFrom?: Reference[]
  dosage?: Dosage[]
  effective?: MedicationStatementEffective
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  informationSource?: InformationSource
  language?: string
  medication?: MedicationStatementMedication
  meta?: Meta
  modifierExtension?: Extension[]
  note?: Annotation[]
  partOf?: MedicationStatementPartOfArray[]
  reasonCode?: CodeableConcept[]
  reasonReference?: MedicationStatementReasonReferenceArray[]
  resourceType?: string
  status: string
  statusReason?: CodeableConcept[]
  subject: MedicationStatementSubject
  text?: Narrative
}

export interface MedicationStatementBasedOnArray {
  resourceType?: HilariousArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationStatementContext {
  resourceType?: ContextResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationStatementEffective {
  dateTime?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
}

export interface InformationSource {
  resourceType?: InformationSourceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationStatementMedication {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference15
}

export interface Reference15 {
  resourceType?: AssociatedMedicationResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationStatementPartOfArray {
  resourceType?: Array52
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array52 {
  MedicationAdministration = "MedicationAdministration",
  MedicationDispense = "MedicationDispense",
  MedicationStatement = "MedicationStatement",
  Observation = "Observation",
  Procedure = "Procedure",
}

export interface MedicationStatementReasonReferenceArray {
  resourceType?: Array19
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationStatementSubject {
  resourceType?: FriskyArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface EnrollmentResponse {
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  created?: string
  disposition?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  organization?: EnrollmentResponseOrganization
  outcome?: string
  request?: EnrollmentResponseRequest
  requestProvider?: RequestProvider
  resourceType?: string
  status?: string
  text?: Narrative
}

export interface EnrollmentResponseOrganization {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface EnrollmentResponseRequest {
  resourceType?: Array53
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array53 {
  EnrollmentRequest = "EnrollmentRequest",
}

export interface RequestProvider {
  resourceType?: AuthenticatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface NutritionOrder {
  allergyIntolerance?: NutritionOrderAllergyIntoleranceArray[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  dateTime: string
  encounter?: NutritionOrderEncounter
  enteralFormula?: NutritionOrderEnteralFormula
  excludeFoodModifier?: CodeableConcept[]
  extension?: Extension[]
  foodPreferenceModifier?: CodeableConcept[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  instantiates?: string[]
  instantiatesCanonical?: string[]
  instantiatesUri?: string[]
  intent: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  note?: Annotation[]
  oralDiet?: NutritionOrderOralDiet
  orderer?: Orderer
  patient: NutritionOrderPatient
  resourceType?: string
  status: string
  supplement?: NutritionOrderSupplementArray[]
  text?: Narrative
}

export interface NutritionOrderAllergyIntoleranceArray {
  resourceType?: AllergyIntoleranceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum AllergyIntoleranceResourceType {
  AllergyIntolerance = "AllergyIntolerance",
}

export interface NutritionOrderEncounter {
  resourceType?: TentacledArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface NutritionOrderEnteralFormula {
  additiveProductName?: string
  additiveType?: CodeableConcept
  administration?: NutritionOrderEnteralFormulaAdministrationArray[]
  administrationInstruction?: string
  baseFormulaProductName?: string
  baseFormulaType?: CodeableConcept
  caloricDensity?: Quantity
  extension?: Extension[]
  id?: string
  maxVolumeToDeliver?: Quantity
  modifierExtension?: Extension[]
  routeofAdministration?: CodeableConcept
}

export interface NutritionOrderEnteralFormulaAdministrationArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  quantity?: Quantity
  rate?: NutritionOrderEnteralFormulaAdministrationArrayRate
  schedule?: Timing
}

export interface NutritionOrderEnteralFormulaAdministrationArrayRate {
  extension?: Extension[]
  modifierExtension?: Extension[]
  Quantity?: Quantity
  Ratio?: Ratio
}

export interface NutritionOrderOralDiet {
  extension?: Extension[]
  fluidConsistencyType?: CodeableConcept[]
  id?: string
  instruction?: string
  modifierExtension?: Extension[]
  nutrient?: NutritionOrderOralDietNutrientArray[]
  schedule?: Timing[]
  texture?: NutritionOrderOralDietTextureArray[]
  type?: CodeableConcept[]
}

export interface NutritionOrderOralDietNutrientArray {
  amount?: Quantity
  extension?: Extension[]
  id?: string
  modifier?: CodeableConcept
  modifierExtension?: Extension[]
}

export interface NutritionOrderOralDietTextureArray {
  extension?: Extension[]
  foodType?: CodeableConcept
  id?: string
  modifier?: CodeableConcept
  modifierExtension?: Extension[]
}

export interface Orderer {
  resourceType?: CollectorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface NutritionOrderPatient {
  resourceType?: CandidateResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface NutritionOrderSupplementArray {
  extension?: Extension[]
  id?: string
  instruction?: string
  modifierExtension?: Extension[]
  productName?: string
  quantity?: Quantity
  schedule?: Timing[]
  type?: CodeableConcept
}

export interface Questionnaire {
  approvalDate?: string
  code?: Coding[]
  contact?: ContactDetail[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  copyright?: string
  date?: string
  derivedFrom?: string[]
  description?: string
  effectivePeriod?: Period
  experimental?: boolean
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  item?: QuestionnaireItemArrayItemArray[]
  jurisdiction?: CodeableConcept[]
  language?: string
  lastReviewDate?: string
  meta?: Meta
  modifierExtension?: Extension[]
  name?: string
  publisher?: string
  purpose?: string
  resourceType?: string
  status: string
  subjectType?: string[]
  text?: Narrative
  title?: string
  url?: string
  useContext?: UsageContext[]
  version?: string
}

export interface QuestionnaireItemArrayItemArray {
  answerOption?: QuestionnaireItemArrayAnswerOptionArray[]
  answerValueSet?: string
  code?: Coding[]
  definition?: string
  enableBehavior?: string
  enableWhen?: QuestionnaireItemArrayEnableWhenArray[]
  extension?: Extension[]
  id?: string
  initial?: QuestionnaireItemArrayInitialArray[]
  item?: QuestionnaireItemArrayItemArray[]
  linkId?: string
  maxLength?: number
  modifierExtension?: Extension[]
  prefix?: string
  readOnly?: boolean
  repeats?: boolean
  required?: boolean
  text?: string
  type: string
}

export interface QuestionnaireItemArrayAnswerOptionArray {
  extension?: Extension[]
  id?: string
  initialSelected?: boolean
  modifierExtension?: Extension[]
  value?: QuestionnaireItemArrayAnswerOptionArrayValue
}

export interface QuestionnaireItemArrayAnswerOptionArrayValue {
  Coding?: Coding
  date?: string
  extension?: Extension[]
  integer?: number
  modifierExtension?: Extension[]
  Reference?: Reference
  string?: string
  time?: string
}

export interface QuestionnaireItemArrayEnableWhenArray {
  answer?: QuestionnaireItemArrayEnableWhenArrayAnswer
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  operator: string
  question: string
}

export interface QuestionnaireItemArrayEnableWhenArrayAnswer {
  boolean?: boolean
  Coding?: Coding
  date?: string
  dateTime?: string
  decimal?: number
  extension?: Extension[]
  integer?: number
  modifierExtension?: Extension[]
  Quantity?: Quantity
  Reference?: Reference
  string?: string
  time?: string
}

export interface QuestionnaireItemArrayInitialArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  value?: QuestionnaireItemArrayInitialArrayValue
}

export interface QuestionnaireItemArrayInitialArrayValue {
  Attachment?: Attachment
  boolean?: boolean
  Coding?: Coding
  date?: string
  dateTime?: string
  decimal?: number
  extension?: Extension[]
  integer?: number
  modifierExtension?: Extension[]
  Quantity?: Quantity
  Reference?: Reference
  string?: string
  time?: string
  uri?: string
}

export interface Account {
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  coverage?: AccountCoverageArray[]
  creditCard?: AccountCreditCardArray[]
  defaultCreditCard?: string
  description?: string
  extension?: Extension[]
  guarantor?: AccountGuarantorArray[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  name?: string
  owner?: AccountOwner
  partOf?: AccountPartOf
  resourceType?: string
  servicePeriod?: Period
  status: string
  subject?: AccountSubjectArray[]
  text?: Narrative
  type?: CodeableConcept
}

export interface AccountCoverageArray {
  coverage: CoverageCoverage
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  priority?: number
}

export interface CoverageCoverage {
  resourceType?: CoverageResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface AccountCreditCardArray {
  billingAddress?: Address
  cardHolderName?: string
  cvv?: string
  expirationMonth?: string
  expirationYear?: string
  extension?: Extension[]
  last4Digits?: string
  modifierExtension?: Extension[]
  number?: string
  text?: string
  token?: Identifier[]
  type?: string
  pciToken?: string
}

export interface AccountGuarantorArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  onHold?: boolean
  party: GuarantorParty
  period?: Period
}

export interface GuarantorParty {
  resourceType?: PayorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface AccountOwner {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface AccountPartOf {
  resourceType?: Array11
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface AccountSubjectArray {
  resourceType?: Array54
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array54 {
  Device = "Device",
  HealthcareService = "HealthcareService",
  Location = "Location",
  Organization = "Organization",
  Patient = "Patient",
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
}

export interface EventDefinition {
  approvalDate?: string
  author?: ContactDetail[]
  contact?: ContactDetail[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  copyright?: string
  date?: string
  description?: string
  editor?: ContactDetail[]
  effectivePeriod?: Period
  endorser?: ContactDetail[]
  experimental?: boolean
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  jurisdiction?: CodeableConcept[]
  language?: string
  lastReviewDate?: string
  meta?: Meta
  modifierExtension?: Extension[]
  name?: string
  publisher?: string
  purpose?: string
  relatedArtifact?: RelatedArtifact[]
  resourceType?: string
  reviewer?: ContactDetail[]
  status: string
  subject?: EventDefinitionSubject
  subtitle?: string
  text?: Narrative
  title?: string
  topic?: CodeableConcept[]
  trigger: TriggerDefinition[]
  url?: string
  usage?: string
  useContext?: UsageContext[]
  version?: string
}

export interface EventDefinitionSubject {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference16
}

export interface Reference16 {
  resourceType?: EnrollmentResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicinalProductUndesirableEffect {
  classification?: CodeableConcept
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  extension?: Extension[]
  frequencyOfOccurrence?: CodeableConcept
  id?: string
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  population?: Population[]
  resourceType?: string
  subject?: MedicinalProductUndesirableEffectSubjectArray[]
  symptomConditionEffect?: CodeableConcept
  text?: Narrative
}

export interface MedicinalProductUndesirableEffectSubjectArray {
  resourceType?: Array10
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SubstanceSpecification {
  code?: SubstanceSpecificationCodeArray[]
  comment?: string
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  description?: string
  domain?: CodeableConcept
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  moiety?: SubstanceSpecificationMoietyArray[]
  molecularWeight?: SubstanceSpecificationStructureIsotopeArrayMolecularWeight[]
  name?: SubstanceSpecificationNameArrayArray[]
  nucleicAcid?: NucleicAcid
  polymer?: Polymer
  property?: SubstanceSpecificationPropertyArray[]
  protein?: Protein
  referenceInformation?: ReferenceInformationClass
  relationship?: SubstanceSpecificationRelationshipArray[]
  resourceType?: string
  source?: SubstanceSpecificationSourceArray[]
  sourceMaterial?: SourceMaterial
  status?: CodeableConcept
  structure?: SubstanceSpecificationStructure
  text?: Narrative
  type?: CodeableConcept
}

export interface SubstanceSpecificationCodeArray {
  code?: CodeableConcept
  comment?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  source?: SubstanceSpecificationCodeArraySourceArray[]
  status?: CodeableConcept
  statusDate?: string
}

export interface SubstanceSpecificationCodeArraySourceArray {
  resourceType?: ReferenceDocumentResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SubstanceSpecificationMoietyArray {
  amount?: SubstanceSpecificationMoietyArrayAmount
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  modifierExtension?: Extension[]
  molecularFormula?: string
  name?: string
  opticalActivity?: CodeableConcept
  role?: CodeableConcept
  stereochemistry?: CodeableConcept
}

export interface SubstanceSpecificationMoietyArrayAmount {
  extension?: Extension[]
  modifierExtension?: Extension[]
  Quantity?: Quantity
  string?: string
}

export interface SubstanceSpecificationStructureIsotopeArrayMolecularWeight {
  amount?: Quantity
  extension?: Extension[]
  id?: string
  method?: CodeableConcept
  modifierExtension?: Extension[]
  type?: CodeableConcept
}

export interface SubstanceSpecificationNameArrayArray {
  domain?: CodeableConcept[]
  extension?: Extension[]
  id?: string
  jurisdiction?: CodeableConcept[]
  language?: CodeableConcept[]
  modifierExtension?: Extension[]
  name: string
  official?: SubstanceSpecificationNameArrayOfficialArray[]
  preferred?: boolean
  source?: SubstanceSpecificationNameArraySourceArray[]
  status?: CodeableConcept
  synonym?: SubstanceSpecificationNameArrayArray[]
  translation?: SubstanceSpecificationNameArrayArray[]
  type?: CodeableConcept
}

export interface SubstanceSpecificationNameArrayOfficialArray {
  authority?: CodeableConcept
  date?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  status?: CodeableConcept
}

export interface SubstanceSpecificationNameArraySourceArray {
  resourceType?: ReferenceDocumentResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface NucleicAcid {
  resourceType?: NucleicAcidResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum NucleicAcidResourceType {
  SubstanceNucleicAcid = "SubstanceNucleicAcid",
}

export interface Polymer {
  resourceType?: PolymerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum PolymerResourceType {
  SubstancePolymer = "SubstancePolymer",
}

export interface SubstanceSpecificationPropertyArray {
  amount?: SubstanceSpecificationPropertyArrayAmount
  category?: CodeableConcept
  code?: CodeableConcept
  definingSubstance?: SubstanceSpecificationPropertyArrayDefiningSubstance
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  parameters?: string
}

export interface SubstanceSpecificationPropertyArrayAmount {
  extension?: Extension[]
  modifierExtension?: Extension[]
  Quantity?: Quantity
  string?: string
}

export interface SubstanceSpecificationPropertyArrayDefiningSubstance {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: DefiningSubstanceReference
}

export interface DefiningSubstanceReference {
  resourceType?: Array55
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array55 {
  Substance = "Substance",
  SubstanceSpecification = "SubstanceSpecification",
}

export interface Protein {
  resourceType?: ProteinResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ProteinResourceType {
  SubstanceProtein = "SubstanceProtein",
}

export interface ReferenceInformationClass {
  resourceType?: ReferenceInformationResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ReferenceInformationResourceType {
  SubstanceReferenceInformation = "SubstanceReferenceInformation",
}

export interface SubstanceSpecificationRelationshipArray {
  amount?: SubstanceSpecificationRelationshipArrayAmount
  amountRatioLowLimit?: Ratio
  amountType?: CodeableConcept
  extension?: Extension[]
  id?: string
  isDefining?: boolean
  modifierExtension?: Extension[]
  relationship?: CodeableConcept
  source?: SubstanceSpecificationRelationshipArraySourceArray[]
  substance?: SubstanceSpecificationRelationshipArraySubstance
}

export interface SubstanceSpecificationRelationshipArrayAmount {
  extension?: Extension[]
  modifierExtension?: Extension[]
  Quantity?: Quantity
  Range?: Range
  Ratio?: Ratio
  string?: string
}

export interface SubstanceSpecificationRelationshipArraySourceArray {
  resourceType?: ReferenceDocumentResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SubstanceSpecificationRelationshipArraySubstance {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference17
}

export interface Reference17 {
  resourceType?: Array56
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array56 {
  SubstanceSpecification = "SubstanceSpecification",
}

export interface SubstanceSpecificationSourceArray {
  resourceType?: ReferenceDocumentResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SourceMaterial {
  resourceType?: SourceMaterialResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum SourceMaterialResourceType {
  SubstanceSourceMaterial = "SubstanceSourceMaterial",
}

export interface SubstanceSpecificationStructure {
  extension?: Extension[]
  id?: string
  isotope?: SubstanceSpecificationStructureIsotopeArray[]
  modifierExtension?: Extension[]
  molecularFormula?: string
  molecularFormulaByMoiety?: string
  molecularWeight?: SubstanceSpecificationStructureIsotopeArrayMolecularWeight
  opticalActivity?: CodeableConcept
  representation?: SubstanceSpecificationStructureRepresentationArray[]
  source?: SubstanceSpecificationStructureSourceArray[]
  stereochemistry?: CodeableConcept
}

export interface SubstanceSpecificationStructureIsotopeArray {
  extension?: Extension[]
  halfLife?: Quantity
  id?: string
  identifier?: Identifier
  modifierExtension?: Extension[]
  molecularWeight?: SubstanceSpecificationStructureIsotopeArrayMolecularWeight
  name?: CodeableConcept
  substitution?: CodeableConcept
}

export interface SubstanceSpecificationStructureRepresentationArray {
  attachment?: Attachment
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  representation?: string
  type?: CodeableConcept
}

export interface SubstanceSpecificationStructureSourceArray {
  resourceType?: ReferenceDocumentResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CommunicationRequest {
  about?: Reference[]
  authoredOn?: string
  basedOn?: Reference[]
  category?: CodeableConcept[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  doNotPerform?: boolean
  encounter?: CommunicationRequestEncounter
  extension?: Extension[]
  groupIdentifier?: Identifier
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  medium?: CodeableConcept[]
  meta?: Meta
  modifierExtension?: Extension[]
  note?: Annotation[]
  occurrence?: CommunicationRequestOccurrence
  payload?: CommunicationRequestPayloadArray[]
  priority?: string
  reasonCode?: CodeableConcept[]
  reasonReference?: CommunicationRequestReasonReferenceArray[]
  recipient?: CommunicationRequestRecipientArray[]
  replaces?: CommunicationRequestReplacesArray[]
  requester?: CommunicationRequestRequester
  resourceType?: string
  sender?: CommunicationRequestSender
  status: string
  statusReason?: CodeableConcept
  subject?: CommunicationRequestSubject
  template?: CommunicationRequestTemplate
  text?: Narrative
}

export interface CommunicationRequestEncounter {
  resourceType?: TentacledArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CommunicationRequestOccurrence {
  dateTime?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
}

export interface CommunicationRequestPayloadArray {
  content?: CommunicationRequestPayloadArrayContent
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
}

export interface CommunicationRequestPayloadArrayContent {
  Attachment?: Attachment
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference
  string?: string
}

export interface CommunicationRequestReasonReferenceArray {
  resourceType?: StickyArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CommunicationRequestRecipientArray {
  resourceType?: Array24
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CommunicationRequestReplacesArray {
  resourceType?: CommunicationRequestResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CommunicationRequestRequester {
  resourceType?: ObserverResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CommunicationRequestSender {
  resourceType?: Array25
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CommunicationRequestSubject {
  resourceType?: FriskyArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CommunicationRequestTemplate {
  data?: CommunicationRequestTemplateData
  extension?: Extension[]
  modifierExtension?: Extension[]
  reference: Coding
}

export interface CommunicationRequestTemplateData {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface SpecimenDefinition {
  collection?: CodeableConcept[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  patientPreparation?: CodeableConcept[]
  resourceType?: string
  text?: Narrative
  timeAspect?: string
  typeCollected?: CodeableConcept
  typeTested?: SpecimenDefinitionTypeTestedArray[]
}

export interface SpecimenDefinitionTypeTestedArray {
  container?: SpecimenDefinitionTypeTestedArrayContainer
  extension?: Extension[]
  handling?: SpecimenDefinitionTypeTestedArrayHandlingArray[]
  id?: string
  isDerived?: boolean
  modifierExtension?: Extension[]
  preference: string
  rejectionCriterion?: CodeableConcept[]
  requirement?: string
  retentionTime?: Duration
  type?: CodeableConcept
}

export interface SpecimenDefinitionTypeTestedArrayContainer {
  additive?: SpecimenDefinitionTypeTestedArrayContainerAdditiveArray[]
  cap?: CodeableConcept
  capacity?: Quantity
  description?: string
  extension?: Extension[]
  id?: string
  material?: CodeableConcept
  minimumVolume?: SpecimenDefinitionTypeTestedArrayContainerMinimumVolume
  modifierExtension?: Extension[]
  preparation?: string
  type?: CodeableConcept
}

export interface SpecimenDefinitionTypeTestedArrayContainerAdditiveArray {
  additive?: SpecimenDefinitionTypeTestedArrayContainerAdditiveArrayAdditive
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
}

export interface SpecimenDefinitionTypeTestedArrayContainerAdditiveArrayAdditive {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference18
}

export interface Reference18 {
  resourceType?: AdditiveResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SpecimenDefinitionTypeTestedArrayContainerMinimumVolume {
  extension?: Extension[]
  modifierExtension?: Extension[]
  Quantity?: Quantity
  string?: string
}

export interface SpecimenDefinitionTypeTestedArrayHandlingArray {
  extension?: Extension[]
  id?: string
  instruction?: string
  maxDuration?: Duration
  modifierExtension?: Extension[]
  temperatureQualifier?: CodeableConcept
  temperatureRange?: Range
}

export interface VerificationResult {
  attestation?: VerificationResultAttestation
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  extension?: Extension[]
  failureAction?: CodeableConcept
  frequency?: Timing
  id?: string
  implicitRules?: string
  language?: string
  lastPerformed?: string
  meta?: Meta
  modifierExtension?: Extension[]
  need?: CodeableConcept
  nextScheduled?: string
  primarySource?: VerificationResultPrimarySourceArray[]
  resourceType?: string
  status: string
  statusDate?: string
  target?: Reference[]
  targetLocation?: string[]
  text?: Narrative
  validationProcess?: CodeableConcept[]
  validationType?: CodeableConcept
  validator?: VerificationResultValidatorArray[]
}

export interface VerificationResultAttestation {
  communicationMethod?: CodeableConcept
  date?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  onBehalfOf?: AttestationOnBehalfOf
  proxyIdentityCertificate?: string
  proxySignature?: Signature
  sourceIdentityCertificate?: string
  sourceSignature?: Signature
  who?: AttestationWho
}

export interface AttestationOnBehalfOf {
  resourceType?: AuthenticatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface AttestationWho {
  resourceType?: AuthenticatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface VerificationResultPrimarySourceArray {
  canPushUpdates?: CodeableConcept
  communicationMethod?: CodeableConcept[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  pushTypeAvailable?: CodeableConcept[]
  type?: CodeableConcept[]
  validationDate?: string
  validationStatus?: CodeableConcept
  who?: PrimarySourceWho
}

export interface PrimarySourceWho {
  resourceType?: AuthenticatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface VerificationResultValidatorArray {
  attestationSignature?: Signature
  extension?: Extension[]
  id?: string
  identityCertificate?: string
  modifierExtension?: Extension[]
  organization: ValidatorOrganization
}

export interface ValidatorOrganization {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface DocumentManifest {
  author?: DocumentManifestAuthorArray[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  content: Reference[]
  created?: string
  description?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  masterIdentifier?: Identifier
  meta?: Meta
  modifierExtension?: Extension[]
  recipient?: DocumentManifestRecipientArray[]
  related?: DocumentManifestRelatedArray[]
  resourceType?: string
  source?: string
  status: string
  subject?: DocumentManifestSubject
  text?: Narrative
  type?: CodeableConcept
}

export interface DocumentManifestAuthorArray {
  resourceType?: ObserverResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface DocumentManifestRecipientArray {
  resourceType?: InformationSourceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface DocumentManifestRelatedArray {
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  modifierExtension?: Extension[]
  ref?: Reference
}

export interface DocumentManifestSubject {
  resourceType?: Array26
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Task {
  authoredOn?: string
  basedOn?: Reference[]
  businessStatus?: CodeableConcept
  code?: CodeableConcept
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  dependsOn?: Reference[]
  description?: string
  encounter?: TaskEncounter
  executionPeriod?: Period
  extension?: Extension[]
  focus?: Reference
  for?: Reference
  groupIdentifier?: Identifier
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  input?: TaskInputArray[]
  instantiatesCanonical?: string
  instantiatesUri?: string
  insurance?: TaskInsuranceArray[]
  intent: string
  language?: string
  lastModified?: string
  location?: TaskLocation
  meta?: Meta
  modifierExtension?: Extension[]
  note?: Annotation[]
  output?: TaskOutputArray[]
  owner?: TaskOwner
  partOf?: TaskPartOfArray[]
  performerType?: CodeableConcept[]
  priority?: string
  reasonCode?: CodeableConcept
  reasonReference?: Reference
  relevantHistory?: TaskRelevantHistoryArray[]
  requestedPerformer?: TaskRequestedPerformerArray[]
  requester?: TaskRequester
  resourceType?: string
  restriction?: TaskRestriction
  status: string
  statusReason?: CodeableConcept
  text?: Narrative
}

export interface TaskEncounter {
  resourceType?: TentacledArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface TaskInputArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  type: CodeableConcept
  value?: TaskInputArrayValue
}

export interface TaskInputArrayValue {
  Address?: Address
  Age?: Age
  Annotation?: Annotation
  Attachment?: Attachment
  base64Binary?: string
  boolean?: boolean
  canonical?: string
  code?: string
  CodeableConcept?: CodeableConcept
  Coding?: Coding
  ContactDetail?: ContactDetail
  ContactPoint?: ContactPoint
  Contributor?: Contributor
  Count?: Count
  DataRequirement?: DataRequirement
  date?: string
  dateTime?: string
  decimal?: number
  Distance?: Distance
  Dosage?: Dosage
  Duration?: Duration
  Expression?: Expression
  extension?: Extension[]
  HumanName?: HumanName
  id?: string
  Identifier?: Identifier
  instant?: Date
  integer?: number
  markdown?: string
  Meta?: Meta
  modifierExtension?: Extension[]
  Money?: Money
  oid?: string
  ParameterDefinition?: ParameterDefinition
  Period?: Period
  positiveInt?: number
  Quantity?: Quantity
  Range?: Range
  Ratio?: Ratio
  Reference?: Reference
  RelatedArtifact?: RelatedArtifact
  SampledData?: SampledData
  Signature?: Signature
  string?: string
  time?: string
  Timing?: Timing
  TriggerDefinition?: TriggerDefinition
  unsignedInt?: number
  uri?: string
  url?: string
  UsageContext?: UsageContext
  uuid?: string
}

export interface TaskInsuranceArray {
  resourceType?: InsuranceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface TaskLocation {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface TaskOutputArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  type: CodeableConcept
  value?: TaskOutputArrayValue
}

export interface TaskOutputArrayValue {
  Address?: Address
  Age?: Age
  Annotation?: Annotation
  Attachment?: Attachment
  base64Binary?: string
  boolean?: boolean
  canonical?: string
  code?: string
  CodeableConcept?: CodeableConcept
  Coding?: Coding
  ContactDetail?: ContactDetail
  ContactPoint?: ContactPoint
  Contributor?: Contributor
  Count?: Count
  DataRequirement?: DataRequirement
  date?: string
  dateTime?: string
  decimal?: number
  Distance?: Distance
  Dosage?: Dosage
  Duration?: Duration
  Expression?: Expression
  extension?: Extension[]
  HumanName?: HumanName
  id?: string
  Identifier?: Identifier
  instant?: Date
  integer?: number
  markdown?: string
  Meta?: Meta
  modifierExtension?: Extension[]
  Money?: Money
  oid?: string
  ParameterDefinition?: ParameterDefinition
  Period?: Period
  positiveInt?: number
  Quantity?: Quantity
  Range?: Range
  Ratio?: Ratio
  Reference?: Reference
  RelatedArtifact?: RelatedArtifact
  SampledData?: SampledData
  Signature?: Signature
  string?: string
  time?: string
  Timing?: Timing
  TriggerDefinition?: TriggerDefinition
  unsignedInt?: number
  uri?: string
  url?: string
  UsageContext?: UsageContext
  uuid?: string
}

export interface TaskOwner {
  resourceType?: RequestedPerformerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface TaskPartOfArray {
  resourceType?: Array57
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array57 {
  Task = "Task",
}

export interface TaskRelevantHistoryArray {
  resourceType?: RelevantHistoryResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface TaskRequestedPerformerArray {
  resourceType?: RequestedPerformerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface TaskRequester {
  resourceType?: ObserverResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface TaskRestriction {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  period?: Period
  recipient?: TaskRestrictionRecipientArray[]
  repetitions?: number
}

export interface TaskRestrictionRecipientArray {
  resourceType?: Array58
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array58 {
  Group = "Group",
  Organization = "Organization",
  Patient = "Patient",
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
  RelatedPerson = "RelatedPerson",
}

export interface RiskEvidenceSynthesis {
  approvalDate?: string
  author?: ContactDetail[]
  certainty?: RiskEvidenceSynthesisCertaintyArray[]
  contact?: ContactDetail[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  copyright?: string
  date?: string
  description?: string
  editor?: ContactDetail[]
  effectivePeriod?: Period
  endorser?: ContactDetail[]
  exposure?: RiskEvidenceSynthesisExposure
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  jurisdiction?: CodeableConcept[]
  language?: string
  lastReviewDate?: string
  meta?: Meta
  modifierExtension?: Extension[]
  name?: string
  note?: Annotation[]
  outcome: RiskEvidenceSynthesisOutcome
  population: RiskEvidenceSynthesisPopulation
  publisher?: string
  relatedArtifact?: RelatedArtifact[]
  resourceType?: string
  reviewer?: ContactDetail[]
  riskEstimate?: RiskEvidenceSynthesisRiskEstimate
  sampleSize?: RiskEvidenceSynthesisSampleSize
  status: string
  studyType?: CodeableConcept
  synthesisType?: CodeableConcept
  text?: Narrative
  title?: string
  topic?: CodeableConcept[]
  url?: string
  useContext?: UsageContext[]
  version?: string
}

export interface RiskEvidenceSynthesisCertaintyArray {
  certaintySubcomponent?: RiskEvidenceSynthesisCertaintyArrayCertaintySubcomponentArray[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  note?: Annotation[]
  rating?: CodeableConcept[]
}

export interface RiskEvidenceSynthesisCertaintyArrayCertaintySubcomponentArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  note?: Annotation[]
  rating?: CodeableConcept[]
  type?: CodeableConcept
}

export interface RiskEvidenceSynthesisExposure {
  resourceType?: ExposureBackgroundResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface RiskEvidenceSynthesisOutcome {
  resourceType?: ExposureBackgroundResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface RiskEvidenceSynthesisPopulation {
  resourceType?: ExposureBackgroundResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface RiskEvidenceSynthesisRiskEstimate {
  denominatorCount?: number
  description?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  numeratorCount?: number
  precisionEstimate?: RiskEvidenceSynthesisRiskEstimatePrecisionEstimateArray[]
  type?: CodeableConcept
  unitOfMeasure?: CodeableConcept
  value?: number
}

export interface RiskEvidenceSynthesisRiskEstimatePrecisionEstimateArray {
  extension?: Extension[]
  from?: number
  id?: string
  level?: number
  modifierExtension?: Extension[]
  to?: number
  type?: CodeableConcept
}

export interface RiskEvidenceSynthesisSampleSize {
  description?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  numberOfParticipants?: number
  numberOfStudies?: number
}

export interface ValueSet {
  compose?: ValueSetCompose
  contact?: ContactDetail[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  copyright?: string
  date?: string
  description?: string
  expansion?: ValueSetExpansion
  experimental?: boolean
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  immutable?: boolean
  implicitRules?: string
  jurisdiction?: CodeableConcept[]
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  name?: string
  publisher?: string
  purpose?: string
  resourceType?: string
  status: string
  text?: Narrative
  title?: string
  url?: string
  useContext?: UsageContext[]
  version?: string
}

export interface ValueSetCompose {
  exclude?: ValueSetComposeCludeArray[]
  extension?: Extension[]
  id?: string
  inactive?: boolean
  include: ValueSetComposeCludeArray[]
  lockedDate?: string
  modifierExtension?: Extension[]
}

export interface ValueSetComposeCludeArray {
  concept?: ValueSetComposeIncludeArrayConceptArray[]
  extension?: Extension[]
  filter?: ValueSetComposeIncludeArrayFilterArray[]
  id?: string
  modifierExtension?: Extension[]
  system?: string
  valueSet?: string[]
  version?: string
}

export interface ValueSetComposeIncludeArrayConceptArray {
  code: string
  designation?: ValueSetArrayDesignationArray[]
  display?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
}

export interface ValueSetArrayDesignationArray {
  extension?: Extension[]
  id?: string
  language?: string
  modifierExtension?: Extension[]
  use?: Coding
  value: string
}

export interface ValueSetComposeIncludeArrayFilterArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  op: string
  property: string
  value: string
}

export interface ValueSetExpansion {
  contains?: ValueSetExpansionContainsArrayContainsArray[]
  extension?: Extension[]
  id?: string
  identifier?: string
  modifierExtension?: Extension[]
  offset?: number
  parameter?: ValueSetExpansionParameterArray[]
  timestamp: string
  total?: number
}

export interface ValueSetExpansionContainsArrayContainsArray {
  abstract?: boolean
  code?: string
  contains?: ValueSetExpansionContainsArrayContainsArray[]
  designation?: ValueSetArrayDesignationArray[]
  display?: string
  extension?: Extension[]
  id?: string
  inactive?: boolean
  modifierExtension?: Extension[]
  system?: string
  version?: string
}

export interface ValueSetExpansionParameterArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  name: string
  value?: ValueSetExpansionParameterArrayValue
}

export interface ValueSetExpansionParameterArrayValue {
  boolean?: boolean
  code?: string
  dateTime?: string
  decimal?: number
  extension?: Extension[]
  integer?: number
  modifierExtension?: Extension[]
  string?: string
  uri?: string
}

export interface IndexCreationJob {
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
}

export interface Claim {
  accident?: ClaimAccident
  billablePeriod?: Period
  careTeam?: ClaimCareTeamArray[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  created: string
  diagnosis?: ClaimDiagnosisArray[]
  enterer?: ClaimEnterer
  extension?: Extension[]
  facility?: ClaimFacility
  fundsReserve?: CodeableConcept
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  insurance: ClaimInsuranceArray[]
  insurer?: ClaimInsurer
  item?: ClaimItemArray[]
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  originalPrescription?: ClaimOriginalPrescription
  patient: ClaimPatient
  payee?: ClaimPayee
  prescription?: ClaimPrescription
  priority: CodeableConcept
  procedure?: ClaimProcedureArray[]
  provider: ClaimProvider
  referral?: ClaimReferral
  related?: ClaimRelatedArray[]
  resourceType?: string
  status: string
  subType?: CodeableConcept
  supportingInfo?: ClaimSupportingInfoArray[]
  text?: Narrative
  total?: Money
  type: CodeableConcept
  use: string
}

export interface ClaimAccident {
  date: string
  extension?: Extension[]
  id?: string
  location?: ClaimAccidentLocation
  modifierExtension?: Extension[]
  type?: CodeableConcept
}

export interface ClaimAccidentLocation {
  Address?: Address
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference19
}

export interface Reference19 {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ClaimCareTeamArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  provider: TentacledProvider
  qualification?: CodeableConcept
  responsible?: boolean
  role?: CodeableConcept
  sequence: number
}

export interface TentacledProvider {
  resourceType?: AuthenticatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ClaimDiagnosisArray {
  diagnosis?: ClaimDiagnosisArrayDiagnosis
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  onAdmission?: CodeableConcept
  packageCode?: CodeableConcept
  sequence: number
  type?: CodeableConcept[]
}

export interface ClaimDiagnosisArrayDiagnosis {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference20
}

export interface Reference20 {
  resourceType?: ResultingConditionResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ClaimEnterer {
  resourceType?: CollectorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ClaimFacility {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ClaimInsuranceArray {
  businessArrangement?: string
  claimResponse?: FluffyClaimResponse
  coverage: StickyCoverage
  extension?: Extension[]
  focal: boolean
  id?: string
  identifier?: Identifier
  modifierExtension?: Extension[]
  preAuthRef?: string[]
  sequence: number
}

export interface FluffyClaimResponse {
  resourceType?: ClaimResponseResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface StickyCoverage {
  resourceType?: CoverageResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ClaimInsurer {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ClaimItemArray {
  bodySite?: CodeableConcept
  careTeamSequence?: number[]
  category?: CodeableConcept
  detail?: ClaimItemArrayDetailArray[]
  diagnosisSequence?: number[]
  encounter?: ClaimItemArrayEncounterArray[]
  extension?: Extension[]
  factor?: number
  id?: string
  informationSequence?: number[]
  location?: ClaimItemArrayLocation
  modifier?: CodeableConcept[]
  modifierExtension?: Extension[]
  net?: Money
  procedureSequence?: number[]
  productOrService: CodeableConcept
  programCode?: CodeableConcept[]
  quantity?: Quantity
  revenue?: CodeableConcept
  sequence: number
  serviced?: ClaimItemArrayServiced
  subSite?: CodeableConcept[]
  udi?: ClaimItemArrayUdiArray[]
  unitPrice?: Money
}

export interface ClaimItemArrayDetailArray {
  category?: CodeableConcept
  extension?: Extension[]
  factor?: number
  id?: string
  modifier?: CodeableConcept[]
  modifierExtension?: Extension[]
  net?: Money
  productOrService: CodeableConcept
  programCode?: CodeableConcept[]
  quantity?: Quantity
  revenue?: CodeableConcept
  sequence: number
  subDetail?: ClaimItemArrayDetailArraySubDetailArray[]
  udi?: ClaimItemArrayDetailArrayUdiArray[]
  unitPrice?: Money
}

export interface ClaimItemArrayDetailArraySubDetailArray {
  category?: CodeableConcept
  extension?: Extension[]
  factor?: number
  id?: string
  modifier?: CodeableConcept[]
  modifierExtension?: Extension[]
  net?: Money
  productOrService: CodeableConcept
  programCode?: CodeableConcept[]
  quantity?: Quantity
  revenue?: CodeableConcept
  sequence: number
  udi?: ClaimItemArrayDetailArraySubDetailArrayUdiArray[]
  unitPrice?: Money
}

export interface ClaimItemArrayDetailArraySubDetailArrayUdiArray {
  resourceType?: UdiResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ClaimItemArrayDetailArrayUdiArray {
  resourceType?: UdiResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ClaimItemArrayEncounterArray {
  resourceType?: TentacledArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ClaimItemArrayLocation {
  Address?: Address
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference21
}

export interface Reference21 {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ClaimItemArrayServiced {
  date?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
}

export interface ClaimItemArrayUdiArray {
  resourceType?: UdiResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ClaimOriginalPrescription {
  resourceType?: Array59
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array59 {
  DeviceRequest = "DeviceRequest",
  MedicationRequest = "MedicationRequest",
  VisionPrescription = "VisionPrescription",
}

export interface ClaimPatient {
  resourceType?: CandidateResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ClaimPayee {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  party?: FluffyParty
  type: CodeableConcept
}

export interface FluffyParty {
  resourceType?: InformationSourceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ClaimPrescription {
  resourceType?: Array59
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ClaimProcedureArray {
  date?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  procedure?: ClaimProcedureArrayProcedure
  sequence: number
  type?: CodeableConcept[]
  udi?: ClaimProcedureArrayUdiArray[]
}

export interface ClaimProcedureArrayProcedure {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference22
}

export interface Reference22 {
  resourceType?: ProcedureReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ClaimProcedureArrayUdiArray {
  resourceType?: UdiResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ClaimProvider {
  resourceType?: AuthenticatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ClaimReferral {
  resourceType?: ReferralRequestResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ClaimRelatedArray {
  claim?: FluffyClaim
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  reference?: Identifier
  relationship?: CodeableConcept
}

export interface FluffyClaim {
  resourceType?: ClaimResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ClaimSupportingInfoArray {
  category: CodeableConcept
  code?: CodeableConcept
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  reason?: CodeableConcept
  sequence: number
  timing?: ClaimSupportingInfoArrayTiming
  value?: ClaimSupportingInfoArrayValue
}

export interface ClaimSupportingInfoArrayTiming {
  date?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
}

export interface ClaimSupportingInfoArrayValue {
  Attachment?: Attachment
  boolean?: boolean
  extension?: Extension[]
  modifierExtension?: Extension[]
  Quantity?: Quantity
  Reference?: Reference
  string?: string
}

export interface Client {
  active?: boolean
  allowed_origins?: string[]
  auth?: ClientAuth
  description?: string
  details?: ClientDetails
  extension?: Extension[]
  first_party?: boolean
  grant_types?: ClientGrantTypesArray[]
  id?: string
  jwks?: ClientJwksArray[]
  jwks_uri?: string
  meta?: Meta
  modifierExtension?: Extension[]
  name?: string
  resourceType?: string
  scope?: string[]
  scopes?: ClientScopesArray[]
  secret?: string
  smart?: ClientSmart
  trusted?: boolean
  type?: string
}

export interface ClientAuth {
  authorization_code?: ClientAuthAuthorizationCode
  client_credentials?: ClientAuthClientCredentials
  extension?: Extension[]
  implicit?: ClientAuthImplicit
  modifierExtension?: Extension[]
  password?: ClientAuthPassword
}

export interface ClientAuthAuthorizationCode {
  access_token_expiration?: number
  audience?: string[]
  extension?: Extension[]
  modifierExtension?: Extension[]
  pkce?: boolean
  redirect_uri: string
  refresh_token?: boolean
  refresh_token_expiration?: number
  secret_required?: boolean
  token_format?: TokenFormat
}

export enum TokenFormat {
  Jwt = "jwt",
}

export interface ClientAuthClientCredentials {
  access_token_expiration?: number
  audience?: string[]
  client_assertion_types?: ClientAuthClientCredentialsClientAssertionTypesArray[]
  extension?: Extension[]
  modifierExtension?: Extension[]
  refresh_token?: boolean
  refresh_token_expiration?: number
  token_format?: TokenFormat
}

export enum ClientAuthClientCredentialsClientAssertionTypesArray {
  UrnIETFParamsOauthClientAssertionTypeJwtBearer = "urn:ietf:params:oauth:client-assertion-type:jwt-bearer",
}

export interface ClientAuthImplicit {
  access_token_expiration?: number
  audience?: string[]
  extension?: Extension[]
  modifierExtension?: Extension[]
  redirect_uri: string
  token_format?: TokenFormat
}

export interface ClientAuthPassword {
  access_token_expiration?: number
  audience?: string[]
  extension?: Extension[]
  modifierExtension?: Extension[]
  redirect_uri?: string
  refresh_token?: boolean
  refresh_token_expiration?: number
  secret_required?: boolean
  token_format?: TokenFormat
}

export interface ClientDetails {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export enum ClientGrantTypesArray {
  AuthorizationCode = "authorization_code",
  Basic = "basic",
  ClientCredentials = "client_credentials",
  Code = "code",
  Implicit = "implicit",
  Password = "password",
  RefreshToken = "refresh_token",
}

export interface ClientJwksArray {
  alg?: Alg
  e?: string
  extension?: Extension[]
  kid?: string
  kty?: Kty
  modifierExtension?: Extension[]
  n?: string
  use?: Use
}

export enum Alg {
  Rs384 = "RS384",
}

export enum Kty {
  RSA = "RSA",
}

export enum Use {
  Sig = "sig",
}

export interface ClientScopesArray {
  extension?: Extension[]
  modifierExtension?: Extension[]
  parameters?: ClientScopesArrayParameters
  policy?: Policy
}

export interface ClientScopesArrayParameters {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface Policy {
  resourceType?: PolicyResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum PolicyResourceType {
  AccessPolicy = "AccessPolicy",
}

export interface ClientSmart {
  description?: string
  extension?: Extension[]
  launch_uri?: string
  modifierExtension?: Extension[]
  name?: string
}

export interface InsurancePlan {
  administeredBy?: AdministeredBy
  alias?: string[]
  contact?: InsurancePlanContactArray[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  coverage?: InsurancePlanCoverageArray[]
  coverageArea?: InsurancePlanCoverageAreaArray[]
  endpoint?: InsurancePlanEndpointArray[]
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  name?: string
  network?: InsurancePlanNetworkArray[]
  ownedBy?: OwnedBy
  period?: Period
  plan?: InsurancePlanPlanArray[]
  resourceType?: string
  status?: string
  text?: Narrative
  type?: CodeableConcept[]
}

export interface AdministeredBy {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface InsurancePlanContactArray {
  address?: Address
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  name?: HumanName
  purpose?: CodeableConcept
  telecom?: ContactPoint[]
}

export interface InsurancePlanCoverageArray {
  benefit: InsurancePlanCoverageArrayBenefitArray[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  network?: InsurancePlanCoverageArrayNetworkArray[]
  type: CodeableConcept
}

export interface InsurancePlanCoverageArrayBenefitArray {
  extension?: Extension[]
  id?: string
  limit?: InsurancePlanCoverageArrayBenefitArrayLimitArray[]
  modifierExtension?: Extension[]
  requirement?: string
  type: CodeableConcept
}

export interface InsurancePlanCoverageArrayBenefitArrayLimitArray {
  code?: CodeableConcept
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  value?: Quantity
}

export interface InsurancePlanCoverageArrayNetworkArray {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface InsurancePlanCoverageAreaArray {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface InsurancePlanEndpointArray {
  resourceType?: EndpointResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface InsurancePlanNetworkArray {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface OwnedBy {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface InsurancePlanPlanArray {
  coverageArea?: InsurancePlanPlanArrayCoverageAreaArray[]
  extension?: Extension[]
  generalCost?: InsurancePlanPlanArrayGeneralCostArray[]
  id?: string
  identifier?: Identifier[]
  modifierExtension?: Extension[]
  network?: InsurancePlanPlanArrayNetworkArray[]
  specificCost?: InsurancePlanPlanArraySpecificCostArray[]
  type?: CodeableConcept
}

export interface InsurancePlanPlanArrayCoverageAreaArray {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface InsurancePlanPlanArrayGeneralCostArray {
  comment?: string
  cost?: Money
  extension?: Extension[]
  groupSize?: number
  id?: string
  modifierExtension?: Extension[]
  type?: CodeableConcept
}

export interface InsurancePlanPlanArrayNetworkArray {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface InsurancePlanPlanArraySpecificCostArray {
  benefit?: InsurancePlanPlanArraySpecificCostArrayBenefitArray[]
  category: CodeableConcept
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
}

export interface InsurancePlanPlanArraySpecificCostArrayBenefitArray {
  cost?: InsurancePlanPlanArraySpecificCostArrayBenefitArrayCostArray[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  type: CodeableConcept
}

export interface InsurancePlanPlanArraySpecificCostArrayBenefitArrayCostArray {
  applicability?: CodeableConcept
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  qualifiers?: CodeableConcept[]
  type: CodeableConcept
  value?: Quantity
}

export interface NotificationTemplate {
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
  subject?: string
  template?: string
}

export interface ExampleScenario {
  actor?: ExampleScenarioActorArray[]
  contact?: ContactDetail[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  copyright?: string
  date?: string
  experimental?: boolean
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  instance?: ExampleScenarioInstanceArray[]
  jurisdiction?: CodeableConcept[]
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  name?: string
  process?: ExampleScenarioProcessArrayProcessArray[]
  publisher?: string
  purpose?: string
  resourceType?: string
  status: string
  text?: Narrative
  url?: string
  useContext?: UsageContext[]
  version?: string
  workflow?: string[]
}

export interface ExampleScenarioActorArray {
  actorId: string
  description?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  name?: string
  type: string
}

export interface ExampleScenarioInstanceArray {
  containedInstance?: ExampleScenarioInstanceArrayContainedInstanceArray[]
  description?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  name?: string
  resourceId: string
  resourceType: string
  version?: ExampleScenarioInstanceArrayVersionArray[]
}

export interface ExampleScenarioInstanceArrayContainedInstanceArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  resourceId: string
  versionId?: string
}

export interface ExampleScenarioInstanceArrayVersionArray {
  description: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  versionId: string
}

export interface ExampleScenarioProcessArrayStepArrayAlternativeArray {
  description?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  step?: ExampleScenarioProcessArrayStepArrayArrayStepArray[]
  title: string
}

export interface ExampleScenarioProcessArrayStepArrayArrayStepArray {
  alternative?: ExampleScenarioProcessArrayStepArrayAlternativeArray[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  operation?: ExampleScenarioProcessArrayStepArrayOperation
  pause?: boolean
  process?: ExampleScenarioProcessArrayProcessArray[]
}

export interface ExampleScenarioProcessArrayProcessArray {
  description?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  postConditions?: string
  preConditions?: string
  step?: ExampleScenarioProcessArrayStepArrayArrayStepArray[]
  title: string
}

export interface ExampleScenarioProcessArrayStepArrayOperation {
  description?: string
  extension?: Extension[]
  id?: string
  initiator?: string
  initiatorActive?: boolean
  modifierExtension?: Extension[]
  name?: string
  number: string
  receiver?: string
  receiverActive?: boolean
  request?: ExampleScenarioInstanceArrayContainedInstanceArray
  response?: ExampleScenarioInstanceArrayContainedInstanceArray
  type?: string
}

export interface ResearchStudy {
  arm?: ResearchStudyArmArray[]
  category?: CodeableConcept[]
  condition?: CodeableConcept[]
  contact?: ContactDetail[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  description?: string
  enrollment?: ResearchStudyEnrollmentArray[]
  extension?: Extension[]
  focus?: CodeableConcept[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  keyword?: CodeableConcept[]
  language?: string
  location?: CodeableConcept[]
  meta?: Meta
  modifierExtension?: Extension[]
  note?: Annotation[]
  objective?: ResearchStudyObjectiveArray[]
  partOf?: ResearchStudyPartOfArray[]
  period?: Period
  phase?: CodeableConcept
  primaryPurposeType?: CodeableConcept
  principalInvestigator?: PrincipalInvestigator
  protocol?: ResearchStudyProtocolArray[]
  reasonStopped?: CodeableConcept
  relatedArtifact?: RelatedArtifact[]
  resourceType?: string
  site?: ResearchStudySiteArray[]
  sponsor?: Sponsor
  status: string
  text?: Narrative
  title?: string
}

export interface ResearchStudyArmArray {
  description?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  name: string
  type?: CodeableConcept
}

export interface ResearchStudyEnrollmentArray {
  resourceType?: EnrollmentResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ResearchStudyObjectiveArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  name?: string
  type?: CodeableConcept
}

export interface ResearchStudyPartOfArray {
  resourceType?: StudyResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface PrincipalInvestigator {
  resourceType?: CollectorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ResearchStudyProtocolArray {
  resourceType?: ProtocolResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ProtocolResourceType {
  PlanDefinition = "PlanDefinition",
}

export interface ResearchStudySiteArray {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Sponsor {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationRequest {
  authoredOn?: string
  basedOn?: MedicationRequestBasedOnArray[]
  category?: CodeableConcept[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  courseOfTherapyType?: CodeableConcept
  detectedIssue?: MedicationRequestDetectedIssueArray[]
  dispenseRequest?: MedicationRequestDispenseRequest
  doNotPerform?: boolean
  dosageInstruction?: Dosage[]
  encounter?: MedicationRequestEncounter
  eventHistory?: MedicationRequestEventHistoryArray[]
  extension?: Extension[]
  groupIdentifier?: Identifier
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  instantiatesCanonical?: string[]
  instantiatesUri?: string[]
  insurance?: MedicationRequestInsuranceArray[]
  intent: string
  language?: string
  medication?: MedicationRequestMedication
  meta?: Meta
  modifierExtension?: Extension[]
  note?: Annotation[]
  pelletInsertionDate?: string
  performer?: MedicationRequestPerformer
  performerType?: CodeableConcept
  priority?: string
  priorPrescription?: PriorPrescription
  reasonCode?: CodeableConcept[]
  reasonReference?: MedicationRequestReasonReferenceArray[]
  recorder?: MedicationRequestRecorder
  reported?: MedicationRequestReported
  requester?: MedicationRequestRequester
  resourceType?: string
  status: string
  statusReason?: CodeableConcept
  subject: MedicationRequestSubject
  substitution?: MedicationRequestSubstitution
  supportingInformation?: Reference[]
  text?: Narrative
}

export interface MedicationRequestBasedOnArray {
  resourceType?: Array60
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array60 {
  CarePlan = "CarePlan",
  ImmunizationRecommendation = "ImmunizationRecommendation",
  MedicationRequest = "MedicationRequest",
  ServiceRequest = "ServiceRequest",
}

export interface MedicationRequestDetectedIssueArray {
  resourceType?: DetectedIssueResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationRequestDispenseRequest {
  dispenseInterval?: Duration
  expectedSupplyDuration?: Duration
  extension?: Extension[]
  id?: string
  initialFill?: MedicationRequestDispenseRequestInitialFill
  modifierExtension?: Extension[]
  nextRefillDate?: string
  numberOfRepeatsAllowed?: number
  performer?: DispenseRequestPerformer
  quantity?: Quantity
  validityPeriod?: Period
}

export interface MedicationRequestDispenseRequestInitialFill {
  duration?: Duration
  extension?: Extension[]
  id?: string
  isDfo?: boolean
  modifierExtension?: Extension[]
  quantity?: Quantity
}

export interface DispenseRequestPerformer {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationRequestEncounter {
  resourceType?: TentacledArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationRequestEventHistoryArray {
  resourceType?: RelevantHistoryResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationRequestInsuranceArray {
  resourceType?: InsuranceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationRequestMedication {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference23
}

export interface Reference23 {
  resourceType?: AssociatedMedicationResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationRequestPerformer {
  resourceType?: OperatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface PriorPrescription {
  resourceType?: AuthorizingPrescriptionResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationRequestReasonReferenceArray {
  resourceType?: Array61
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array61 {
  Condition = "Condition",
  Observation = "Observation",
}

export interface MedicationRequestRecorder {
  resourceType?: CollectorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationRequestReported {
  boolean?: boolean
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: ReportedReference
}

export interface ReportedReference {
  resourceType?: InformationSourceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationRequestRequester {
  resourceType?: ObserverResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationRequestSubject {
  resourceType?: FriskyArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationRequestSubstitution {
  allowed?: MedicationRequestSubstitutionAllowed
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  reason?: CodeableConcept
}

export interface MedicationRequestSubstitutionAllowed {
  boolean?: boolean
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface Measure {
  approvalDate?: string
  author?: ContactDetail[]
  clinicalRecommendationStatement?: string
  compositeScoring?: CodeableConcept
  contact?: ContactDetail[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  copyright?: string
  date?: string
  definition?: string[]
  description?: string
  disclaimer?: string
  editor?: ContactDetail[]
  effectivePeriod?: Period
  endorser?: ContactDetail[]
  experimental?: boolean
  extension?: Extension[]
  group?: MeasureGroupArray[]
  guidance?: string
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  improvementNotation?: CodeableConcept
  jurisdiction?: CodeableConcept[]
  language?: string
  lastReviewDate?: string
  library?: string[]
  meta?: Meta
  modifierExtension?: Extension[]
  name?: string
  publisher?: string
  purpose?: string
  rateAggregation?: string
  rationale?: string
  relatedArtifact?: RelatedArtifact[]
  resourceType?: string
  reviewer?: ContactDetail[]
  riskAdjustment?: string
  scoring?: CodeableConcept
  status: string
  subject?: MeasureSubject
  subtitle?: string
  supplementalData?: MeasureSupplementalDataArray[]
  text?: Narrative
  title?: string
  topic?: CodeableConcept[]
  type?: CodeableConcept[]
  url?: string
  usage?: string
  useContext?: UsageContext[]
  version?: string
}

export interface MeasureGroupArray {
  code?: CodeableConcept
  description?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  population?: MeasureGroupArrayPopulationArray[]
  stratifier?: MeasureGroupArrayStratifierArray[]
}

export interface MeasureGroupArrayPopulationArray {
  code?: CodeableConcept
  criteria: Expression
  description?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
}

export interface MeasureGroupArrayStratifierArray {
  code?: CodeableConcept
  component?: MeasureGroupArrayStratifierArrayComponentArray[]
  criteria?: Expression
  description?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
}

export interface MeasureGroupArrayStratifierArrayComponentArray {
  code?: CodeableConcept
  criteria: Expression
  description?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
}

export interface MeasureSubject {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference24
}

export interface Reference24 {
  resourceType?: EnrollmentResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MeasureSupplementalDataArray {
  code?: CodeableConcept
  criteria: Expression
  description?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  usage?: CodeableConcept[]
}

export interface List {
  code?: CodeableConcept
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  date?: string
  emptyReason?: CodeableConcept
  encounter?: ListEncounter
  entry?: ListEntryArray[]
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  meta?: Meta
  mode: string
  modifierExtension?: Extension[]
  note?: Annotation[]
  orderedBy?: CodeableConcept
  resourceType?: string
  source?: ListSource
  status: string
  subject?: ListSubject
  text?: Narrative
  title?: string
}

export interface ListEncounter {
  resourceType?: TentacledArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ListEntryArray {
  date?: string
  deleted?: boolean
  extension?: Extension[]
  flag?: CodeableConcept
  id?: string
  item: Reference
  modifierExtension?: Extension[]
}

export interface ListSource {
  resourceType?: Array62
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array62 {
  Device = "Device",
  Patient = "Patient",
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
}

export interface ListSubject {
  resourceType?: IndecentArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Hl7V2Config {
  extension?: Extension[]
  extensions?: Hl7V2ConfigExtensionsArray[]
  id?: string
  isStrict?: boolean
  mapping?: MappingClass
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
  text?: Hl7V2ConfigText
}

export interface Hl7V2ConfigExtensionsArray {
  after?: string
  extension?: Extension[]
  fields?: Hl7V2ConfigExtensionsArrayFieldsArray[]
  modifierExtension?: Extension[]
  msh?: string
  segment?: string
}

export interface Hl7V2ConfigExtensionsArrayFieldsArray {
  extension?: Extension[]
  key?: string
  modifierExtension?: Extension[]
  name?: string
  type?: string
}

export interface MappingClass {
  resourceType?: MappingResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum MappingResourceType {
  Mapping = "Mapping",
}

export interface Hl7V2ConfigText {
  div?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  status?: string
}

export interface Encounter {
  account?: EncounterAccountArray[]
  appointment?: EncounterAppointmentArray[]
  basedOn?: EncounterBasedOnArray[]
  class: Coding
  classHistory?: EncounterClassHistoryArray[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  diagnosis?: EncounterDiagnosisArray[]
  episodeOfCare?: EncounterEpisodeOfCareArray[]
  extension?: Extension[]
  hospitalization?: EncounterHospitalization
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  length?: Duration
  location?: EncounterLocationArray[]
  meta?: Meta
  modifierExtension?: Extension[]
  participant?: EncounterParticipantArray[]
  partOf?: EncounterPartOf
  period?: Period
  priority?: CodeableConcept
  reasonCode?: CodeableConcept[]
  reasonReference?: EncounterReasonReferenceArray[]
  resourceType?: string
  serviceProvider?: ServiceProvider
  serviceType?: CodeableConcept
  status: string
  statusHistory?: EncounterStatusHistoryArray[]
  subject?: EncounterSubject
  text?: Narrative
  type?: CodeableConcept[]
}

export interface EncounterAccountArray {
  resourceType?: Array11
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface EncounterAppointmentArray {
  resourceType?: AppointmentResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum AppointmentResourceType {
  Appointment = "Appointment",
}

export interface EncounterBasedOnArray {
  resourceType?: ReferralRequestResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface EncounterClassHistoryArray {
  class: Coding
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  period: Period
}

export interface EncounterDiagnosisArray {
  condition: FluffyCondition
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  rank?: number
  use?: CodeableConcept
}

export interface FluffyCondition {
  resourceType?: Array63
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array63 {
  Condition = "Condition",
  Procedure = "Procedure",
}

export interface EncounterEpisodeOfCareArray {
  resourceType?: EpisodeOfCareResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum EpisodeOfCareResourceType {
  EpisodeOfCare = "EpisodeOfCare",
}

export interface EncounterHospitalization {
  admitSource?: CodeableConcept
  destination?: HospitalizationDestination
  dietPreference?: CodeableConcept[]
  dischargeDisposition?: CodeableConcept
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  origin?: Origin
  preAdmissionIdentifier?: Identifier
  reAdmission?: CodeableConcept
  specialArrangement?: CodeableConcept[]
  specialCourtesy?: CodeableConcept[]
}

export interface HospitalizationDestination {
  resourceType?: OriginResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum OriginResourceType {
  Location = "Location",
  Organization = "Organization",
}

export interface Origin {
  resourceType?: OriginResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface EncounterLocationArray {
  extension?: Extension[]
  id?: string
  location: LocationLocation
  modifierExtension?: Extension[]
  period?: Period
  physicalType?: CodeableConcept
  status?: string
}

export interface LocationLocation {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface EncounterPartOf {
  resourceType?: TentacledArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface EncounterParticipantArray {
  extension?: Extension[]
  id?: string
  individual?: ParticipantIndividual
  modifierExtension?: Extension[]
  period?: Period
  type?: CodeableConcept[]
}

export interface ParticipantIndividual {
  resourceType?: Array64
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array64 {
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
  RelatedPerson = "RelatedPerson",
}

export interface EncounterReasonReferenceArray {
  resourceType?: Array4
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ServiceProvider {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface EncounterStatusHistoryArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  period: Period
  status: string
}

export interface EncounterSubject {
  resourceType?: FriskyArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface VisionPrescription {
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  created: string
  dateWritten: string
  encounter?: VisionPrescriptionEncounter
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  lensSpecification: VisionPrescriptionLensSpecificationArray[]
  meta?: Meta
  modifierExtension?: Extension[]
  patient: VisionPrescriptionPatient
  prescriber: Prescriber
  resourceType?: string
  status: string
  text?: Narrative
}

export interface VisionPrescriptionEncounter {
  resourceType?: TentacledArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface VisionPrescriptionLensSpecificationArray {
  add?: number
  axis?: number
  backCurve?: number
  brand?: string
  color?: string
  cylinder?: number
  diameter?: number
  duration?: Quantity
  extension?: Extension[]
  eye: string
  id?: string
  modifierExtension?: Extension[]
  note?: Annotation[]
  power?: number
  prism?: VisionPrescriptionLensSpecificationArrayPrismArray[]
  product: CodeableConcept
  sphere?: number
}

export interface VisionPrescriptionLensSpecificationArrayPrismArray {
  amount: number
  base: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
}

export interface VisionPrescriptionPatient {
  resourceType?: CandidateResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Prescriber {
  resourceType?: CollectorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface RiskAssessment {
  basedOn?: Reference
  basis?: Reference[]
  code?: CodeableConcept
  condition?: RiskAssessmentCondition
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  encounter?: RiskAssessmentEncounter
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  meta?: Meta
  method?: CodeableConcept
  mitigation?: string
  modifierExtension?: Extension[]
  note?: Annotation[]
  occurrence?: RiskAssessmentOccurrence
  parent?: Reference
  performer?: RiskAssessmentPerformer
  prediction?: RiskAssessmentPredictionArray[]
  reasonCode?: CodeableConcept[]
  reasonReference?: RiskAssessmentReasonReferenceArray[]
  resourceType?: string
  status: string
  subject: RiskAssessmentSubject
  text?: Narrative
}

export interface RiskAssessmentCondition {
  resourceType?: ResultingConditionResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface RiskAssessmentEncounter {
  resourceType?: TentacledArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface RiskAssessmentOccurrence {
  dateTime?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
}

export interface RiskAssessmentPerformer {
  resourceType?: Array14
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface RiskAssessmentPredictionArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  outcome?: CodeableConcept
  probability?: RiskAssessmentPredictionArrayProbability
  qualitativeRisk?: CodeableConcept
  rationale?: string
  relativeRisk?: number
  when?: RiskAssessmentPredictionArrayWhen
}

export interface RiskAssessmentPredictionArrayProbability {
  decimal?: number
  extension?: Extension[]
  modifierExtension?: Extension[]
  Range?: Range
}

export interface RiskAssessmentPredictionArrayWhen {
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
  Range?: Range
}

export interface RiskAssessmentReasonReferenceArray {
  resourceType?: StickyArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface RiskAssessmentSubject {
  resourceType?: FriskyArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SubstanceProtein {
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  disulfideLinkage?: string[]
  extension?: Extension[]
  id?: string
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  numberOfSubunits?: number
  resourceType?: string
  sequenceType?: CodeableConcept
  subunit?: SubstanceProteinSubunitArray[]
  text?: Narrative
}

export interface SubstanceProteinSubunitArray {
  cTerminalModification?: string
  cTerminalModificationId?: Identifier
  extension?: Extension[]
  id?: string
  length?: number
  modifierExtension?: Extension[]
  nTerminalModification?: string
  nTerminalModificationId?: Identifier
  sequence?: string
  sequenceAttachment?: Attachment
  subunit?: number
}

export interface ImmunizationRecommendation {
  authority?: ImmunizationRecommendationAuthority
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  date: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  patient: ImmunizationRecommendationPatient
  recommendation: ImmunizationRecommendationRecommendationArray[]
  resourceType?: string
  text?: Narrative
}

export interface ImmunizationRecommendationAuthority {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ImmunizationRecommendationPatient {
  resourceType?: CandidateResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ImmunizationRecommendationRecommendationArray {
  contraindicatedVaccineCode?: CodeableConcept[]
  dateCriterion?: ImmunizationRecommendationRecommendationArrayDateCriterionArray[]
  description?: string
  doseNumber?: ImmunizationRecommendationRecommendationArrayDoseNumber
  extension?: Extension[]
  forecastReason?: CodeableConcept[]
  forecastStatus: CodeableConcept
  id?: string
  modifierExtension?: Extension[]
  series?: string
  seriesDoses?: ImmunizationRecommendationRecommendationArraySeriesDoses
  supportingImmunization?: ImmunizationRecommendationRecommendationArraySupportingImmunizationArray[]
  supportingPatientInformation?: Reference[]
  targetDisease?: CodeableConcept
  vaccineCode?: CodeableConcept[]
}

export interface ImmunizationRecommendationRecommendationArrayDateCriterionArray {
  code: CodeableConcept
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  value: string
}

export interface ImmunizationRecommendationRecommendationArrayDoseNumber {
  extension?: Extension[]
  modifierExtension?: Extension[]
  positiveInt?: number
  string?: string
}

export interface ImmunizationRecommendationRecommendationArraySeriesDoses {
  extension?: Extension[]
  modifierExtension?: Extension[]
  positiveInt?: number
  string?: string
}

export interface ImmunizationRecommendationRecommendationArraySupportingImmunizationArray {
  resourceType?: SupportingImmunizationResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum SupportingImmunizationResourceType {
  Immunization = "Immunization",
  ImmunizationEvaluation = "ImmunizationEvaluation",
}

export interface BatchValidationError {
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
}

export interface RelatedPerson {
  active?: boolean
  address?: Address[]
  birthDate?: string
  communication?: RelatedPersonCommunicationArray[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  extension?: Extension[]
  gender?: string
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  name?: HumanName[]
  patient: RelatedPersonPatient
  period?: Period
  photo?: Attachment[]
  relationship?: CodeableConcept[]
  resourceType?: string
  telecom?: ContactPoint[]
  text?: Narrative
}

export interface RelatedPersonCommunicationArray {
  extension?: Extension[]
  id?: string
  language: CodeableConcept
  modifierExtension?: Extension[]
  preferred?: boolean
}

export interface RelatedPersonPatient {
  resourceType?: CandidateResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Medication {
  amount?: Ratio
  batch?: MedicationBatch
  code?: CodeableConcept
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  extension?: Extension[]
  form?: CodeableConcept
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  ingredient?: MedicationIngredientArray[]
  language?: string
  manufacturer?: MedicationManufacturer
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
  status?: string
  text?: Narrative
}

export interface MedicationBatch {
  expirationDate?: string
  extension?: Extension[]
  id?: string
  lotNumber?: string
  modifierExtension?: Extension[]
}

export interface MedicationIngredientArray {
  extension?: Extension[]
  id?: string
  isActive?: boolean
  item?: MedicationIngredientArrayItem
  modifierExtension?: Extension[]
  strength?: Ratio
}

export interface MedicationIngredientArrayItem {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference25
}

export interface Reference25 {
  resourceType?: AmbitiousArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicationManufacturer {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface AppointmentResponse {
  actor?: AppointmentResponseActor
  appointment: AppointmentClass
  comment?: string
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  end?: Date
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  participantStatus: string
  participantType?: CodeableConcept[]
  resourceType?: string
  start?: Date
  text?: Narrative
}

export interface AppointmentResponseActor {
  resourceType?: Array3
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface AppointmentClass {
  resourceType?: AppointmentResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ResearchElementDefinition {
  approvalDate?: string
  author?: ContactDetail[]
  characteristic: ResearchElementDefinitionCharacteristicArray[]
  comment?: string[]
  contact?: ContactDetail[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  copyright?: string
  date?: string
  description?: string
  editor?: ContactDetail[]
  effectivePeriod?: Period
  endorser?: ContactDetail[]
  experimental?: boolean
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  jurisdiction?: CodeableConcept[]
  language?: string
  lastReviewDate?: string
  library?: string[]
  meta?: Meta
  modifierExtension?: Extension[]
  name?: string
  publisher?: string
  purpose?: string
  relatedArtifact?: RelatedArtifact[]
  resourceType?: string
  reviewer?: ContactDetail[]
  shortTitle?: string
  status: string
  subject?: ResearchElementDefinitionSubject
  subtitle?: string
  text?: Narrative
  title?: string
  topic?: CodeableConcept[]
  type: string
  url?: string
  usage?: string
  useContext?: UsageContext[]
  variableType?: string
  version?: string
}

export interface ResearchElementDefinitionCharacteristicArray {
  definition?: ResearchElementDefinitionCharacteristicArrayDefinition
  exclude?: boolean
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  participantEffective?: ResearchElementDefinitionCharacteristicArrayParticipantEffective
  participantEffectiveDescription?: string
  participantEffectiveGroupMeasure?: string
  participantEffectiveTimeFromStart?: Duration
  studyEffective?: ResearchElementDefinitionCharacteristicArrayStudyEffective
  studyEffectiveDescription?: string
  studyEffectiveGroupMeasure?: string
  studyEffectiveTimeFromStart?: Duration
  unitOfMeasure?: CodeableConcept
  usageContext?: UsageContext[]
}

export interface ResearchElementDefinitionCharacteristicArrayDefinition {
  canonical?: string
  CodeableConcept?: CodeableConcept
  DataRequirement?: DataRequirement
  Expression?: Expression
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface ResearchElementDefinitionCharacteristicArrayParticipantEffective {
  dateTime?: string
  Duration?: Duration
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
  Timing?: Timing
}

export interface ResearchElementDefinitionCharacteristicArrayStudyEffective {
  dateTime?: string
  Duration?: Duration
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
  Timing?: Timing
}

export interface ResearchElementDefinitionSubject {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference26
}

export interface Reference26 {
  resourceType?: EnrollmentResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Substance {
  category?: CodeableConcept[]
  code: CodeableConcept
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  description?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  ingredient?: SubstanceIngredientArray[]
  instance?: SubstanceInstanceArray[]
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
  status?: string
  text?: Narrative
}

export interface SubstanceIngredientArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  quantity?: Ratio
  substance?: SubstanceIngredientArraySubstance
}

export interface SubstanceIngredientArraySubstance {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference27
}

export interface Reference27 {
  resourceType?: AdditiveResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SubstanceInstanceArray {
  expiry?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  modifierExtension?: Extension[]
  quantity?: Quantity
}

export interface PaymentReconciliation {
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  created: string
  creditCard?: PaymentReconciliationCreditCard
  detail?: PaymentReconciliationDetailArray[]
  disposition?: string
  extension?: Extension[]
  formCode?: CodeableConcept
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  outcome?: string
  paymentAmount: Money
  paymentDate: string
  paymentIdentifier?: Identifier
  paymentIssuer?: PaymentIssuer
  paymentStatus?: string
  period?: Period
  processNote?: PaymentReconciliationProcessNoteArray[]
  R5_request?: R5Request
  request?: PaymentReconciliationRequest
  requestor?: PaymentReconciliationRequestor
  resourceType?: string
  status: string
  text?: Narrative
}

export interface R5Request {
  resourceType?: R5RequestResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum R5RequestResourceType {
  Invoice = "Invoice",
  Task = "Task",
}

export interface PaymentReconciliationCreditCard {
  billingAddress?: Address
  cardHolderName?: string
  cvv?: string
  expirationMonth?: string
  expirationYear?: string
  extension?: Extension[]
  last4Digits?: string
  modifierExtension?: Extension[]
  number?: string
  text?: string
  token?: Identifier[]
  type?: string
}

export interface PaymentReconciliationDetailArray {
  amount?: Money
  date?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  modifierExtension?: Extension[]
  payee?: DetailPayee
  predecessor?: Identifier
  reason?: PaymentReconciliationDetailArrayReasonArray[]
  request?: Reference
  response?: Reference
  responsible?: DetailResponsible
  submitter?: Submitter
  type: CodeableConcept
}

export interface DetailPayee {
  resourceType?: AuthenticatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface PaymentReconciliationDetailArrayReasonArray {
  extension?: Extension[]
  item?: PaymentReconciliationDetailArrayReasonArrayItemArray[]
  modifierExtension?: Extension[]
  text?: string
}

export interface PaymentReconciliationDetailArrayReasonArrayItemArray {
  amountComponent?: PaymentReconciliationDetailArrayReasonArrayItemArrayAmountComponentArray[]
  extension?: Extension[]
  modifierExtension?: Extension[]
  quantity?: Quantity
  request?: ItemRequest
}

export interface PaymentReconciliationDetailArrayReasonArrayItemArrayAmountComponentArray {
  amount?: Money
  code?: CodeableConcept
  extension?: Extension[]
  factor?: number
  modifierExtension?: Extension[]
  type: string
}

export interface ItemRequest {
  resourceType?: Array33
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface DetailResponsible {
  resourceType?: PractitionerRoleResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum PractitionerRoleResourceType {
  PractitionerRole = "PractitionerRole",
}

export interface Submitter {
  resourceType?: AuthenticatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface PaymentIssuer {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface PaymentReconciliationProcessNoteArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  text?: string
  type?: string
}

export interface PaymentReconciliationRequest {
  resourceType?: Array57
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface PaymentReconciliationRequestor {
  resourceType?: AuthenticatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ConceptMap {
  contact?: ContactDetail[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  copyright?: string
  date?: string
  description?: string
  experimental?: boolean
  extension?: Extension[]
  group?: ConceptMapGroupArray[]
  id?: string
  identifier?: Identifier
  implicitRules?: string
  jurisdiction?: CodeableConcept[]
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  name?: string
  publisher?: string
  purpose?: string
  resourceType?: string
  source?: ConceptMapSource
  status: string
  target?: ConceptMapTarget
  text?: Narrative
  title?: string
  url?: string
  useContext?: UsageContext[]
  version?: string
}

export interface ConceptMapGroupArray {
  element: ConceptMapGroupArrayElementArray[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  source?: string
  sourceVersion?: string
  target?: string
  targetVersion?: string
  unmapped?: ConceptMapGroupArrayUnmapped
}

export interface ConceptMapGroupArrayElementArray {
  code?: string
  display?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  target?: ConceptMapGroupArrayElementArrayTargetArray[]
}

export interface ConceptMapGroupArrayElementArrayTargetArray {
  code?: string
  comment?: string
  dependsOn?: ConceptMapGroupArrayElementArrayTargetArrayArray[]
  display?: string
  equivalence: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  product?: ConceptMapGroupArrayElementArrayTargetArrayArray[]
}

export interface ConceptMapGroupArrayElementArrayTargetArrayArray {
  display?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  property: string
  system?: string
  value: string
}

export interface ConceptMapGroupArrayUnmapped {
  code?: string
  display?: string
  extension?: Extension[]
  id?: string
  mode: string
  modifierExtension?: Extension[]
  url?: string
}

export interface ConceptMapSource {
  canonical?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  uri?: string
}

export interface ConceptMapTarget {
  canonical?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  uri?: string
}

export interface Person {
  active?: boolean
  address?: Address[]
  birthDate?: string
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  extension?: Extension[]
  gender?: string
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  link?: PersonLinkArray[]
  managingOrganization?: PersonManagingOrganization
  meta?: Meta
  modifierExtension?: Extension[]
  name?: HumanName[]
  photo?: Attachment
  resourceType?: string
  telecom?: ContactPoint[]
  text?: Narrative
}

export interface PersonLinkArray {
  assurance?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  target: LinkTarget
}

export interface LinkTarget {
  resourceType?: Array65
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array65 {
  Patient = "Patient",
  Person = "Person",
  Practitioner = "Practitioner",
  RelatedPerson = "RelatedPerson",
}

export interface PersonManagingOrganization {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Condition {
  abatement?: ConditionAbatement
  asserter?: ConditionAsserter
  bodySite?: CodeableConcept[]
  category?: CodeableConcept[]
  clinicalStatus?: CodeableConcept
  code?: CodeableConcept
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  encounter?: ConditionEncounter
  evidence?: ConditionEvidenceArray[]
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  note?: Annotation[]
  onset?: ConditionOnset
  recordedDate?: string
  recorder?: ConditionRecorder
  resourceType?: string
  severity?: CodeableConcept
  stage?: ConditionStageArray[]
  subject: ConditionSubject
  text?: Narrative
  verificationStatus?: CodeableConcept
}

export interface ConditionAbatement {
  Age?: Age
  dateTime?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
  Range?: Range
  string?: string
}

export interface ConditionAsserter {
  resourceType?: ExpressedByResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ConditionEncounter {
  resourceType?: TentacledArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ConditionEvidenceArray {
  code?: CodeableConcept[]
  detail?: Reference[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
}

export interface ConditionOnset {
  Age?: Age
  dateTime?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
  Range?: Range
  string?: string
}

export interface ConditionRecorder {
  resourceType?: ExpressedByResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ConditionStageArray {
  assessment?: ConditionStageArrayAssessmentArray[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  summary?: CodeableConcept
  type?: CodeableConcept
}

export interface ConditionStageArrayAssessmentArray {
  resourceType?: AssessmentResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum AssessmentResourceType {
  ClinicalImpression = "ClinicalImpression",
  DiagnosticReport = "DiagnosticReport",
  Observation = "Observation",
}

export interface ConditionSubject {
  resourceType?: FriskyArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Role {
  context?: RoleContext
  description?: string
  extension?: Extension[]
  id?: string
  links?: RoleLinks
  meta?: Meta
  modifierExtension?: Extension[]
  name: string
  resourceType?: string
  user: RoleUser
}

export interface RoleContext {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface RoleLinks {
  extension?: Extension[]
  modifierExtension?: Extension[]
  organization?: LinksOrganization
  patient?: LinksPatient
  person?: PersonClass
  practitioner?: LinksPractitioner
  practitionerRole?: PractitionerRoleClass
  relatedPerson?: RelatedPersonClass
}

export interface LinksOrganization {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface LinksPatient {
  resourceType?: CandidateResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface PersonClass {
  resourceType?: PersonResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum PersonResourceType {
  Person = "Person",
}

export interface LinksPractitioner {
  resourceType?: PractitionerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface PractitionerRoleClass {
  resourceType?: PractitionerRoleResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface RelatedPersonClass {
  resourceType?: RelatedPersonResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum RelatedPersonResourceType {
  RelatedPerson = "RelatedPerson",
}

export interface RoleUser {
  resourceType?: UserResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CareTeam {
  category?: CodeableConcept[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  encounter?: CareTeamEncounter
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  managingOrganization?: CareTeamManagingOrganizationArray[]
  meta?: Meta
  modifierExtension?: Extension[]
  name?: string
  note?: Annotation[]
  participant?: CareTeamParticipantArray[]
  period?: Period
  reasonCode?: CodeableConcept[]
  reasonReference?: CareTeamReasonReferenceArray[]
  resourceType?: string
  status?: string
  subject?: CareTeamSubject
  telecom?: ContactPoint[]
  text?: Narrative
}

export interface CareTeamEncounter {
  resourceType?: TentacledArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CareTeamManagingOrganizationArray {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CareTeamParticipantArray {
  extension?: Extension[]
  id?: string
  member?: Member
  modifierExtension?: Extension[]
  onBehalfOf?: ParticipantOnBehalfOf
  period?: Period
  role?: CodeableConcept[]
}

export interface Member {
  resourceType?: MemberResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ParticipantOnBehalfOf {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CareTeamReasonReferenceArray {
  resourceType?: ResultingConditionResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CareTeamSubject {
  resourceType?: FriskyArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SubsSubscription {
  channel: SubsSubscriptionChannel
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
  status?: SubsSubscriptionStatus
  trigger?: { [key: string]: SubsSubscriptionTrigger }
}

export interface SubsSubscriptionChannel {
  endpoint: string
  extension?: Extension[]
  headers?: { [key: string]: string }
  heartbeatPeriod?: number
  modifierExtension?: Extension[]
  payload?: SubsSubscriptionChannelPayload
  timeout?: number
  type: ChannelType
}

export interface SubsSubscriptionChannelPayload {
  content?: Content
  contentType?: ContentType
  context?: SubsSubscriptionChannelPayloadContext
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export enum Content {
  FullResource = "full-resource",
  IDOnly = "id-only",
}

export enum ContentType {
  FhirJSON = "fhir+json",
  JSON = "json",
}

export interface SubsSubscriptionChannelPayloadContext {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export enum ChannelType {
  RESTHook = "rest-hook",
}

export enum SubsSubscriptionStatus {
  Active = "active",
  Off = "off",
}

export interface SubsSubscriptionTrigger {
  event?: Event[]
  extension?: Extension[]
  filter?: Filter[]
  modifierExtension?: Extension[]
}

export enum Event {
  All = "all",
  Create = "create",
  Delete = "delete",
  Update = "update",
}

export interface Filter {
  extension?: Extension[]
  fhirpath?: string
  match?: Match[]
  matcho?: Matcho
  modifierExtension?: Extension[]
  search?: string
  type?: FilterType
}

export interface Match {
  extension?: Extension[]
  matchType?: MatchType
  modifierExtension?: Extension[]
  name?: string
  value?: string
}

export enum MatchType {
  Above = "above",
  Below = "below",
  In = "in",
  NotIn = "not-in",
}

export interface Matcho {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export enum FilterType {
  Fhirpath = "fhirpath",
  Matcho = "matcho",
  Search = "search",
}

export interface FlatImportStatus {
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
}

export interface CatalogEntry {
  additionalCharacteristic?: CodeableConcept[]
  additionalClassification?: CodeableConcept[]
  additionalIdentifier?: Identifier[]
  classification?: CodeableConcept[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  lastUpdated?: string
  meta?: Meta
  modifierExtension?: Extension[]
  orderable: boolean
  referencedItem: ReferencedItemClass
  relatedEntry?: CatalogEntryRelatedEntryArray[]
  resourceType?: string
  status?: string
  text?: Narrative
  type?: CodeableConcept
  validityPeriod?: Period
  validTo?: string
}

export interface ReferencedItemClass {
  resourceType?: ReferencedItemResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ReferencedItemResourceType {
  ActivityDefinition = "ActivityDefinition",
  Binary = "Binary",
  Device = "Device",
  HealthcareService = "HealthcareService",
  Medication = "Medication",
  ObservationDefinition = "ObservationDefinition",
  Organization = "Organization",
  PlanDefinition = "PlanDefinition",
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
  SpecimenDefinition = "SpecimenDefinition",
}

export interface CatalogEntryRelatedEntryArray {
  extension?: Extension[]
  id?: string
  item: Item
  modifierExtension?: Extension[]
  relationtype: string
}

export interface Item {
  resourceType?: Array66
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array66 {
  CatalogEntry = "CatalogEntry",
}

export interface Procedure {
  asserter?: ProcedureAsserter
  basedOn?: ProcedureBasedOnArray[]
  bodySite?: CodeableConcept[]
  category?: CodeableConcept
  code?: CodeableConcept
  complication?: CodeableConcept[]
  complicationDetail?: ProcedureComplicationDetailArray[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  encounter?: ProcedureEncounter
  extension?: Extension[]
  focalDevice?: ProcedureFocalDeviceArray[]
  followUp?: CodeableConcept[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  instantiatesCanonical?: string[]
  instantiatesUri?: string[]
  language?: string
  location?: ProcedureLocation
  meta?: Meta
  modifierExtension?: Extension[]
  note?: Annotation[]
  outcome?: CodeableConcept
  partOf?: ProcedurePartOfArray[]
  performed?: ProcedurePerformed
  performer?: ProcedurePerformerArray[]
  reasonCode?: CodeableConcept[]
  reasonReference?: ProcedureReasonReferenceArray[]
  recorder?: ProcedureRecorder
  report?: ProcedureReportArray[]
  resourceType?: string
  status: string
  statusReason?: CodeableConcept
  subject: ProcedureSubject
  text?: Narrative
  usedCode?: CodeableConcept[]
  usedReference?: ProcedureUsedReferenceArray[]
}

export interface ProcedureAsserter {
  resourceType?: ExpressedByResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ProcedureBasedOnArray {
  resourceType?: Array7
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ProcedureComplicationDetailArray {
  resourceType?: ResultingConditionResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ProcedureEncounter {
  resourceType?: TentacledArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ProcedureFocalDeviceArray {
  action?: CodeableConcept
  extension?: Extension[]
  id?: string
  manipulated: Manipulated
  modifierExtension?: Extension[]
}

export interface Manipulated {
  resourceType?: UdiResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ProcedureLocation {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ProcedurePartOfArray {
  resourceType?: Array67
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array67 {
  MedicationAdministration = "MedicationAdministration",
  Observation = "Observation",
  Procedure = "Procedure",
}

export interface ProcedurePerformed {
  Age?: Age
  dateTime?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
  Range?: Range
  string?: string
}

export interface ProcedurePerformerArray {
  actor: HilariousActor
  extension?: Extension[]
  function?: CodeableConcept
  id?: string
  modifierExtension?: Extension[]
  onBehalfOf?: PerformerOnBehalfOf
}

export interface HilariousActor {
  resourceType?: ObserverResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface PerformerOnBehalfOf {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ProcedureReasonReferenceArray {
  resourceType?: Array68
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array68 {
  Condition = "Condition",
  DiagnosticReport = "DiagnosticReport",
  DocumentReference = "DocumentReference",
  Observation = "Observation",
  Procedure = "Procedure",
}

export interface ProcedureRecorder {
  resourceType?: ExpressedByResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ProcedureReportArray {
  resourceType?: ReportResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ReportResourceType {
  Composition = "Composition",
  DiagnosticReport = "DiagnosticReport",
  DocumentReference = "DocumentReference",
}

export interface ProcedureSubject {
  resourceType?: FriskyArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ProcedureUsedReferenceArray {
  resourceType?: UsedReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Consent {
  category: CodeableConcept[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  dateTime?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  organization?: ConsentOrganizationArray[]
  patient?: ConsentPatient
  performer?: ConsentPerformerArray[]
  policy?: ConsentPolicyArray[]
  policyRule?: CodeableConcept
  provision?: ConsentProvision
  resourceType?: string
  scope: CodeableConcept
  source?: ConsentSource
  status: string
  text?: Narrative
  verification?: ConsentVerificationArray[]
}

export interface ConsentOrganizationArray {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ConsentPatient {
  resourceType?: CandidateResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ConsentPerformerArray {
  resourceType?: InformationSourceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ConsentPolicyArray {
  authority?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  uri?: string
}

export interface ConsentProvision {
  action?: CodeableConcept[]
  actor?: ConsentProvisionActorArray[]
  class?: Coding[]
  code?: CodeableConcept[]
  data?: ConsentProvisionDataArray[]
  dataPeriod?: Period
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  period?: Period
  provision?: ConsentProvision[]
  purpose?: Coding[]
  securityLabel?: Coding[]
  type?: string
}

export interface ConsentProvisionActorArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  reference: ActorReference
  role: CodeableConcept
}

export interface ActorReference {
  resourceType?: Array69
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array69 {
  CareTeam = "CareTeam",
  Device = "Device",
  Group = "Group",
  Organization = "Organization",
  Patient = "Patient",
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
  RelatedPerson = "RelatedPerson",
}

export interface ConsentProvisionDataArray {
  extension?: Extension[]
  id?: string
  meaning: string
  modifierExtension?: Extension[]
  reference: Reference
}

export interface ConsentSource {
  Attachment?: Attachment
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: SourceReference
}

export interface SourceReference {
  resourceType?: Array70
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array70 {
  Consent = "Consent",
  Contract = "Contract",
  DocumentReference = "DocumentReference",
  QuestionnaireResponse = "QuestionnaireResponse",
}

export interface ConsentVerificationArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  verificationDate?: string
  verified: boolean
  verifiedWith?: VerifiedWith
}

export interface VerifiedWith {
  resourceType?: SubscriberResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ObservationDefinition {
  abnormalCodedValueSet?: AbnormalCodedValueSet
  category?: CodeableConcept[]
  code: CodeableConcept
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  criticalCodedValueSet?: CriticalCodedValueSet
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  meta?: Meta
  method?: CodeableConcept
  modifierExtension?: Extension[]
  multipleResultsAllowed?: boolean
  normalCodedValueSet?: NormalCodedValueSet
  permittedDataType?: string[]
  preferredReportName?: string
  qualifiedInterval?: ObservationDefinitionQualifiedIntervalArray[]
  quantitativeDetails?: ObservationDefinitionQuantitativeDetails
  resourceType?: string
  text?: Narrative
  url?: string
  validCodedValueSet?: ValidCodedValueSet
  version?: string
}

export interface AbnormalCodedValueSet {
  resourceType?: AbnormalCodedValueSetResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum AbnormalCodedValueSetResourceType {
  ValueSet = "ValueSet",
}

export interface CriticalCodedValueSet {
  resourceType?: AbnormalCodedValueSetResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface NormalCodedValueSet {
  resourceType?: AbnormalCodedValueSetResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ObservationDefinitionQualifiedIntervalArray {
  age?: Range
  appliesTo?: CodeableConcept[]
  category?: string
  condition?: string
  context?: CodeableConcept
  extension?: Extension[]
  gender?: string
  gestationalAge?: Range
  id?: string
  modifierExtension?: Extension[]
  range?: Range
}

export interface ObservationDefinitionQuantitativeDetails {
  conversionFactor?: number
  customaryUnit?: CodeableConcept
  decimalPrecision?: number
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  unit?: CodeableConcept
}

export interface ValidCodedValueSet {
  resourceType?: AbnormalCodedValueSetResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Attribute {
  description?: string
  enum?: string[]
  extension?: Extension[]
  extensionUrl?: string
  id?: string
  isCollection?: boolean
  isModifier?: boolean
  isOpen?: boolean
  isRequired?: boolean
  isSummary?: boolean
  isUnique?: boolean
  meta?: Meta
  modifierExtension?: Extension[]
  module?: string
  order?: number
  path: string[]
  refers?: string[]
  resource: Reference
  resourceType?: string
  schema?: AttributeSchema
  text?: string
  type?: Reference
  union?: Reference[]
  valueSet?: AttributeValueSet
}

export interface AttributeSchema {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface AttributeValueSet {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  resourceType?: string
  uri?: string
}

export interface AidboxWorkflow {
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
}

export interface Location {
  address?: Address
  alias?: string[]
  availabilityExceptions?: string
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  description?: string
  endpoint?: Reference[]
  extension?: Extension[]
  hoursOfOperation?: LocationHoursOfOperationArray[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  managingOrganization?: Reference
  meta?: Meta
  mode?: string
  modifierExtension?: Extension[]
  name?: string
  operationalStatus?: Coding
  partOf?: Reference
  physicalType?: CodeableConcept
  position?: LocationPosition
  resourceType?: "Location"
  status?: string
  telecom?: ContactPoint[]
  text?: Narrative
  timezone?: CodeableConcept
  type?: CodeableConcept[]
}

export interface LocationEndpointArray {
  resourceType?: EndpointResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface LocationHoursOfOperationArray {
  allDay?: boolean
  closingTime?: string
  daysOfWeek?: string[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  openingTime?: string
}

export interface LocationManagingOrganization {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface LocationPartOf {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface LocationPosition {
  altitude?: number
  extension?: Extension[]
  id?: string
  latitude: number
  longitude: number
  modifierExtension?: Extension[]
}

export interface Organization {
  active?: boolean
  address?: Address[]
  alias?: string[]
  contact?: OrganizationContactArray[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  endpoint?: OrganizationEndpointArray[]
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  name?: string
  partOf?: OrganizationPartOf
  resourceType?: string
  telecom?: ContactPoint[]
  text?: Narrative
  type?: CodeableConcept[]
}

export const isOrganization = (
  resource?: any[] | boolean | number | number | null | ResourceObject | string,
): resource is Organization => isResourceObject(resource) && resource.resourceType === "Organization"

export interface OrganizationContactArray {
  address?: Address
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  name?: HumanName
  purpose?: CodeableConcept
  telecom?: ContactPoint[]
}

export interface OrganizationEndpointArray {
  resourceType?: EndpointResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface OrganizationPartOf {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Device {
  contact?: ContactPoint[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  definition?: DeviceDefinitionClass
  deviceName?: DeviceDeviceNameArray[]
  distinctIdentifier?: string
  expirationDate?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  location?: DeviceLocation
  lotNumber?: string
  manufactureDate?: string
  manufacturer?: string
  meta?: Meta
  modelNumber?: string
  modifierExtension?: Extension[]
  note?: Annotation[]
  owner?: Reference
  parent?: DeviceParent
  partNumber?: string
  patient?: DevicePatient
  property?: DevicePropertyArray[]
  resourceType?: string
  safety?: CodeableConcept[]
  serialNumber?: string
  specialization?: DeviceSpecializationArray[]
  status?: string
  statusReason?: CodeableConcept[]
  text?: Narrative
  type?: CodeableConcept
  udiCarrier?: DeviceUdiCarrierArray[]
  url?: string
  version?: DeviceVersionArray[]
}

export interface DeviceDefinitionClass {
  resourceType?: ParentDeviceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface DeviceDeviceNameArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  name: string
  type: string
}

export interface DeviceLocation {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface DeviceOwner {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface DeviceParent {
  resourceType?: UdiResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface DevicePatient {
  resourceType?: CandidateResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface DevicePropertyArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  type: CodeableConcept
  valueCode?: CodeableConcept[]
  valueQuantity?: Quantity[]
}

export interface DeviceSpecializationArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  systemType: CodeableConcept
  version?: string
}

export interface DeviceUdiCarrierArray {
  carrierAIDC?: string
  carrierHRF?: string
  deviceIdentifier?: string
  entryType?: string
  extension?: Extension[]
  id?: string
  issuer?: string
  jurisdiction?: string
  modifierExtension?: Extension[]
}

export interface DeviceVersionArray {
  component?: Identifier
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  type?: CodeableConcept
  value: string
}

export interface Session {
  access_token?: string
  active?: boolean
  audience?: string
  authorization_code?: string
  client?: SessionClient
  ctx?: SessionCtx
  end?: string
  exp?: number
  extension?: Extension[]
  id?: string
  jti?: string
  meta?: Meta
  modifierExtension?: Extension[]
  "on-behalf"?: OnBehalf
  parent?: SessionParent
  refresh_token?: string
  refresh_token_exp?: number
  resourceType?: string
  scope?: string[]
  start?: string
  type?: string
  user?: SessionUser
}

export interface SessionClient {
  resourceType?: ClientResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SessionCtx {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface OnBehalf {
  resourceType?: UserResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SessionParent {
  resourceType?: Array71
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array71 {
  Session = "Session",
}

export interface SessionUser {
  resourceType?: UserResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Search {
  extension?: Extension[]
  format?: string
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  module?: string
  multi?: Multi
  name?: string
  "order-by"?: string
  "param-parser"?: ParamParser
  resource?: Reference
  resourceType?: string
  "token-sql"?: SearchTokenSQL
  where?: string
}

export enum Multi {
  Array = "array",
}

export enum ParamParser {
  Reference = "reference",
  Token = "token",
}

export interface SearchTokenSQL {
  both?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  "no-system"?: string
  "only-code"?: string
  "only-system"?: string
  text?: string
  "text-format"?: string
}

export interface App {
  apiVersion: number
  endpoint?: AppEndpoint
  entities?: { [key: string]: AppEntity }
  extension?: Extension[]
  hooks?: { [key: string]: any }
  id?: string
  meta?: Meta
  migrations?: AppMigrationsArray[]
  modifierExtension?: Extension[]
  operations?: { [key: string]: AppOperation }
  resourceType?: string
  subscriptions?: { [key: string]: AppSubscription }
  type: AppType
}

export interface AppEndpoint {
  extension?: Extension[]
  modifierExtension?: Extension[]
  secret?: string
  type?: EndpointType
  url?: string
}

export enum EndpointType {
  HTTPRPC = "http-rpc",
  Native = "native",
  WsRPC = "ws-rpc",
}

export interface AppEntity {
  attrs?: { [key: string]: Attr }
  description?: string
  extension?: Extension[]
  history?: string
  isOpen?: boolean
  modifierExtension?: Extension[]
  search?: { [key: string]: SearchValue }
}

export interface Attr {
  attrs?: { [key: string]: AttrValue }
  description?: string
  enum?: string[]
  extension?: Extension[]
  extensionUrl?: string
  isCollection?: boolean
  isOpen?: boolean
  isRequired?: boolean
  isUnique?: boolean
  modifierExtension?: Extension[]
  refers?: string[]
  search?: FluffySearch
  type?: string
  value?: FluffyValue
}

export interface AttrValue {
  attrs?: { [key: string]: AttrValue }
  description?: string
  enum?: string[]
  extension?: Extension[]
  extensionUrl?: string
  isCollection?: boolean
  isOpen?: boolean
  isRequired?: boolean
  isUnique?: boolean
  modifierExtension?: Extension[]
  refers?: string[]
  search?: PurpleSearch
  type?: string
  value?: PurpleValue
}

export interface PurpleSearch {
  extension?: Extension[]
  modifierExtension?: Extension[]
  name?: string
  type?: SearchType
}

export enum SearchType {
  Date = "date",
  Datetime = "datetime",
  Number = "number",
  Quantity = "quantity",
  Reference = "reference",
  String = "string",
  Token = "token",
}

export interface PurpleValue {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface FluffySearch {
  extension?: Extension[]
  modifierExtension?: Extension[]
  name?: string
  type?: SearchType
}

export interface FluffyValue {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface SearchValue {
  expression?: any[]
  extension?: Extension[]
  modifierExtension?: Extension[]
  type?: SearchType
}

export interface AppMigrationsArray {
  action: string
  dateTime: string
  id: string
}

export interface AppOperation {
  action?: string
  extension?: Extension[]
  method?: Method
  modifierExtension?: Extension[]
  path?: Array<PathObject | string>
  policies?: { [key: string]: AccessPolicy }
  timeout?: number
}

export enum Method {
  Delete = "DELETE",
  Get = "GET",
  MethodDelete = "delete",
  MethodGet = "get",
  MethodOption = "option",
  MethodPatch = "patch",
  MethodPost = "post",
  MethodPut = "put",
  Option = "OPTION",
  Patch = "PATCH",
  Post = "POST",
  Put = "PUT",
}

export interface PathObject {
  name: any
}

export interface AccessPolicy {
  and?: AccessPolicyAndArray[]
  clj?: string
  description?: string
  engine?: AccessPolicyEngine
  extension?: Extension[]
  id?: string
  link?: AccessPolicyLinkArray[]
  matcho?: AccessPolicyMatcho
  meta?: Meta
  modifierExtension?: Extension[]
  module?: string
  or?: AccessPolicyOrArray[]
  resourceType?: string
  roleName?: string
  rpc?: AccessPolicyRPC
  schema?: AccessPolicySchema
  source?: string
  sql?: AccessPolicySQL
  type?: AccessPolicyType
}

export interface AccessPolicyAndArray {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export enum AccessPolicyEngine {
  Allow = "allow",
  AllowRPC = "allow-rpc",
  Clj = "clj",
  Complex = "complex",
  JSONSchema = "json-schema",
  Matcho = "matcho",
  MatchoRPC = "matcho-rpc",
  SQL = "sql",
  SignedRPC = "signed-rpc",
}

export interface AccessPolicyLinkArray {
  resourceType?: Array20
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array20 {
  Client = "Client",
  Operation = "Operation",
  User = "User",
}

export interface AccessPolicyMatcho {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface AccessPolicyOrArray {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface AccessPolicyRPC {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface AccessPolicySchema {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface AccessPolicySQL {
  extension?: Extension[]
  modifierExtension?: Extension[]
  query?: string
}

export enum AccessPolicyType {
  REST = "rest",
  RPC = "rpc",
  Scope = "scope",
}

export interface AppSubscription {
  extension?: Extension[]
  handler?: string
  modifierExtension?: Extension[]
}

export enum AppType {
  Addon = "addon",
  App = "app",
}

export interface SupplyRequest {
  authoredOn?: string
  category?: CodeableConcept
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  deliverFrom?: DeliverFrom
  deliverTo?: DeliverTo
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  item?: SupplyRequestItem
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  occurrence?: SupplyRequestOccurrence
  parameter?: SupplyRequestParameterArray[]
  priority?: string
  quantity: Quantity
  reasonCode?: CodeableConcept[]
  reasonReference?: SupplyRequestReasonReferenceArray[]
  requester?: SupplyRequestRequester
  resourceType?: string
  status?: string
  supplier?: SupplyRequestSupplierArray[]
  text?: Narrative
}

export interface DeliverFrom {
  resourceType?: OriginResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface DeliverTo {
  resourceType?: DeliverToResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum DeliverToResourceType {
  Location = "Location",
  Organization = "Organization",
  Patient = "Patient",
}

export interface SupplyRequestItem {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference28
}

export interface Reference28 {
  resourceType?: UsedReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SupplyRequestOccurrence {
  dateTime?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
  Timing?: Timing
}

export interface SupplyRequestParameterArray {
  code?: CodeableConcept
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  value?: SupplyRequestParameterArrayValue
}

export interface SupplyRequestParameterArrayValue {
  boolean?: boolean
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Quantity?: Quantity
  Range?: Range
}

export interface SupplyRequestReasonReferenceArray {
  resourceType?: StickyArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SupplyRequestRequester {
  resourceType?: ObserverResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SupplyRequestSupplierArray {
  resourceType?: Array72
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array72 {
  HealthcareService = "HealthcareService",
  Organization = "Organization",
}

export interface AllergyIntolerance {
  asserter?: AllergyIntoleranceAsserter
  category?: string[]
  clinicalStatus?: CodeableConcept
  code?: CodeableConcept
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  criticality?: string
  encounter?: AllergyIntoleranceEncounter
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  lastOccurrence?: string
  meta?: Meta
  modifierExtension?: Extension[]
  note?: Annotation[]
  onset?: AllergyIntoleranceOnset
  patient: AllergyIntolerancePatient
  reaction?: AllergyIntoleranceReactionArray[]
  recordedDate?: string
  recorder?: AllergyIntoleranceRecorder
  resourceType?: string
  text?: Narrative
  type?: string
  verificationStatus?: CodeableConcept
}

export interface AllergyIntoleranceAsserter {
  resourceType?: ExpressedByResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface AllergyIntoleranceEncounter {
  resourceType?: TentacledArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface AllergyIntoleranceOnset {
  Age?: Age
  dateTime?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
  Range?: Range
  string?: string
}

export interface AllergyIntolerancePatient {
  resourceType?: CandidateResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface AllergyIntoleranceReactionArray {
  description?: string
  exposureRoute?: CodeableConcept
  extension?: Extension[]
  id?: string
  manifestation: CodeableConcept[]
  modifierExtension?: Extension[]
  note?: Annotation[]
  onset?: string
  severity?: string
  substance?: CodeableConcept
}

export interface AllergyIntoleranceRecorder {
  resourceType?: ExpressedByResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Setting {
  accessPolicy?: CodeableConcept
  category?: CodeableConcept
  code: Coding
  date?: string
  definition?: SettingDefinitionClass
  description?: string
  extension?: Extension[]
  id?: string
  item?: SettingItemArray[]
  meta?: Meta
  modifierExtension?: Extension[]
  name?: string
  owner: SettingOwner
  resourceType?: string
  scope?: CodeableConcept
  status: string
}

export interface SettingDefinitionClass {
  resourceType?: Array73
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array73 {
  SettingDefinition = "SettingDefinition",
}

export interface SettingItemArray {
  code?: Coding
  description?: string
  extension?: Extension[]
  label?: string
  modifierExtension?: Extension[]
  required?: boolean
  secure?: boolean
  value?: SettingItemArrayValue
  flag?: CodeableConcept[]
}

export interface SettingItemArrayValue {
  Attachment?: Attachment
  boolean?: boolean
  Coding?: Coding
  dateTime?: string
  decimal?: number
  extension?: Extension[]
  integer?: number
  modifierExtension?: Extension[]
  Reference?: Reference
  string?: string
  time?: string
  uri?: string
}

export interface SettingOwner {
  resourceType?: Array27
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface SearchParameter {
  expression: Array<
    Array<Array<{ [key: string]: any } | string> | boolean | number | { [key: string]: any } | null | string>
  >
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  module?: string
  name: string
  resource: Reference
  resourceType?: string
  type: SearchParameterType
}

export enum SearchParameterType {
  Composite = "composite",
  Date = "date",
  Number = "number",
  Quantity = "quantity",
  Reference = "reference",
  String = "string",
  Token = "token",
  URI = "uri",
}

export interface TokenIntrospector {
  extension?: Extension[]
  id?: string
  identity_provider?: IdentityProviderClass
  introspection_endpoint?: TokenIntrospectorIntrospectionEndpoint
  jwks_uri?: string
  jwt?: TokenIntrospectorJwt
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
  type: TokenIntrospectorType
}

export interface IdentityProviderClass {
  resourceType?: IdentityProviderResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum IdentityProviderResourceType {
  IdentityProvider = "IdentityProvider",
}

export interface TokenIntrospectorIntrospectionEndpoint {
  authorization?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  url?: string
}

export interface TokenIntrospectorJwt {
  extension?: Extension[]
  iss?: string
  modifierExtension?: Extension[]
  secret?: string
}

export enum TokenIntrospectorType {
  Jwt = "jwt",
  Opaque = "opaque",
}

export interface ResearchDefinition {
  approvalDate?: string
  author?: ContactDetail[]
  comment?: string[]
  contact?: ContactDetail[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  copyright?: string
  date?: string
  description?: string
  editor?: ContactDetail[]
  effectivePeriod?: Period
  endorser?: ContactDetail[]
  experimental?: boolean
  exposure?: ResearchDefinitionExposure
  exposureAlternative?: ResearchDefinitionExposureAlternative
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  jurisdiction?: CodeableConcept[]
  language?: string
  lastReviewDate?: string
  library?: string[]
  meta?: Meta
  modifierExtension?: Extension[]
  name?: string
  outcome?: ResearchDefinitionOutcome
  population: ResearchDefinitionPopulation
  publisher?: string
  purpose?: string
  relatedArtifact?: RelatedArtifact[]
  resourceType?: string
  reviewer?: ContactDetail[]
  shortTitle?: string
  status: string
  subject?: ResearchDefinitionSubject
  subtitle?: string
  text?: Narrative
  title?: string
  topic?: CodeableConcept[]
  url?: string
  usage?: string
  useContext?: UsageContext[]
  version?: string
}

export interface ResearchDefinitionExposure {
  resourceType?: Array74
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array74 {
  ResearchElementDefinition = "ResearchElementDefinition",
}

export interface ResearchDefinitionExposureAlternative {
  resourceType?: Array74
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ResearchDefinitionOutcome {
  resourceType?: Array74
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ResearchDefinitionPopulation {
  resourceType?: Array74
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ResearchDefinitionSubject {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference29
}

export interface Reference29 {
  resourceType?: EnrollmentResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface OperationDefinition {
  affectsState?: boolean
  base?: string
  code: string
  comment?: string
  contact?: ContactDetail[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  date?: string
  description?: string
  experimental?: boolean
  extension?: Extension[]
  id?: string
  implicitRules?: string
  inputProfile?: string
  instance: boolean
  jurisdiction?: CodeableConcept[]
  kind: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  name: string
  outputProfile?: string
  overload?: OperationDefinitionOverloadArray[]
  parameter?: OperationDefinitionParameterArrayArray[]
  publisher?: string
  purpose?: string
  resource?: string[]
  resourceType?: string
  status: string
  system: boolean
  text?: Narrative
  title?: string
  type: boolean
  url?: string
  useContext?: UsageContext[]
  version?: string
}

export interface OperationDefinitionOverloadArray {
  comment?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  parameterName?: string[]
}

export interface OperationDefinitionParameterArrayArray {
  binding?: OperationDefinitionParameterArrayBinding
  documentation?: string
  extension?: Extension[]
  id?: string
  max: string
  min: number
  modifierExtension?: Extension[]
  name: string
  part?: OperationDefinitionParameterArrayArray[]
  referencedFrom?: OperationDefinitionParameterArrayReferencedFromArray[]
  searchType?: string
  targetProfile?: string[]
  type?: string
  use: string
}

export interface OperationDefinitionParameterArrayBinding {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  strength: string
  valueSet: string
}

export interface OperationDefinitionParameterArrayReferencedFromArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  source: string
  sourceId?: string
}

export interface MedicinalProductManufactured {
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  extension?: Extension[]
  id?: string
  implicitRules?: string
  ingredient?: MedicinalProductManufacturedIngredientArray[]
  language?: string
  manufacturedDoseForm: CodeableConcept
  manufacturer?: MedicinalProductManufacturedManufacturerArray[]
  meta?: Meta
  modifierExtension?: Extension[]
  otherCharacteristics?: CodeableConcept[]
  physicalCharacteristics?: ProdCharacteristic
  quantity: Quantity
  resourceType?: string
  text?: Narrative
  unitOfPresentation?: CodeableConcept
}

export interface MedicinalProductManufacturedIngredientArray {
  resourceType?: IngredientResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicinalProductManufacturedManufacturerArray {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ImagingStudy {
  basedOn?: ImagingStudyBasedOnArray[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  description?: string
  encounter?: ImagingStudyEncounter
  endpoint?: ImagingStudyEndpointArray[]
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  interpreter?: ImagingStudyInterpreterArray[]
  language?: string
  location?: ImagingStudyLocation
  meta?: Meta
  modality?: Coding[]
  modifierExtension?: Extension[]
  note?: Annotation[]
  numberOfInstances?: number
  numberOfSeries?: number
  procedureCode?: CodeableConcept[]
  procedureReference?: ProcedureReferenceClass
  reasonCode?: CodeableConcept[]
  reasonReference?: ImagingStudyReasonReferenceArray[]
  referrer?: ReferrerClass
  resourceType?: string
  series?: ImagingStudySeriesArray[]
  started?: string
  status: string
  subject: ImagingStudySubject
  text?: Narrative
}

export interface ImagingStudyBasedOnArray {
  resourceType?: Array75
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array75 {
  Appointment = "Appointment",
  AppointmentResponse = "AppointmentResponse",
  CarePlan = "CarePlan",
  ServiceRequest = "ServiceRequest",
  Task = "Task",
}

export interface ImagingStudyEncounter {
  resourceType?: TentacledArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ImagingStudyEndpointArray {
  resourceType?: EndpointResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ImagingStudyInterpreterArray {
  resourceType?: CollectorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ImagingStudyLocation {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ProcedureReferenceClass {
  resourceType?: ProcedureReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ImagingStudyReasonReferenceArray {
  resourceType?: Array40
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ReferrerClass {
  resourceType?: CollectorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ImagingStudySeriesArray {
  bodySite?: Coding
  description?: string
  endpoint?: ImagingStudySeriesArrayEndpointArray[]
  extension?: Extension[]
  id?: string
  instance?: ImagingStudySeriesArrayInstanceArray[]
  laterality?: Coding
  modality: Coding
  modifierExtension?: Extension[]
  number?: number
  numberOfInstances?: number
  performer?: ImagingStudySeriesArrayPerformerArray[]
  specimen?: ImagingStudySeriesArraySpecimenArray[]
  started?: string
  uid: string
}

export interface ImagingStudySeriesArrayEndpointArray {
  resourceType?: EndpointResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ImagingStudySeriesArrayInstanceArray {
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  number?: number
  sopClass: Coding
  title?: string
  uid: string
}

export interface ImagingStudySeriesArrayPerformerArray {
  actor: AmbitiousActor
  extension?: Extension[]
  function?: CodeableConcept
  id?: string
  modifierExtension?: Extension[]
}

export interface AmbitiousActor {
  resourceType?: OperatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ImagingStudySeriesArraySpecimenArray {
  resourceType?: SpecimanResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ImagingStudySubject {
  resourceType?: Array76
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array76 {
  Device = "Device",
  Group = "Group",
  Patient = "Patient",
}

export interface CoverageEligibilityRequest {
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  created: string
  enterer?: CoverageEligibilityRequestEnterer
  extension?: Extension[]
  facility?: CoverageEligibilityRequestFacility
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  insurance?: CoverageEligibilityRequestInsuranceArray[]
  insurer: CoverageEligibilityRequestInsurer
  item?: CoverageEligibilityRequestItemArray[]
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  patient: CoverageEligibilityRequestPatient
  priority?: CodeableConcept
  provider?: CoverageEligibilityRequestProvider
  purpose: string[]
  resourceType?: string
  serviced?: CoverageEligibilityRequestServiced
  status: string
  supportingInfo?: CoverageEligibilityRequestSupportingInfoArray[]
  text?: Narrative
}

export interface CoverageEligibilityRequestEnterer {
  resourceType?: CollectorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CoverageEligibilityRequestFacility {
  resourceType?: LocationReferenceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CoverageEligibilityRequestInsuranceArray {
  businessArrangement?: string
  coverage: IndigoCoverage
  extension?: Extension[]
  focal?: boolean
  id?: string
  modifierExtension?: Extension[]
}

export interface IndigoCoverage {
  resourceType?: CoverageResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CoverageEligibilityRequestInsurer {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CoverageEligibilityRequestItemArray {
  category?: CodeableConcept
  detail?: Reference[]
  diagnosis?: CoverageEligibilityRequestItemArrayDiagnosisArray[]
  extension?: Extension[]
  facility?: ItemFacility
  id?: string
  modifier?: CodeableConcept[]
  modifierExtension?: Extension[]
  productOrService?: CodeableConcept
  provider?: StickyProvider
  quantity?: Quantity
  supportingInfoSequence?: number[]
  unitPrice?: Money
}

export interface CoverageEligibilityRequestItemArrayDiagnosisArray {
  diagnosis?: CoverageEligibilityRequestItemArrayDiagnosisArrayDiagnosis
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
}

export interface CoverageEligibilityRequestItemArrayDiagnosisArrayDiagnosis {
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  Reference?: Reference30
}

export interface Reference30 {
  resourceType?: ResultingConditionResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface ItemFacility {
  resourceType?: OriginResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface StickyProvider {
  resourceType?: CollectorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CoverageEligibilityRequestPatient {
  resourceType?: CandidateResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CoverageEligibilityRequestProvider {
  resourceType?: AuthenticatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface CoverageEligibilityRequestServiced {
  date?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
}

export interface CoverageEligibilityRequestSupportingInfoArray {
  appliesToAll?: boolean
  extension?: Extension[]
  id?: string
  information: Reference
  modifierExtension?: Extension[]
  sequence: number
}

export interface MedicinalProductIngredient {
  allergenicIndicator?: boolean
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  implicitRules?: string
  language?: string
  manufacturer?: MedicinalProductIngredientManufacturerArray[]
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
  role: CodeableConcept
  specifiedSubstance?: MedicinalProductIngredientSpecifiedSubstanceArray[]
  substance?: MedicinalProductIngredientSubstance
  text?: Narrative
}

export interface MedicinalProductIngredientManufacturerArray {
  resourceType?: AssignerResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MedicinalProductIngredientSpecifiedSubstanceArray {
  code: CodeableConcept
  confidentiality?: CodeableConcept
  extension?: Extension[]
  group: CodeableConcept
  id?: string
  modifierExtension?: Extension[]
  strength?: MedicinalProductIngredientSStrengthArray[]
}

export interface MedicinalProductIngredientSStrengthArray {
  concentration?: Ratio
  concentrationLowLimit?: Ratio
  country?: CodeableConcept[]
  extension?: Extension[]
  id?: string
  measurementPoint?: string
  modifierExtension?: Extension[]
  presentation: Ratio
  presentationLowLimit?: Ratio
  referenceStrength?: MedicinalProductIngredientSpecifiedSubstanceArrayStrengthArrayReferenceStrengthArray[]
}

export interface MedicinalProductIngredientSpecifiedSubstanceArrayStrengthArrayReferenceStrengthArray {
  country?: CodeableConcept[]
  extension?: Extension[]
  id?: string
  measurementPoint?: string
  modifierExtension?: Extension[]
  strength: Ratio
  strengthLowLimit?: Ratio
  substance?: CodeableConcept
}

export interface MedicinalProductIngredientSubstance {
  code: CodeableConcept
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  strength?: MedicinalProductIngredientSStrengthArray[]
}

export interface GuidanceResponse {
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  dataRequirement?: DataRequirement[]
  encounter?: GuidanceResponseEncounter
  evaluationMessage?: GuidanceResponseEvaluationMessageArray[]
  extension?: Extension[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  language?: string
  meta?: Meta
  modifierExtension?: Extension[]
  module?: GuidanceResponseModule
  note?: Annotation[]
  occurrenceDateTime?: string
  outputParameters?: OutputParameters
  performer?: GuidanceResponsePerformer
  reasonCode?: CodeableConcept[]
  reasonReference?: GuidanceResponseReasonReferenceArray[]
  requestIdentifier?: Identifier
  resourceType?: string
  result?: Result
  status: string
  subject?: GuidanceResponseSubject
  text?: Narrative
}

export interface GuidanceResponseEncounter {
  resourceType?: TentacledArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface GuidanceResponseEvaluationMessageArray {
  resourceType?: DetailsResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface GuidanceResponseModule {
  canonical?: string
  CodeableConcept?: CodeableConcept
  extension?: Extension[]
  modifierExtension?: Extension[]
  uri?: string
}

export interface OutputParameters {
  resourceType?: OutputParametersResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum OutputParametersResourceType {
  Parameters = "Parameters",
}

export interface GuidanceResponsePerformer {
  resourceType?: UdiResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface GuidanceResponseReasonReferenceArray {
  resourceType?: StickyArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Result {
  resourceType?: Array77
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array77 {
  CarePlan = "CarePlan",
  RequestGroup = "RequestGroup",
}

export interface GuidanceResponseSubject {
  resourceType?: FriskyArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface AzureAccount {
  extension?: Extension[]
  id?: string
  key?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
}

export interface LoaderFile {
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
}

export interface Media {
  basedOn?: MediaBasedOnArray[]
  bodySite?: CodeableConcept
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  content: Attachment
  created?: MediaCreated
  device?: MediaDevice
  deviceName?: string
  duration?: number
  encounter?: MediaEncounter
  extension?: Extension[]
  frames?: number
  height?: number
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  issued?: Date
  language?: string
  meta?: Meta
  modality?: CodeableConcept
  modifierExtension?: Extension[]
  note?: Annotation[]
  operator?: Operator
  partOf?: Reference[]
  reasonCode?: CodeableConcept[]
  resourceType?: string
  status: string
  subject?: MediaSubject
  text?: Narrative
  type?: CodeableConcept
  view?: CodeableConcept
  width?: number
}

export interface MediaBasedOnArray {
  resourceType?: Array7
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MediaCreated {
  dateTime?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  Period?: Period
}

export interface MediaDevice {
  resourceType?: Array1
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MediaEncounter {
  resourceType?: TentacledArray
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Operator {
  resourceType?: OperatorResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface MediaSubject {
  resourceType?: Array78
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array78 {
  Device = "Device",
  Group = "Group",
  Location = "Location",
  Patient = "Patient",
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
  Specimen = "Specimen",
}

export interface SearchQuery {
  as?: string
  extension?: Extension[]
  id?: string
  includes?: { [key: string]: SearchQueryInclude }
  limit?: number
  meta?: Meta
  modifierExtension?: Extension[]
  params?: { [key: string]: SearchQueryParam }
  query?: SearchQueryQuery
  resource: Reference
  resourceType?: string
  total?: boolean
}

export interface SearchQueryInclude {
  extension?: Extension[]
  includes?: { [key: string]: SearchQueryInclude }
  modifierExtension?: Extension[]
  path?: Array<Array<{ [key: string]: any } | string> | boolean | number | { [key: string]: any } | null | string>
  resource?: ResourceClass
  reverse?: boolean
  where?: string
}

export interface ResourceClass {
  resourceType?: ResourceResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ResourceResourceType {
  Entity = "Entity",
}

export interface SearchQueryParam {
  /**
   * Anything
   */
  default?: any
  extension?: Extension[]
  format?: string
  includes?: { [key: string]: SearchQueryInclude }
  isRequired?: boolean
  join?: { [key: string]: Join }
  modifierExtension?: Extension[]
  "order-by"?: string
  type?: FluffyType
  where?: string
}

export interface Join {
  by: string
  extension?: Extension[]
  join?: { [key: string]: JoinValue }
  modifierExtension?: Extension[]
  table: string
}

export interface JoinValue {
  by: string
  extension?: Extension[]
  join?: { [key: string]: JoinValue }
  modifierExtension?: Extension[]
  table: string
}

export enum FluffyType {
  Boolean = "boolean",
  Date = "date",
  DateTime = "dateTime",
  Integer = "integer",
  Number = "number",
  Object = "object",
  String = "string",
}

export interface SearchQueryQuery {
  extension?: Extension[]
  join?: { [key: string]: SearchQueryQueryJoin }
  modifierExtension?: Extension[]
  "order-by"?: string
  where?: string
}

export interface SearchQueryQueryJoin {
  by: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  table: string
}

export interface MeasureReport {
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  date?: string
  evaluatedResource?: Reference[]
  extension?: Extension[]
  group?: MeasureReportGroupArray[]
  id?: string
  identifier?: Identifier[]
  implicitRules?: string
  improvementNotation?: CodeableConcept
  language?: string
  measure: string
  meta?: Meta
  modifierExtension?: Extension[]
  period: Period
  reporter?: Reporter
  resourceType?: string
  status: string
  subject?: MeasureReportSubject
  text?: Narrative
  type: string
}

export interface MeasureReportGroupArray {
  code?: CodeableConcept
  extension?: Extension[]
  id?: string
  measureScore?: Quantity
  modifierExtension?: Extension[]
  population?: MeasureReportGroupArrayPopulationArray[]
  stratifier?: MeasureReportGroupArrayStratifierArray[]
}

export interface MeasureReportGroupArrayPopulationArray {
  code?: CodeableConcept
  count?: number
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  subjectResults?: PurpleSubjectResults
}

export interface PurpleSubjectResults {
  resourceType?: SubjectResultsResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum SubjectResultsResourceType {
  List = "List",
}

export interface MeasureReportGroupArrayStratifierArray {
  code?: CodeableConcept[]
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  stratum?: MeasureReportGroupArrayStratifierArrayStratumArray[]
}

export interface MeasureReportGroupArrayStratifierArrayStratumArray {
  component?: MeasureReportGroupArrayStratifierArrayStratumArrayComponentArray[]
  extension?: Extension[]
  id?: string
  measureScore?: Quantity
  modifierExtension?: Extension[]
  population?: MeasureReportGroupArrayStratifierArrayStratumArrayPopulationArray[]
  value?: CodeableConcept
}

export interface MeasureReportGroupArrayStratifierArrayStratumArrayComponentArray {
  code: CodeableConcept
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  value: CodeableConcept
}

export interface MeasureReportGroupArrayStratifierArrayStratumArrayPopulationArray {
  code?: CodeableConcept
  count?: number
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  subjectResults?: FluffySubjectResults
}

export interface FluffySubjectResults {
  resourceType?: SubjectResultsResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export interface Reporter {
  resourceType?: ReporterResourceType
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum ReporterResourceType {
  Location = "Location",
  Organization = "Organization",
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
}

export interface MeasureReportSubject {
  resourceType?: Array79
  display?: string
  extension?: Extension[]
  id?: string
  identifier?: Identifier
  localRef?: string
  modifierExtension?: Extension[]
  resource?: any[] | boolean | number | number | null | ResourceObject | string
  type?: string
  uri?: string
}

export enum Array79 {
  Device = "Device",
  Group = "Group",
  Location = "Location",
  Patient = "Patient",
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
  RelatedPerson = "RelatedPerson",
}

export interface GraphDefinition {
  contact?: ContactDetail[]
  contained?: Array<any[] | boolean | number | number | null | ResourceObject | string>
  date?: string
  description?: string
  experimental?: boolean
  extension?: Extension[]
  id?: string
  implicitRules?: string
  jurisdiction?: CodeableConcept[]
  language?: string
  link?: GraphDefinitionLinkArrayLinkArray[]
  meta?: Meta
  modifierExtension?: Extension[]
  name: string
  profile?: string
  publisher?: string
  purpose?: string
  resourceType?: string
  start: string
  status: string
  text?: Narrative
  url?: string
  useContext?: UsageContext[]
  version?: string
}

export interface GraphDefinitionLinkArrayTargetArray {
  compartment?: GraphDefinitionLinkArrayTargetArrayCompartmentArray[]
  extension?: Extension[]
  id?: string
  link?: GraphDefinitionLinkArrayLinkArray[]
  modifierExtension?: Extension[]
  params?: string
  profile?: string
  type: string
}

export interface GraphDefinitionLinkArrayLinkArray {
  description?: string
  extension?: Extension[]
  id?: string
  max?: string
  min?: number
  modifierExtension?: Extension[]
  path?: string
  sliceName?: string
  target?: GraphDefinitionLinkArrayTargetArray[]
}

export interface GraphDefinitionLinkArrayTargetArrayCompartmentArray {
  code: string
  description?: string
  expression?: string
  extension?: Extension[]
  id?: string
  modifierExtension?: Extension[]
  rule: string
  use: string
}

export interface Entity {
  description?: string
  extension?: Extension[]
  history?: History
  id?: string
  idGeneration?: IDGeneration
  isMeta?: boolean
  isOpen?: boolean
  meta?: Meta
  modifierExtension?: Extension[]
  module?: string
  nonPersistable?: boolean
  resourceType?: string
  schema?: EntitySchema
  sequencePrefix?: string
  text?: string
  type: EntityType
}

export enum History {
  Diff = "diff",
  None = "none",
}

export enum IDGeneration {
  Sequence = "sequence",
  UUID = "uuid",
}

export interface EntitySchema {
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export enum EntityType {
  Abstract = "abstract",
  Primitive = "primitive",
  Resource = "resource",
  Type = "type",
}

export interface ConceptMapRule {
  conceptmapId?: string
  conceptmapUrl?: string
  element?: ConceptMapRuleElement
  extension?: Extension[]
  id?: string
  meta?: Meta
  modifierExtension?: Extension[]
  resourceType?: string
  source?: string
  sourceValueSet?: string
  target?: string
  targetValueSet?: string
  unmapped?: ConceptMapRuleUnmapped
}

export interface ConceptMapRuleElement {
  code?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
  target?: ConceptMapRuleElementTargetArray[]
}

export interface ConceptMapRuleElementTargetArray {
  code?: string
  comment?: string
  display?: string
  equivalence?: string
  extension?: Extension[]
  modifierExtension?: Extension[]
}

export interface ConceptMapRuleUnmapped {
  code?: string
  display?: string
  extension?: Extension[]
  mode?: string
  modifierExtension?: Extension[]
  url?: string
}

export interface RelatedPractitioner {
  practitioner: Reference
  relationType: Coding
}
