import { Form, Formik, FormikProps } from "formik"
import { Reference } from "fhir"
import { Button } from "primereact/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilterSlash } from "@fortawesome/pro-regular-svg-icons"

import { DropdownField } from "commons"

import { OrganizationListState } from "../types"

const OrganizationFilters = ({ initialValues, roots, onSearch, onClearFilters }: Props) => {
  const statusTypes = [
    { code: "true", display: "Active" },
    { code: "false", display: "Deactivated" },
  ]
  const renderForm = ({ isSubmitting }: FormikProps<OrganizationListState>) => (
    <Form className="flex flex-col h-full bg-white">
      <DropdownField
        field="active"
        label="Status"
        optionLabel="display"
        options={statusTypes}
        horizontal
        inputClassName="slashed"
      />
      {!!roots?.length && (
        <DropdownField
          field="root"
          label="Root Organization"
          optionLabel="display"
          options={roots}
          horizontal
          inputClassName="slashed"
          optionValue="id"
        />
      )}
      <div className="flex w-full justify-end pt-3 lg:pt-5 h-12 lg:h-16 bg-white">
        <Button
          type="button"
          label="Clear"
          disabled={isSubmitting || (!initialValues.active && !initialValues.root)}
          icon={<FontAwesomeIcon icon={faFilterSlash} />}
          onClick={onClearFilters}
          className="p-button-sm mr-3 button-default"
        />
        <Button type="submit" label="Search" loading={isSubmitting} className="p-button-sm button-primary" />
      </div>
    </Form>
  )

  return (
    <Formik initialValues={initialValues} onSubmit={onSearch}>
      {renderForm}
    </Formik>
  )
}

type Props = {
  initialValues: OrganizationListState
  roots?: Reference[]
  onSearch(filters: OrganizationListState): void
  onClearFilters(): void
}

export { OrganizationFilters }
