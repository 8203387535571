import { faBars } from "@fortawesome/pro-light-svg-icons"
import { faSearch } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Reference, ServiceRequest, humanNameAsString } from "fhir"
import pluralize from "pluralize"
import { Checkbox } from "primereact/checkbox"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { OverlayPanel } from "primereact/overlaypanel"
import { classNames } from "primereact/utils"
import { FC, useEffect, useId, useRef, useState } from "react"
import { useSearchParams } from "react-router-dom"

import {
  Button,
  EmptyMessage,
  InfiniteScroll,
  SearchInput,
  SearchWithFilters,
  SkeletonLoader,
  StackedListContainer,
  useScreenContext,
} from "commons"
import { formatsByTypes, isLabOrder, isMedicationOrder, isRxOrder } from "data"
import { useOrganization } from "organizations"
import { usePatient } from "patients"
import { useLoginContext } from "security"
import { formatDate } from "utils"

import { useCancelMrOrder, useOrganizationOrders, useRevokeLabOrder } from "../hooks"
import { Order, OrderFilter } from "../types"
import { getCleanDate, getCleanType, getOrderIdentifierText, getStatusesByType, getStatusesClean } from "../utils"
import { OrdersFiltersFormContiner } from "./OrdersFiltersFormContainer"
import { orderModelBuilder } from "./orderModelBuilder"

const NUTRA_VIEW = "nutraceuticals"
const RX_VIEW = "eprescribe"
const DEFAULT_KP_VIEW = "kp=patient-information"
const LAB_VIEW = "labs"

const OrdersView: FC = () => {
  const loaderKey = useId()
  const [params, setParams] = useSearchParams()
  const searchText = params.get("search") ?? undefined
  const type = getCleanType(params.get("type") ?? "") ?? undefined
  const organizationId = params.get("organization") ?? undefined
  const patientId = params.get("patientId") ?? undefined
  const status = getStatusesClean(params.get("status")?.split(",") ?? [], getStatusesByType(type ?? "")) ?? undefined
  const authored = params.get("authored") ? getCleanDate(params.get("authored") ?? "") : undefined
  const occurrence = params.get("occurrence") ? getCleanDate(params.get("occurrence") ?? "") : undefined
  const [patient, setPatient] = useState<Reference>()
  const [organization, setOrganization] = useState<Reference>()
  const { isSmallScreen, setSidebarCollapsed } = useScreenContext()
  const { isRootAdmin } = useLoginContext()

  useEffect(() => {
    status?.length ? params.set("status", status.map((status) => status).join(",")) : params.delete("status")
    !type && params.delete("type")
    !authored && params.delete("authored")
    !occurrence && params.delete("occurrence")
    setParams(params)
  }, [])

  const { orders, count, total, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useOrganizationOrders(
    organizationId,
    type,
    patientId,
    undefined,
    status?.join(","),
    authored,
    occurrence,
    searchText,
  )
  const { patient: patientData, isLoading: isLoadingPatient } = usePatient(patientId, !patient?.display)
  const { organization: org, isLoading: isLoadingOrg } = useOrganization(organizationId)

  useEffect(() => {
    if (!isLoadingPatient) {
      if (patientId && !patient?.display && patientData?.name?.[0]) {
        setPatient({ id: patientId, display: humanNameAsString(patientData?.name?.[0]) })
      } else if (!patient) {
        params.delete("patientId")
        setPatient(undefined)
        setParams(params)
      }
    }
  }, [patient?.display, patientData?.name, patientId, isLoadingPatient])

  useEffect(() => {
    if (!isLoadingOrg) {
      if (organizationId && !organization?.display && org?.organization.name) {
        setOrganization({ id: organizationId, display: org?.organization.name })
      } else if (!organization) {
        params.delete("organization")
        setOrganization(undefined)
        setParams(params)
      }
    }
  }, [organization?.display, org?.organization.name, organizationId, isLoadingOrg])

  const setFilters = ({ searchText, type, patient, organization, status, authored, occurrence }: OrderFilter) => {
    searchText ? params.set("search", encodeURI(searchText)) : params.delete("search")
    type ? params.set("type", type) : params.delete("type")
    patient?.id ? params.set("patientId", patient?.id) : params.delete("patientId")
    organization?.id ? params.set("organization", organization.id) : params.delete("organization")
    status?.length ? params.set("status", status.map((status) => status).join(",")) : params.delete("status")
    authored
      ? params.set("authored", formatDate(authored, formatsByTypes.ISO_8601_DATE) ?? "")
      : params.delete("authored")
    occurrence
      ? params.set("occurrence", formatDate(occurrence, formatsByTypes.ISO_8601_DATE) ?? "")
      : params.delete("occurrence")
    setPatient(patient)
    setOrganization(organization)
    setParams(params)
  }
  const navigate = (domain: string) => {
    window.open(domain, "_blank", "noopener")
  }
  const overlayFilter = useRef<OverlayPanel>(null)
  const [overlaySidebarFilter, setOverlaySidebarFilter] = useState(false)
  const [cancelReason, setCancelReason] = useState("")
  const [orderToCancel, setOrderToCancel] = useState<ServiceRequest>()

  const closeCancelModal = () => {
    setOrderToCancel(undefined)
    setCancelFutureOrder("skip")
  }

  const { cancelMrOrder, isCancelling } = useCancelMrOrder(undefined, closeCancelModal)
  const { revokeLabOrder, isRevoking } = useRevokeLabOrder(undefined, closeCancelModal)

  const [cancelFutureOrder, setCancelFutureOrder] = useState("skip")

  const cancelOrder = () => {
    if (isMedicationOrder(orderToCancel as ServiceRequest))
      cancelMrOrder({
        order: orderToCancel as ServiceRequest,
        cancelReason,
        cancelMode: cancelFutureOrder,
      })
    else revokeLabOrder({ order: orderToCancel as ServiceRequest, revokeReason: cancelReason })
  }

  const showOrder = ({ sr, ehrUrl, orgId }: Order) => {
    const cleanUrl = (str: string) => str.replace(/([^:]\/)\/+/g, "$1")
    let url = `${ehrUrl}/orgs/${orgId}/patients/${sr.subject.id}`

    if (isMedicationOrder(sr)) {
      const view = isRxOrder(sr) ? RX_VIEW : NUTRA_VIEW

      url = cleanUrl(
        `${url}?${DEFAULT_KP_VIEW}&view=${view}&subview=${sr?.status === "active" ? "orders" : "history"}&order=${
          sr?.id
        }`,
      )
    } else if (isLabOrder(sr)) {
      url = cleanUrl(`${url}?${DEFAULT_KP_VIEW}&view=${LAB_VIEW}&order=${sr?.id}`)
    }

    navigate(url)
  }

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  return (
    <>
      <div className="px-6 pt-6 border-b drop-shadow">
        <div className={classNames(" flex", { "justify-between": isSmallScreen })}>
          {isSmallScreen ? (
            <FontAwesomeIcon
              icon={faBars}
              size="lg"
              className="cursor-pointer pt-0.5 hover:text-primary-hover mt-1"
              onClick={() => setSidebarCollapsed(false)}
            />
          ) : null}
          <div className={classNames("ml-2", { "flex flex-col items-end": isSmallScreen })}>
            <h6 className="font-medium text-lg">Orders</h6>
            <p className="text-sm text-gray-500">
              Showing {count} {pluralize("order", count)} of {total} found
            </p>
          </div>
        </div>

        <div className="flex py-4 w-full justify-between">
          <div className="card flex justify-content-center">
            <SearchWithFilters
              showOverlaypanel={overlayFilter}
              showSidebar={overlaySidebarFilter}
              setShowSidebar={setOverlaySidebarFilter}
              filterNone={!type && !patient && !status && !organization && !authored && !occurrence}
              showSearchInput={false}
              customSearchInput={
                <SearchInput
                  className="flex-1"
                  search={(searchText) => {
                    setFilters({
                      searchText: searchText,
                      type,
                      patient,
                      organization,
                      status,
                      authored,
                      occurrence,
                    })
                  }}
                  isLoading={isLoading}
                  autoFocus={false}
                  initialValue={searchText}
                />
              }
              isLoading={isLoading || isFetchingNextPage}
              formContent={
                <OrdersFiltersFormContiner
                  initialValues={{
                    type,
                    patient,
                    organization,
                    searchText,
                    status,
                    authored,
                    occurrence,
                  }}
                  onSearch={(filters) => {
                    setFilters(filters)
                    overlayFilter?.current?.hide()
                    setOverlaySidebarFilter(false)
                  }}
                  onClearFilters={() => {
                    setFilters({})
                    overlayFilter?.current?.hide()
                    setOverlaySidebarFilter(false)
                  }}
                />
              }
            />
          </div>
        </div>
      </div>
      {isLoading || isLoadingOrg || isLoadingPatient ? (
        loader()
      ) : !orders.length ? (
        <EmptyMessage icon={faSearch} message="No Orders Found" subMessage={false} />
      ) : (
        <div className="flex flex-col overflow-auto h-full">
          <InfiniteScroll useWindow={false} hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
            <StackedListContainer
              itemPadding
              data={orders}
              itemModelBuilder={(item) =>
                orderModelBuilder(
                  item,
                  () => showOrder(item),
                  isRootAdmin
                    ? () => {
                        setOrderToCancel(item.sr)
                        setCancelReason("")
                      }
                    : undefined,
                )
              }
            />
          </InfiniteScroll>
          <Dialog
            header={`Cancel order ${getOrderIdentifierText(orderToCancel)}`}
            visible={!!orderToCancel || isCancelling || isRevoking}
            draggable={false}
            style={{ width: "35vw" }}
            onHide={closeCancelModal}
            footer={
              <div className="mt-2">
                <Button
                  label="Cancel order"
                  className="button-primary"
                  disabled={!cancelReason}
                  loading={isCancelling || isRevoking}
                  onClick={cancelOrder}
                />
              </div>
            }
          >
            <label>Please provide a reason</label>
            <InputText className="w-full mt-2" value={cancelReason} onChange={(e) => setCancelReason(e.target.value)} />
            <div className="flex items-center mt-3">
              <Checkbox
                name="status"
                checked={cancelFutureOrder === "stop"}
                onChange={() => setCancelFutureOrder(cancelFutureOrder === "stop" ? "skip" : "stop")}
              />
              <label htmlFor="status" className="p-checkbox-label ml-2">
                Cancel all future orders
              </label>
            </div>
          </Dialog>
        </div>
      )}
    </>
  )
}

export { OrdersView }
