import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Reference } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { patientQueryKey } from "../query-keys"

const useUploadPatients = (onSucces: () => void) => {
  const { uploadFormDataFile } = useClient()
  const queryClient = useQueryClient()

  const uploadPatientsFile = async ({
    file,
    organization,
    practitioner,
  }: {
    file: File
    organization: Reference
    practitioner: Reference
  }) => {
    const formData = new FormData()

    formData.append("organizationId", organization.id ?? "")
    formData.append("practitionerId", practitioner.id ?? "")
    formData.append("csvFile", file)

    return uploadFormDataFile<UploadedFileResponse>("patients/upload/from-csv", formData)
  }
  const {
    mutate: uploadPatients,
    isPending,
    data,
  } = useMutation({
    mutationFn: uploadPatientsFile,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: patientQueryKey.all,
        refetchType: "all",
      })
      onSucces()
      displayNotificationSuccess("Users imported successfully!")
    },
  })
  return { uploadPatients, isUploading: isPending, data }
}

type UploadedFileResponse = {
  parameter: [
    {
      name: "CreatedCount"
      value: {
        positiveInt: number
      }
    },
    {
      name: "UpdatedCount"
      value: {
        positiveInt: number
      }
    },
  ]
}

export { useUploadPatients }
