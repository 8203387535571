import { useQuery } from "@tanstack/react-query"

import { useClient } from "api"

import { OrganizationApi } from "../types"
import { organizationQueryKeys } from "../query-keys"

const useOrganization = (organizationId: string | undefined) => {
  const { read } = useClient()
  const queryKey = organizationQueryKeys.organizationId(organizationId)

  const { data, isLoading, refetch } = useQuery({
    queryKey,
    queryFn: async () => {
      const organization = await read<OrganizationApi>(`organizations/${organizationId}`)

      if (!organization) {
        throw new Error("Not found", { cause: { name: "404", message: "Not organization found" } })
      }

      return organization
    },
    enabled: !!organizationId,
  })

  return { organization: data as OrganizationApi, isLoading, reloadOrganization: refetch }
}

export { useOrganization }
