import { asReference, getResources, Organization, Reference } from "fhir"
import { useQuery } from "@tanstack/react-query"

import { IClient, useClient } from "api"
import { ORGANIZATION_TYPE } from "data"

const useFacilityProvidersOrgs = () => {
  const { search } = useClient(IClient.AIDBOX)
  const queryKey = ["facilityProvidersOrgs"]

  const { data, isLoading, isFetching } = useQuery({
    queryKey,
    queryFn: async () => {
      const filters = new URLSearchParams({
        type: ORGANIZATION_TYPE.FACILITY_PROVIDER,
        _count: "100",
        _sort: "name",
      })

      const bundle = await search("Organization", filters)
      const organizations = getResources<Organization>(bundle, "Organization")

      return { organizations, total: bundle?.total ?? 0 }
    },
  })

  const { organizationRefs } =
    data?.organizations.reduce<{ organizationRefs: Reference[] }>(
      (prev, organization) => {
        return {
          organizationRefs: [...prev.organizationRefs, asReference(organization)],
        }
      },
      { organizationRefs: [] },
    ) ?? {}

  return { organizationRefs, isLoading, isFetching, total: data?.total }
}

export { useFacilityProvidersOrgs }
