import {
  isLabOrder,
  isMedicationOrder,
  LAB_REFERENCE_ID_CODE,
  laboratoryOrderStatusCodes,
  ORDER_STATUS_SYSTEM,
  requestCommonsStatusCodes,
  requestStatusCodes,
  srCategoryCodes,
} from "data"
import { isBefore, isExists, isSameDay, parseISO } from "date-fns"
import { codeableConceptAsString, ServiceRequest } from "fhir"
import { strCapitalize } from "utils"

const getStatusesByType = (type: string) => {
  let statuses = requestCommonsStatusCodes

  if (type === srCategoryCodes["lab-order"].code) {
    statuses = [...statuses, ...laboratoryOrderStatusCodes]
  } else if (type === srCategoryCodes["nutraceutical-order"].code) {
    statuses = [...statuses, ...requestStatusCodes]
  } else if (type === srCategoryCodes["pharmaceutical-order"].code) {
    statuses = [...statuses, ...requestStatusCodes]
  } else {
    statuses = [...statuses, ...laboratoryOrderStatusCodes, ...requestStatusCodes]
  }

  return statuses
}
const getCategory = (category?: string) => {
  switch (category) {
    case "lab-order": {
      return "lab-order"
    }
    case "pharmaceutical-order":
    case "nutraceutical-order": {
      return "medication-order"
    }
    default: {
      return "lab-order,medication-order"
    }
  }
}

const getOrderDetail = (category?: string) => {
  switch (category) {
    case "pharmaceutical-order": {
      return "medication-order"
    }
    case "nutraceutical-order": {
      return "nutraceutical-order"
    }
    default: {
      return undefined
    }
  }
}

const getStatusesClean = (
  status: string[],
  statusCodes: {
    code: string
    display: string
  }[],
) => {
  const result = status?.filter((x) => statusCodes.find((y) => y.code === x))
  return result.length ? result : undefined
}

const getCleanType = (type: string) => {
  const srCategories = Object.keys(srCategoryCodes)
  return srCategories.includes(type) ? type : undefined
}

const getCleanDate = (date: string) => {
  return isExists(parseInt(date.split("-")[0]), parseInt(date.split("-")[1]), parseInt(date.split("-")[2])) &&
    (isBefore(new Date(parseISO(date)), new Date()) || isSameDay(new Date(parseISO(date)), new Date()))
    ? new Date(parseISO(date))
    : undefined
}

const getLabOrderStatus = (labOrder: ServiceRequest) =>
  labOrder?.orderDetail?.find((item) => item?.coding?.[0]?.system === ORDER_STATUS_SYSTEM)?.coding?.[0]

const getLabOrderIdentifier = (orderRoot: ServiceRequest, ordersSplitted: ServiceRequest[]) => {
  let identifiers = "unavailable ID"

  const orderID = orderRoot.identifier?.find(({ type }) =>
    type?.coding?.some((c) => c.code === LAB_REFERENCE_ID_CODE),
  )?.value

  const splittedIdentifers = ordersSplitted.reduce<string[]>((prev, { identifier }) => {
    const id = identifier?.find(({ type }) => type?.coding?.some((c) => c.code === LAB_REFERENCE_ID_CODE))?.value

    if (id) {
      return [...prev, id]
    }

    return prev
  }, [])

  if (orderID !== undefined || splittedIdentifers.length) {
    identifiers = orderID !== undefined ? [orderID, ...splittedIdentifers].join(", ") : splittedIdentifers.join(", ")
  }

  return identifiers
}

const getOrderIdentifierText = (order?: ServiceRequest) => {
  if (!order) return ""

  const orderStatus = isMedicationOrder(order) ? order.status : getLabOrderStatus(order)?.display

  const identifier = isMedicationOrder(order)
    ? order.identifier?.[0]?.value ?? "Unspecified number"
    : isLabOrder(order)
      ? `${strCapitalize(order.performer?.[0]?.display as string)} (${orderStatus !== "draft" ? `${getLabOrderIdentifier(order, [])}` : ""})`
      : codeableConceptAsString(order.code)

  return identifier
}

export {
  getCategory,
  getCleanDate,
  getCleanType,
  getLabOrderIdentifier,
  getLabOrderStatus,
  getOrderDetail,
  getOrderIdentifierText,
  getStatusesByType,
  getStatusesClean,
}
