import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import {
  faUsers,
  faUserAlt,
  faSitemap,
  faBell,
  faUsersCog,
  faUsersMedical,
  faMemoCircleCheck,
} from "@fortawesome/pro-light-svg-icons"
import { useRef, PropsWithChildren } from "react"
import { Link, useMatch, useNavigate, useResolvedPath } from "react-router-dom"
import { Menu } from "primereact/menu"
import { classNames } from "primereact/utils"

import { useAuth, useLoginContext } from "security"

import chIconLogo from "../../images/chIconLogo.webp"
import { ActionType, UserProfileMenu } from "./UserProfileMenu"

const MainNavbar = () => {
  const { user } = useAuth()
  const { isRootAdmin } = useLoginContext()
  const navigate = useNavigate()

  const menu = useRef<Menu>(null)

  const viewProfile = () => {
    navigate(`/practitioner-profile/${user?.practitionerResource?.id}`)
  }

  const userActions: ActionType[] = [{ label: "View Profile", icon: faUserAlt, command: viewProfile }]

  return (
    <div className="flex bg-white border-y border-gray-200 drop-shadow-sm h-14 pt-1.5 pb-0.5 pl-3 pr-7">
      <nav className="flex flex-row flex-1 leading-6">
        <Link to="/" className="flex mx-3 items-center">
          <img src={chIconLogo} alt="app logo" className={"w-8"} />
        </Link>
        <ul className="flex items-center">
          <SidebarLink icon={faSitemap} to={`/organizations`}>
            Organizations
          </SidebarLink>
          <SidebarLink icon={faUsersMedical} to={`/practitioners`}>
            Practitioners
          </SidebarLink>
          <SidebarLink icon={faUsers} to={`/patients`}>
            Patients
          </SidebarLink>
          <SidebarLink icon={faUsersCog} to={`/users`}>
            Users
          </SidebarLink>
          <SidebarLink icon={faMemoCircleCheck} to={`/orders`}>
            Orders
          </SidebarLink>
          {isRootAdmin && (
            <SidebarLink icon={faBell} to={`/subnotifications`}>
              Notifications
            </SidebarLink>
          )}
        </ul>

        <div className="flex flex-1 justify-end">
          <div
            className="flex items-center justify-center hover:bg-gray-50 cursor-pointer w-16"
            onClick={(event) => menu?.current?.toggle(event)}
          >
            <div className="flex items-center justify-center cursor-pointer">
              <UserProfileMenu
                actions={userActions}
                avatarClassName="h-9 w-9"
                className="hover:ring-1 ring-primary-hover rounded-full"
              />
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

const SidebarLink = ({ to, icon, children }: PropsWithChildren<LinkProps>) => {
  const resolved = useResolvedPath(to)
  const match = useMatch({ path: resolved.pathname })

  return (
    <li
      className={classNames("sidebar-link flex h-full", {
        "bg-gray-100": match,
        "hover:bg-gray-50": !match,
      })}
    >
      <Link
        to={to}
        className={classNames("flex items-center text-center px-4 border-b-2", {
          "border-secondary text-secondary": match,
          "text-slate-500 border-transparent": !match,
        })}
      >
        <span className="link-item items-center justify-center text-xl">
          <FontAwesomeIcon icon={icon} className="fa-fw" />
        </span>
        <span className="pl-3">{children}</span>
      </Link>
    </li>
  )
}

type LinkProps = {
  to: string
  icon: IconProp
}

export { MainNavbar }
