import { classNames } from "primereact/utils"
import { FC } from "react"

import defaultUser from "images/default-user.png"
import { getDefaultAvatar } from "utils"

import { SignedAzureImage } from "./SignedAzureImage"

const AvatarImage: FC<Props> = ({ photoUrl, name, className, loadingClassName, staticDefaultUser }) => {
  const bg = document.body.style.getPropertyValue("--color-photo-bg-color")

  const defaultAvatarSource = staticDefaultUser ? defaultUser : getDefaultAvatar(name, bg)
  const fallbackImage = <img src={defaultAvatarSource} className={className} alt="" />

  if (!photoUrl) return fallbackImage

  return (
    <SignedAzureImage
      url={photoUrl}
      imageClassName={classNames("object-cover object-top", className)}
      loadingClassName={loadingClassName}
      fallbackIconNode={fallbackImage}
      alt=""
    />
  )
}

type Props = {
  photoUrl?: string
  name: string
  className?: string
  loadingClassName?: string
  staticDefaultUser?: boolean
}

export { AvatarImage }
