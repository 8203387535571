import { FormikValues } from "formik"
import { isEqual } from "lodash"
import { PropsWithChildren, useEffect, useState } from "react"
import { ObjectSchema } from "yup"
import { AnyObject } from "yup/lib/types"

import { DataContainerSlideoverForm, InformationCardContainer, InfoRow } from "commons"

const InformationCard = <T extends FormikValues>({
  title,
  data,
  isUpdating,
  lineEditData,
  children,
  onSubmit,
  initialValue,
  validationSchema,
}: PropsWithChildren<Props<T>>) => {
  const [showForm, setShowForm] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [formTitle, setFormTitle] = useState(title)
  const [formValue, setFormValue] = useState(initialValue)

  const submit = (data: T) => {
    onSubmit(data)
    setSubmitting(true)
  }

  useEffect(() => {
    if (submitting && !isUpdating) setShowForm(false)
  }, [isUpdating])

  useEffect(() => {
    if (!isEqual(initialValue, formValue)) {
      setFormValue(initialValue)
    }
  }, [initialValue])

  return (
    <InformationCardContainer title={title} onEdit={() => setShowForm(true)} showEdit={!lineEditData}>
      {Object.entries(data).map(([itemTitle, data], index) => (
        <InfoRow
          key={index}
          title={itemTitle}
          content={data}
          loading={isUpdating}
          editable={!!lineEditData}
          onEdit={() => {
            setFormTitle(`${title} - ${itemTitle}`)
            setFormValue(lineEditData?.[index] ?? initialValue)
            setShowForm(true)
          }}
        />
      ))}
      <DataContainerSlideoverForm
        hasData={true}
        showSlide={showForm}
        formInitialValue={formValue}
        onCancel={() => setShowForm(false)}
        form={children}
        formTitle={formTitle}
        onSubmit={submit}
        validationSchema={validationSchema}
        showFab={false}
      ></DataContainerSlideoverForm>
    </InformationCardContainer>
  )
}

type Props<T> = {
  title: string
  data: Record<string, string | JSX.Element>
  isUpdating: boolean
  lineEditData?: T[]
  initialValue: T
  validationSchema: ObjectSchema<AnyObject>
  onSubmit(data: T): void
}

export { InformationCard }
