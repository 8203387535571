import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Account, Address, Extension } from "fhir"
import { AccountBETACreditCardArray } from "fhir/fhir-extended"

import { IClient, useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { organizationQueryKeys, useOrganizationContext } from "organizations"
import { displayNotificationSuccess } from "utils"

import { sanitize } from "../components/validations"
import { creditCardQueryKeys } from "../query-keys"
import { CreditCardFormData } from "../types"
import { useAuthorizeBasisTheorySession } from "./useAuthorizeBasisTheorySession"
import { useBasisTheoryInstance } from "./useBasisTheoryInstance"

const useCreateCreditCard = (onSettled: () => void, onSuccess?: () => void) => {
  const { patch } = useClient(IClient.AIDBOX)
  const queryClient = useQueryClient()
  const { bt } = useBasisTheoryInstance()
  const { authorize } = useAuthorizeBasisTheorySession()
  const { currentOrganizationId } = useOrganizationContext()

  const updateAccount = async ({ creditCard, account, creditCardList, organizationId }: UpdateArgs) => {
    if (!bt) return

    const { sessionKey, nonce } = await bt.sessions.create()
    const { container } = await authorize({
      nonce,
      organizationId: organizationId as string,
    })

    // Delete the previous organization card token from BT to achieve a replacement also at the BT level
    if (organizationId) {
      const tokenId = creditCardList[0]?.pciToken

      if (tokenId) {
        await bt.tokens.delete(tokenId, { apiKey: sessionKey })
      }
    }

    const token = await bt.tokens.create(
      {
        type: "card",
        data: {
          number: creditCard.number?.replace(/-/g, "") as string,
          cvv: creditCard.cvv as string,
          expiration_month: creditCard.expirationMonth as string,
          expiration_year: creditCard.expirationYear as string,
        },
        containers: [container],
      },
      { apiKey: sessionKey },
    )

    const sanitizedCreditCard = sanitize(creditCard, true)
    const creditCardWithPCIToken: AccountBETACreditCardArray<Address, Extension> = {
      ...sanitizedCreditCard,
      pciToken: token.id,
    }

    return patch<Partial<Account>>("Account", account.id as string, {
      // Organization must only have one credit card
      creditCard: [...(organizationId ? [] : creditCardList), creditCardWithPCIToken],
      defaultCreditCard: `${sanitizedCreditCard.type}|${sanitizedCreditCard.last4Digits}`,
    })
  }

  const { mutate: createCreditCard, isPending: isAdding } = useMutation({
    mutationFn: updateAccount,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: creditCardQueryKeys.withOrgId(currentOrganizationId) })
      await queryClient.invalidateQueries({ queryKey: organizationQueryKeys.account.current(currentOrganizationId) })
      onSuccess?.()
      displayNotificationSuccess("Credit card created successfully!")
    },
    onSettled,
    onError: (error: CustomError) => {
      displayNotificationError(registerErrorTrace(error))
    },
  })

  return { createCreditCard, isAdding }
}

type UpdateArgs = {
  creditCard: CreditCardFormData
  account: Account
  creditCardList: AccountBETACreditCardArray<Address, Extension>[]
} & { organizationId: string }

export { useCreateCreditCard }
